import React, { useEffect, useRef } from 'react';
import HeaderTitle from '@/components/HeaderTitle/HeaderTitle';
import KakaoSettingNotiBanner from '@/components/Settings/KakaoSettingNotiBanner';
import { ReactComponent as IcEdit } from '@/assets/images/icons/ic_kakao_edit.svg';
import KakaoChannelMessageGuideField from '@/components/KakaoChannelMessageGuideField/KakaoChannelMessageGuideField';
import KakaoChannelMessageWarningField from '@/components/KakaoChannelMessageGuideField/KakaoChannelMessageWarningField';
import KakaoTextarea from '@/components/KakaoTextarea/KakaoTextarea';
import { useModalStore } from '@/stores/common/useModalStore';
import IconTextButton from '@/components/IconTextButton/IconTextButton';
import KakaoTextImageEdit from '@/components/KakaoTextImageEdit';
import classNames from 'classnames';
import { useKakaoMessageService } from '@/hooks/useKakaoMessageService';
import useKakaoImagePicker from '@/hooks/useKakaoImagePicker';
import { useKakaoTextImageStore } from '@/stores/common/useKakaoTextImageStore';
import { useKakaoAlimtalkStore } from '@/stores/common/useKakaoAlimtalkStore';
import KakaoTextImagePreview from '../KakaoTextImagePreview/KakaoTextImagePreview';

export interface IKakaoMallMessageProps {
  type: string;
  title: string;
  label: string;
  messages: {
    guideMessage?: string;
    initMessage: string;
    warningMessage?: string;
    toastMessage: string;
  };
  initImage?: string;
  textImageIcon: React.FunctionComponent;
}

const KakaoMallMessage = ({
  type,
  title,
  label,
  messages: { toastMessage, guideMessage, initMessage, warningMessage },
  initImage,
  textImageIcon: TextImageIcon,
}: IKakaoMallMessageProps) => {
  const mallType = type.split('-')[1] as 'ad' | 'normal';
  const textImageRef = useRef();
  const { ModifyModal, Editor, showModal, image, imageText } = useKakaoImagePicker({
    title: '가게 대표 이미지',
    editTextLabel: '텍스트 이미지 편집',
    initImage,
    renderEditor: ({ setImageText, close }) => <KakaoTextImageEdit text={imageText} setText={setImageText} closeModal={close} TextImageIcon={TextImageIcon} />,
    textImageRef,
    type: mallType,
  });

  const alimtalk = useKakaoAlimtalkStore((store) => store.alimtalk);

  const { setText } = useKakaoTextImageStore((store) => ({
    setText: (t) => store.setText(mallType, t),
  }));

  const {
    message,
    savable,
    setMessage,
    handleSave: _handleSave,
    handleClose,
    SaveMessageModals,
  } = useKakaoMessageService({
    title: title,
    type,
    image,
    mallInfo: { mallName: alimtalk.mall.mallName, address: alimtalk.mall.address, profileImageUrl: alimtalk.setting.profileImageUrl },
    messages: {
      initMessage: initMessage,
      toastMessage: toastMessage,
      warningMessage: warningMessage,
    },
  });
  const { setMenuModal } = useModalStore();

  useEffect(() => {
    return () => setMenuModal(false);
  }, []);

  const handleSave = () => {
    setText(imageText);
    _handleSave();
  };

  return (
    <div className="kakao-setting-wrapper">
      <HeaderTitle title={title} noBorder={true} onClose={handleClose} />
      <section className={classNames('kakao-setting', `kakao-mall-message`)}>
        <KakaoSettingNotiBanner />
        <div className="kakao-setting-item">
          <div className="kakao-setting-item-title">
            <label>{label}</label>
            <IconTextButton icon={IcEdit} text="수정" onClick={() => showModal()} />
          </div>
          <div>
            {!imageText ? (
              <img src={image} style={{ width: '240px', height: '120px' }} />
            ) : (
              <KakaoTextImagePreview TextImageIcon={TextImageIcon} ref={textImageRef} imageText={imageText} />
            )}
          </div>
        </div>
        <div className="kakao-setting-divider w-full" />
        <div className="kakao-setting-item">
          <div className="kakao-setting-item-title">
            <label className="required">메시지 내용</label>
          </div>
          {guideMessage?.trim() && <KakaoChannelMessageGuideField text={guideMessage} />}
          <KakaoTextarea value={message} setValue={setMessage} maxLength={120} minLength={15} placeholder="메시지를 입력해주세요" />
          {warningMessage?.trim() && <KakaoChannelMessageWarningField text={warningMessage} />}
        </div>
        <div className="kakao-setting-button-wrapper">
          <button className="tblm-btn-secondary" onClick={() => setMenuModal({ visible: true, key: 'reminedMessage' })}>
            미리보기
          </button>
          <button className="tblm-btn-primary" disabled={!savable} onClick={handleSave}>
            변경사항 저장
          </button>
        </div>
      </section>
      <Editor />
      <SaveMessageModals />
      <ModifyModal />
    </div>
  );
};

export default KakaoMallMessage;
