import React, { useEffect, useRef, useState, useMemo } from 'react';
import { ITutorialSelector } from './TutorialSelector.type';
import {} from './TutorialSelector.constants';
import './TutorialSelector.scss';
import { IScrollPicker } from '@/components/Picker/Picker';
import ScrollPicker from '@/components/ScrollPicker/ScrollPicker';

const TutorialSelector = ({ title, unit = [], value, options, onChange, idx }: ITutorialSelector): React.ReactElement => {
  const optionGroups: IScrollPicker['optionGroups'] = {
    0: { list: options[0], unit: unit[0] || '시간' },
    ...(options[1] ? { 1: { list: options[1], unit: unit[1] || '분' } } : {}),
  };
  const [valueGroups, setValueGroups] = useState<IScrollPicker['valueGroups']>({
    0: value && value.length ? value[0] : optionGroups[0].list[0],
    ...(options[1] ? { 1: value && value.length ? value[1] : optionGroups[1].list[0] } : {}),
  });

  useEffect(() => {
    if (value === null) {
      const values = Object.values(valueGroups);
      onChange(values.length === 1 ? values[0] : values);
    }
  }, []);

  const onChangeValue = (key, v) => {
    setValueGroups((state) => Object.assign({}, state, { [key]: v }));
  };
  useEffect(() => {
    const values = Object.values(valueGroups);
    onChange(values.length === 1 ? values[0] : values);
  }, [valueGroups]);
  return (
    <div className="tutorial-selector picker-body">
      <div className="title picker-title">{title}</div>
      <ScrollPicker key={title} optionGroups={optionGroups} valueGroups={valueGroups} onChange={onChangeValue} />
    </div>
  );
};

export default TutorialSelector;
