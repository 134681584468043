import './Agreement.scss';

import React, { useEffect, useRef, useState } from 'react';

import AGREEMENT_LIST from './Agreement.constants';
import AgreementDoc from './AgreementDoc';
import { ReactComponent as Arrow_Right } from '@/assets/images/icons/Arrow_Right.svg';
import { ReactComponent as Close_BoottoMenu } from '@/assets/images/icons/Close_BoottoMenu.svg';
import Grant from './docs/grant';
import Marketing from './docs/marketing';
import Privacy from './docs/privacy';
import { TAgreement } from '@/components/Agreement/Agreement.type';
import Third from './docs/third';
import Tos from './docs/tos';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useSettingTutorialStore } from '@/stores/common/useSettingTutorialStore';
import { useLayoutStore } from '@/stores/common/useLayoutStore';
import { instantDisabler } from '@/shared/lib/functions';
import classNames from 'classnames';
import ScrollShadow from '../ScrollShadow/ScrollShadow';
import useNative from '@/hooks/useNative';

const AGREEMENT_MODAL_DATA = [
  {
    type: 'tos',
    title: '테이블매니저\n서비스 이용약관',
    component: Tos,
  },
  {
    type: 'privacy',
    title: '개인정보 수집 및\n이용 동의',
    component: Privacy,
  },
  {
    type: 'third',
    title: '개인정보\n제3자 제공 동의',
    component: Third,
  },
  {
    type: 'grant',
    title: '개인정보 처리\n위탁 동의',
    component: Grant,
  },
  {
    type: 'marketing',
    title: '홍보 및 마케팅\n수집/이용 동의서',
    component: Marketing,
  },
];

const AgreementWait = ({ onClose }): React.ReactElement => {
  const { setAccessToken, setRefreshToken } = useAuthStore();
  const { setSpinner } = useLayoutStore();
  const { setAgreements } = useSettingTutorialStore();
  const [checked, setChecked] = useState([]);
  const scrollEl = useRef();
  const { callAlert } = useNative();

  const [isDisabled, setIsDisabled] = useState(!false);
  useEffect(() => {
    const essential: TAgreement[] = AGREEMENT_LIST.filter((a) => a.isEssential).map((a) => a.name as TAgreement);

    checked.forEach((item) => {
      if (essential.includes(item)) essential.splice(essential.indexOf(item), 1);
    });

    setIsDisabled(essential.length !== 0);
  }, [checked]);

  useEffect(() => {
    document.querySelector('body').style.overflow = 'hidden';
    return () => {
      document.querySelector('body').style.overflow = 'auto';
    };
  }, []);

  const [doc, setDoc] = useState<TAgreement>(null);

  const handleCheck = (e) => {
    const value = e.target.value;
    setChecked(!checked.includes(value) ? [value, ...checked] : checked.filter((d) => d !== value));
  };
  const allCheck = (e) => {
    setChecked(!e.target.checked ? [] : AGREEMENT_LIST.map((a) => a.name));
  };
  const openDoc = (e) => {
    switch (e) {
      case 'SERVICE_FOR_KT_SECRETARY':
        setDoc('tos');
        break;
      case 'PRIVACY_FOR_KT_SECRETARY':
        setDoc('privacy');
        break;
      case 'PRIVACY_FOR_THIRD_PARTY_FOR_KT_SECRETARY':
        setDoc('third');
        break;
      case 'PRIVACY_CONSIGN_FOR_KT_SECRETARY':
        setDoc('grant');
        break;
      case 'MARKETING_FOR_KT_SECRETARY':
        setDoc('marketing');
        break;
      default:
        setDoc(null);
    }
  };

  const agree = async () => {
    if (isDisabled) return;
    setSpinner(true);
    const notChecked = AGREEMENT_LIST.filter((policya) => {
      return !checked.includes(policya.name);
    }).map((item) => ({ servicePolicyType: item.name, agreement: false }));

    const resultCheckedItem = checked.map((AGREEMENT) => ({ servicePolicyType: AGREEMENT, agreement: true })).concat(notChecked);

    await devServerApi.api
      .post('/terms-agree', { termsAgreements: resultCheckedItem })
      .then((e) => {
        if (e.data.status < 0) {
          throw Error(e.data.message);
        }
        console.log(e.data.accessToken);
        setAccessToken(e.data.accessToken);
        setRefreshToken(e.data.accessToken);
        localStorage.setItem('accessToken', e.data.accessToken);
        localStorage.setItem('refreshToken', e.data.accessToken);

        devServerApi.configure({ headers: { 'x-tm-apigw-token': e.data.accessToken } });
        setAgreements(true);
      })
      .catch((e) => {
        callAlert('오류', e.message, [{ btnNm: '확인', value: 'close' }]);
      });
    setSpinner(false);
    onClose(true);
    return true;
  };

  return (
    <>
      <div className="modal-wrapper menu">
        <div className="modal-dim" onClick={() => onClose(false)} />
        <div className={'modal-content animation agreement'}>
          <div className="agreement_modal_title">
            <span className="agreement_modal_title_span">우리가게 예약/대기 기능 출시!</span>
            <Close_BoottoMenu onClick={() => onClose(false)} />
          </div>
          <ScrollShadow observe={scrollEl} />
          <div className="agreement_modal_content" ref={scrollEl}>
            <div className="agreement_modal_note">
              예약 관리 솔루션 기업 테이블매니저가 LG와 함께 기존 우리가게AI의 예약 기능을 개선했습니다. 업그레이드 된 우리가게AI에서 더욱 편리하고 풍부해진 예약 기능을
              경험해보세요!
            </div>
            <div className="agreement_modal_body">
              <div className="agreement_modal_subtitle">테이블매니저 약관 동의</div>
              <div className="allCheck">
                <label className={checked.length === 7 ? 'allcheck_change_color' : null}>
                  <input type="checkbox" value={'all'} checked={checked.length === 7} onChange={allCheck} />
                  <i />
                  전체선택
                </label>
              </div>
              <ul className="agreement_list">
                {AGREEMENT_LIST.map((item, idx) => (
                  <li key={item.title}>
                    <label>
                      <input type="checkbox" value={item.name} checked={checked.includes(item.name)} onChange={handleCheck} />
                      <i />
                      <p className="agreement_title">
                        <span className={classNames(item.isEssential ? 'essential' : '')}>[{item.isEssential ? '필수' : '선택'}]</span>
                        {item.title}
                      </p>
                    </label>
                    {item.isOpenDoc && <Arrow_Right onClick={() => openDoc(item.name)} />}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <footer className="agreement-btn-wrapper">
            <button className={'list__filter_apply'} disabled={isDisabled} onClick={instantDisabler(agree)}>
              동의하고 시작하기
            </button>
          </footer>
        </div>
        {doc && (
          <div className="modal-wrapper menu">
            {AGREEMENT_MODAL_DATA.map((modal) =>
              doc === modal.type ? (
                <AgreementDoc setIsOpenMarketing={null} isOpenMarketing={null} setDoc={setDoc} key={modal.type}>
                  <div className="agreement-doc-title">{modal.title}</div>
                  <modal.component className={`agreement-doc-body ${modal.type}`} />
                </AgreementDoc>
              ) : null
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AgreementWait;
