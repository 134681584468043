import React, { useEffect, useRef, useState } from 'react';
import { useModalStore } from '@/stores/common/useModalStore';
import Modal from '../Modal/Modal';
import ModalPortal from '../ModalPortal/ModalPortal';
import './Food.scss';
import { initialFoods } from '@/shared/lib/controlInfo';
import ScrollShadow from '../ScrollShadow/ScrollShadow';

function Food({ basicData, setBasicData }) {
  const [defaultFoodState, setDefaultFoodState] = useState(initialFoods);
  const { setMenuModal, setNormalModal } = useModalStore();
  const scrollEl = useRef();

  useEffect(() => {
    if (basicData.foods.length <= 0) {
      setBasicData((data) => ({ foods: [initialFoods[0]], facility: data.facility }));
    }
    setMenuModal({ visible: true, key: 'reservationAgent' });
  }, []);

  const onChangeFoodSelect = (e) => {
    const { value, name } = e.target;
    const food = defaultFoodState[value];
    basicData.foods = [food];
    setBasicData(basicData);
  };

  return (
    <div className="food-list-wrapper">
      <div className="food-title">음식종류</div>
      <ScrollShadow observe={scrollEl} />
      <div className="list__filter_content list__filter_group_list food-list" ref={scrollEl}>
        {defaultFoodState.map((x, index) => {
          return (
            <div key={index}>
              <label className="tblm-rc">
                <input
                  type="radio"
                  name="reservationManager"
                  value={index}
                  defaultChecked={basicData.foods.length <= 0 ? index === 0 : basicData.foods[0].type_food_id === x.type_food_id}
                  onClick={onChangeFoodSelect}
                />
                <i />
                <span>{x.name}</span>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Food;
