import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BottomScrollPicker from '@/components/BottomScrollPicker/BottomScrollPicker';
import { devServerApi } from '@/shared/apis/devServerApi';
import { ReactComponent as ArrowLeft } from '@/assets/images/icons/Arrow_Left.svg';
import { useQuery } from 'react-query';
import queryClient from '@/shared/utils/queryClient';
import '@/styles/base/tailwind.css';
import useNative from '@/hooks/useNative';
const MIN_OPTION_GROUPS = Array.from({ length: 10 }, (_, i) => `${i + 1}명`);

const MAX_OPTION_GROUPS = Array.from({ length: 51 }, (_, i) => {
  if (i === 0) return '제한없음';
  return `${i}명`;
});

interface Form {
  minPeople: string;
  maxPeople: string;
}

const WaitingPeopleLimitSetting: React.FC = () => {
  let location = useLocation();
  // MEMO 기존 세팅값 어디서 받아오나?
  const [isPickerOpen, setIsPickerOpen] = React.useState<{
    min: boolean;
    max: boolean;
  }>({
    min: false,
    max: false,
  });
  const [_form, set_Form] = React.useState<Form>();
  const [form, setForm] = React.useState<Form>();
  const navigate = useNavigate();
  const { callAlert } = useNative();

  React.useEffect(() => {
    let state = location.state;

    if (state) {
      const savedOptions = {
        minPeople: `${state.option.minPeople || 1}명`,
        maxPeople: state.option.maxPeople === null ? '제한없음' : `${state.option.maxPeople ?? 1}명`,
      };
      setForm(savedOptions);
      set_Form(savedOptions);
    }
  }, []);

  const {
    data: optionsResponse,
    refetch,
    isLoading,
  } = useQuery(['waiting_options'], () => devServerApi.api.get('/waiting/option'), {
    onSuccess: ({ data }) => {
      const options = data.option;
      const savedOptions = {
        minPeople: `${options.minPeople || 1}명`,
        maxPeople: options.maxPeople === null ? '제한없음' : `${options.maxPeople ?? 1}명`,
      };
      setForm(savedOptions);
      set_Form(savedOptions);
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  const validate = async () => {
    const minPeople = Number(_form.minPeople.split('명')[0]);
    const maxPeople = _form.maxPeople === '제한없음' ? null : Number(_form.maxPeople.split('명')[0]);
    if (maxPeople !== null && minPeople > maxPeople) {
      callAlert('인원수를 확인해주세요.', `최대 인원수가 최소 인원수보다\n크게 설정되어야 합니다.`, [
        {
          btnNm: '확인',
          value: 'close',
        },
      ]);
      return;
    }
    await devServerApi.api.put('/waiting/option', {
      minPeople: minPeople,
      maxPeople: maxPeople,
      isUsePeopleMinMax: true,
    });
    await queryClient.invalidateQueries(['waiting_options']);
    setForm(_form);
  };
  const changeLimit = (key: keyof Form, value: string) => {
    set_Form((prev) => ({ ...prev, [key]: value }));
  };

  if (isLoading || !form) return null;

  return (
    <div className="min-w-screen min-h-screen flex flex-col">
      <div className="relative py-[15px] justify-center items-center w-full flex">
        <p className="text-zinc-800 text-lg font-bold">인원 제한</p>
        <ArrowLeft className="absolute left-5 w-[20px] h-[20px]" onClick={() => navigate(-1)} />
      </div>
      <div className="flex flex-col flex-1 bg-zinc-100 divide-y divide-gray-100">
        <div className="bg-white px-5 py-6 flex w-full items-center justify-between">
          <div className="flex w-full justify-between items-center">
            <div className="flex flex-col">
              <p className="text-xl text-zinc-800 font-bold mb-[6px]">최소 인원수</p>
              <p className="text-sm text-zinc-500 font-medium whitespace-pre-wrap">몇 명부터 대기를 받을까요?</p>
            </div>
            <div
              className="flex items-center text-lg font-bold text-zinc-800"
              onClick={() => {
                setIsPickerOpen((prev) => ({
                  ...prev,
                  min: true,
                }));
              }}
            >
              <span>{form.minPeople}</span>
            </div>
          </div>
        </div>
        <div className="bg-white px-5 py-6 flex w-full items-center justify-between">
          <div className="flex w-full justify-between items-center">
            <div className="flex flex-col">
              <p className="text-xl text-zinc-800 font-bold mb-[6px]">최대 인원수</p>
              <p className="text-sm text-zinc-500 font-medium whitespace-pre-wrap">몇 명까지 대기를 받을까요?</p>
            </div>
            <div
              className="flex items-center text-lg font-bold text-zinc-800"
              onClick={() => {
                setIsPickerOpen((prev) => ({
                  ...prev,
                  max: true,
                }));
              }}
            >
              <span>{form.maxPeople}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-1 bg-zinc-100 w-full" />
      <BottomScrollPicker
        title="최소 인원수"
        onDismiss={() => {
          setIsPickerOpen((prev) => ({
            ...prev,
            min: false,
          }));
        }}
        onSave={() =>
          validate().then(() => {
            setIsPickerOpen((prev) => ({
              ...prev,
              min: false,
            }));
          })
        }
        value={form.minPeople}
        isOpen={isPickerOpen.min}
        list={MIN_OPTION_GROUPS}
        onSelectedChange={(selected) => changeLimit('minPeople', selected)}
      />
      <BottomScrollPicker
        value={form.maxPeople}
        title="최대 인원수"
        onDismiss={() => {
          setIsPickerOpen((prev) => ({
            ...prev,
            max: false,
          }));
        }}
        onSave={() =>
          validate().then(() => {
            setIsPickerOpen((prev) => ({
              ...prev,
              max: false,
            }));
          })
        }
        isOpen={isPickerOpen.max}
        list={MAX_OPTION_GROUPS}
        onSelectedChange={(selected) => changeLimit('maxPeople', selected)}
      />
    </div>
  );
};

export default WaitingPeopleLimitSetting;
