import { ReactComponent as IcEdit } from '@/assets/images/icons/ic_kakao_edit.svg';
import { ReactComponent as IcWarning } from '@/assets/images/icons/ic_warning.svg';
import HeaderTitle from '@/components/HeaderTitle/HeaderTitle';
import IconTextButton from '@/components/IconTextButton/IconTextButton';
import KakaoInput from '@/components/KakaoInput/KakaoInput';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigate } from 'react-router-dom';
import React, { useId, useMemo, useRef, useState } from 'react';
import HeaderBack from '@/components/HeaderBack/HeaderBack';
import classNames from 'classnames';
import { useKakaoAlimtalkStore } from '@/stores/common/useKakaoAlimtalkStore';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import useKakaoImagePicker from '@/hooks/useKakaoImagePicker';
import { useKakaoTextImageStore } from '@/stores/common/useKakaoTextImageStore';
import KakaoTextProfilePreview from '@/components/KakaoTextProfilePreview/KakaoTextProfilePreview';
import { uploadImage } from '@/shared/utils/kakaoImageUtil';
import useNative from '@/hooks/useNative';

const KakaoChannelEdit = ({ profileText, setProfileText, closeModal }) => {
  const { setAlertModal } = useModalStore();
  const [tmpValue, setTmpValue] = useState(profileText || '');
  const closeConfirmModalId = useId();

  const preventEnter = (e) => {
    // Enter was pressed without shift key
    if (e.keyCode === 13) {
      // prevent default behavior
      e.preventDefault();
    }
  };

  const onChange = (e) => {
    setTmpValue(e.target.value.replaceAll('\n', '').slice(0, 20));
  };
  const cancelChange = () => {
    setTmpValue(profileText);
    closeModal();
  };
  const handleChange = () => {
    if (tmpValue.length < 1) {
      setAlertModal({ visible: true, key: closeConfirmModalId });
    } else {
      if (tmpValue !== profileText) {
        setProfileText(tmpValue);
      }
      closeModal();
    }
  };

  console.debug(tmpValue);

  return (
    <>
      <section className="kakao-setting-wrapper modaled">
        <HeaderBack inBack={true} isCenter noDummy onBack={cancelChange}>
          <div className="font__heading_4">텍스트 프로필 편집</div>
          <div className="font__body_5 color-green" onClick={handleChange}>
            완료
          </div>
        </HeaderBack>
        <div className="kakao-channel-profile-edit">
          <div className="kakao-channel-profile-image-edit dummy">
            <textarea
              rows={6}
              cols={6}
              maxLength={20}
              className={classNames({
                'small-oneline': tmpValue.length === 6,
                twoline: tmpValue.length > 6,
                'small-twoline': tmpValue.length > 12,
                'small-threeline': tmpValue.length > 14,
              })}
              onChange={onChange}
              onKeyDown={preventEnter}
              placeholder="텍스트입력"
              value={tmpValue}
            >
              {tmpValue}
            </textarea>
          </div>
        </div>
      </section>
      <ModalPortal>
        <Modal.Alert key={closeConfirmModalId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__header_title">필수정보를 입력해주세요</div>
          <div className="content font__body_md">{`매장을 대표할수 있는 단어를 
한글자 이상 입력해주세요`}</div>
          <div className="footer font__body_4">
            <div
              onClick={() => {
                setAlertModal(false);
              }}
              className="success"
            >
              확인
            </div>
          </div>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

const KakaoChannel = () => {
  const navigate = useNavigate();
  const { isFetching, setFetching, alimtalk, refreshAlimtalk } = useKakaoAlimtalkStore();
  const { setAlertModal, setToastModal } = useModalStore();
  const ref = useRef();
  const { ModifyModal, Editor, showModal, image, imageText } = useKakaoImagePicker({
    title: '프로필 이미지',
    editTextLabel: '텍스트 프로필 편집',
    initImage: alimtalk.setting.profileImageUrl,
    renderEditor: ({ setImageText, close }) => <KakaoChannelEdit profileText={imageText} setProfileText={setImageText} closeModal={close} />,
    textImageRef: ref,
    type: 'profile',
  });

  const { setText } = useKakaoTextImageStore((store) => ({
    setText: (t) => store.setText('profile', t),
  }));
  const { callAlert } = useNative();

  const profileChanged = useMemo(() => {
    return alimtalk.setting.profileImageUrl !== image;
  }, [image]);

  const save = () => {
    setAlertModal(false);
    setText(imageText);
    Promise.resolve(setFetching(true))
      .then(() => {
        if (profileChanged) {
          return uploadImage(image)
            .then(refreshAlimtalk)
            .then(() => setToastModal({ visible: true, key: 'global', text: '프로필 이미지가 변경되었습니다.' }))
            .catch((error) => {
              callAlert('오류', error, [{ btnNm: '확인', value: 'close' }]);
            });
        }
      })
      .finally(() => setFetching(false))
      .then(() => navigate('/setting/kakao'))
      .catch(() => callAlert('파일 업로드에 실팽하였습니다', '1MB 이하 이미지만 업로드 가능합니다.', [{ btnNm: '닫기', value: 'close' }]));
  };

  // useEffect(() => {
  //   const canvas = document.getElementById('canvas') as HTMLCanvasElement;
  //   const ctx = canvas.getContext('2d');
  //   // ctx.reset();

  //   ctx.fillStyle = '#ffe500';
  //   ctx.fillRect(0, 0, 160, 160);
  //   ctx.fillStyle = '#2e2e30';

  //   const text = '일이삼사오륙칠팔구십십일';
  //   const ditormineFontsize = (t: string): number => {
  //     const length = t.length;
  //     if (length < 6) {
  //       return 28;
  //     } else if (length === 6) {
  //       return 24;
  //     } else if (length <= 12) {
  //       return 24;
  //     } else if (length <= 14) {
  //       return 22;
  //     }
  //     return 22;
  //   };
  //   ctx.font = `700 ${ditormineFontsize(text)}px "Roboto, Noto Sans KR"`;
  //   ctx.textAlign = 'center';
  //   ctx.textBaseline = 'middle';
  //   ctx.fillText(text.slice(0, 6), canvas.width / 2, canvas.height / 2 - 15);
  //   ctx.fillText(text.slice(6), canvas.width / 2, canvas.height / 2 + 15);

  //   ctx.save();

  //   console.log(canvas.toDataURL());
  // }, []);

  return (
    <>
      <div className="kakao-setting-wrapper">
        <HeaderTitle title="내 카카오톡 채널" noBorder={true} onClose={() => navigate(-1)} />
        <section className="kakao-setting kakao-channel">
          <div className="kakao-setting-item">
            <div className="kakao-setting-item-title">
              <label>카카오톡 채널 이름</label>
            </div>
            <KakaoInput value={alimtalk.setting.channelName} disabled={true} />
          </div>
          <div className="kakao-setting-divider w-full" />
          <div className="kakao-setting-item">
            <div className="kakao-setting-item-title">
              <label>프로필 이미지</label>
              <IconTextButton icon={IcEdit} text="수정" onClick={() => showModal()} />
            </div>
            {!imageText ? (
              <img className="kakao-channel-profile-image" src={image} style={{ width: 160, height: 160 }} />
            ) : (
              <KakaoTextProfilePreview ref={ref} imageText={imageText} />
            )}
            {/* {/* <canvas id="canvas" width={160} height={160} style={{ width: 160, height: 160, borderRadius: '60px' }}></canvas> */}
            {/* <img className="kakao-channel-profile-image" src={image} style={{ width: 160, height: 160 }} /> */}
            <div className="kakao-channel-profile-image-warning">
              <IcWarning />
              <span className="text font__s_small">주류(술)은 노출 불가합니다.</span>
            </div>
          </div>
        </section>
        <div className="kakao-setting-button-wrapper">
          <button
            className="tblm-btn-primary"
            onClick={() => {
              profileChanged ? setAlertModal({ visible: true, key: 'save_alert' }) : save();
            }}
          >
            변경사항 저장
          </button>
        </div>
        <Editor />
        <ModifyModal />
        <ModalPortal>
          {/* Alert 공통화 가능 요소 */}
          <Modal.Alert key="save_alert" modal="alert" isAnimation={false} isDim={true}>
            <div className="title font__subtitle">변경사항을 저장할까요?</div>
            <div className="content font__body_6">
              {`*프로필 이미지 수정후 실제 적용까지는 
휴일 제외 3~5일 소요됩니다.
*부적절한 내용이 포함될경우, 심사에 
반려될수 있습니다.`}
            </div>
            <div className="footer font__body_4">
              <div onClick={() => setAlertModal({ visible: false, key: 'save_alert' })}>취소</div>
              <div className="success" onClick={save}>
                변경사항 저장
              </div>
            </div>
          </Modal.Alert>
        </ModalPortal>
      </div>
      <SpinnerEffector loading={isFetching} />
    </>
  );
};

export default KakaoChannel;
