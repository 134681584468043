import React from 'react';
import './SettingItem.scss';
import IconTextButton from '../IconTextButton/IconTextButton';
import { ReactComponent as Edit } from '@/assets/images/icons/Edit.svg';
import { ReactComponent as Add } from '@/assets/images/icons/Add.svg';
import { Link, useNavigate } from 'react-router-dom';

const renderButton = (type: string, to: string, disabled?: boolean) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(to);
  };

  if (type === 'change') {
    return <IconTextButton icon={Edit} text="변경" disabled={disabled} onClick={onClick} />;
  } else if (type === 'register') {
    return <IconTextButton icon={Add} text="등록" theme="purple" disabled={disabled} onClick={onClick} />;
  } else if (type === 'review') {
    return <IconTextButton text="심사중" theme="purple" disabled onClick={onClick} />;
  }
};

const SettingItem = ({ icon: Icon, title, tabletCustomInformationMessage, type, to, disabled = false }) => {
  return (
    <div className="setting-item-container">
      <div className="setting-item-content">
        <div className="setting-item-title-container">
          <div className="setting-item-icon">
            <Icon />
          </div>
          <div className="setting-item-title">{title}</div>
        </div>
        <div className="setting-item-tabletCustomInformationMessage-container">
          <div className="setting-item-tabletCustomInformationMessage">{tabletCustomInformationMessage}</div>
        </div>
      </div>
      <div className="setting-item-interaction-container">{renderButton(type, to, disabled)}</div>
    </div>
  );
};

export default SettingItem;
