const KakaoHiddenArea = ({ hidden, children }) => {
  if (!hidden) {
    return children;
  }

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'white' }} />
      {children}
    </div>
  );
};

export default KakaoHiddenArea;
