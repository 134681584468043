import React, { useEffect, useState } from 'react';
import { ITutorialKakaoChannelProps } from '@/components/TutorialKakaoChannel/TutorialKakaoChannel.type';
import KakaoTextarea from '@/components/KakaoTextarea/KakaoTextarea';
import KakaoChannelMessageGuideField from '@/components/KakaoChannelMessageGuideField/KakaoChannelMessageGuideField';
import KakaoChannelMessageWarningField from '@/components/KakaoChannelMessageGuideField/KakaoChannelMessageWarningField';

const TutorialKakaoChannelMessage = (props: ITutorialKakaoChannelProps) => {
  const { step, dispatcher } = props;

  const [message, setMessage] = useState<string>(
    '이제 카카오톡에서도 손쉽고 빠르게 예약이 가능합니다. 카카오톡으로 실시간 예약가능 여부를 확인하고 예약 즉시 확정까지 진행해보세요.'
  );

  const stringOrNull = (e: string | null): string | null => (e?.length > 0 ? e : null);

  useEffect(() => dispatcher({ type: 'SET_CHANGE', value: stringOrNull(message), step }), [message]);

  return (
    <div className="tutorial-kakao-channel-content-container">
      <h1 className="tutorial-kakao-channel-title">메시지 내용</h1>
      <span className="tutorial-kakao-channel-tabletCustomInformationMessage">고정된 변수를 제외한 영역만 수정 가능합니다.</span>
      <KakaoChannelMessageGuideField
        text={`- 매장명 [(상호명)]
- 영업시간 (00:00) ~ (00:00)
- 매장위치 (매장 위치)
[(상호명)]입니다.`}
      />
      <KakaoTextarea value={message} setValue={setMessage} placeholder="메시지를 입력해주세요." maxLength={120} minLength={15} />
      <KakaoChannelMessageWarningField
        text={`*이후 광고성 메시지가 발송될 수 있습니다.
*해당 메시지는 민생경제 활성화를 위한 소상공인 지원 서비스로 발송되는 가게정보 알림 메시지입니다.`}
      />
    </div>
  );
};

export default TutorialKakaoChannelMessage;
