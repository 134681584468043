const QUERY_KEYS = {
  COMMON_MALL: 'COMMON_MALL',
  LIST_RESERVATIONS: 'LIST_RESERVATIONS',
  LIST_STATE: 'LIST_STATE',
  LIST_CALENDAR: 'LIST_CALENDAR',
  LIST_TERMS_AGREE: 'LIST_TERMS_AGREE',
  LIST_TUTORIAL: 'LIST_TUTORIAL',
  SETTINGS_GROUPS: 'SETTINGS_GROUPS',
  SETTINGS_TABLES: 'SETTINGS_TABLES',
  RESERVATION_CALENDAR: 'RESERVATION_CALENDAR',
  USER_SEARCH: 'USER_SEARCH',
  SETTINGS_MALL: 'SETTINGS_MALL',
  SETTING_POLICY: 'SETTING_POLICY',
  GET_AGREEMENT_STATE: 'GET_AGREEMENT_STATE',
  GET_TUTORIAL_STATE: 'GET_TUTORIAL_STATE',
  GET_RESERVATIONS_DATE: 'GET_RESERVATIONS_DATE',
  SCHEDULE_CALENDAR: 'SCHEDULE_CALENDAR',
  GET_EVENT_DAY: 'GET_EVENT_DAY',
  GET_ROOM_EXISTS: 'GET_ROOM_EXISTS',
  KAKAO_SETTINGS: 'KAKAO_SETTINGS',
};

export default QUERY_KEYS;
