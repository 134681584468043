import React from 'react';
import './KakaoChannelMessageGuideField.scss';

const KakaoChannelMessageGuideField = ({ text = '' }) => {
  return (
    <div className="kakao-channel-message-guide-field">
      {text.split('\n').map((t, i) => (
        <p key={`kakao-channel-message-guide-field-${i}`}>{t}</p>
      ))}
    </div>
  );
};

export default KakaoChannelMessageGuideField;
