import { useEffect } from 'react';
import useNative from './useNative';
import { useNativeStore } from '@/stores/reservation/useNativeStore';

const useBackButton = (backButtonAction: () => void) => {
  const { setBackAction } = useNativeStore();

  useEffect(() => {
    setBackAction(backButtonAction);
    return () => setBackAction(null);
  }, []);
};

export default useBackButton;
