import axios, { AxiosRequestConfig } from 'axios';
import merge from 'lodash/merge';
import { REQUESTS_OPERATION } from '../utils/common.constants';
import { IMutationProps } from '../utils/common.type';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthStore } from '@/stores/common/useAuthStore';





const getRequestUuidFromRes = (res) => {
  return res.data['tm-api-request-uuid'] ? `\ntm-api-request-uuid: ${res.data['tm-api-request-uuid']}` : '';
};

const handleError = (res, stack) => {
  if (res.data.status < 0) {
    throw new Error(`[${res.data.status}] ${res.data.message || `\n${JSON.stringify(res.data, null, 2)}`}${getRequestUuidFromRes(res)}\n${stack}`);
  }
};

const apiBase = function (defaultHosts, defaultConfig: AxiosRequestConfig) {

  const extentions = {
    authGet: function <T>(url: string, config?: AxiosRequestConfig<any>): Promise<T> {
      const stack = new Error('').stack;
      return this.get(url, config).then((res) => {
        handleError(res, stack);
        return res.data as T;
      });
    },
    mutation: function (config: IMutationProps) {
      const { operation, data, headers, url } = config;
      const stack = new Error('').stack;
      switch (operation) {
        case REQUESTS_OPERATION.PATCH:
          return this.patch(url, data).then((res) => {
            handleError(res, stack);
            return res;
          });
        case REQUESTS_OPERATION.POST:
          return this.post(url, { data }, { headers }).then((res) => {
            handleError(res, stack);
            return res;
          });
        case REQUESTS_OPERATION.DELETE:
          return this.delete(url, { headers, data }).then((res) => {
            handleError(res, stack);
            return res;
          });
        default:
          break;
      }
    },
  };



  const api = merge(axios.create({ ...defaultConfig, baseURL: defaultHosts.api }), extentions);
  const lg = merge(axios.create({ ...defaultConfig, baseURL: defaultHosts.lg }), extentions);
  const soho = merge(axios.create({ ...defaultConfig, baseURL: defaultHosts.soho }), extentions);

  function configure(config: AxiosRequestConfig) {
    merge(api.defaults, config);
    merge(lg.defaults, config);
    merge(soho.defaults, config);
  }

  return { configure, api, lg, soho };
};

export default apiBase;
