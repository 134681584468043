import { create } from 'zustand';
import { Dayjs } from 'dayjs';
import { IReservation, TReservationState } from '@/shared/utils/common.type';

interface IWeekCalendar {
  viewDate: Dayjs;
  setViewDate: (arg0: Dayjs) => void;
}

interface IAllReservations {
  allReservation: IReservation[];
  setAllReservation: (arg0: IReservation[]) => void;
}

type routeActionType = 'back' | null;

export interface IReservationFilter {
  status: TReservationState[];
  group: string;
}

interface INativeStore extends IWeekCalendar, IAllReservations {
  reservationDetailId: number | null;
  setReservationDetailId: (arg0: number | null) => void;
  routeAction: routeActionType;
  setRouteAction: (arg0: routeActionType) => void;
  filter: IReservationFilter;
  setFilter: (arg0: IReservationFilter) => void;
  requestReservationEnable: boolean;
  setRequestReservationEnable: (arg0: boolean) => void;
  backAction: (() => void) | null;
  setBackAction: (arg0: () => void) => void;
}

const initialVariables = {
  viewDate: null,
  allReservation: [],
  reservationDetailId: null,
  routeAction: null,
  filter: {
    status: ['valid', 'enter'],
    group: 'all',
  },
  requestReservationEnable: false,
  backAction: null,
};

export const useNativeStore = create<INativeStore>((set) => ({
  ...initialVariables,
  setViewDate: (arg0: Dayjs) => set({ viewDate: arg0 }),
  setAllReservation: (arg0: IReservation[]) => set({ allReservation: arg0 }),
  setReservationDetailId: (arg0: number | null) => set({ reservationDetailId: arg0 }),
  setRouteAction: (arg0: routeActionType) => set({ routeAction: arg0 }),
  setFilter: (arg0: IReservationFilter) => set({ filter: arg0 }),
  setRequestReservationEnable: (arg0: boolean) => set({ requestReservationEnable: arg0 }),
  setBackAction: (arg0: () => void) => set({ backAction: arg0 }),
}));
