import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModalStore } from '../stores/common/useModalStore';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Modal from '@/components/Modal/Modal';
import { IKakaoMallInfo, useKakaoAlimtalkStore } from '../stores/common/useKakaoAlimtalkStore';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useLayoutStore } from '../stores/common/useLayoutStore';
import { ReactComponent as KakaoSettingWaitingPreviewIcon } from '@/assets/images/icons/kakao_setting_waiting_preview_icon.svg';
import { uploadImage } from '@/shared/utils/kakaoImageUtil';
import useNative from '@/hooks/useNative';
import { AxiosResponse } from 'axios';

const MallMessagePreview = ({ type, image, message, mallInfo = undefined, warningMessage }) => {
  return (
    <>
      <div>고객님이 처음 전화를 걸면 자동으로 보내주는 정보성 알림입니다.</div>
      <div className="kakaotalk-message-area">
        <div className="kakaotalk-message-profile">
          <img src={mallInfo.profileImageUrl} />
        </div>
        <div className="kakaotalk-message-container">
          <div className="kakaotalk-message-nickname">{mallInfo.mallName}</div>
          <div className="kakaotalk-message">
            <div className="kakaotalk-message-header">
              <img src={image} />
            </div>
            <div className="kakaotalk-message-body-container">
              {type === 'ourmall-normal' && (
                <>
                  <div className="kakaotalk-message-body-title">{mallInfo.mallName}</div>
                  <div className="divider w-full" />
                  <div className="kakaotalk-message-body-info">
                    <ul>
                      <li>
                        <span>매장명</span>
                        <span>{mallInfo.mallName}</span>
                      </li>
                      {/* <li>
                      <span>이용시간</span>
                      <span>18:00 ~ 22:00</span>
                    </li> */}
                      <li>
                        <span>주소</span>
                        <span>{mallInfo.address}</span>
                      </li>
                    </ul>
                  </div>
                </>
              )}
              <div className="kakaotalk-message-body-content">
                {`${message ?? ''}
                ${warningMessage ?? ''}`}
              </div>
              <div className="kakaotalk-message-body-footer">
                <div className="button secondary">예약하기</div>
                <div className="button primary">채널 추가</div>
              </div>
            </div>
          </div>
          <div className="noti-off">가게정보알림 OFF</div>
        </div>
      </div>
    </>
  );
};

const whenMessageSended = {
  'reservation-create': '예약 생성시',
  'reservation-alarm': '예약 당일',
  'reservation-change': '예약 수정시',
  'reservation-cancel': '예약 취소시',
  'waiting-create': '대기 등록시',
};
const hollInfoMessage = {
  'waiting-waiting': '고객님의 입장이 가까워졌을때 자동으로 보내주는 알림 입니다.',
  'waiting-remind': '대기 호출시 매장 입장을 요청하는 알림입니다.',
  'waiting-cancel': '매장에서 대기 강제 취소시 전송되는 안내입니다.',
};

const MessagePreview = ({ image: Image, type, message, warningMessage, guideMessage }) => {
  return (
    <>
      <div className="font__body_6 color-grey-66">{hollInfoMessage[type] ? hollInfoMessage[type] : `${whenMessageSended[type]} 전송되는 안내입니다.`}</div>
      <div className="kakaotalk-notitalk">
        <div className="kakaotalk-notitalk-container">
          <div className="kakaotalk-notitalk-title-container">
            <div style={{ color: '#191919' }} className="font__header_title">
              알림톡 도착
            </div>
          </div>
          {Image && (
            <div className="kakaotalk-message-header">
              <Image />
            </div>
          )}
          <div className="kakaotalk-notitalk-message-container">
            {type === 'waiting-create' && (
              <>
                <div className="kakaotalk-message-create-header">
                  <span>대기 접수완료</span>
                </div>
                <hr />
                <div className="kakaotalk-message-create-waiting-number">
                  <div>
                    <div className="waiting-number">(00)번</div>
                    <div className="waiting-number-label">대기번호</div>
                  </div>
                  <KakaoSettingWaitingPreviewIcon style={{ margin: 8 }} />
                </div>
                <hr />
                <div className="kakaotalk-message-body-info">
                  <ul>
                    <span>매장명</span>
                    <span>테이블매니저</span>
                    <span>접수인원</span>
                    <span>(00)명</span>
                    <span>내 앞 대기팀</span>
                    <span>(00)팀</span>
                  </ul>
                </div>
              </>
            )}
            <div className="kakaotalk-notitalk-message">
              {`${guideMessage ?? ''}
              

              ${message ?? ''}
              
              
              ${warningMessage ?? ''}`.trim()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export interface IKakaoMessageServiceProps {
  title: string;
  type: string;
  mallInfo?: IKakaoMallInfo & { profileImageUrl: string };
  messages: {
    guideMessage?: string;
    initMessage: string;
    warningMessage?: string;
    toastMessage: string;
  };
  image?: string;
}

export function useKakaoMessageService({
  title: previewTitle,
  type,
  mallInfo = undefined,
  messages: { guideMessage = '', initMessage, warningMessage = '', toastMessage },
  image = undefined,
}: IKakaoMessageServiceProps) {
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>(initMessage);
  const { setMenuModal, alertModal, setAlertModal, setToastModal } = useModalStore();
  const { setSpinner } = useLayoutStore();
  const { refreshAlimtalk, alimtalk } = useKakaoAlimtalkStore();
  const { callAlert } = useNative();

  const savable = useMemo(() => message && message.length >= 15, [message]);

  const handleSave = () => {
    const types = type.split('-');
    const isSaveAlert = alertModal.visible && alertModal.key === 'save_alert';
    const isMessageChanged = message !== initMessage;
    const isImageChnaged = alimtalk[types[0]][types[1]].imageUrl !== image;
    if (!isMessageChanged && !isImageChnaged) {
      navigate(-1);
    } else if (isSaveAlert) {
      setMenuModal(false);
      setAlertModal(false);

      // immerAlimtalk((alimtalk) => {
      //   const config = alimtalk[types[0]][types[1]];
      //   config.customMessage = message;
      //   if (types[0] === 'ourmall') config.imageUrl = image;
      // });
      const postData = {};
      postData[types[0]] = {
        type: types[1],
        customMessage: message,
      };

      Promise.resolve(setSpinner(true))
        .then(() => {
          const promises: Promise<void | AxiosResponse<any, any>>[] = [devServerApi.lg.post('/api/partner/kakao/alimtalk/setting', postData)];
          if (isImageChnaged && typeof image === 'string' && image.startsWith('data:image')) {
            promises.push(uploadImage(image, types[1] as 'ad' | 'normal'));
          }
          return Promise.allSettled(promises);
        })
        .then((result) => {
          setSpinner(false);

          if (result.length > 1 && result[1].status === 'rejected') {
            callAlert('파일 업로드에 실팽하였습니다', '1MB 이하 이미지만 업로드 가능합니다.', [{ btnNm: '닫기', value: 'close' }]);
            throw Error('File Upload fail');
          }
          if (result[0].status === 'fulfilled') {
            return refreshAlimtalk();
          }
        })
        .then(() => setToastModal({ visible: true, key: 'global', text: toastMessage }))
        .then(() => navigate(-1))
        .catch((error) => {
          console.error('오류', error);
          // callAlert('오류', error, [{ btnNm: '확인', value: 'close' }]);
        });
    } else {
      setAlertModal({ visible: true, key: 'save_alert' });
    }
  };

  const handleClose = () => {
    message === initMessage ? navigate(-1) : setAlertModal({ visible: true, key: 'empty_alert' });
  };

  const SaveMessageModals = () => (
    <ModalPortal>
      <Modal.Menu
        key="reminedMessage"
        modal="menu"
        isDim={true}
        isAnimation={true}
        title={previewTitle}
      // onClose={() => {
      //   setDialog(-1);
      // }}
      >
        <div className="content kakao-alimtalk">
          {type.startsWith('ourmall') ? (
            <MallMessagePreview type={type} image={image} mallInfo={mallInfo} message={message} warningMessage={warningMessage} />
          ) : (
            <MessagePreview type={type} {...{ image, message, warningMessage, guideMessage }} />
          )}
        </div>
        <div className="picker-footer">
          <button className="set-button tblm-button-normal tblm-btn-primary" disabled={!savable} onClick={handleSave}>
            변경사항 저장
          </button>
        </div>
      </Modal.Menu>
      <Modal.Alert key="save_alert" modal="alert" isAnimation={false} isDim={true}>
        <div className="title font__subtitle">변경사항을 저장할까요?</div>
        <div className="content font__body_6">{`*프로필 이미지 수정후 실제 적용까지는 
휴일 제외 3~5일 소요됩니다.
*부적절한 내용이 포함될경우, 심사에 
반려될수 있습니다.`}</div>
        <div className="footer font__body_4">
          <div onClick={() => setAlertModal({ visible: false, key: 'save_alert' })}>취소</div>
          <div className="success" onClick={handleSave}>
            변경사항 저장
          </div>
        </div>
      </Modal.Alert>
      <Modal.Alert key="empty_alert" modal="alert" isAnimation={false} isDim={true}>
        <div className="title font__subtitle">변경된 내용이 있습니다.</div>
        <div className="content font__body_6">{`나가기 선택시 변경된 내용이
삭제됩니다.`}</div>
        <div className="footer font__body_4">
          <div
            onClick={() => {
              setAlertModal({ visible: false, key: 'empty_alert' });
              navigate(-1);
            }}
          >
            나가기
          </div>
          <div className="success" onClick={() => setAlertModal({ visible: false, key: 'empty_alert' })}>
            취소
          </div>
        </div>
      </Modal.Alert>
    </ModalPortal>
  );

  return {
    message,
    savable,
    setMessage,
    handleClose,
    handleSave,
    SaveMessageModals,
  };
}
