import React, { useEffect, useId, useMemo, useRef, useState } from 'react';
import { ITutorialReservation } from './TutorialReservation.type';
import {} from './TutorialReservation.constants';
import './TutorialReservation.scss';
import classnames from 'classnames';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Picker from '@/components/Picker/Picker';
import ReceiveReservation from '@/components/ReceiveReservation';
import ScrollNote from '@/components/ScrollNote/ScrollNote';
import { timeFilter } from '@/shared/lib/functions';
import { Nullable } from '@/shared/utils/common.type';
import { useMallStore } from '@/stores/common/useMallStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useReservationSettingStore } from '@/stores/common/useReservationSettingStore';
import { ReactComponent as Arrow_Right } from '@/assets/images/icons/Arrow_Right.svg';
//import classnames from 'classnames';

const initialTimeUnitList = [
  { label: '10분', value: 10, selected: true },
  { label: '30분', value: 30, selected: false },
  { label: '1시간', value: 60, selected: false },
];

const daylist = ['월', '화', '수', '목', '금', '토', '일'];
const daylistMap = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const TutorialReservation = ({ question, dispatcher }: ITutorialReservation): React.ReactElement => {
  const { storageQuestion, setStorageQuestion } = useReservationSettingStore();
  const { mallTime } = useMallStore();
  const mallTimeMap = daylistMap.map((d) => mallTime[d]);
  // @ts-ignore
  const storedValue: {
    enabledTimeList: any;
    copyList: any;
    dayIndex: any;
    showType: any;
    ynList: any;
    step: any;
  } = storageQuestion.step3_0.value || {
    enabledTimeList: daylist.map((key) => [key, []]),
    copyList: daylist.map((key) => [key, []]),
    dayIndex: 0,
    showType: 0,
    ynList: daylist.map((key) => [key, null]),
    step: [],
  };

  useEffect(() => {
    setStorageQuestion(question);
  }, [question]);

  useEffect(() => {
    const timeList = JSON.parse(JSON.stringify(storedValue.enabledTimeList));
    for (const dayIndex in storedValue.copyList) {
      let day = storedValue.copyList[dayIndex];
      for (const i of day[1]) {
        timeList[i][1] = [...timeList[dayIndex][1]];
      }
    }
    for (const timeIdx in timeList) {
      timeList[timeIdx][1] = timeFilter(timeList[timeIdx][1], mallTimeMap[timeIdx]);
    }
    storageQuestion.step3_0.value = {
      // @ts-ignore
      enabledTimeList: timeList,
      copyList: daylist.map((key) => [key, []]),
      dayIndex: 0,
      showType: 0,
      ynList: daylist.map((key) => [key, null]),
      step: [],
    };
    setStorageQuestion(storageQuestion);
  }, []);

  const { setToastModal, setAlertModal, setMenuModal } = useModalStore();
  const [showScrollnote, setShowScrollnote] = useState(true);

  const [dialog, setDialog] = useState(-1);

  useEffect(() => {
    if (dialog === -1) document.querySelector('body').style.overflow = 'auto';
  }, [dialog]);

  useEffect(() => {
    switch (dialog) {
      case 0:
        setMenuModal({ visible: true, key: 'reminedMessage' });
        break;
      case 1:
        // eslint-disable-next-line no-use-before-define
        setMenuModal({ visible: true, key: timeSelectModalId });
        break;
      case 2:
        break;
      case 3:
        break;
      default:
        setToastModal(false);
        setAlertModal(false);
        setMenuModal(false);
        break;
    }
  }, [dialog]);

  // 당일예약받는시간
  const [visibleModalOnSameDayReservation, setVisibleModalOnSameDayReservation] = useState(false);
  const [visiblePickerOnReceiveReservation, setVisiblePickerOnReceiveReservation] = useState(false);

  const alertModalId = useId();
  const timeSelectModalId = useId();
  const weekSelectModalId = useId();

  const handleOnPickerSuccessOnReceiveReservation = (selected) => {
    setAlertModal({ visible: true, key: alertModalId });
    setVisiblePickerOnReceiveReservation(false);
    dispatcher({ type: 'SET_CHANGE', value: selected.leftArea, step: 'step2_2' });
  };

  // 최대이용시간
  const handleOnPickerSuccessOnMaxHoursUse = (selected) => {
    setDialog(-1);
    dispatcher({ type: 'SET_CHANGE', value: selected.leftArea, step: 'step2_3' });
  };
  // 예약신청가능기간
  const handleOnPickerSuccessOnAvailableReservation = (selected) => {
    dispatcher({ type: 'SET_CHANGE', value: selected.leftArea, step: 'step4_0' });
    setDialog(-1);
  };

  const unitize = (option, unit, fix = ' 전') => {
    if (unit === '층') return `${option}층`.replace('-', '지하 ');
    if (unit === '시간분') {
      const h = Math.floor(option / 60);
      const m = Math.floor(option % 60);

      let ret = '';
      ret += h > 0 ? h + '시간' : '';
      ret += h > 0 && m > 0 ? ' ' : '';
      ret += m > 0 ? m + '분' : '';

      if (h === 0 && m === 0) return '제한없음';

      return ret + fix;
    }
    return option + unit;
  };
  const until = question.step2_0.value && question.step2_1.value ? unitize(question.step2_1.value[0] * 60 + question.step2_1.value[1], '시간분') : null;

  /// For Dialog
  // eslint-disable-next-line no-nested-ternary
  const initState = !question.step1_0.value ? 3 : question.step2_0.value ? 1 : 2;
  const [reminedMessage, setReminedMessage] = useState(initState);

  const handleOnPickerSuccessOnSameDayReservation = (selected) => {
    setDialog(-1);
    setVisibleModalOnSameDayReservation(false);
    setReminedMessage(1);
    dispatcher({ type: 'SET_CHANGE', value: true, step: 'step1_0' });
    dispatcher({ type: 'SET_CHANGE', value: true, step: 'step2_0' });
    dispatcher({ type: 'SET_CHANGE', value: [selected.leftArea, selected.rightArea], step: 'step2_1' });
  };

  const commitRemindMessage = () => {
    if (reminedMessage === 1) {
      // dispatcher({ type: 'SET_CHANGE', value: true, step: 'step2_0' });
      setReminedMessage(initState);
      if (!storageQuestion.step2_1.value) {
        dispatcher({ type: 'SET_CHANGE', value: [0, 0], step: 'step2_1' });
      }
      setVisibleModalOnSameDayReservation(true);
    } else {
      dispatcher({ type: 'SET_CHANGE', value: reminedMessage !== 3, step: 'step1_0' });
      if (reminedMessage === 2) {
        dispatcher({ type: 'SET_CHANGE', value: false, step: 'step2_0' });
        setDialog(-1);
      }
      if (reminedMessage === 3) setDialog(-1);
    }
  };

  // @ts-ignore
  return (
    <>
      <ul className="tutorial-reservation">
        <li>
          <div>
            <span>당일 예약</span>
            <em className="possible-value">{question.step1_0.value ? '가능' : '불가능'}</em>
            <span>당일 예약 마감시간</span>
            {/* eslint-disable-next-line no-nested-ternary */}
            <em className="possible-value">{question.step1_0.value ? (question.step1_0.value && question.step2_0.value ? `${until}` : '제한없음') : '-'}</em>
          </div>
          <div className="modify" onClick={() => setDialog(0)}>
            <Arrow_Right />
          </div>
        </li>
        <li>
          <div>
            <span>최대 이용 시간</span>
            <em className="possible-value">{unitize(question.step2_3.value, '시간분', ' ')}</em>
          </div>
          <div className="modify" onClick={() => setDialog(2)}>
            <Arrow_Right />
          </div>
        </li>
        <li>
          <div>
            <span>예약 신청 가능 기간</span>
            <em className="possible-value">{question.step4_0.value}일 후까지</em>
          </div>
          <div className="modify" onClick={() => setDialog(3)}>
            <Arrow_Right />
          </div>
        </li>
        <li className="timelist">
          <div>
            <span>예약 받는 시간</span>
            <div className="modify" onClick={() => setDialog(1)}>
              <Arrow_Right />
            </div>
          </div>
          <ul>
            {/* @ts-ignore */}
            {storageQuestion.step3_0.value.enabledTimeList
              .filter((time) => time[1].length !== 0)
              .map(([day, list]) => (
                <li key={day}>
                  <p>{day}</p>
                  <div className="reservation-time-tag-wrapper">
                    {list.map((t) => (
                      <button className="reservation-time-tag" key={`${day}_${t}`}>
                        {t}
                      </button>
                    ))}
                  </div>
                </li>
              ))}
          </ul>
        </li>
      </ul>
      {/*{showScrollnote ? <ScrollNote onScroll={() => setShowScrollnote(false)}/> : null}*/}
      {/*당일예약받는시간*/}
      {dialog === 0 ? (
        <>
          {!visibleModalOnSameDayReservation ? (
            <ModalPortal>
              <Modal.Menu
                key="reminedMessage"
                modal="menu"
                isDim={true}
                isAnimation={true}
                onClose={() => {
                  setReminedMessage(initState);
                  setDialog(-1);
                }}
                title="당일 예약"
              >
                <div className="content">
                  당일 예약을 받을까요?
                  <div className="list__filter_content list__filter_group_list">
                    <div>
                      <label className="tblm-rc">
                        <input type="radio" checked={reminedMessage === 1} name="filter_group" value={1} onChange={() => setReminedMessage(1)} />
                        <i />
                        <span>네. 대신 당일 마감시간을 둘게요.</span>
                      </label>
                    </div>
                    <div>
                      <label className="tblm-rc">
                        <input type="radio" checked={reminedMessage === 2} name="filter_group" value={2} onChange={() => setReminedMessage(2)} />
                        <i />
                        <span>네. 제한 없이 받을게요.</span>
                      </label>
                    </div>
                    <div>
                      <label className="tblm-rc">
                        <input type="radio" checked={reminedMessage === 3} name="filter_group" value={3} onChange={() => setReminedMessage(3)} />
                        <i />
                        <span>아니요</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="picker-footer">
                  <button className="set-button tblm-button-normal tblm-btn-primary" onClick={commitRemindMessage}>
                    완료
                  </button>
                </div>
              </Modal.Menu>
            </ModalPortal>
          ) : (
            <Picker
              title="당일 예약 마감시간"
              tabletCustomInformationMessage="최소한 언제까지는 예약해야 하나요?"
              modalKey="same-day-reservation-picker-modal"
              visible={visibleModalOnSameDayReservation}
              leftArea={{ list: Array.from({ length: 7 }, (_, i) => i), unit: '시간', selected: question.step2_1.value ? question.step2_1.value[0] : 0 }}
              rightArea={{ list: Array.from({ length: 6 }, (_, i) => i * 10), unit: '분전', selected: question.step2_1.value ? question.step2_1.value[1] : 0 }}
              onSuccess={handleOnPickerSuccessOnSameDayReservation}
              onClose={() => {
                setDialog(-1);
                setVisibleModalOnSameDayReservation(false);
              }}
            />
          )}
        </>
      ) : null}
      {/*예약받는시간*/}
      {dialog === 1 ? (
        <ReceiveReservation
          commit={() => {
            setDialog(-1);
          }}
        />
      ) : null}
      {/*최대이용시간*/}
      {dialog === 2 ? (
        <Picker
          title="최대 이용 시간"
          tabletCustomInformationMessage="테이블 당 최대 이용 시간은 몇 시간으로 할까요?"
          modalKey="max-hours-use-reservation-picker-modal"
          visible={dialog === 2}
          leftArea={{ list: [30, 60 * 1, 60 * 1.5, 60 * 2, 60 * 3, 60 * 4], unit: '시간분', selected: Number(question.step2_3.value) }}
          onSuccess={handleOnPickerSuccessOnMaxHoursUse}
          onClose={() => setDialog(-1)}
        />
      ) : null}
      {/*예약신청가능기간*/}
      {dialog === 3 ? (
        <Picker
          title="예약 신청 가능 기간"
          tabletCustomInformationMessage="오늘부터 며칠 후까지 예약을 열어둘까요?"
          modalKey="available-reservation-picker-modal"
          visible={dialog === 3}
          leftArea={{ list: [7, 14, 30, 60, 90], unit: '일', selected: Number(question.step4_0.value) }}
          onSuccess={handleOnPickerSuccessOnAvailableReservation}
          onClose={() => setDialog(-1)}
        />
      ) : null}
    </>
  );
};

export default TutorialReservation;
