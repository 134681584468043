import { ReactComponent as Close } from '@/assets/images/icons/Close.svg';
import { IModalProps } from '@/components/Modal/Modal.type';
import { useModalStore } from '@/stores/common/useModalStore';
import classNames from 'classnames';

const Alert = ({ isDim, classKey = undefined, isAnimation, children, onClose, modalData }: IModalProps) => {
  const { alertModal, setAlertModal } = useModalStore();

  if (!alertModal.visible) return <></>;

  return (
    <div className={`modal-wrapper alert ${isAnimation ? 'animation' : ''} ${classKey}`}>
      {isDim ? (
        <div
          className="modal-dim"
          onClick={() => {
            if (classKey !== 'alert-error') {
              onClose ? onClose() : setAlertModal(false);
            }
          }}
        />
      ) : null}
      <div className="modal-content">
        {modalData ? (
          <>
            <div className="title font__header_title">{modalData.title}</div>
            <div className="content font__body_md">{modalData.content}</div>
            <div className="footer font__body_4">
              {modalData.buttons.map((button) => (
                <div onClick={button.onClick} className={classNames({ success: button.success })}>
                  {button.text}
                </div>
              ))}
            </div>
          </>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default Alert;
