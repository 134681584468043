import React from 'react';
import { Form } from '@/pages/waiting/tutorial';
import { ReactComponent as Message } from '@/assets/images/icons/Message.svg';
import AlarmTalk from '@/assets/images/waiting/AlarmTalk.png';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

interface TutorialStep3Props {
  form: Form;
  setForm: (key: keyof Form, value: any) => () => void;
  goNext: () => Promise<void>;
}

const TutorialStep3: React.FC<TutorialStep3Props> = ({ form, setForm, goNext }) => {
  return (
    <>
      <div className="p-5 flex flex-col mb-[40px]">
        <p className="text-zinc-800 text-[28px] font-medium mb-[8px] leading-10">
          매장 입장 순서가
          <br />
          가까워진 고객에게
          <br />
          메시지를 보낼까요?
        </p>
      </div>
      <div className="p-5 flex flex-col gap-y-2">
        <button
          className={classNames(
            'w-full border justify-center items-center rounded p-5',
            form.useCustomEnterWaitingAlarm === true ? 'border-violet-700 text-violet-700 bg-indigo-50' : 'border-stone-300 text-zinc-500 bg-white'
          )}
          onClick={ () =>{
            setForm('useCustomEnterWaitingAlarm', true)();
            goNext();
          }}
        >
          네
        </button>
        <button
          className={classNames(
            'w-full border justify-center items-center rounded p-5',
            form.useCustomEnterWaitingAlarm === false ? 'border-violet-700 text-violet-700 bg-indigo-50' : 'border-stone-300 text-zinc-500 bg-white'
          )}
          onClick={() => {
            setForm('useCustomEnterWaitingAlarm', false)();
            goNext();
            goNext();
          }}
        >
          아니오
        </button>
      </div>
      <div className="p-5 bg-zinc-100 flex-col">
        <div className="flex items-center mb-[14px]">
          <Message className="w-6 h-6 mr-1" />
          <span className="text-zinc-800 font-bold align-middle">입장 대기 알림이란?</span>
        </div>
        <p className="text-violet-950 text-sm font-bold mb-[4px]">{`입장 순서가 가까워진 고객에게 자동으로 입장 대기 요청 메시지를 보내드려요.`}</p>
        <p className="text-zinc-500 text-sm mb-[10px]">{`설정은 채널 생성후 우리가게 > 우리가게 AI 탭 > 대기 설정에서 바꿀 수 있어요`}</p>
        <div className="px-10 pb-[80px]">
          <img src={AlarmTalk} alt="알림톡 설정" className="w-full" />
        </div>
      </div>
    </>
  );
};

export default TutorialStep3;
