import { Link } from 'react-router-dom';
import { ReactComponent as IcNext } from '@/assets/images/icons/ic_next.svg';

const KakaoSettingMenu = ({ title, tabletCustomInformationMessage, to, check = undefined }) => {
  return (
    <>
      <Link className="kakao-setting-menu" to={to} onClick={check}>
        <div className="kakao-setting-menu-content">
          <h1 className="title">{title}</h1>
          <h2 className="tabletCustomInformationMessage">{tabletCustomInformationMessage}</h2>
        </div>
        <IcNext />
      </Link>
    </>
  );
};

export default KakaoSettingMenu;
