import Banner from '@/assets/images/kakao/preview_banner.png';
import BannerMiddle from '@/assets/images/kakao/preview_banner_middle.png';
import BannerLarge from '@/assets/images/kakao/preview_banner_large.png';
import List from '@/assets/images/kakao/preview_list.png';
import ListMiddle from '@/assets/images/kakao/preview_list_middle.png';
import ListLarge from '@/assets/images/kakao/preview_list_large.png';
import './KakaoPreview.scss';
import { useEffect, useState } from 'react';
import { useKakaoAlimtalkStore } from '@/stores/common/useKakaoAlimtalkStore';

const KakaoPreview = ({ state, onClick }) => {
  const { alimtalk, refreshAlimtalk } = useKakaoAlimtalkStore();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [bannerImage, setBannerImage] = useState(Banner);
  const [bannerList, setBannerList] = useState(List);
  const [showSpan, setShowSpan] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (windowWidth < 744) {
      setBannerImage(Banner);
      setBannerList(List);
    } else if (windowWidth >= 744 && windowWidth < 1180) {
      setBannerImage(BannerMiddle);
      setBannerList(ListMiddle);
    } else {
      setBannerImage(BannerLarge);
      setBannerList(ListLarge);
    }
  }, [windowWidth]);

  useEffect(() => {
    refreshAlimtalk();
  }, []);

  return (
    <div className="kakao-preview">
      <div>
        <img src={bannerImage} />
      </div>
      <div>
        {showSpan && (
          <span>
            주요 서비스<span className="kakao-dot">.</span>
          </span>
        )}
        <img src={bannerList} />
      </div>
      {alimtalk.product.reservationYn === 'N' && alimtalk.product.waitingYn === 'N' ? (
        <></>
      ) : (
        <button className="reservation-off-wrapper-submit-btn" onClick={onClick}>
          설정하기
        </button>
      )}
    </div>
  );
};

export default KakaoPreview;
