import { create } from 'zustand';
import { produce } from 'immer';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useEffect } from 'react';
import useNative from '@/hooks/useNative';
import { useQuery } from 'react-query';

interface IKakaoProductSetting {
  waitingYn: string;
  reservationYn: string;
}

export interface IKakaoMallInfo {
  mallName: string;
  address: string;
}

interface IAlimtalkSetting {
  existsChannel: boolean;
  channelName: string;
  profileImageUrl?: string;
  toggleOurMall: boolean;
  toggleKakaoSendMsg: boolean;
}

interface ICustomMessage {
  id: string;
  customMessage: string;
  imageUrl?: string;
  use?: boolean;
}

interface ICustomMessageGroup {
  use: boolean;
  [key: string]: any & ICustomMessage;
}

const initialAlimtalk = {
  product: {
    waitingYn: 'N',
    reservationYn: 'N',
  },
  mall: {
    mallName: '',
    address: '',
  },
  setting: {
    existsChannel: false,
    channelName: '',
    toggleOurMall: false,
    toggleKakaoSendMsg: false,
  },
  reservation: {
    use: false,
    create: {},
    alarm: {},
    change: {},
    cancel: {},
  },
  waiting: {
    use: false,
    create: {},
    waiting: {},
    remind: {},
    cancel: {},
  },
  ourmall: {
    use: false,
    normal: {},
    ad: {},
  },
};

export interface IAlimtalk {
  product: IKakaoProductSetting;
  mall: IKakaoMallInfo;
  setting: IAlimtalkSetting;
  reservation: ICustomMessageGroup;
  waiting: ICustomMessageGroup;
  ourmall: ICustomMessageGroup;
}

interface IUseAlimtalkStore {
  alimtalk: IAlimtalk;
  isFetching: boolean;
  setFetching: (boolean) => void;
  immerAlimtalk: (recipe: (alimtalk: IAlimtalk) => void) => void;
  replaceAlimtalk: (props) => void;
  refreshAlimtalk: () => Promise<IAlimtalk>;
}

export const useKakaoAlimtalkStore = create<IUseAlimtalkStore>((set) => {
  return {
    alimtalk: initialAlimtalk,
    isFetching: false,
    setFetching: (isFetching: boolean) => set({ isFetching }),
    immerAlimtalk: (recipe: (alimtalk: IAlimtalk) => void) => set(produce((state) => recipe(state.alimtalk))),
    replaceAlimtalk: (alimtalk) => set({ alimtalk }),
    refreshAlimtalk: () => {
      return Promise.resolve(set({ isFetching: true }))
        .then(() => devServerApi.lg.authGet<IAlimtalk>('/api/partner/kakao/alimtalk/setting?type=all'))
        .then((alimtalk) => {
          set({ alimtalk });
          return alimtalk;
        })
        .finally(() => set({ isFetching: false }));
    },
  };
});
