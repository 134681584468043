import { useModalStore } from '@/stores/common/useModalStore';
import apiBase from './apiBase';
import useNative from '@/hooks/useNative';
import { useLayoutEffect } from 'react';
import { useLayoutStore } from '@/stores/common/useLayoutStore';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthStore } from '@/stores/common/useAuthStore';



export const devServerApi = apiBase(
  {
    api: process.env.REACT_APP_API_HOST,
    lg: process.env.REACT_APP_LG_API_HOST,
    soho: process.env.REACT_APP_SOHO_API_HOST,
  },
  {
    headers: {
      'x-tm-apigw-token': process.env.NODE_ENV !== 'production' && process.env.REACT_APP_TOKEN,
    },
    timeout: 10000,
  }
);

export const useDevServerApi = () => {
  const { callAlert } = useNative();
  const { setSpinner } = useLayoutStore();

  devServerApi.api.interceptors.response.use(undefined, (error) => {
    callAlert('오류', error, [{ btnNm: '확인', value: 'close' }]);
    setSpinner(false);
  });
  devServerApi.lg.interceptors.response.use(undefined, (error) => {
    callAlert('오류', error, [{ btnNm: '확인', value: 'close' }]);
    setSpinner(false);
  });

  devServerApi.soho.interceptors.response.use(undefined, (error) => {
    callAlert('오류', error, [{ btnNm: '확인', value: 'close' }]);
    setSpinner(false);
  });
  return devServerApi;
};
