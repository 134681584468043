import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import CommonProvider from '@/provider/common.provider';
import AuthIndexPage from '@/pages/auth/index';
import DebugEncPage from '@/pages/debug/enc';
import DebugIndexPage from '@/pages/debug/index';
import IndexPage from '@/pages/index';
import ReservationAcceptStep_1IndexPage from '@/pages/reservation/accept/step-1/index';
import ReservationAcceptStep_2IndexPage from '@/pages/reservation/accept/step-2/index';
import ReservationAcceptStep_3IndexPage from '@/pages/reservation/accept/step-3/index';
import ReservationAcceptStep_4IndexPage from '@/pages/reservation/accept/step-4/index';
import ReservationAcceptStep_5IndexPage from '@/pages/reservation/accept/step-5/index';
import ReservationDetail$IdPage from '@/pages/reservation/detail/[id]';
import ReservationListIndexPage from '@/pages/reservation/list/index';
import ReservationUserSearchIndexPage from '@/pages/reservation/user-search/index';
import SettingAgreementWaitPage from '@/pages/setting/agreement_wait';
import SettingAgreementPage from '@/pages/setting/agreement';
import SettingChannelIndexPage from '@/pages/setting/channel/index';
import SettingEssentialEditPage from '@/pages/setting/essential/edit';
import SettingEssentialIndexPage from '@/pages/setting/essential/index';
import SettingIndexPage from '@/pages/setting/index';
import SettingKakaoAdMallMessageIndexPage from '@/pages/setting/kakao-ad-mall-message/index';
import SettingKakaoChannelIndexPage from '@/pages/setting/kakao-channel/index';
import SettingKakaoNormalMallMessageIndexPage from '@/pages/setting/kakao-normal-mall-message/index';
import SettingKakaoReservationMessageIndexPage from '@/pages/setting/kakao-reservation-message/index';
import SettingKakaoReservationMessage$TypePage from '@/pages/setting/kakao-reservation-message/[type]';
import SettingKakaoWaitingMessageIndexPage from '@/pages/setting/kakao-waiting-message/index';
import SettingKakaoWaitingMessage$TypePage from '@/pages/setting/kakao-waiting-message/[type]';
import SettingKakaoIndexPage from '@/pages/setting/kakao/index';
import SettingPolicyAvailableReservationIndexPage from '@/pages/setting/policy/available-reservation/index';
import SettingPolicyIndexPage from '@/pages/setting/policy/index';
import SettingPolicyMaxHoursUseIndexPage from '@/pages/setting/policy/max-hours-use/index';
import SettingPolicyReceiveReservationIndexPage from '@/pages/setting/policy/receive-reservation/index';
import SettingPolicySameDayReservationIndexPage from '@/pages/setting/policy/same-day-reservation/index';
import SettingScheduleIndexPage from '@/pages/setting/schedule/index';
import SettingScheduleManagementDayIndexPage from '@/pages/setting/schedule/management-day/index';
import SettingTableIndexPage from '@/pages/setting/table/index';
import SettingTestPage from '@/pages/setting/test';
import TutorialBusinessWaitPage from '@/pages/tutorial/business_wait';
import TutorialBusinessPage from '@/pages/tutorial/business';
import TutorialEssentialPage from '@/pages/tutorial/essential';
import TutorialIndexPage from '@/pages/tutorial/index';
import TutorialKakaoChannelWaitPage from '@/pages/tutorial/kakao-channel_wait';
import TutorialKakaoChannelPage from '@/pages/tutorial/kakao-channel';
import TutorialReservationPage from '@/pages/tutorial/reservation';
import TutorialTablePage from '@/pages/tutorial/table';
import WaitingDashboardIndexPage from '@/pages/waiting/dashboard/index';
import WaitingIndexPage from '@/pages/waiting/index';
import WaitingSettingAlarmIndexPage from '@/pages/waiting/setting/alarm/index';
import WaitingSettingExpiredIndexPage from '@/pages/waiting/setting/expired/index';
import WaitingSettingInformationIndexPage from '@/pages/waiting/setting/information/index';
import WaitingSettingPeopleIndexPage from '@/pages/waiting/setting/people/index';
import WaitingSettingWaitingIndexPage from '@/pages/waiting/setting/waiting/index';
import WaitingTutorialIndexPage from '@/pages/waiting/tutorial/index';

const CustomRouter = (props = {}) => <BrowserRouter>
  <Routes {...props}>
    <Route key="AuthIndexPage" path="/auth/" element={<AuthIndexPage/> }/>
    <Route key="DebugEncPage" path="/debug/enc" element={<CommonProvider><DebugEncPage/></CommonProvider> }/>
    <Route key="DebugIndexPage" path="/debug/" element={<CommonProvider><DebugIndexPage/></CommonProvider> }/>
    <Route key="IndexPage" path="/" element={<CommonProvider><IndexPage/></CommonProvider> }/>
    <Route key="ReservationAcceptStep_1IndexPage" path="/reservation/accept/step-1/" element={<CommonProvider><ReservationAcceptStep_1IndexPage/></CommonProvider> }/>
    <Route key="ReservationAcceptStep_2IndexPage" path="/reservation/accept/step-2/" element={<CommonProvider><ReservationAcceptStep_2IndexPage/></CommonProvider> }/>
    <Route key="ReservationAcceptStep_3IndexPage" path="/reservation/accept/step-3/" element={<CommonProvider><ReservationAcceptStep_3IndexPage/></CommonProvider> }/>
    <Route key="ReservationAcceptStep_4IndexPage" path="/reservation/accept/step-4/" element={<CommonProvider><ReservationAcceptStep_4IndexPage/></CommonProvider> }/>
    <Route key="ReservationAcceptStep_5IndexPage" path="/reservation/accept/step-5/" element={<CommonProvider><ReservationAcceptStep_5IndexPage/></CommonProvider> }/>
    <Route key="ReservationDetail$IdPage" path="/reservation/detail/:id" element={<CommonProvider><ReservationDetail$IdPage/></CommonProvider> }/>
    <Route key="ReservationListIndexPage" path="/reservation/list/" element={<CommonProvider><ReservationListIndexPage/></CommonProvider> }/>
    <Route key="ReservationUserSearchIndexPage" path="/reservation/user-search/" element={<CommonProvider><ReservationUserSearchIndexPage/></CommonProvider> }/>
    <Route key="SettingAgreementWaitPage" path="/setting/agreement_wait" element={<CommonProvider><SettingAgreementWaitPage/></CommonProvider> }/>
    <Route key="SettingAgreementPage" path="/setting/agreement" element={<CommonProvider><SettingAgreementPage/></CommonProvider> }/>
    <Route key="SettingChannelIndexPage" path="/setting/channel/" element={<CommonProvider><SettingChannelIndexPage/></CommonProvider> }/>
    <Route key="SettingEssentialEditPage" path="/setting/essential/edit" element={<CommonProvider><SettingEssentialEditPage/></CommonProvider> }/>
    <Route key="SettingEssentialIndexPage" path="/setting/essential/" element={<CommonProvider><SettingEssentialIndexPage/></CommonProvider> }/>
    <Route key="SettingIndexPage" path="/setting/" element={<CommonProvider><SettingIndexPage/></CommonProvider> }/>
    <Route key="SettingKakaoAdMallMessageIndexPage" path="/setting/kakao-ad-mall-message/" element={<CommonProvider><SettingKakaoAdMallMessageIndexPage/></CommonProvider> }/>
    <Route key="SettingKakaoChannelIndexPage" path="/setting/kakao-channel/" element={<CommonProvider><SettingKakaoChannelIndexPage/></CommonProvider> }/>
    <Route key="SettingKakaoNormalMallMessageIndexPage" path="/setting/kakao-normal-mall-message/" element={<CommonProvider><SettingKakaoNormalMallMessageIndexPage/></CommonProvider> }/>
    <Route key="SettingKakaoReservationMessageIndexPage" path="/setting/kakao-reservation-message/" element={<CommonProvider><SettingKakaoReservationMessageIndexPage/></CommonProvider> }/>
    <Route key="SettingKakaoReservationMessage$TypePage" path="/setting/kakao-reservation-message/:type" element={<CommonProvider><SettingKakaoReservationMessage$TypePage/></CommonProvider> }/>
    <Route key="SettingKakaoWaitingMessageIndexPage" path="/setting/kakao-waiting-message/" element={<CommonProvider><SettingKakaoWaitingMessageIndexPage/></CommonProvider> }/>
    <Route key="SettingKakaoWaitingMessage$TypePage" path="/setting/kakao-waiting-message/:type" element={<CommonProvider><SettingKakaoWaitingMessage$TypePage/></CommonProvider> }/>
    <Route key="SettingKakaoIndexPage" path="/setting/kakao/" element={<CommonProvider><SettingKakaoIndexPage/></CommonProvider> }/>
    <Route key="SettingPolicyAvailableReservationIndexPage" path="/setting/policy/available-reservation/" element={<CommonProvider><SettingPolicyAvailableReservationIndexPage/></CommonProvider> }/>
    <Route key="SettingPolicyIndexPage" path="/setting/policy/" element={<CommonProvider><SettingPolicyIndexPage/></CommonProvider> }/>
    <Route key="SettingPolicyMaxHoursUseIndexPage" path="/setting/policy/max-hours-use/" element={<CommonProvider><SettingPolicyMaxHoursUseIndexPage/></CommonProvider> }/>
    <Route key="SettingPolicyReceiveReservationIndexPage" path="/setting/policy/receive-reservation/" element={<CommonProvider><SettingPolicyReceiveReservationIndexPage/></CommonProvider> }/>
    <Route key="SettingPolicySameDayReservationIndexPage" path="/setting/policy/same-day-reservation/" element={<CommonProvider><SettingPolicySameDayReservationIndexPage/></CommonProvider> }/>
    <Route key="SettingScheduleIndexPage" path="/setting/schedule/" element={<CommonProvider><SettingScheduleIndexPage/></CommonProvider> }/>
    <Route key="SettingScheduleManagementDayIndexPage" path="/setting/schedule/management-day/" element={<CommonProvider><SettingScheduleManagementDayIndexPage/></CommonProvider> }/>
    <Route key="SettingTableIndexPage" path="/setting/table/" element={<CommonProvider><SettingTableIndexPage/></CommonProvider> }/>
    <Route key="SettingTestPage" path="/setting/test" element={<CommonProvider><SettingTestPage/></CommonProvider> }/>
    <Route key="TutorialBusinessWaitPage" path="/tutorial/business_wait" element={<CommonProvider><TutorialBusinessWaitPage/></CommonProvider> }/>
    <Route key="TutorialBusinessPage" path="/tutorial/business" element={<CommonProvider><TutorialBusinessPage/></CommonProvider> }/>
    <Route key="TutorialEssentialPage" path="/tutorial/essential" element={<CommonProvider><TutorialEssentialPage/></CommonProvider> }/>
    <Route key="TutorialIndexPage" path="/tutorial/" element={<CommonProvider><TutorialIndexPage/></CommonProvider> }/>
    <Route key="TutorialKakaoChannelWaitPage" path="/tutorial/kakao-channel_wait" element={<CommonProvider><TutorialKakaoChannelWaitPage/></CommonProvider> }/>
    <Route key="TutorialKakaoChannelPage" path="/tutorial/kakao-channel" element={<CommonProvider><TutorialKakaoChannelPage/></CommonProvider> }/>
    <Route key="TutorialReservationPage" path="/tutorial/reservation" element={<CommonProvider><TutorialReservationPage/></CommonProvider> }/>
    <Route key="TutorialTablePage" path="/tutorial/table" element={<CommonProvider><TutorialTablePage/></CommonProvider> }/>
    <Route key="WaitingDashboardIndexPage" path="/waiting/dashboard/" element={<CommonProvider><WaitingDashboardIndexPage/></CommonProvider> }/>
    <Route key="WaitingIndexPage" path="/waiting/" element={<CommonProvider><WaitingIndexPage/></CommonProvider> }/>
    <Route key="WaitingSettingAlarmIndexPage" path="/waiting/setting/alarm/" element={<CommonProvider><WaitingSettingAlarmIndexPage/></CommonProvider> }/>
    <Route key="WaitingSettingExpiredIndexPage" path="/waiting/setting/expired/" element={<CommonProvider><WaitingSettingExpiredIndexPage/></CommonProvider> }/>
    <Route key="WaitingSettingInformationIndexPage" path="/waiting/setting/information/" element={<CommonProvider><WaitingSettingInformationIndexPage/></CommonProvider> }/>
    <Route key="WaitingSettingPeopleIndexPage" path="/waiting/setting/people/" element={<CommonProvider><WaitingSettingPeopleIndexPage/></CommonProvider> }/>
    <Route key="WaitingSettingWaitingIndexPage" path="/waiting/setting/waiting/" element={<CommonProvider><WaitingSettingWaitingIndexPage/></CommonProvider> }/>
    <Route key="WaitingTutorialIndexPage" path="/waiting/tutorial/" element={<CommonProvider><WaitingTutorialIndexPage/></CommonProvider> }/>
  </Routes>
</BrowserRouter>;

export default CustomRouter;
