import React, { useRef } from 'react';
import autosize from 'autosize';
import { ReactComponent as Close } from '@/assets/images/icons/Close.svg';
import { ReactComponent as RadioOn } from '@/assets/images/icons/Radio-On.svg';
import { ReactComponent as RadioOff } from '@/assets/images/icons/Radio-Off.svg';
import { ReactComponent as ArrowLeft } from '@/assets/images/icons/Arrow_Left.svg';

import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useQuery } from 'react-query';
import queryClient from '@/shared/utils/queryClient';
import '@/styles/base/tailwind.css';
const DEFAULT_MESSAGE = `• 입장 순서가 가까워지면 카카오톡이나 문자로 안내 메세지가 나갑니다.\n• 인원수에 따라 입장 순서가 변경될 수 있습니다.\n• 입장 안내 메세지를 받으셨다면 매장 직원에게 확인 후 입장하실 수 있습니다.\n• 입장 순서가 되었을 때 매장 앞에 계시지 않을 경우 웨이팅이 취소될 수 있습니다.\n`;

interface Form {
  tabletInformationType: 'DEFAULT' | 'CUSTOM';
  tabletDefaultInformationMessage: string;
  tabletCustomInformationMessage: string;
}
const WaitingInformationMessage: React.FC = () => {
  let location = useLocation();
  const [isSettingOpen, setIsSettingOpen] = React.useState(false);
  const [_form, set_Form] = React.useState<Form>();
  const [form, setForm] = React.useState<Form>();
  const sheetRef = useRef<BottomSheetRef>();
  const navigate = useNavigate();

  React.useEffect(() => {
    let state = location.state;
    if (state) {
      const savedOptions = {
        tabletInformationType: state.option.tabletInformationType,
        tabletDefaultInformationMessage: state.option.tabletDefaultInformationMessage || DEFAULT_MESSAGE,
        tabletCustomInformationMessage: state.option.tabletCustomInformationMessage,
      };
      setForm(savedOptions);
      set_Form(savedOptions);
    }
  }, []);

  const {
    data: optionsResponse,
    refetch,
    isLoading,
  } = useQuery(['waiting_options'], () => devServerApi.api.get('/waiting/option'), {
    onSuccess: ({ data }) => {
      const options = data.option;
      const savedOptions = {
        tabletInformationType: options.tabletInformationType,
        tabletDefaultInformationMessage: options.tabletDefaultInformationMessage || DEFAULT_MESSAGE,
        tabletCustomInformationMessage: options.tabletCustomInformationMessage,
      };
      setForm(savedOptions);
      set_Form(savedOptions);
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  const toggleType = (type: 'DEFAULT' | 'CUSTOM') => () => {
    set_Form({ ...form, tabletInformationType: type });
  };

  const onSave = async () => {
    let requestBody = {};
    if (_form.tabletInformationType === 'DEFAULT') {
      requestBody = {
        tabletInformationType: _form.tabletInformationType,
        tabletDefaultInformationMessage: _form.tabletDefaultInformationMessage,
      };
    } else {
      requestBody = {
        tabletInformationType: _form.tabletInformationType,
        tabletCustomInformationMessage: _form.tabletCustomInformationMessage,
      };
    }
    await devServerApi.api.put('/waiting/option', requestBody);
    await queryClient.invalidateQueries(['waiting_options']);
    setIsSettingOpen(false);
    setForm(_form);
  };

  React.useEffect(() => {
    // all textarea in document
    const textareas = document.querySelectorAll('textarea');
    textareas.forEach((textarea) => {
      autosize(textarea);
    });
  }, []);

  if (isLoading || !form) return null;

  return (
    <div className="min-w-screen min-h-screen flex flex-col">
      <div className="relative py-[15px] justify-center items-center w-full flex">
        <p className="text-zinc-800 text-lg font-bold">대기 안내사항</p>
        <ArrowLeft className="absolute left-5 w-[20px] h-[20px]" onClick={() => navigate(-1)} />
      </div>
      <div className="flex flex-col flex-1 bg-zinc-100 divide-y divide-stone-300">
        <div className="flex flex-col bg-white">
          <div className="bg-white px-5 py-6 flex w-full items-center justify-between">
            <div className="flex flex-col">
              <p className="text-xl text-zinc-800 font-bold mb-[6px]">안내 내용</p>
              <p className="text-sm text-zinc-500 font-medium whitespace-pre-wrap">{`대기 등록시 노출되는 안내사항을\n변경 할 수 있어요.`}</p>
            </div>
            <div
              className="flex items-center"
              onClick={() => {
                setIsSettingOpen(true);
                set_Form({ ...form });
              }}
            >
              <span className="text-zinc-800 text-lg font-bold whitespace-pre-wrap text-right">{form.tabletInformationType === 'DEFAULT' ? '기본' : '직접 입력'}</span>
            </div>
          </div>
          <div className="h-px w-full mx-[20px] bg-gray-100" />
          <div className="p-3.5 m-[20px] rounded border border-stone-300">
            <span
              className={classNames(
                'text-sm whitespace-pre-wrap break-words',
                form.tabletInformationType === 'CUSTOM' && !form.tabletCustomInformationMessage ? 'text-zinc-500' : 'text-zinc-800 '
              )}
            >
              {form.tabletInformationType === 'DEFAULT' ? form.tabletDefaultInformationMessage : form.tabletCustomInformationMessage || '안내사항을 입력해주세요.'}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-1 bg-zinc-100 w-full" />
      <BottomSheet
        className="bottomSheetScrollable"
        snapPoints={({ maxHeight }) => [maxHeight * 0.8]}
        open={isSettingOpen}
        header={
          <div className="flex justify-between items-center w-full">
            <b className="text-zinc-800 text-lg font-bold px-1">안내사항 입력</b>
            <Close className="w-6 h-6" onClick={() => setIsSettingOpen(false)} />
          </div>
        }
        footer={
          <div>
            <button onClick={onSave} className={classNames('p-[14px] items-center justify-center rounded text-white w-full text-sm font-bold bg-violet-700')}>
              저장
            </button>
          </div>
        }
        ref={sheetRef}
        onDismiss={() => setIsSettingOpen(false)}
      >
        <div className="p-5">
          <div className="py-4 flex justify-between">
            <span className="text-zinc-800">기본 안내사항</span>
            {_form.tabletInformationType === 'DEFAULT' ? <RadioOn /> : <RadioOff onClick={toggleType('DEFAULT')} />}
          </div>
          {_form.tabletInformationType === 'DEFAULT' && (
            <p className="mb-[16px] p-[14px] border border-gray-100 rounded text-sm font-medium text-zinc-800 leading-5 whitespace-pre-wrap">
              {form.tabletDefaultInformationMessage}
            </p>
          )}
          <div className="w-full h-px bg-gray-100" />
          <div className="py-4 flex justify-between">
            <span className="text-zinc-800">직접 입력</span>
            {_form.tabletInformationType === 'CUSTOM' ? <RadioOn /> : <RadioOff onClick={toggleType('CUSTOM')} />}
          </div>
          {_form.tabletInformationType === 'CUSTOM' && (
            <div className="w-full flex flex-col relative">
              <textarea
                value={_form.tabletCustomInformationMessage}
                // onChange={(e) => {
                //   const textarea = e.target;
                //   if (textarea.value.length > 200) return;
                //   set_Form((prev) => ({ ...prev, tabletCustomInformationMessage: textarea.value }));

                //   // Reset the height to auto (to shrink back if needed)
                //   textarea.style.height = 'auto';
                //   // Set the height to scrollHeight to expand as needed
                //   textarea.style.height = `${textarea.scrollHeight}px`;
                // }}
                onChange={(e) => {
                  const textarea = e.target;
                  if (e.target.value.length > 200) return;
                  set_Form((prev) => ({ ...prev, tabletCustomInformationMessage: e.target.value }));
                }}
                rows={1}
                maxLength={200}
                className="overflow-ios-webview"
                placeholder="안내사항을 입력해주세요."
              />
              {_form.tabletCustomInformationMessage && (
                <button onClick={() => set_Form((prev) => ({ ...prev, tabletCustomInformationMessage: '' }))} className="absolute top-[14px] right-[14px] w-6 h-6 text-gray-500">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <circle cx="12" cy="12" r="12" fill="#CBC8CF" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" fill="none" className="absolute inset-0" style={{ padding: '6px' }}>
                    <path d="M1 1L11 11" stroke="#2E2E30" stroke-width="1.5" stroke-linecap="round" />
                    <path d="M11 1L1 11" stroke="#2E2E30" stroke-width="1.5" stroke-linecap="round" />
                  </svg>
                </button>
              )}
              <p className="text-right mt-1 text-stone-300">{`${_form.tabletCustomInformationMessage.length}자/200자`}</p>
            </div>
          )}
        </div>
      </BottomSheet>
    </div>
  );
};

export default WaitingInformationMessage;
