import cloneDeep from 'lodash/cloneDeep';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { getSelectValues } from '@/shared/lib/functions';
import { IPersistStore, IQuestion, IQuestionAction, Nullable } from '@/shared/utils/common.type';

const INITIAL_QUESTIONS: IQuestion = {
  step1_0: {
    value: null,
    question: '아래 채널을 통해 고객님께\n예약/대기/매장 안내/홍보\n메시지를 보낼게요.',
    type: 'plain',
    nextStep: {
      to: 'step2_0',
    },
  },
  step2_0: {
    value: null,
    question: '전화한 고객님께\n자동으로 메시지를\n보낼게요.',
    type: 'plain',
    nextStep: {
      to: 'step3_0',
    },
  },
  step3_0: {
    value: null,
    question: '카카오톡 신청이\n완료되었어요!',
    note: '서비스 가입을 위해 테이블매니저 팀에서\n"1544-2017" 번호로 연락 드릴 거예요.\n잘 받아주세요!',
    type: 'plain',
    nextStep: {
      to: 'done',
    },
  },
};

export const questionReducer = (state: IQuestion, action: IQuestionAction) => {
  const { type, value, step, multiple, idx } = action;
  const copyState = cloneDeep(state);
  switch (type) {
    case 'SET_CHANGE': {
      copyState[step].value = value;
      return copyState;
    }
    case 'SET_REF_CHANGE': {
      copyState[step].value[idx][1] = value;
      return copyState;
    }
    case 'SET_SELECT_CHANGE': {
      if (multiple) {
        copyState[step].value = getSelectValues(value);
      } else {
        copyState[step].value = value.value;
      }
      return copyState;
    }
    default: {
      return state;
    }
  }
};

export const useKakaoChannelSettingStore = create(
  persist<IPersistStore>(
    (set) => ({
      // @ts-ignore
      storageQuestion: INITIAL_QUESTIONS,
      setStorageQuestion: (storageQuestion) => set({ storageQuestion: storageQuestion }),
      resetStorageQuestion: () => set({ storageQuestion: INITIAL_QUESTIONS }),
    }),
    { name: 'kakao-channel-setting', getStorage: () => localStorage, version: 11 }
  )
);
