import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Back } from '@/assets/images/icons/back.svg';
import './HeaderBack.scss';

interface IHeaderBack {
  isFixed?: boolean;
  isCenter?: boolean;
  noDummy?: boolean;
  onBack?: () => void;
  inBack?: boolean;
  children: any;
}

const HeaderBack = ({ isFixed = false, isCenter, noDummy, onBack, inBack = false, children }: IHeaderBack) => {
  const navigate = useNavigate();

  const onClickBack =
    onBack ||
    (() => {
      if (!inBack && window.ExitPage) window.ExitPage.postMessage('');
      navigate(-1);
    });
  return (
    <div className={classNames('header-back-wrapper', { 'is-fixed': isFixed, 'is-center': isCenter })}>
      <Back onClick={onClickBack} className="pointer" />
      {children}
      {isCenter && !noDummy && <div className="dummy" />}
    </div>
  );
};

export default HeaderBack;
