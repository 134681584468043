import React, { Fragment } from 'react';
import { ITutorialKakaoChannelContentItemProps } from '@/components/TutorialKakaoChannel/TutorialKakaoChannelContentItem.type';
import { ReactComponent as IcWarning } from '@/assets/images/icons/ic_warning.svg';
import classNames from 'classnames';
import './TutorialKakaoChannelContentItem.scss';

const TutorialKakaoChannelContentItem = (props: ITutorialKakaoChannelContentItemProps) => {
  const { contents, warning, padding } = props;

  return (
    <div className={classNames('tutorial-kakao-channel-content-item-wrapper', { padding })}>
      {contents.map((e, i) => (
        <Fragment key={`content-item-${i}`}>
          <div className="tutorial-kakao-channel-content-item">
            <div className="ellipse-box">
              <div className="ellipse" />
            </div>
            <p className="paragraph">{e}</p>
          </div>
          {i + 1 < contents.length && <div className="content-horizontal-divider" />}
        </Fragment>
      ))}
      {warning !== undefined && (
        <div className="tutorial-kakao-channel-content-warning">
          <IcWarning />
          <p className="paragraph">{warning}</p>
        </div>
      )}
    </div>
  );
};

export default TutorialKakaoChannelContentItem;
