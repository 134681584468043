import React from 'react';
import './OnOffItem.scss';
import classNames from 'classnames';

interface IProps {
  icon?: React.FunctionComponent;
  title: string;
  tabletCustomInformationMessage: string;
  check: boolean;
  beforeChange?: React.MouseEventHandler;
  onChagne?: React.ChangeEventHandler;
}

const OnOffItem = ({ icon: Icon, title, tabletCustomInformationMessage, check, beforeChange, onChagne }: IProps) => {
  const handleChange: React.ChangeEventHandler = (e) => {
    onChagne && onChagne(e);
  };

  const onClick: React.MouseEventHandler = (e) => {
    console.debug('click');
    if (beforeChange) {
      console.debug('call beforeChange');
      beforeChange(e);
    }
  };

  return (
    <div className="onoff-item-container">
      <div className="onoff-item-context">
        <div className="onoff-item-title-container">
          {Icon && (
            <div className="onoff-item-icon">
              <Icon />
            </div>
          )}
          <div className="onoff-item-title">{title}</div>
        </div>
        <div className={classNames({ 'onoff-item-tabletCustomInformationMessage-container': Boolean(Icon) })}>
          <div className="onoff-item-tabletCustomInformationMessage">{tabletCustomInformationMessage}</div>
        </div>
      </div>
      <label className="tblm-switch pointer">
        <input type="checkbox" name="switch_example" checked={check} onClick={onClick} onChange={handleChange} />
        <i />
      </label>
    </div>
  );
};

export default OnOffItem;
