import * as yup from 'yup';

import { useEffect, useMemo, useState } from 'react';

import { ControlledInput } from '@/hook-form/ControlledInput';
import HeaderBack from '@/components/HeaderBack/HeaderBack';
import { MallSetting } from '@/shared/utils/common.type';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import Terces from '@/shared/apis/terces';
import { autoHypenCompany } from '@/shared/lib/functions/auto-hypen-company';
import { autoHypenTel } from '@/shared/lib/functions/auto-hypen-tel';
import { devServerApi } from '@/shared/apis/devServerApi';
import { instantDisabler, parseJwt } from '@/shared/lib/functions';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useForm } from 'react-hook-form';
import { useModalStore } from '@/stores/common/useModalStore';
import { useQuery } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import FooterSub from '@/components/FooterSub/FooterSub';
import { useSearchParams } from 'react-router-dom';
import useBackToSettings from '@/hooks/useBackToSetting';





const Channel = () => {
  const { accessToken, payload, setPayload } = useAuthStore();
  const { setAlertModal, setMenuModal } = useModalStore();
  const [failedChatbotMessage, setFailedChatbotMessage] = useState(null);
  const [channelConnect, setChannelConnect] = useState({});
  const [serviceCheck, setServiceCheck] = useState(null);
  const [buttonState, setButtonState] = useState(false);
  const [buttonText, setButtonText] = useState('추가');
  const [description, setDiscription] = useState('없음');
  const back = useBackToSettings();

  /* !!!!! 디자인 확인을 위한 임시 로직 */
  const [searchParams] = useSearchParams();
  const testType = searchParams.get('testType');

  async function setService(mallId: String) {
    await devServerApi.lg.post('api/partner/mall/check-service', { "mallId": mallId }).then((check) => {
      setServiceCheck(check.data.state);
    });
  }
  function getServiceStatus(serviceData) {
    if (serviceData !== null) {
      const kakao = serviceData.kakao;
      const naver = serviceData.naver;
      if (kakao === 'NONE' && naver === 'NONE') {
        setButtonState(false);
        setButtonText('추가');
        setDiscription('없음');
      }
      if (kakao === 'IN_PROGRESS' && naver === 'IN_PROGRESS') {
        setButtonState(true);
        setDiscription('연동 신청 완료');
        setButtonText('처리 중');
      }
      let result = [];
      if (naver === 'DONE') {
        setButtonState(true);
        setButtonText('연동완료');
        result.push('네이버');
        setDiscription(result.join(', ') || '없음');
      }
      if (kakao === 'DONE') {
        setButtonState(true);
        setButtonText('연동완료');
        result.push('카카오');
        setDiscription(result.join(', ') || '없음');
      }
    }
  }

  useEffect(() => {
    setChannelConnect(JSON.parse(localStorage['channel-connect'] || '{}'));
    const { pid, pmid, tmid } = parseJwt(accessToken);
    setPayload({ pid, pmid, tmid });
    setService(pmid);
  }, [accessToken]);

  useEffect(() => {
    getServiceStatus(serviceCheck);
    console.log(serviceCheck);
  }, [serviceCheck]);



  const { data, isFetching } = testType
    ? { data: { enabledPartnerNames: ['네이버', '카카오'] }, isFetching: false }
    : useQuery([QUERY_KEYS.SETTINGS_MALL], () => devServerApi.api.authGet<MallSetting>('/settings/mall').then((enc_res) => Terces.decrypt(enc_res)), {
      cacheTime: 0,
    });

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      mallName: yup.string().required('매장명을 입력해주세요.'),
      subscriber: yup.string().required('신청인 이름을 입력해주세요.'),
      phone: yup.string().required('신청인 연락처를 입력해주세요.').max(13, '올바른 연락처를 입력해주세요.'),
      eid: yup.string().required('사업자 번호를 입력해주세요.').min(12, '올바른 사업자번호를 입력해주세요.').max(12, '올바른 사업자번호를 입력해주세요.'),
    });
  }, []);

  const methods = useForm({
    defaultValues: {
      mallName: '',
      subscriber: '',
      phone: '',
      eid: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { handleSubmit, control, setValue } = methods;

  const onClickYes = () => {
    setMenuModal({ visible: true, key: 'naverkakaoform' });
  };

  const onSubmit = (form) => {
    devServerApi.api.post('/chatbot', Terces.encrypt(form)).then(({ data: enc_res }: any) => {
      const res = Terces.decrypt(enc_res);
      if (res.status === -1009) {
        setFailedChatbotMessage(res.message);
        setAlertModal({ visible: true, key: 'failedChatbot' });
        return;
      }
      channelConnect[payload.tmid] = true;
      localStorage['channel-connect'] = JSON.stringify(channelConnect);
      setService(payload.tmid);
      // onNext(true);
      setMenuModal({ visible: false, key: 'naverkakaoform' });
      setAlertModal({ visible: true, key: 'successChatbot' });
    });
  };
  // @ts-ignore
  return (
    <>
      <HeaderBack inBack={false} isCenter onBack={back}>
        <div className="font__heading_4">온라인 예약 연동</div>
      </HeaderBack>
      <section className="settings-policy-detail-wrapper">
        <div className="switch-area pointer">
          <div className="left-area" style={{ flexBasis: 'auto' }}>
            <div className="font__title_20_b white-pre">{'현재 연동 중인\n온라인 예약 채널'}</div>
            <div className="font__body_5 keep-all">
              {/* @ts-ignore */}
              {description}
            </div>
          </div>
          <div className="right-area gap-16">
            {/* @ts-ignore*/}
            <button
              className={buttonText === '연동완료' ? 'tblm-btn-applegreen font__small_m' : 'tblm-btn-paleblue font__small_m'}
              style={{ minWidth: '80px', height: '36px', borderRadius: 40, fontWeight: 500 }}
              onClick={() => onClickYes()}
              disabled={buttonState}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </section>
      <div className="font__body_7" style={{ padding: '16px 20px' }}>
        네이버 예약, 카카오 챗봇으로도 예약을 받아보세요.
        <br />
        늘어난 예약량을 우리가게에서 한 번에 관리할 수 있어요.
      </div>
      <ModalPortal>
        <Modal.Menu key="naverkakaoform" modal="menu" isDim={true} isAnimation={true} title="서비스 신청 정보">
          <div className="content">
            <div className="title-note font__body_7">*필수 입력</div>
            <ul className={'form-wrapper'}>
              <li>
                <div className="form-label">*매장명</div>
                <div className="form-value">
                  <ControlledInput
                    name="mallName"
                    control={control}
                    placeholder="매장명을 입력해주세요."
                    onChange={(e) => {
                      setValue('mallName', e.target.value.replace(/[^\w\sㄱ-힣]|[_]/g, ''));
                    }}
                  />
                </div>
              </li>
              <li>
                <div className="form-label">*신청인 이름</div>
                <div className="form-value">
                  <ControlledInput
                    name="subscriber"
                    control={control}
                    placeholder="신청인 이름을 입력해주세요."
                    onChange={(e) => {
                      setValue('subscriber', e.target.value.replace(/[^\w\sㄱ-힣]|[_]/g, ''));
                    }}
                  />
                </div>
              </li>
              <li>
                <div className="form-label">*신청인 연락처</div>
                <div className="form-value three">
                  <ControlledInput
                    name="phone"
                    type="text"
                    control={control}
                    placeholder="010-0000-0000"
                    onChange={(e) => {
                      setValue('phone', autoHypenTel(e.target.value));
                    }}
                    maxLength={13}
                  />
                </div>
              </li>
              <li>
                <div className="form-label">*사업자 번호</div>
                <div className="form-value three">
                  <ControlledInput
                    name="eid"
                    control={control}
                    placeholder="000-00-00000"
                    onChange={(e) => {
                      setValue('eid', autoHypenCompany(e.target.value));
                    }}
                    maxLength={12}
                  />
                </div>
              </li>
            </ul>
            <FooterSub isVisible isBackArea>
              <button className="tblm-button-normal tblm-btn-primary w-full" onClick={instantDisabler(handleSubmit((result) => onSubmit(result)))}>
                완료
              </button>
            </FooterSub>
          </div>
        </Modal.Menu>
        <Modal.Alert
          key="failedChatbot"
          modal="alert"
          isDim={true}
          isAnimation={true}
          modalData={{
            title: '연동신청에 실패하였습니다.',
            content: failedChatbotMessage,
            buttons: [
              {
                text: '확인',
                success: true,
                onClick: () => setAlertModal({ visible: false, key: 'failedChatbot' }),
              },
            ],
          }}
        />
        <Modal.Alert
          key="successChatbot"
          modal="alert"
          isDim={true}
          isAnimation={true}
          modalData={{
            title: '온라인 예약 연동 신청 완료',
            content: '서비스 가입을 위해 테이블매니저 팀에서 “1544-2017” 번호로 연락드릴 거예요.\n잘 받아주세요!',
            buttons: [
              {
                text: '확인',
                success: true,
                onClick: () => setAlertModal({ visible: false, key: 'successChatbot' }),
              },
            ],
          }}
        />
      </ModalPortal>
    </>
  );
};

export default Channel;
