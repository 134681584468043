/* eslint-disable */
import React from 'react';

const privacy_doc = ({ className }: { className: string }): React.ReactElement => {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: `<span class='h2'>개인정보 수집 및 이용 동의</span>테이블매니저(테이블매니저(주))의 개인정보 수집 이용 목적은 다음과 같습니다. 내용을 자세히 읽어 보신 후 동의 여부를 결정하여 주시기 바랍니다.<table>
<tbody>
<tr>
  <th>수집목적</th>
  <th>필수항목</th>
  <th>보유/이용<br />기간</th>
</tr>
  <tr>
    <td>이용자 식별 및 본인 확인, 회원관리</td>
    <td>사업자번호, 사업자명, 매장명,대표자 이름, 대표자 휴대전화번호</td>
    <td>회원탈퇴 후 6개월 또는 관련 법령에 따른 기간까지</td>
  </tr>
  <tr>
    <td>각종 고지 사항 문자 발송</td>
    <td>이름, 휴대전화번호, 이용내역</td>
    <td>회원탈퇴까지</td>
  </tr>
  <tr>
    <td>상담관리, 서비스 이용안내 및 각종 고지, 서비스 품질 향상</td>
    <td>이름, 휴대전화번호, 이메일 주소, 매장명</td>
    <td>회원탈퇴 후 6개월 또는 관련 법령에 따른 기간까지</td>
  </tr>
</tbody>
</table><div class='dotted'>원활한 서비스 운영을 위해 개인정보처리업무를 카카오(대한민국), 에스케이브로드밴드 주식회사(대한민국), (주)에스엠티앤티(대한민국), (주)엠앤와이즈(대한민국)가 대행하고 있습니다. 자세한 내용은 개인정보처리방침(링크)을 확인해주세요.</div>`,
      }}
    />
  );
};

export default privacy_doc;
