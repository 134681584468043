import React from 'react';
import { ReactComponent as ArrowRight } from '@/assets/images/icons/Arrow_Right.svg';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

interface WaitingSettingProps {
  setting: any;
}

const WaitingSetting: React.FC<WaitingSettingProps> = ({ setting }) => {
  const navigate = useNavigate();
  if (!setting) {
    history.back();
  }
  return (
    <div className="min-h-screen min-w-screen flex flex-col text-zinc-800">
      <div className="px-5 divide-y divide-gray-100 flex flex-col">
        <div
          className="py-4 font-medium flex justify-between items-center"
          onClick={() => {
            navigate('/waiting/setting/waiting', { state: setting });
          }}
        >
          <span>대기 접수</span>
          <div className="flex items-center">
            <span className={classNames('mr-[4px]', !setting.option.isWaitingClosing ? 'text-emerald-400' : 'text-zinc-500')}>
              {!setting.option.isWaitingClosing ? '접수중' : '마감'}
            </span>
            <ArrowRight className="w-4 h-4" />
          </div>
        </div>
        <div
          className="py-4 font-medium flex justify-between items-center"
          onClick={() => {
            navigate('/waiting/setting/people', { state: setting });
          }}
        >
          <span>인원 제한</span>
          <div className="flex items-center text-emerald-400">
            <ArrowRight className="w-4 h-4" />
          </div>
        </div>
        <div
          className="py-4 font-medium flex justify-between items-center"
          onClick={() => {
            navigate('/waiting/setting/alarm', { state: setting });
          }}
        >
          <span>입장 대기 알림</span>
          <div className="flex items-center text-emerald-400">
            <ArrowRight className="w-4 h-4" />
          </div>
        </div>
        <div
          className="py-4 font-medium flex justify-between items-center"
          onClick={() => {
            navigate('/waiting/setting/expired', { state: setting });
          }}
        >
          <span>대기 자동 취소</span>
          <div className="flex items-center text-emerald-400">
            <ArrowRight className="w-4 h-4" />
          </div>
        </div>
        <div
          className="py-4 font-medium flex justify-between items-center"
          onClick={() => {
            navigate('/waiting/setting/information', { state: setting });
          }}
        >
          <span>대기 안내사항</span>
          <div className="flex items-center text-emerald-400">
            <ArrowRight className="w-4 h-4" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitingSetting;
