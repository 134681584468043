import React, { useEffect, useRef, useState } from 'react';
import Close from '@/assets/images/btn/close.png';
import 'react-spring-bottom-sheet/dist/style.css';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import classNames from 'classnames';
import useNative from '@/hooks/useNative';

interface ScrollPickerProps {
  title?: string;
  tabletCustomInformationMessage?: string;
  isOpen?: boolean;
  list: any[];
  onSelectedChange?: (selected: any) => void;
  value?: string | number;
  onDismiss?: () => void;
  onSave?: () => void;
  isShowGNB?: boolean;
  onSelectedSave?: (selected: any) => void;
}

const BottomScrollPicker = ({
  isOpen,
  title,
  tabletCustomInformationMessage,
  value,
  list,
  onSelectedChange,
  onDismiss,
  onSave,
  onSelectedSave,
  isShowGNB = true,
}: ScrollPickerProps) => {
  const SCROLL_DEBOUNCE_TIME = 100;

  const newList = [...list];
  const sheetRef = useRef<BottomSheetRef>();
  const ref = useRef<HTMLUListElement>(null);
  const [selected, setSelected] = useState(0);
  const itemRefs = useRef<(HTMLLIElement | null)[]>([]);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const ITEM_HEIGHT = 48;

  const { hideGNB, showGNB } = useNative();

  // React.useEffect(() => {
  //   if (!isShowGNB) return;
  //   if (isOpen) {
  //     hideGNB();
  //   } else {
  //     showGNB();
  //   }
  //   return showGNB;
  // }, [isOpen, isShowGNB]);

  React.useEffect(() => {
    const idx = list.findIndex((item) => item === value);
    // delay 100ms
    setTimeout(() => {
      if (isOpen) {
        setSelected(idx);
        if (ref.current) {
          itemRefs.current[idx]?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }
    }, 100);
  }, [isOpen, value]);

  const handleScroll = () => {
    if (ref.current) {
      clearTimeout(timerRef.current!);
      timerRef.current = setTimeout(() => {
        const index = Math.round(ref.current!.scrollTop / ITEM_HEIGHT);
        if (newList[index] !== '') {
          setSelected(index);
          itemRefs.current[index]?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          onSelectedChange && onSelectedChange(newList[index]);
        }
      }, SCROLL_DEBOUNCE_TIME);
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = selected * ITEM_HEIGHT;
    }
  }, []);

  const onClose = () => {
    onDismiss?.();
  };

  return (
    <BottomSheet
      snapPoints={({ maxHeight }) => [maxHeight * 0.35]}
      defaultSnap={({ maxHeight }) => maxHeight * 0.35}
      open={isOpen}
      header={
        title && (
          <div className="w-full">
            <div className="flex justify-between items-center">
              <b className="text-zinc-800 text-lg font-bold">{title}</b>
              <img src={Close} alt="close" className="w-6 h-6" onClick={onClose} />
            </div>
            <p className="flex text-zinc-500 text-sm mt-[20px] mb-[20px] pl-0">{tabletCustomInformationMessage}</p>
          </div>
        )
      }
      footer={
        <div>
          <button
            onClick={() => {
              if (onSave) {
                onSave();
              }
              if (onSelectedSave) {
                onSelectedSave(newList[selected]);
              }
              onClose();
            }}
            className={classNames('p-[14px] items-center justify-center rounded text-white w-full text-sm font-bold bg-violet-700')}
          >
            저장
          </button>
        </div>
      }
      ref={sheetRef}
      onDismiss={onClose}
    >
      <div className="flex justify-center flex-col">
        <ul className="list-none m-0 p-0 h-[calc(100% - 40px)] overflow-y-scroll relative" ref={ref} onScroll={handleScroll}>
          <div className="px-5">
            {newList.map((item, index) => (
              <li
                className={`text-xl rounded h-[50px] w-full flex items-center justify-center ${index === selected ? 'bg-indigo-50 font-bold opacity-100' : 'opacity-40'}`}
                key={index}
                ref={(el) => (itemRefs.current[index] = el)}
                onClick={() => {
                  setSelected(index);
                  itemRefs.current[index]?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  });
                  onSelectedChange && onSelectedChange(newList[index]);
                }}
              >
                {item}
              </li>
            ))}
          </div>
        </ul>
      </div>
    </BottomSheet>
  );
};

export default BottomScrollPicker;
