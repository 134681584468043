import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const IndexPage = (): React.ReactElement => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/waiting/dashboard', { replace: true });
  }, []);

  return <div />;
};
export default IndexPage;
