import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useState } from 'react';

import FooterSub from '@/components/FooterSub/FooterSub';
import HeaderSub from '@/components/HeaderSub/HeaderSub';
import TimePicker from '@/components/TimePicker/TimePicker';
import dayjs from 'dayjs';
import { useMallStore } from '@/stores/common/useMallStore';
import { usePolicyStore } from '@/stores/common/usePolicyStore';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import { useLayoutStore } from '@/stores/common/useLayoutStore';
import HeaderSubType from '@/components/HeaderSubType/HeaderSubType';
import { autoHypenTel } from '@/shared/lib/functions/auto-hypen-tel';

const INITIAL_STEP_TEXTS = {
  header: (
    <>
      예약 시간을 <br />
      선택 해주세요.
    </>
  ),
  footer: { save: '저장', back: '뒤로', next: '다음' },
};

const Step2 = (): React.ReactElement => {
  const { visitDate, visitTime, setVisitTime, setParty, setRooms, depositInfo, setDepositInfo, setReservationResetStore, userInfo } = useReservationStore();
  const { reservationDays } = usePolicyStore();
  const { mall } = useMallStore();
  const { fromApp } = useLayoutStore();
  const [selectTime, setSelectTime] = useState(false);
  const [searchParams] = useSearchParams({ type: 'default' });

  const navigate = useNavigate();

  const today = dayjs();

  // const bookingTimes = visitDate ? reservationDays[dayNamesTypeB[visitDate.day()]].bookingTimes : [];

  const handleOnRouterMove = (path) => navigate(path);

  const handleOnClose = () => {
    if (searchParams.get('type') === 'default') {
      setReservationResetStore();
      navigate('/reservation/list');
    }
    if (searchParams.get('type') === 'modify' || searchParams.get('type') === 'deposit') navigate(-1);
  };

  if (searchParams.get('type') === 'deposit' && !depositInfo?.depositDue) navigate('/reservation/accept/step-1?type=deposit');

  return (
    <>
      {fromApp ? (
        <>
          <HeaderSubType header={autoHypenTel(userInfo.userPhone)}>{INITIAL_STEP_TEXTS.header}</HeaderSubType>
        </>
      ) : (
        <HeaderSub onClose={handleOnClose}>{INITIAL_STEP_TEXTS.header}</HeaderSub>
      )}
      <section className="timepicker-wrapper">
        <TimePicker
          onSelect={(target) => {
            setSelectTime(true);
            if (searchParams.get('type') === 'deposit') {
              setDepositInfo({ ...depositInfo, depositDue: target });
              // handleOnRouterMove()
            } else if (searchParams.get('type') === 'modify') {
              setVisitTime(target);
              setParty([]);
              setRooms([]);
              handleOnRouterMove(-1);
            } else {
              setVisitTime(target);
              handleOnRouterMove('/reservation/accept/step-3/');
            }
          }}
          selectedDate={visitTime ? visitTime : today}
          startHour={mall.operationTime.openTime / 60}
          endHour={mall.operationTime.closeTime / 60}
        />
      </section>
      <FooterSub isVisible isBorder isBackArea noTransparent>
        {searchParams.get('type') === 'default' || searchParams.get('type') === 'modify' ? (
          <div className="font__heading_5 flex-container items-center">
            {visitDate?.format('MM.DD(ddd)')}
            {visitDate && visitTime && ', '} {visitTime?.format('a HH:mm')}
          </div>
        ) : null}
        {searchParams.get('type') === 'deposit' ? (
          <div className="font__heading_5 flex-container items-center">
            {depositInfo?.depositDue?.format('MM.DD(ddd)')}
            {selectTime && depositInfo?.depositDue?.format(', a HH:mm')}
          </div>
        ) : null}
        <div className="flex-container gap-8">
          {searchParams.get('type') === 'modify' ? (
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="footer tblm-button-normal tblm-btn-primary"
            >
              {INITIAL_STEP_TEXTS.footer.save}
            </button>
          ) : null}
          {searchParams.get('type') === 'default' ? (
            <>
              <Link to={'/reservation/accept/step-1/'}>
                <button className="tblm-button-normal">{INITIAL_STEP_TEXTS.footer.back}</button>
              </Link>
              <Link to={'/reservation/accept/step-3/'}>
                <button disabled={Boolean(!visitTime)} className="tblm-button-normal tblm-btn-primary">
                  {INITIAL_STEP_TEXTS.footer.next}
                </button>
              </Link>
            </>
          ) : null}
          {/* {searchParams.get('type') === 'deposit' ? (
            <Link to="/reservation/accept/step-5/">
              <button className="tblm-button-normal tblm-btn-primary">{INITIAL_STEP_TEXTS.footer.save}</button>
            </Link>
          ) : null} */}
        </div>
      </FooterSub>
    </>
  );
};

export default Step2;
