import React from 'react';
import './KakaoSettingNotiBanner.scss';
import { ReactComponent as IcAlert } from '@/assets/images/icons/ic_alert.svg';

const KakaoSettingNotiBanner = () => {
  return (
    <div className="kakao-setting-noti-banner-wrapper">
      <div className="kakao-setting-noti-banner">
        <IcAlert />
        <p>고정된 변수영역을 제외한 알림톡 내용을 일부 수정할 수 있습니다.</p>
      </div>
    </div>
  );
};

export default KakaoSettingNotiBanner;
