import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useMallStore } from '@/stores/common/useMallStore';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import Terces from '@/shared/apis/terces';
import { useLayoutStore } from '@/stores/common/useLayoutStore';
import useNative from '@/hooks/useNative';
import { useDevServerApi } from '@/shared/apis/devServerApi';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import { parseJwt } from '@/shared/lib/functions';
import dayjs from 'dayjs';

const AuthPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setAccessToken, setRefreshToken } = useAuthStore();
  const { userInfo, setUserInfo } = useReservationStore();
  const { setFromApp } = useLayoutStore();
  const accessToken = searchParams.get('accessToken');
  const refreshToken = searchParams.get('refreshToken');
  const redirect = searchParams.get('redirect');
  const { callAlert, pageMove } = useNative();
  const devServerApi = useDevServerApi();

  useEffect(() => {
    /// alert current full url
    // alert(window.location.href);
    try {
      if (!accessToken || !refreshToken) {
        callAlert('AI전화를 먼저 설정해주세요.', 'AI전화 설정 이후에 가능합니다.\nAI전화를 설정할까요?', [{ btnNm: 'AI전화 설정하기', value: () => pageMove('ai') }]);
        throw new Error('Invalid Token Data');
      }
      // process.env.NODE_ENV === 'production' && alert('accessToken:::' + accessToken);
      // process.env.NODE_ENV === 'production' && alert('refreshToken:::' + refreshToken);
      // process.env.NODE_ENV === 'production' && alert('jwt decoded:::' + JSON.stringify(parseJwt(accessToken)))

      setAccessToken(accessToken);
      setRefreshToken(refreshToken);

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      if (redirect.startsWith('/reservation/accept/step-1')) {
        setFromApp(true);
        const userName = searchParams.get('userName');
        const userPhone = searchParams.get('userPhone');
        const redirectWithoutParam = redirect.split('?')[0];
        console.log();
        devServerApi.api.authGet('/get-webview-key').then((res) => {
          // @ts-ignore
          if (res.status < 0) return;
          Terces.fetchYek(res);
          devServerApi.api
            .post('/reservation/get-users', Terces.encrypt({ phone: userPhone }))
            .then(({ data: enc_res }: any) => Terces.decrypt(enc_res))
            .then((getUserRes) => {
              if (getUserRes && getUserRes.users.length > 0) {
                let target = getUserRes.users[0];
                setUserInfo({
                  ...userInfo,
                  userId: target.userId,
                  userMemo: target.userMemo,
                  userName: target.userName,
                  userNickname: target.userNickname,
                  userPhone: target.userPhone,
                  isSearch: true,
                });
                navigate(redirectWithoutParam, { replace: true });
              } else {
                setUserInfo({
                  ...userInfo,
                  userName: userName,
                  userPhone: userPhone
                    .replace(/[^0-9]/g, '')
                    .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
                    .replace('--', '-'),
                  isSearch: false,
                });
                navigate(redirectWithoutParam, { replace: true });
              }
            })
            .catch(() => {})
            .finally(() => {});
        });
      } else if (redirect.startsWith('/reservation/list')) {
        let date = searchParams.get('date');
        const orderId = searchParams.get('orderId');
        if (!date) {
          date = dayjs().format('YYYY-MM-DD');
        }
        if (orderId) {
          navigate(redirect + `&orderId=${orderId}`, { replace: true, state: date });
        } else {
          navigate(redirect, { replace: true, state: date });
        }
      } else {
        navigate(redirect || '/', { replace: true });
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  return <SpinnerEffector loading />;
};

export default AuthPage;
