import './ReservationListCalendar.scss';

import React, { useEffect, useState } from 'react';

import HeaderSub from '@/components/HeaderSub/HeaderSub';
import MonthCalendar from '@/components/MonthCalendar/MonthCalendar';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import SpinnerEffector from '../Spinner/SpinnerEffector';
import dayjs from 'dayjs';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useQuery } from 'react-query';
import { useReservationCalendarStore } from '@/stores/reservation/useReservationCalendarStore';
import HeaderTitle from '../HeaderTitle/HeaderTitle';
import useNative from '@/hooks/useNative';

const ReservationListCalendar = ({ visitDate, setVisitDate, onClose }): React.ReactElement => {
  const { reservationList } = useReservationCalendarStore();
  const [eventDays, setEventDays] = useState([]);
  const { showGNB, hideGNB } = useNative();

  const today = dayjs();
  const { isFetching } = useQuery([QUERY_KEYS.GET_EVENT_DAY], () => devServerApi.api.authGet('/eventDays'), {
    onSuccess: (res: any) => {
      setEventDays(res.eventdays.map((item) => ({ date: dayjs(`${item.year}-${item.month}-${item.day}`), memo: '테스트 휴무' })));
    },
  });

  useEffect(() => {
    hideGNB(true, false);
    return showGNB;
  }, []);

  if (isFetching) return <SpinnerEffector loading />;
  return (
    <>
      <HeaderTitle onClose={onClose} title="월간 예약 현황" noBorder />
      <section className="month-calendar-wrapper">
        <MonthCalendar
          select={(target) => {
            setVisitDate(target);
            onClose();
          }}
          startDate={today.add(-1, 'month')}
          targetDate={today}
          selectedDate={visitDate}
          disabledBeforeDay={today}
          orderList
          period={6}
          holidays={eventDays}
          headerHeight={55}
          reservationList={reservationList}
        />
      </section>
    </>
  );
};

export default ReservationListCalendar;
