import React, { useEffect, useState } from 'react';

import AGREEMENT_LIST from '@/components/Agreement/Agreement.constants';
import { ReactComponent as Arrow_Right } from '@/assets/images/icons/Arrow_Right.svg';
import { devServerApi } from '@/shared/apis/devServerApi';
import styles from './index.module.scss';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useNavigate } from 'react-router-dom';
import { useSettingTutorialStore } from '@/stores/common/useSettingTutorialStore';
import { essentialTutorialApi } from '@/shared/apis/tutorialApi';
import { parseInt } from 'lodash';

const Setting = (): React.ReactElement => {
  // @ts-ignore
  const navigate = useNavigate();
  const { accessToken, payload } = useAuthStore();
  const { state, setState, agreements, setAgreements } = useSettingTutorialStore();
  const [b2cStatus, setb2cStatus] = useState();
  const [isChecked, setIsChecked] = useState(null);

  const getStateString = () => {
    if (b2cStatus === 'using') {
      return '우리가게AI가 응대';
    }
    if (b2cStatus === 'disable') {
      return '예약 불가 안내';
    }
    if (b2cStatus === 'forwarding') {
      return '매장으로 연결';
    }
    return '';
  };

  const postScreenHeightToWebView = () => {
    let listHeight: number = 0;
    const listNodes = document.querySelectorAll(`.${styles.reservation_nav} > *`);
    listNodes.forEach((e) => {
      listHeight += parseInt(getComputedStyle(e).height);
    });
    if (window.ReceiveReservationSettingsPageHeight && listHeight > 0) {
      window.ReceiveReservationSettingsPageHeight.postMessage(listHeight.toString());
    }
  };

  useEffect(() => {
    devServerApi.api
      .authGet('/terms-agree')
      .then(({ response }) => {
        let essentialAgreementsList = AGREEMENT_LIST.filter((a) => a.isEssential).map((a) => a.name);
        for (let a of response) {
          if (a.isAgree) {
            essentialAgreementsList = essentialAgreementsList.filter((e) => e !== a.type);
          }
        }
        setAgreements(essentialAgreementsList.length === 0);
      })
      .catch(() => {
        setAgreements(false);
      });
    devServerApi.api
      .authGet('/tutorial')
      .then(({ tutorial }) => setState(tutorial))
      .catch(() => {
        setState('null');
      });
    devServerApi.api
      .authGet('/settings/reservation/state')
      .then(({ status }) => {
        setb2cStatus(status);
      })
      .catch((e) => {
        setb2cStatus(null);
      });
  }, []);

  useEffect(() => {
    // setIsChecked(state === 'done' && agreements && b2cStatus === 'using');
    setIsChecked(true);
  }, [state, agreements, b2cStatus]);

  useEffect(() => postScreenHeightToWebView(), [isChecked]);

  const reservationMenuData = [
    {
      id: 'reservationMenu1',
      name: '기본정보',
      path: '/setting/essential',
    },
    {
      id: 'reservationMenu2',
      name: '일정관리',
      path: '/setting/schedule',
    },
    {
      id: 'reservationMenu3',
      name: '테이블',
      path: '/setting/table',
    },
    {
      id: 'reservationMenu4',
      name: '예약정책',
      path: '/setting/policy',
    },
    {
      id: 'reservationMenu5',
      name: '온라인 예약 연동',
      path: '/setting/channel',
    },
    {
      id: 'reservationMenu6',
      name: '내 카카오톡 채널',
      path: '/setting/kakao-channel',
    },
    {
      id: 'reservationMenu7',
      name: '예약 메시지',
      path: '/setting/kakao-reservation-message',
    },
    {
      id: 'reservationMenu8',
      name: '대기 메시지',
      path: '/setting/kakao-waiting-message',
    },
    {
      id: 'reservationMenu9',
      name: '가게정보 안내 메시지',
      path: '/setting/kakao-normal-mall-message',
    },
    {
      id: 'reservationMenu10',
      name: '매장홍보 메시지',
      path: '/setting/kakao-ad-mall-message',
    },
  ];

  const routePush = (path) => {
    if (state === 'done' || path === '/setting/test') {
      if (window.NavigatePage) window.NavigatePage.postMessage(path);
      else navigate(path);
    } else {
      if (window.NavigatePage) window.NavigatePage.postMessage('/tutorial/' + (!state || state === 'null' ? 'essential' : ''));
      else navigate('/tutorial/' + (!state || state === 'null' ? 'essential' : ''));
    }
  };

  const reservationOn = () => {
    if (!agreements) {
      if (window.NavigatePage) window.NavigatePage.postMessage('/setting/agreement');
      else navigate('/setting/agreement');
      return;
    }
    if (state !== 'done') {
      if (window.NavigatePage) window.NavigatePage.postMessage('/tutorial');
      else navigate('/tutorial', { replace: true });
      return;
    }
    if (window.NavigateReservationSettingsPage) window.NavigateReservationSettingsPage.postMessage('');
  };

  // @ts-ignore
  return (
    <>
      <ul className={styles.reservation_nav}>
        <li className={styles.reservation_nav__list} onClick={() => reservationOn()}>
          <div className={styles.reservation_nav_tabletCustomInformationMessage}>
            <div>예약</div>
            <div className={styles.reservation_nav_using_state}>{getStateString()}</div>
          </div>
          <div className={isChecked ? styles.reservation_nav_using : styles.reservation_nav_unusing}>
            {isChecked ? '사용' : '미사용'}
            <Arrow_Right />
          </div>
        </li>
        {isChecked
          ? reservationMenuData.map((menu, i) => (
              <li className={styles.reservation_nav__list} onClick={() => routePush(menu.path)} key={`${menu.id}_${i}`}>
                {menu.name}
                <Arrow_Right />
              </li>
            ))
          : null}
      </ul>
    </>
  );
};

export default Setting;
