import cloneDeep from 'lodash/cloneDeep';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { getSelectValues } from '@/shared/lib/functions';
import { IPersistStore, IQuestion, IQuestionAction, Nullable } from '@/shared/utils/common.type';

const INITIAL_QUESTIONS: IQuestion = {
  step1_0: {
    value: null,
    question: '우리매장의 사업자정보를\n입력해주세요.',
    note: '본 서비스는 사업자만\n이용할 수 있는 서비스 입니다.',
    type: 'plain',
    nextStep: {
      api: ['business', 'essential'],
    },
  },
};

export const questionReducer = (state: IQuestion, action: IQuestionAction) => {
  const { type, value, step, multiple, idx } = action;
  const copyState = cloneDeep(state);
  switch (type) {
    case 'SET_CHANGE': {
      copyState[step].value = value;
      return copyState;
    }
    case 'SET_REF_CHANGE': {
      copyState[step].value[idx][1] = value;
      return copyState;
    }
    case 'SET_SELECT_CHANGE': {
      if (multiple) {
        copyState[step].value = getSelectValues(value);
      } else {
        copyState[step].value = value.value;
      }
      return copyState;
    }
    default: {
      return state;
    }
  }
};

export const useBusinessInfoSettingStore = create(
  persist<IPersistStore>(
    (set) => ({
      // @ts-ignore
      storageQuestion: INITIAL_QUESTIONS,
      setStorageQuestion: (storageQuestion) => set({ storageQuestion: storageQuestion }),
      resetStorageQuestion: () => set({ storageQuestion: INITIAL_QUESTIONS }),
    }),
    { name: 'business-info-setting', getStorage: () => localStorage, version: 1 }
  )
);
