import { checkOS } from '@/shared/lib/functions/check-os';
import dayjs, { Dayjs } from 'dayjs';
import { IReservationFilter, useNativeStore } from '@/stores/reservation/useNativeStore';
import { IReservation } from '@/shared/utils/common.type';
import { devServerApi } from '@/shared/apis/devServerApi';
import Terces from '@/shared/apis/terces';
import queryClient from '@/shared/utils/queryClient';
import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import { useAuthStore } from '@/stores/common/useAuthStore';

interface ICallNative {
  type: string;
  param?: {
    [key: string]: any;
  };
}

interface IResponse {
  temp_user: string;
  soho_token: string;
}

type nativeCall = (arg0: string) => void;

interface ISoho {
  sendEvent: nativeCall;
}

interface AlertButtonItem {
  btnNm: string;
  value: any;
}

export interface IWebkit {
  messageHandlers: {
    sendEvent: {
      postMessage: nativeCall;
    };
  };
}

type messageType =
  | 'SOHO_ACTION_EVENT'
  | 'SOHO_CALL_ALERT'
  | 'TM_CALENDAR_SUBMIT'
  | 'TM_RESERVATION_CHANGE'
  | 'TM_RESERVATION_EDIT'
  | 'SOHO_REQUEST_TOKEN'
  | 'TM_RESERVATION_FILTER_SUBMIT'
  | 'TM_RESERVATION_ON_SUBMIT';

export interface IMessageEvent {
  type: messageType;
  param?: {
    [key: string]: any;
  };
}

type reservationStateType = 'valid' | 'exit' | 'enter' | 'canceled' | 'noShow' | 'cancel' | 'rollback';

interface IReservationChange {
  reservationId: string;
  // cancel 값은 API 통신 규격이 canceled 대신 사용하고 있어 예외 처리
  // rollback 값은 valid 로 사용하나 해당 동작 시 API 통신 규격에 userId 값이 포함되어 예외 처리
  reservationState?: reservationStateType;
  sendCancelAlarm?: boolean;
  colorId?: string;
}

interface Duplicate {
  retSysMsg: String;
  retCode: String;
  retMsg: String;
}

let alertCallbacks = {};

const randomId = function (length = 6) {
  return Math.random()
    .toString(36)
    .substring(2, length + 2);
};

// Android WebView Interface
declare const SohoInterface: ISoho;

const useNative = () => {
  const os = checkOS();
  const {
    setViewDate,
    filter,
    viewDate,
    routeAction,
    reservationDetailId,
    requestReservationEnable,
    allReservation,
    setAllReservation,
    setReservationDetailId,
    setRouteAction,
    setFilter,
    setRequestReservationEnable,
  } = useNativeStore();
  const navigate = useNavigate();
  const { setAccessToken, setRefreshToken } = useAuthStore();

  const call = (arg0: ICallNative) => {
    try {
      console.debug('callNative', arg0);
      switch (os) {
        case 'android':
          SohoInterface && SohoInterface.sendEvent(JSON.stringify(arg0));
          break;
        case 'ios':
          window.webkit.messageHandlers.sendEvent.postMessage(JSON.stringify(arg0));
          break;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const openReservationCalendar = (date: Dayjs) =>
    call({
      type: 'TM_OPEN_CALENDAR',
      param: { date: date.format('YYYY-MM') },
    });

  const openReservationCard = (reservation: IReservation, reservationMarkingColors: any[]) =>
    call({
      type: 'TM_OPEN_RESERVATION',
      param: {
        reservation: {
          ...reservation,
          userPhone: reservation.userPhone?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3'),
          reservationMarkingColors,
        },
      },
    });

  const openReservationFilter = (groups: [string, string][]) =>
    call({
      type: 'TM_OPEN_FILTER',
      param: { groups, filter },
    });

  const openReservationOffAlert = () =>
    call({
      type: 'TM_RESERVATION_OFF_ALERT',
    });

  const hideGNB = (top = true, bottom = true) => {
    top &&
      call({
        type: 'SOHO_SET_TOP_GNB',
        param: { flag: 1 },
      });

    bottom &&
      call({
        type: 'SOHO_SET_GNB',
        param: { flag: 1 },
      });
  };

  const showGNB = () => {
    call({
      type: 'SOHO_SET_GNB',
      param: { flag: 0 },
    });
    call({
      type: 'SOHO_SET_TOP_GNB',
      param: { flag: 0 },
    });
  };

  const callAlert = (title: string, contents: string, items: AlertButtonItem[]) => {
    items.forEach((item) => {
      if (typeof item.value === 'function') {
        const id = randomId();
        alertCallbacks[id] = item.value;
        item.value = 'cb:::' + id;
      }
    });

    return call({
      type: 'SOHO_CALL_ALERT',
      param: {
        title,
        contents,
        items,
      },
    });
  };

  const pageMove = (page: string) => {
    const pageParam = {
      setting: {
        tab_code: 'ai',
        webview_code: 'ai',
        webview_subcode: 'setting',
        back_subcode: 'tmreservation',
      },
      ai: {
        tab_code: 'ai',
        webview_code: 'ai',
        back_subcode: 'tmreservation',
      },
    };

    if (!pageParam[page]) {
      return;
    }

    return call({
      type: 'PAGE_MOVE',
      param: pageParam[page],
    });
  };

  const reservationColorChange = (param: IReservationChange, reservation: IReservation) => {
    const originReservationMarkingColorId = reservation.reservationMarkingColorId;
    devServerApi.api.patch('/reservations/' + param.reservationId, Terces.encrypt({ reservationMarkingColorId: param.colorId })).catch(() => {
      reservation.reservationMarkingColorId = originReservationMarkingColorId;
    });
    reservation.reservationMarkingColorId = param.colorId;
    setAllReservation(allReservation);
  };

  const reservationChange = async (param: IReservationChange) => {
    const reservation = allReservation.find((e) => e.reservationId === param.reservationId);

    console.debug('param : ', param);
    console.debug('reservations : ', allReservation, filter, viewDate, routeAction, reservationDetailId, requestReservationEnable);
    // 컬러값 변경 요청인 경우 reservationColorChange 에서 처리
    if (param.colorId) return reservationColorChange(param, reservation);

    const originState = reservation.reservationState;
    const data = {
      reservationState: param.reservationState,
    };

    /* eslint-disable dot-notation */
    if (param.reservationState === 'canceled') {
      data['reservationState'] = 'cancel';
      data['userId'] = reservation.userId;
      data['cancelMessageTemplate'] = param.sendCancelAlarm;
    }
    if (param.reservationState === 'rollback') {
      // param.reservationState = 'valid';
      data['reservationState'] = 'valid';
      data['userId'] = reservation.userId;
    }
    /* eslint-enable dot-notation */

    await devServerApi.api
      .patch('/reservations/' + param.reservationId, Terces.encrypt(data))
      .catch(() => {
        callAlert('오류', '상태 변경에 실패하였습니다.', [{ btnNm: '확인', value: 'close' }]);
        reservation.reservationState = originState;
        switch (reservation.reservationState) {
          case 'enter':
            reservation.enteredAt = null;
            break;
          case 'exit':
            reservation.exitedAt = null;
            break;
        }
      })
      .then(() => {
        if (param.reservationState === 'rollback') {
          return devServerApi.api
            .authGet('reservations/' + param.reservationId)
            .then((enc_res: any) => Terces.decrypt(enc_res))
            .then((res: any) => {
              reservation.reservationState = res.reservation.reservationState;
            });
        }
        reservation.reservationState = param.reservationState;
        return Promise.resolve();
      });
    switch (reservation.reservationState) {
      case 'enter':
        reservation.enteredAt = dayjs().format();
        break;
      case 'exit':
        reservation.exitedAt = dayjs().format();
        break;
    }

    setAllReservation(allReservation);
  };

  const requestLogout = () => {
    call({
      type: 'SOHO_POP_LOGOUT',
      param: {
        title: '로그인 해제',
        contents: '다른 휴대전화 기기에서 동일 계정의 접속이 확인되어 해당 휴대전화 기기에서 로그아웃 됩니다.',
        logout_type: 'same_time',
      },
    });
  };

  const handleMessageByNative = (e: IMessageEvent) => {
    const { type, param } = e;
    console.debug('ReceiveFronNative', e);

    switch (type) {
      case 'SOHO_REQUEST_TOKEN':
        {
          const set = e.param.soho_token;
          devServerApi.configure({ headers: { Authorization: set } });

          try {
            const response = devServerApi.soho.post('sohosvc/api/v2/tokenCrt', {}).then((result) => {
              const retCode = result.data.retCode;
              if (retCode === '9003' || retCode === '1502' || retCode === '1503') {
                requestLogout();
              }
            });
          } catch (er) {
            console.log(er);
          }
        }
        break;

      case 'SOHO_ACTION_EVENT':
        if (param.action === 'keypress' && param.detail === 'back') {
          setRouteAction('back');
        }
        break;

      case 'TM_CALENDAR_SUBMIT':
        setViewDate(dayjs(param.date, 'YYYY-MM'));
        break;

      case 'TM_RESERVATION_CHANGE':
        reservationChange(param as IReservationChange);
        break;

      case 'TM_RESERVATION_EDIT':
        setReservationDetailId(param.reservationId);
        break;

      case 'TM_RESERVATION_FILTER_SUBMIT':
        setFilter(param as IReservationFilter);
        break;

      case 'TM_RESERVATION_ON_SUBMIT':
        setRequestReservationEnable(true);
        break;

      case 'SOHO_CALL_ALERT': {
        const value = param.value;
        if (value.startsWith('navigate')) {
          navigate(value.split(':::')[1]);
        }
        if (value.startsWith('cb')) {
          const id = value.split(':::')[1];
          if (alertCallbacks[id]) {
            alertCallbacks[id]?.().then(() => {
              delete alertCallbacks[id];
            });
          }
        }

        alertCallbacks = {};
        break;
      }

      default:
        break;
    }
  };

  window.NativeInvoker = handleMessageByNative;
  const initMessageListener = () => {
    window.NativeInvoker = handleMessageByNative;
  };

  const requestToken = () => {
    call({
      type: 'SOHO_REQUEST_TOKEN',
    });
    initMessageListener();
  };

  window.NativeBack = () => {
    handleMessageByNative({
      type: 'SOHO_ACTION_EVENT',
      param: {
        action: 'keypress',
        detail: 'back',
      },
    });
  };

  return {
    allReservation,
    openReservationCalendar,
    openReservationCard,
    openReservationFilter,
    openReservationOffAlert,
    initMessageListener,
    hideGNB,
    showGNB,
    callAlert,
    pageMove,
    requestToken,
    os,
  };
};

export default useNative;
