import React from 'react';
import { ISpinner } from './Spinner.type';
import {} from './Spinner.constants';
import './Spinner.scss';
import classnames from 'classnames';

const Spinner = ({ className }: ISpinner): React.ReactElement => {
  return (
    <div className={classnames('loading-spinner', className)}>
      <div className="loader" />
    </div>
  );
};

export default Spinner;
