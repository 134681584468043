import classNames from 'classnames';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import './CheckReservation.scss';
import IconTextButton from '@/components/IconTextButton/IconTextButton';

const CheckReservation = ({ handleMoveRoute }): React.ReactElement => {
  const { visitDate, visitTime, parties, rooms, userInfo } = useReservationStore();

  const methods = useFormContext();
  const {
    formState: { errors },
  }: any = methods;

  return (
    <>
      <div className="check-contents">
        <div className="check-item-wrapper">
          <div className={classNames('check-item', { error: errors.visitDate })} onClick={() => handleMoveRoute('/reservation/accept/step-1?type=modify')}>
            <div className="font__body_1">{visitDate ? <span className="highlight">{visitDate.format('MM.DD(ddd)')}</span> : <span>예약 날짜</span>}</div>
            <IconTextButton text="수정" />
          </div>
          <div className="error-message font__s_small">{errors.visitDate?.message}</div>
        </div>

        <div className="check-item-wrapper">
          <div className={classNames('check-item', { error: errors.visitTime })} onClick={() => handleMoveRoute('/reservation/accept/step-2?type=modify')}>
            <div className="font__body_1">{visitTime ? <span className="highlight">{visitTime.format('a HH:mm')}</span> : <span>예약 시간</span>}</div>
            <IconTextButton text="수정" />
          </div>
          <div className="error-message font__s_small">{errors.visitTime?.message}</div>
        </div>

        <div className="check-item-wrapper">
          <div className={classNames('check-item', { error: errors.parties || errors.rooms })} onClick={() => handleMoveRoute('/reservation/accept/step-3?type=modify')}>
            <div className="font__body_1">
              {parties.find((item) => item.size) ? (
                <>
                  {parties.map((item) => (
                    <span key={item.name} className="highlight">{`${item.name} ${item.size}`}</span>
                  ))}
                </>
              ) : (
                <span>예약 인원</span>
              )}
              {rooms.length > 0 ? <span className="highlight">{`${rooms.map((room) => room.name).join(', ')}`}</span> : <span>테이블</span>}
            </div>
            <IconTextButton text="수정" />
          </div>

          <div className="error-message font__s_small">{errors.parties?.message}</div>
          <div className={classNames('error-message font__s_small', { none: errors.parties?.message && errors.rooms?.message })}>{errors.rooms?.message}</div>
        </div>

        <div className="check-item-wrapper">
          <div className={classNames('check-item', { error: errors.userName || errors.userPhone })} onClick={() => handleMoveRoute('/reservation/accept/step-4?type=modify')}>
            <div className="font__body_1">
              {userInfo?.userName ? <span className="highlight">{userInfo?.userName}</span> : <span>이름</span>}
              {userInfo?.userPhone ? <span className="highlight">{userInfo?.userPhone}</span> : <span>전화번호</span>}
            </div>
            <IconTextButton text="수정" />
          </div>
          <div className="error-message font__s_small">{errors.userPhone?.message}</div>
        </div>
      </div>
    </>
  );
};

export default CheckReservation;
