import { IStep } from '@/shared/utils/common.type';

const useGetInitialStep = (storageState: any): string => {
  const state = Object.entries(storageState);

  const lastStep = state.reverse().find((e: [string, IStep]) => e[1].value !== null);
  return lastStep ? lastStep[0] : 'step1_0';
};

export default useGetInitialStep;
