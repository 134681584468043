import React from 'react';
import './Input.scss';
import classnames from 'classnames';

const Input = (props) => {
  const { error, warn } = props;
  return (
    <div className={classnames('custom-input-wrapper flex-col gap-4', props.className)}>
      <input {...props} className="font__body_6" />
      {error && <span className={classnames('font__body_7', { warn })}>{error}</span>}
    </div>
  );
};

export default Input;
