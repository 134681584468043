import React, { useEffect, useId, useState } from 'react';
import Facilities from '@/components/Settings/Facilities';
import Food from '@/components/Settings/Food';
import '@/styles/pages/tutorial/SettingBasicInformaion.scss';
import { ReactComponent as Close_BoottoMenu } from '@/assets/images/icons/Close_BoottoMenu.svg';
import classnames from 'classnames';
import QuestionContent from '@/components/QuestionContent/QuestionContent';
import { useNavigate } from 'react-router-dom';
import { useSettingTutorialStore } from '@/stores/common/useSettingTutorialStore';
import { essentialTutorialApi } from '@/shared/apis/tutorialApi';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { instantDisabler } from '@/shared/lib/functions';
import { initialFacilities } from '@/shared/lib/controlInfo';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Modal from '@/components/Modal/Modal';
import { useModalStore } from '@/stores/common/useModalStore';

import HeaderTitle from '@/components/HeaderTitle/HeaderTitle';
import ProgressBar from '@/components/ProgressBar/ProgressBar';

const initStep = [
  {
    title: '예약 설정을 \n 시작할게요.',
    next: '시작',
    comment: '예약을 받으시려면 \n 기본 세팅을 마쳐주셔야 해요!',
  },
  {
    title: '우리 매장은 \n 어떤 종류의 음식을 \n 판매하고 있나요?',
    next: '다음',
  },
  {
    title: '우리 매장에 있는 \n 편의시설을 모두 \n 골라주세요.',
    prev: '이전',
    next: '다음',
  },
];

const initBasicData = {
  foods: [],
  facility: [],
};

function SettingBaseInformaion() {
  const { accessToken } = useAuthStore();
  const { state, setState } = useSettingTutorialStore();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [stepList, setStepList] = useState(initStep);
  const [basicData, setBasicData] = useState(initBasicData);
  const closeConfirmModalId = useId();
  const { setAlertModal } = useModalStore();

  const onClickRouterButton = (e) => {
    const { name } = e.target;
    if (name === 'next') {
      if (currentStep === initStep.length - 1) {
        essentialTutorialApi(basicData);
        setState('table');
        navigate('/tutorial/table');
        return;
      }
      setCurrentStep(currentStep + 1);
    } else {
      // if (currentStep === 2) {
      //   setBasicData({ ...basicData, facility: [] });
      // }
      setCurrentStep(currentStep - 1);
    }
  };

  const setFacilitiesList = (facility) => {
    if (basicData.facility.some((item) => item.type_facility_id === facility.type_facility_id)) {
      basicData.facility = basicData.facility.filter((item) => item.type_facility_id !== facility.type_facility_id);
    } else {
      basicData.facility = [...basicData.facility, facility];
    }
    setBasicData(basicData);
  };

  const sectionSplit = () => {
    switch (currentStep) {
      case 0:
        return;
      case 1:
        return <Food basicData={basicData} setBasicData={setBasicData} />;
      case 2:
        return (
          <>
          <Facilities
            edit={true}
            facilitiesList={initialFacilities.map((f) => ({ ...f, checked: basicData.facility.some((ff) => ff.type_facility_id === f.type_facility_id) }))}
            setFacilitiesList={setFacilitiesList}
          />
          <div style= {{ paddingBottom: '60px' }}/>
         </>
        );
      default:
        alert('error');
        return <></>;
    }
  };

  useEffect(() => {
    sectionSplit();
  }, [currentStep]);

  return (
    <div className={classnames('tutorial-page')}>
      <HeaderTitle onClose={() => setAlertModal({ visible: true, key: closeConfirmModalId })}>
        <ProgressBar steps={initStep.length} currentStep={currentStep + 1} />
      </HeaderTitle>
      <div className="tutorial-content-container essential-tutorial-content-container">
        <QuestionContent question={stepList[currentStep].title} option={sectionSplit()} note={stepList[currentStep].comment} type={null} value={null} key={currentStep} />
      </div>
      <div className={classnames('button-container')}>
        {!stepList[currentStep]?.prev ? null : (
          <button className="prev" name="prev" onClick={onClickRouterButton}>
            {stepList[currentStep]?.prev}
          </button>
        )}
        {!stepList[currentStep]?.next ? null : (
          <button className="next" name="next" onClick={instantDisabler(onClickRouterButton)}>
            {stepList[currentStep]?.next}
          </button>
        )}
      </div>
      <ModalPortal>
        {/* Alert 공통화 가능 요소 */}
        <Modal.Alert
          key={closeConfirmModalId}
          modal="alert"
          isDim={true}
          isAnimation={true}
          modalData={{
            title: '알림',
            content: '예약 설정을 그만하시겠어요? \n 설정을 마치지 않으면 예약 기능을 \n 이용할 수 없어요.',
            buttons: [
              {
                text: '그만하기',
                onClick: () => {
                  setAlertModal({ visible: false, key: closeConfirmModalId });
                  setBasicData(initBasicData);
                  setCurrentStep(0);
                  navigate('/reservation/list', { replace: true });
                },
              },
              {
                text: '계속하기',
                success: true,
                onClick: () => setAlertModal({ visible: false, key: closeConfirmModalId }),
              },
            ],
          }}
        />
      </ModalPortal>
    </div>
  );
}

export default SettingBaseInformaion;
