import classNames from 'classnames';
import React from 'react';
import { ReactComponent as Arrow_Down } from '@/assets/images/icons/Arrow_Down.svg';
import { ISelect } from './Select.type';
import './Select.scss';

const Select = ({ value, selected, textClass = 'font__body_6', ...props }: ISelect): React.ReactElement => {
  const resultValue = value ? value : '선택';

  return (
    <div className={classNames('custom-select-wrapper', { disabled: props.disabled })} {...props}>
      <input className={classNames(textClass, { isSelected: Boolean(value) })} type="text" value={resultValue} readOnly />
      <Arrow_Down />
    </div>
  );
};

export default Select;
