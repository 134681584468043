import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import TableEdit from '@/components/TableEdit/TableEdit';
import TableView from '@/components/TableView/TableView';
import { devServerApi } from '@/shared/apis/devServerApi';
import QUERY_KEYS from '@/shared/apis/queryKeys/TABLE_SETTING';
import { ITables, ITablesGroup, Nullable } from '@/shared/utils/common.type';

// const mockTableGroups: ITablesGroup[] = [
//   {
//     groupName: '테이블명 늘어날 경우 한줄처리 1줄 처리합니다 테이블명 늘어날 경우 1줄 처리1줄 처리합니다',
//     groupId: '1',
//     item: [
//       {
//         enabled: true,
//         _id: '1',
//         peopleMax: 4,
//         peopleMin: 2,
//         name: '테이블명 늘어날 경우 1줄 처리1줄 처리합니다 테이블명 늘어날 경우 1줄 처리1줄 처리합니다',
//       },
//       {
//         enabled: true,
//         _id: '2',
//         peopleMax: 4,
//         peopleMin: 2,
//         name: '홀2',
//       },
//       {
//         enabled: true,
//         _id: '3',
//         peopleMax: 4,
//         peopleMin: 2,
//         name: '홀3',
//       },
//     ],
//   },
//   {
//     groupName: '123456789012345678901234567890',
//     groupId: '2',
//     item: [
//       {
//         enabled: true,
//         _id: '4',
//         peopleMax: 4,
//         peopleMin: 2,
//         name: '홀1 테이블명 늘어날경우 테이블명 늘어날',
//       },
//     ],
//   },
//   {
//     groupName: '홀2',
//     groupId: '3',
//     item: [
//       {
//         enabled: true,
//         _id: '5',
//         peopleMax: 4,
//         peopleMin: 2,
//         name: '1룸',
//       },
//       {
//         enabled: true,
//         _id: '6',
//         peopleMax: 4,
//         peopleMin: 2,
//         name: '2룸',
//       },
//     ],
//   },
// ];

const TablePage = (): React.ReactElement => {
  const [isTableEdit, setIsTableEdit] = useState(false);
  const [tableGroups, setTableGroups] = useState<Nullable<ITablesGroup[]>>();
  useQuery([QUERY_KEYS.TABLES], () => devServerApi.api.authGet<ITables>('/settings/tables'), {
    onSuccess: (res: ITables) => setTableGroups(res.list),
  });
  return <>{isTableEdit ? <TableEdit list={tableGroups} edit={setIsTableEdit} commit={setTableGroups} /> : <TableView list={tableGroups} edit={setIsTableEdit} />}</>;
};

export default TablePage;
