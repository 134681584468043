import React, { useEffect, useState } from 'react';
import '../TutorialKakaoChannel/TutorialKakaoChannelName.scss';
import { ReactComponent as IcWarning } from '@/assets/images/icons/ic_warning.svg';
import KakaoInput from '@/components/KakaoInput/KakaoInput';
import { ITutorialKakaoChannelProps } from '@/components/TutorialKakaoChannel/TutorialKakaoChannel.type';
import { ITutorialInputProps } from './TutorialInput.type';
import { ControlledInput } from '@/hook-form/ControlledInput';

const TutorialInput = ({ name, form, title, tabletCustomInformationMessage, placeholder, valueDecorator, maxLength = 20, type }: ITutorialInputProps) => {
  // const [value, setValue] = useState<string>(null);
  const { control, setValue } = form;

  const stringOrNull = (e: string | null): string | null => (e?.length > 0 ? e : null);

  // useEffect(() => dispatcher({ type: 'SET_CHANGE', value: stringOrNull(value), step }), [value]);

  return (
    <div className="tutorial-kakao-channel-content-container">
      <h1 className="tutorial-kakao-channel-title">{title}</h1>
      {tabletCustomInformationMessage && <span className="tutorial-kakao-channel-tabletCustomInformationMessage">{tabletCustomInformationMessage}</span>}
      <ControlledInput
        name={name}
        control={control}
        placeholder={placeholder}
        onChange={(e) => {
          valueDecorator ? setValue(name, valueDecorator(e.target.value)) : setValue(name, e.target.value);
        }}
        scrollContainer=".tutorial-content-container"
        scrollToTop
        maxLength={maxLength}
        className="tutorial"
        type={type}
      />
    </div>
  );
};

export default TutorialInput;
