import React, { useEffect } from 'react';
import { ReactComponent as Close } from '@/assets/images/icons/Close_BoottoMenu.svg';
import './docs/AgreementDoc.scss';
import useNative from '@/hooks/useNative';
import useBackButton from '@/hooks/useBackButton';

const AgreementDoc = ({ setDoc, children, isOpenMarketing, setIsOpenMarketing }): React.ReactElement => {
  const { hideGNB, showGNB } = useNative();
  useEffect(() => {
    hideGNB();
    // 선택 동의 내역 확인 -> 마케팅 수집 이용 동의 진입시 외에는 GNB표시
    return () => !isOpenMarketing && showGNB();
  }, []);

  const closeHandler = () => {
    isOpenMarketing ? setIsOpenMarketing(false) : setDoc(null);
    showGNB();
  };
  useBackButton(closeHandler);

  return (
    <div className="agreement-doc">
      <div className="agreement-doc-inner">
        <Close onClick={closeHandler} />
        {children}
      </div>
    </div>
  );
};

export default AgreementDoc;
