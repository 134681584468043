import React from 'react';
import { useParams } from 'react-router-dom';
import KakaoAlimtalkMessage from '@/components/KakaoAlimtalkMessage';
import { useKakaoAlimtalkStore } from '@/stores/common/useKakaoAlimtalkStore';

const messageProps = {
  create: {
    messages: {
      guideMessage: `[(상호명)]
안녕하세요 (고객명)고객님.
예약하신 내용을 알려드립니다.

- 예약명: (예약명)
- 예약일시 : (00)월 (00)일(요일) (00:00)
- 예약인원 : (00)명`,
      initMessage: `위와 같이 예약이 완료되었습니다.
예약 수정사항이 있으면 아래 번호로 연락바랍니다.
감사합니다.`,
      warningMessage: `주소 : (매장주소)
지도 : (지도 링크)
번호 : (매장 전화번호)`,
      toastMessage: '예약 안내 메시지가 저장되었습니다.',
    },
    saveNavigateUrl: '/setting/kakao-reservation-message',
    title: '예약 안내',
  },
  alarm: {
    messages: {
      guideMessage: `[(상호명)]
안녕하세요, (고객명)고객님.

- 예약명 : (예약명)
- 예약일시 : (00)월 (00)일 (오후/오전 00:00)
- 예약인원 : (00)명
- 메뉴 : (메뉴명)`,
      initMessage: `위와 같이 예약이 완료되었습니다.
예약 수정사항이 있으면 아래 번호로 연락바랍니다.
감사합니다.`,
      warningMessage: `주소 : (주소)
지도 : (지도)`,
      toastMessage: '당일 알림 메시지가 저장되었습니다.',
    },
    saveNavigateUrl: '/setting/kakao-reservation-message',
    title: '당일 알림',
  },
  change: {
    messages: {
      guideMessage: `[(상호명)]

안녕하세요 (고객명) 고객님.`,
      initMessage: `예약이 변경되어 안내 드립니다.`,
      warningMessage: `- 예약일시 : (00)월 (00)일(요일) (00:00)
- 예약인원 : (00)명
- 예약명: (예약명)`,
      toastMessage: '예약 수정 메시지가 저장되었습니다.',
    },
    saveNavigateUrl: '/setting/kakao-reservation-message',
    title: '예약 수정',
  },
  cancel: {
    messages: {
      guideMessage: `[(상호명)]

*예약 취소 안내
(0000.00.00)예약이 정상적으로 취소되었습니다.`,
      initMessage: ``,
      toastMessage: '예약 취소 메시지가 저장되었습니다.',
    },
    saveNavigateUrl: '/setting/kakao-reservation-message',
    title: '예약 취소',
  },
};

const KakaoReservationMessage = () => {
  const { type } = useParams();
  const { alimtalk } = useKakaoAlimtalkStore();

  if (!messageProps[type]) {
    throw new Response('Not Found', { status: 404 });
  }

  messageProps[type].type = 'reservation-' + type;
  messageProps[type].messages.initMessage = alimtalk.reservation[type]?.customMessage;
  return <KakaoAlimtalkMessage {...messageProps[type]} />;
};

export default KakaoReservationMessage;
