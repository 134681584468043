import { useEffect } from 'react';
import useNative from './useNative';
import useBackButton from './useBackButton';

const useBackToSettings = () => {
  const { pageMove } = useNative();

  const back = () => pageMove('setting');
  useBackButton(back);

  return back;
};

export default useBackToSettings;
