import React, { useEffect, useState } from 'react';
import TopBanner from '@/assets/images/waiting/waiting-top-banner.png';
import TopBannerMiddle from '@/assets/images/waiting/waiting-top-banner-middle.png';
import TopBannerLarge from '@/assets/images/waiting/waiting-top-banner-large.png';
import MainService from '@/assets/images/waiting/main-service.png';
import MainServiceMiddel from '@/assets/images/waiting/main-service-middle.png';
import MainServiceLarge from '@/assets/images/waiting/main-service-large.png';

import { useNavigate } from 'react-router-dom';
import '@/styles/base/tailwind.css';
import { devServerApi } from '@/shared/apis/devServerApi';
import useNative from '@/hooks/useNative';

const WaitingMainPage: React.FC = () => {
  const { showGNB } = useNative();
  const isTutorialDo = localStorage.getItem('tutorial');
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [bannerImage, setBannerImage] = useState(TopBanner);
  const [bannerInfo, setBannerInfo] = useState(MainService);
  const [bannerClass, setBannerClass] = useState('w-full');

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 1200) {
      setBannerImage(TopBanner);
      setBannerInfo(MainService);
      setBannerClass('w-full');
    } else if (windowWidth >= 1200 && windowWidth < 2000) {
      setBannerImage(TopBannerMiddle);
      setBannerInfo(MainServiceMiddel);
      setBannerClass('w-full');
    } else {
      setBannerImage(TopBannerLarge);
      setBannerInfo(MainServiceLarge);
      setBannerClass('w-full');
    }
  }, [windowWidth]);

  React.useEffect(() => {
    return () => showGNB();
  }, []);


  interface BusinessResponse {
    existsBusiness: boolean;
  }
  interface TermsResponse {
    response: [
      {
        type: String;
        isAgree: boolean;
        createdAt: String;
      }
    ];
  }

  async function executeFunctionsSequentially() {

    const responseBusiness = devServerApi.lg.authGet('/api/partner/mall/is-business') as Promise<BusinessResponse>;

    const termsAgreementsPromise = devServerApi.api.authGet('/terms-agree') as Promise<TermsResponse>;


    const isAgree = (await termsAgreementsPromise).response.length;

    const isBusiness = (await responseBusiness).existsBusiness;
    // 튜토리얼 비즈니스로 이동하고 닫을 경우 waiting으로 복귀
    if (isAgree <= 0) {
      // 약관에 동의하지 않은 경우, 약관 동의 페이지로 리다이렉트
      await navigate('/setting/agreement_wait?redirect=/waiting&isWaiting=true', { replace: true });
    } else if (!isBusiness) {
      // 비즈니스 정보가 없는 경우, 비즈니스 설정 튜토리얼 페이지로 리다이렉트
      navigate('/tutorial/business_wait?redirect=/waiting&isWaiting=true', { replace: true });
    } else {
      // 위의 두 조건에 해당하지 않는 경우, 대기 튜토리얼 페이지로 리다이렉트
      navigate('/waiting/tutorial?redirect=/waiting', { replace: true });
    }

  }

  return (
    <div className="min-w-screen mt-gnb relative bg-white">
      <div className="w-full">
        <img src={bannerImage} alt="waiting-top-banner" className={bannerClass} />
      </div>
      <img src={bannerInfo} alt="main-service" className="w-full relative -top-2" />
      <div className="w-full h-20" />
      <div className="p-5 fixed w-full bottom-0">
        <button
          onClick={() => {
            if (isTutorialDo === 'true') {
              navigate('/tutorial/kakao-channel_wait?redirect=/waiting');
            } else {
              executeFunctionsSequentially();
            }
          }}
          className="p-[14px] font-bold text-sm bg-violet-700 rounded w-full text-white"
        >
          설정하기
        </button>
      </div>
    </div>
  );
};

export default WaitingMainPage;
