import React, { Fragment } from 'react';
import './TutorialKakaoChannelGuide.scss';
import { ReactComponent as IcMessage } from '@/assets/images/icons/ic_message.svg';
import { ITutorialKakaoChannelGuideProps } from '@/components/TutorialKakaoChannel/TutorialKakaoChannelGuide.type';

const TutorialKakaoChannelGuide = (props: ITutorialKakaoChannelGuideProps) => {
  const { title, contents } = props;

  return (
    <div className="tutorial-kakao-channel-guide">
      <div className="tutorial-kakao-channel-guide-title">
        <IcMessage />
        <h1 className="tutorial-kakao-channel-guide-title-text">{title}</h1>
      </div>
      <div className="tutorial-kakao-channel-guide-content">
        {contents.map((e, i) => (
          <Fragment key={`content-${i}`}>
            <div className="content-item">
              <div className="content-item-tabletCustomInformationMessage">
                <p className="paragraph">{e.tabletCustomInformationMessage}</p>
                <span className="paragraph-ext">{e.secondarytabletCustomInformationMessage}</span>
              </div>
              <div className="content-item-image">
                <img alt="" src={e.image} />
              </div>
            </div>
            {i + 1 < contents.length && <div className="content-horizontal-divider" />}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default TutorialKakaoChannelGuide;
