import React from 'react';
import Close from '@/assets/images/btn/close.png';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { TutorialStep1, TutorialStep2, TutorialStep3, TutorialStep4, TutorialStep5, TutorialStep6, TutorialStep7 } from '@/components/WaitingTutorial';
import { devServerApi } from '@/shared/apis/devServerApi';
import queryClient from '@/shared/utils/queryClient';

import '@/styles/base/tailwind.css';
import useNative from '@/hooks/useNative';

const DEFAULT_INFORMATION_MESSAGE = `입장 순서가 가까워지면 카카오톡이나 문자로 안내 메세지가 나갑니다.\n인원수에 따라 입장 순서가 변경될 수 있습니다.\n입장 안내 메세지를 받으셨다면 매장 직원에게 확인 후 입장하실 수 있습니다.\n입장 순서가 되었을 때 매장 앞에 계시지 않을 경우 웨이팅이 취소될 수 있습니다.`;

export interface Form {
  // 대기 추가 설정 사용 여부
  isWaitingClosing?: boolean;
  // 대기 접수 최소 인원
  minPeople?: string;
  // 대기 접수 최대 인원
  maxPeople?: string;
  // 입장 임박 고객 메시지 전송 여부
  useCustomEnterWaitingAlarm?: boolean;
  // 입장 임박 전송 몇 팀 전에?
  enterWaitingAlarmNum?: string;
  // 입장 요청 후 자동 대기 취소 여부
  isCallExpiredCancel?: boolean;
  // 입장 요청 후 자동 대기 취소 시간
  callExpiredMinute?: string;
  // 기본 안내 사항
  tabletDefaultInformationMessage?: boolean;
  // 안내 메시지
  tabletCustomInformationMessage?: string;
}

const INIT_FORM = {
  isWaitingClosing: null,
  minPeople: '1명',
  maxPeople: '제한없음',
  useCustomEnterWaitingAlarm: null,
  enterWaitingAlarmNum: '1팀',
  isCallExpiredCancel: null,
  callExpiredMinute: '5분',
  tabletDefaultInformationMessage: true,
  tabletCustomInformationMessage: '',
};

const WaitingTutorialPage: React.FC = () => {
  const [step, setStep] = React.useState(0);
  const [form, setForm] = React.useState<Form>(INIT_FORM);
  const [isCanNext, setIsCanNext] = React.useState(false);
  const navigate = useNavigate();
  const { callAlert } = useNative();

  React.useEffect(() => {
    // MEMO 튜토리얼 하나라도 하면 로컬스토리지에 저장
    if (step === 0) {
      localStorage.setItem('tutorial', 'true');
    }
    if (step === 1) {
      setIsCanNext(true);
    }
    if (step === 3) {
      setIsCanNext(true);
    }
  }, [step]);

  const saveForm = async () => {
    if (form.isWaitingClosing === false) return;
    let requestBody = {};
    switch (step) {
      case 0:
        requestBody = {
          isWaitingClosing: form.isWaitingClosing,
        };
        break;
      case 1:
        requestBody = {
          minPeople: Number(form.minPeople.split('명')[0]),
          maxPeople: form.maxPeople === '제한없음' ? null : Number(form.maxPeople.split('명')[0]),
          isUsePeopleMinMax: true,
        };
        break;
      case 2:
        requestBody = {
          useCustomEnterWaitingAlarm: form.useCustomEnterWaitingAlarm,
        };
        break;
      case 3:
        requestBody = {
          enterWaitingAlarmNum: Number(form.enterWaitingAlarmNum.split('팀')[0]),
        };
        break;
      case 4:
        requestBody = {
          isCallExpiredCancel: form.isCallExpiredCancel,
        };
        break;
      case 5:
        requestBody = {
          callExpiredMinute: Number(form.callExpiredMinute.split('분')[0]),
        };
        break;
      case 6:
        form.tabletDefaultInformationMessage
          ? (requestBody = {
            tabletInformationType: 'DEFAULT',
            tabletDefaultInformationMessage: DEFAULT_INFORMATION_MESSAGE,
          })
          : (requestBody = {
            tabletInformationType: 'CUSTOM',
            tabletCustomInformationMessage: form.tabletCustomInformationMessage,
          });
    }
    await devServerApi.api.put('/waiting/option', requestBody);
    await queryClient.invalidateQueries(['waiting_options']);
  };

  const routeToKakaoTalkSetting = () => {
    navigate('/tutorial/kakao-channel_wait?from=waiting');
  };

  const onNextStep = async () => {
    await saveForm();
    if (step !== 6) {
      if (step === 0) {
        if (form.isWaitingClosing === false) {
          const requestBody = {
            isWaitingClosing: false,
            isCallExpiredCancel: false,
            tabletInformationType: 'DEFAULT',
            tabletDefaultInformationMessage: DEFAULT_INFORMATION_MESSAGE,
          };
          await devServerApi.api.put('/waiting/option', requestBody);
          await queryClient.invalidateQueries(['waiting_options']);
          routeToKakaoTalkSetting();
          return;
        }
      }
      if (step === 1) {
        // validation form minPeople and maxPeople
        if (form.maxPeople !== '제한없음') {
          let minPeopleNum = Number(form.minPeople.split('명')[0]);
          let maxPeopleNum = Number(form.maxPeople.split('명')[0]);
          if (minPeopleNum > maxPeopleNum) {
            callAlert('인원수를 확인해주세요.', `최대 인원수가 최소 인원수보다\n크게 설정되어야 합니다.`, [
              {
                btnNm: '확인',
                value: 'close',
              },
            ]);
            return;
          }
        }
        setIsCanNext(false);
      }
      // if (step === 2) {
      //   // if (form.useCustomEnterWaitingAlarm === false) {
      //   //   setStep(3);
      //   // }
      // }
      if (step === 4) {
        if (form.isCallExpiredCancel === false) {
          setStep(5);
        }
      }

      if (step !== 6) {
        setStep((prev) => prev + 1);
      }
      return;
    }
    navigate('/tutorial/kakao-channel_wait?from=waiting');
  };

  const onChangeForm = (key: keyof Form, value: any) => () => {
    setForm({
      ...form,
      [key]: value,
    });
    if (key === 'isWaitingClosing' || key === 'useCustomEnterWaitingAlarm' || key === 'isCallExpiredCancel') {
      setIsCanNext(true);
    }
    if (key === 'tabletDefaultInformationMessage') {
      value ? setIsCanNext(true) : setIsCanNext(false);
    }
    if (key === 'tabletCustomInformationMessage') {
      setIsCanNext(Boolean(value));
    }
  };

  const renderTutorial = () => {
    switch (step) {
      case 0:
        return <TutorialStep1 setForm={onChangeForm} form={form} goNext={onNextStep} />;
      case 1:
        return <TutorialStep2 setForm={onChangeForm} form={form} />;
      case 2:
        return <TutorialStep3 setForm={onChangeForm} form={form} goNext={onNextStep} />;
      case 3:
        return <TutorialStep4 setForm={onChangeForm} form={form} />;
      case 4:
        return <TutorialStep5 setForm={onChangeForm} form={form} goNext={onNextStep} />;
      case 5:
        return <TutorialStep6 setForm={onChangeForm} form={form} />;
      default:
        return <TutorialStep7 setForm={onChangeForm} form={form} />;
    }
  };

  const onPrevStep = () => {
    if (step === 4) {
      if (form.useCustomEnterWaitingAlarm === false) {
        setStep(2);
        return;
      }
      setStep(3);
      return;
    }
    setStep((prev) => prev - 1);
  };

  const renderNext = () => {
    switch (step) {
      case 0:
        return <></>;
      case 2:
        return (
          <button
            onClick={onPrevStep}
            className={classNames('p-[14px] items-center justify-center rounded text-zinc-800 w-full text-sm font-bold bg-white border border-stone-399')}
          >
            이전
          </button>
        );
      case 1:
      case 3:
      case 4:
      case 5:
        return (
          <div className="flex gap-x-[10px]">
            <button
              onClick={onPrevStep}
              className={classNames('p-[14px] items-center justify-center rounded text-zinc-800 w-full text-sm font-bold bg-white border border-stone-399')}
            >
              이전
            </button>
            <button
              disabled={!isCanNext}
              onClick={onNextStep}
              className={classNames('p-[14px] items-center justify-center rounded text-white w-full text-sm font-bold', isCanNext ? 'bg-violet-700' : 'bg-[#C4AFF0]')}
            >
              다음
            </button>
          </div>
        );
      case 6:
        return (
          <button
            disabled={!isCanNext}
            onClick={onNextStep}
            className={classNames('p-[14px] items-center justify-center rounded text-white w-full text-sm font-bold', isCanNext ? 'bg-violet-700' : 'bg-[#C4AFF0]')}
          >
            완료
          </button>
        );
    }
  };

  return (
    <div className="min-w-screen min-h-screen max-h-screen overflow-hidden relative">
      <section className="p-5 flex justify-end items-center">
        <img
          src={Close}
          alt="close"
          className="w-[30px] h-[30px]"
          onClick={() => {
            callAlert('알림', `대기 설정을 그만하시겠어요?\n대기 인원수 제한, 자동 취소 설정들을 통해\n더 편리하게 대기를 사용할 수 있어요.`, [
              {
                btnNm: '그만하기',
                value: 'navigate:::/waiting/dashboard',
              },
              { btnNm: '계속하기', value: 'close' },
            ]);
          }}
        />
      </section>
      <section className="p-5 flex items-center justify-center gap-x-1">
        {Array.from({ length: 7 }).map((_, index) => (
          <div key={index} className={classNames('w-full h-2 rounded-[9px]', step >= index ? 'bg-emerald-400' : 'bg-gray-300')} />
        ))}
      </section>
      <div className="w-screen h-px bg-zinc-100" />
      <div className="overflow-auto without-header">{renderTutorial()}</div>
      <div className="w-full p-5 absolute bottom-0">{renderNext()}</div>
    </div>
  );
};
export default WaitingTutorialPage;
