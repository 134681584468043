import { create } from 'zustand';
import { Nullable } from '@/shared/utils/common.type';

type tutorialState = Nullable<'table' | 'reservation' | 'essential' | 'done' | 'null'>;

interface IUseSettingTutorialStore {
  state: tutorialState;
  setState: (state: tutorialState) => void;
  agreements: any;
  setAgreements: (agreements: any) => void;
  agreementsDetail: any;
  setAgreementsDetail: (agreements: any) => void;
}

export const useSettingTutorialStore = create<IUseSettingTutorialStore>((set) => ({
  state: null,
  setState: (state) => set({ state }),
  agreements: null,
  setAgreements: (agreements) => set({ agreements }),
  agreementsDetail: [],
  setAgreementsDetail: (agreementsDetail) => set({ agreementsDetail }),
}));
