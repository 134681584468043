import React, { Fragment, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Select from '@/components/Select/Select';
import { ControlledInput } from '@/hook-form/ControlledInput';
import { ControlledRadioBox } from '@/hook-form/ControlledRadioBox';
import { ControlledToggle } from '@/hook-form/ControlledToggle';
import { useMallStore } from '@/stores/common/useMallStore';
import { useModalStore } from '@/stores/common/useModalStore';
import './ReservationInfo.scss';
import { useReservationStore } from '@/stores/reservation/useReservationStore';

const ReservationInfo = (): React.ReactElement => {
  const { reservationInfo } = useReservationStore();
  const { mall } = useMallStore();
  const { setMenuModal } = useModalStore();
  const methods = useFormContext();
  const { control, getValues, setValue, register } = methods;

  useEffect(() => {
    setValue('reservationExtravalues', !reservationInfo.reservationExtravalues.length ? mall.reservationExtravalues : reservationInfo.reservationExtravalues);
  }, []);

  return (
    <div className="detail-content">
      <div className="font__body_sm_sb">예약 정보</div>
      <div className="detail-body">
        <div>
          <div className="font__body_6">예약명</div>
          <ControlledInput name="reservationName" control={control} placeholder="예약명을 입력해주세요" />
        </div>
        <div>
          <div className="font__body_6">요청사항</div>
          <ControlledInput name="reservationRequests" control={control} placeholder="요청사항을 입력해주세요" />
        </div>
        <div>
          <div className="font__body_6">예약메모</div>
          <ControlledInput name="reservationMemo" control={control} placeholder="예약메모를 입력해주세요" />
        </div>
        {getValues('reservationExtravalues').map((item: any, i) => {
          if (item.position === 'main') {
            return (
              <div key={item.id}>
                <div className="font__body_6">{item.name}</div>
                {item.type === 'string' || item.type === 'text' || item.type === 'textarea' || item.type === 'user' || item.type === 'longtext' ? (
                  <ControlledInput name={`reservationExtravalues.${i}.value`} control={control} placeholder={`${item.name} 입력`} />
                ) : null}
                {item.type === 'toggle' ? <ControlledToggle name={`reservationExtravalues.${i}.value`} control={control} defaultChecked={Boolean(item.choices[0])} /> : null}
                {item.type === 'count' ? <ControlledInput name={`reservationExtravalues.${i}.value`} control={control} placeholder={`${item.name} 입력`} /> : null}
                {item.type === 'select' || item.type === 'multiselect' ? (
                  <>
                    <Select onClick={() => setMenuModal({ visible: true, key: item.id })} value={getValues(`reservationExtravalues.${i}.value`)} />
                    <ModalPortal>
                      <Modal.Menu key={item.id} modal="menu" isDim={true} isAnimation={true}>
                        <div className="user-info-menu-modal-wrapper">
                          <div className="title title font__subtitle_sb">{item.name}</div>

                          {item.type === 'multiselect' ? (
                            <div className="content custom-check-wrapper">
                              {item.choices.map((subItem, j) => (
                                <div className="custom-check-item" key={`${item}_${j}`}>
                                  <label className="tblm-rc">
                                    <input type="checkbox" value={subItem} {...register(`reservationExtravalues.${i}.value`)} />
                                    <i />
                                    <span>{subItem}</span>
                                  </label>
                                </div>
                              ))}
                            </div>
                          ) : null}

                          {item.type === 'select' ? (
                            <ControlledRadioBox
                              name={`reservationExtravalues.${i}.value`}
                              options={item.choices.map((subItem) => ({ label: subItem, value: subItem }))}
                              control={control}
                              defaultChecked={getValues(`reservationExtravalues.${i}.value`)}
                              onChange={() => setMenuModal({ visible: false, key: item.id })}
                            />
                          ) : null}
                        </div>
                        {item.type === 'multiselect' ? (
                          <div className="footer is-border">
                            <button className="tblm-button-normal tblm-btn-primary" onClick={() => setMenuModal({ visible: false, key: item.id })}>
                              확인
                            </button>
                          </div>
                        ) : null}
                      </Modal.Menu>
                    </ModalPortal>
                  </>
                ) : null}
              </div>
            );
          }
          return <Fragment key={item.id} />;
        })}
      </div>
    </div>
  );
};

export default ReservationInfo;
