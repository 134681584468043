import React, { useEffect, useState } from 'react';
import './ReservationInfo.scss';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { ControlledInput } from '@/hook-form/ControlledInput';
import { ControlledRadioBox } from '@/hook-form/ControlledRadioBox';
import { ControlledToggle } from '@/hook-form/ControlledToggle';
import { LocalStorage } from '@/shared/utils/storage';
import { useMallStore } from '@/stores/common/useMallStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import Input from '../Input/Input';
import Modal from '../Modal/Modal';
import ModalPortal from '../ModalPortal/ModalPortal';
import Select from '../Select/Select';
import IconTextButton from '../IconTextButton/IconTextButton';

function Reservationinfo(): React.ReactElement {
  const { setMenuModal } = useModalStore();
  const { visitDate, visitTime, menus, parties, rooms, initGetAllInfo, reservationInfo, userInfo, setUserInfo } = useReservationStore();
  const { mall } = useMallStore();
  let navigate = useNavigate();

  const methods = useFormContext();
  const {
    control,
    setValue,
    getValues,
    register,
    formState: { errors },
  } = methods;

  const handleRouter = (path) => {
    initGetAllInfo({ ...getValues(), menus: menus });
    navigate(path);
  };

  useEffect(() => {
    const reservationExtravalues = getValues('reservationExtravalues');
    setValue(
      'reservationExtravalues',
      mall.reservationExtravalues.map((item, i) => {
        const extraValue = reservationExtravalues.find((extra) => extra?.id === item?.id);
        return { ...item, id: item.id, value: extraValue?.value };
      })
    );
  }, []);

  return (
    <div className="reservation-info-wrapper" data-scrolled="1" key="1">
      <div className="reservation-info-header">
        <div className="title">예약정보</div>
        <div className="essential">*필수 입력 정보입니다.</div>
      </div>
      <div className="reservation-info-content">
        <div>
          <div>
            예약일
            <span className="essential">*</span>
          </div>
          <div>{visitDate && visitDate.format('YYYY년 MM월 DD일')}</div>
          <IconTextButton text="수정" onClick={() => handleRouter('/reservation/accept/step-1?type=modify')} />
        </div>
        {/* <div className="progress-bar" /> */}
        <div>
          <div>
            예약시간
            <span className="essential">*</span>
          </div>
          <div>
            {visitTime && visitTime.format('a hh시 mm분')}
            <div className="error-message font__s_small">{String(errors.visitTime?.message ?? '')}</div>
          </div>
          <IconTextButton text="수정" onClick={() => handleRouter('/reservation/accept/step-2?type=modify')} />
        </div>
        {/* <div className="progress-bar" /> */}
        <div>
          <div>
            인원
            <span className="essential">*</span>
          </div>
          <div>
            <span>
              {parties.find((item) => item.size) ? (
                <>{parties.map((item) => (item.size ? <span key={item.name} className="highlight">{`${item.name} ${item.size}`}</span> : null))}</>
              ) : null}
            </span>
            <div className="error-message font__s_small">{String(errors.parties?.message ?? '')}</div>
          </div>
          <IconTextButton text="수정" onClick={() => handleRouter('/reservation/accept/step-3?type=modify-1')} />
        </div>
        {/* <div className="progress-bar" /> */}
        <div>
          <div>테이블 번호</div>
          <div>
            {rooms?.map((table, index) => {
              return (
                <span key={index}>
                  {table.name}
                  {rooms.length - 1 > index ? ', ' : ''}
                </span>
              );
            })}
          </div>
          <IconTextButton text="수정" onClick={() => handleRouter('/reservation/accept/step-3?type=modify-2')} />
        </div>
        {/* <div className="progress-bar" /> */}
        <div className="userName">
          <div>
            이름
            <span className="essential">*</span>
          </div>
          <ControlledInput disabled={reservationInfo.reservationState === 'canceled'} name="userName" control={control} placeholder="이름을 입력해주세요." />
          <IconTextButton text="검색" onClick={() => handleRouter('/reservation/accept/step-4?type=modify-2')} />
        </div>
        <div className="userPhone">
          <div>
            전화번호
            <span className="essential">*</span>
          </div>
          <div>{getValues().userPhone}</div>
          {/* <ControlledInput
            disabled={reservationInfo.reservationState === 'canceled'}
            name="userPhone"
            control={control}
            placeholder="전화번호를 입력해주세요."
            onChange={(e) => {
              setValue(
                'userPhone',
                e.target.value
                  .replace(/[^0-9]/g, '')
                  .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
                  .replace('--', '-')
              );
            }}
          /> */}
        </div>
        <div className="reservationName">
          <div>예약명</div>
          <ControlledInput disabled={reservationInfo.reservationState === 'canceled'} name="reservationName" control={control} placeholder="예약명을 입력해주세요" />
        </div>
        <div className="requests">
          <div>요청사항</div>
          <ControlledInput disabled={reservationInfo.reservationState === 'canceled'} name="reservationRequests" control={control} placeholder="요청사항을 입력해주세요" />
        </div>
        <div>
          <div className="reservationmemo">예약메모</div>
          <ControlledInput disabled={reservationInfo.reservationState === 'canceled'} name="reservationMemo" control={control} placeholder="예약메모를 입력해주세요" />
        </div>
        {mall.reservationExtravalues.map((item: any, i) => {
          return item.position === 'main' ? (
            <div key={item.id}>
              <div className="font__body_sm">{item.name}</div>
              {item.type === 'string' || item.type === 'text' || item.type === 'textarea' || item.type === 'user' || item.type === 'longtext' ? (
                <ControlledInput id={item.id} name={`reservationExtravalues.${i}.value`} control={control} placeholder={`${item.name} 입력`} />
              ) : null}
              {item.type === 'toggle' ? <ControlledToggle name={`reservationExtravalues.${i}.value`} control={control} defaultChecked={Boolean(item.choices[0])} /> : null}
              {item.type === 'count' ? <ControlledInput name={`reservationExtravalues.${i}.value`} control={control} placeholder={`${item.name} 입력`} /> : null}
              {item.type === 'select' || item.type === 'multiselect' ? (
                <>
                  <Select
                    onClick={() => {
                      setMenuModal({ visible: true, key: item.id });
                    }}
                    value={getValues(`reservationExtravalues.${i}.value`)}
                  />
                  <ModalPortal>
                    <Modal.Menu key={item.id} modal="menu" isDim={true} isAnimation={true}>
                      <div className="user-info-menu-modal-wrapper">
                        <div className="title font__subtitle">{item.name}</div>

                        {item.type === 'multiselect' ? (
                          <div className="content custom-check-wrapper">
                            {item.choices.map((subItem, j) => (
                              <div className="custom-check-item" key={`${item}_${j}`}>
                                <label className="tblm-rc">
                                  <input type="checkbox" value={subItem} {...register(`reservationExtravalues.${i}.value`)} />
                                  <i />
                                  <span>{subItem}</span>
                                </label>
                              </div>
                            ))}
                          </div>
                        ) : null}

                        {item.type === 'select' ? (
                          <ControlledRadioBox
                            name={`reservationExtravalues.${i}.value`}
                            options={item.choices.map((subItem) => ({ label: subItem, value: subItem }))}
                            control={control}
                            defaultChecked={getValues(`reservationExtravalues.${i}.value`)}
                            onChange={() => setMenuModal({ visible: false, key: item.id })}
                          />
                        ) : null}
                      </div>
                      {item.type === 'multiselect' ? (
                        <div className="footer is-border">
                          <button className="tblm-button-normal tblm-btn-primary" onClick={() => setMenuModal({ visible: false, key: item.id })}>
                            확인
                          </button>
                        </div>
                      ) : null}
                    </Modal.Menu>
                  </ModalPortal>
                </>
              ) : null}
            </div>
          ) : null;
        })}
      </div>
      <ModalPortal>
        <Modal.Menu key="b" modal="menu" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">진료과목</div>
        </Modal.Menu>
      </ModalPortal>
    </div>
  );
}

export default Reservationinfo;
