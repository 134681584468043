import React from 'react';
import RainBow from '@/assets/images/waiting/rainbow.png';
import { ReactComponent as ColorNone } from '@/assets/images/icons/Color-None.svg';
import 'dayjs/locale/ko';
import dayjs, { Dayjs } from 'dayjs';
import { devServerApi } from '@/shared/apis/devServerApi';
import queryClient from '@/shared/utils/queryClient';
import classNames from 'classnames';
import useNative from '@/hooks/useNative';

dayjs.locale('ko');

export interface PeopleInfo {
  name: string;
  count: number;
}

export type WaitingStatus = 'WAIT' | 'ENTER' | 'CANCEL';
export type WaitingTransactionStatus = 'WAIT' | 'ENTER_ABLE' | 'ENTER' | 'EXIT' | 'ENTER_CANCEL' | 'CANCEL_RECOVERY' | 'CUSTOMER_CANCEL' | 'MALL_CANCEL' | 'SYSTEM_CANCEL';
export type ExtraValue = {
  id: number;
  name: string;
  value: number | string | boolean;
};

export interface Waiting {
  id: number;
  mallId: string;
  peopleInfos: PeopleInfo[];
  name: string;
  phone: string;
  dailyNumber: number;
  extraValues: ExtraValue[];
  status: WaitingStatus;
  transactionStatus: WaitingTransactionStatus;
  funnel: string;
  color: any;
  isGuideSent: boolean;
  startAt: string;
  callCount: number;
  calledAt: any;
  lastCalledAt: any;
  enterAt: any;
  canceledAt: any;
  createdAt: string;
  updatedAt: string;
}

type WaitingType = 'call' | 'enter' | 'retract-enter' | 'cancel' | 'retract-cancel';

const TRANSACTION_STATUS = {
  MALL_CANCEL: {
    label: '매장 취소',
    color: '#2E2E30',
  },
  CUSTOMER_CANCEL: {
    label: '고객 취소',
    color: '#FF6161',
  },
  SYSTEM_CANCEL: {
    label: '자동 취소',
    color: '#7E7B83',
  },
};

const COLOR_PLATE = ['#FFE100', '#F98C06', '#9E7BDE', '#8AEE6B', '#0140FE', '#00FFF7', '#F3F30C', '#FFACED'];
// rgb(35,34,37)

type Dialog = {
  title: string;
  subTitle: string;
};
const WaitingCard: React.FC<Waiting> = ({ ...waiting }) => {
  const [currentTime, setCurrentTime] = React.useState<Dayjs>(dayjs());
  const [openDialog, setOpenDialog] = React.useState<Dialog | null>(null);
  const [isColorPick, setIsColorPick] = React.useState<boolean>(false);
  const diff = currentTime.diff(dayjs(waiting.createdAt), 'minute');
  const enterDiff = currentTime.diff(dayjs(waiting.enterAt), 'minute');
  const peopleCount = waiting.peopleInfos.reduce((acc, cur) => acc + cur.count, 0);
  const { callAlert } = useNative();

  const adult = waiting.peopleInfos.find((item) => item.name === '성인')?.count || 0;
  const non_adult = waiting.peopleInfos.find((item) => item.name !== '성인')?.count || 0;

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const waitingAction = (waitingType: WaitingType) => async () => {
    if (waitingType === 'cancel') {
      callAlert(`${waiting.dailyNumber}번 고객을 대기 취소\n하시겠습니까?`, '대기 취소 시 대기 취소 목록으로 이동합니다.', [
        {
          btnNm: '닫기',
          value: 'close',
        },
        {
          btnNm: '대기 취소',
          value: () =>
            devServerApi.api
              .post(`/waiting/func`, {
                waitingId: waiting.id,
                waitingType: 'cancel',
              })
              .then(() => {
                queryClient.invalidateQueries(['waiting_list']);
              }),
        },
      ]);
      return;
    }
    await devServerApi.api.post(`/waiting/func`, {
      waitingId: waiting.id,
      waitingType,
    });
    await queryClient.invalidateQueries(['waiting_list']);
    setCurrentTime(dayjs());
  };

  const changeColor = (color: string) => async () => {
    await devServerApi.api.put(`/waiting/setting`, {
      waitingId: waiting.id,
      color,
    });
    await queryClient.invalidateQueries(['waiting_list']);
    setIsColorPick(false);
  };

  // React.useEffect(() => {
  //   if (waiting.color) {
  //     alert('COLOR::' + waiting.color);
  //   }
  // }, [waiting.color]);

  return (
    <div className="shadow rounded-lg border border-gray-100 flex flex-col">
      <div className="flex gap-x-4 p-5">
        <div className="text-xl text-zinc-800 font-bold">{waiting.dailyNumber}</div>
        <div className="flex flex-col">
          <div className="flex items-center divide-x divide-gray-100 text-zinc-800 gap-x-1 mb-[6px]" style={{ background: waiting.color || '#FFFFFF' }}>
            <span className="">{waiting.phone}</span>
            <div className="flex items-center">
              <b className="font-bold ml-[4px]">{peopleCount + '명'}</b>
              {waiting.peopleInfos.length > 0 && <span className="text-sm ml-[2px]">{`(${adult}/${non_adult})`}</span>}
            </div>
          </div>
          <div className="flex items-center">
            <span className="text-zinc-800 text-sm mr-[2px]">{dayjs(waiting.createdAt).format('a hh시 mm분')}</span>
            {diff > 0 && waiting.status === 'WAIT' && <span className="text-violet-700 text-sm">{`(+${diff}분)`}</span>}
            {enterDiff > 0 && waiting.status === 'ENTER' && <span className="text-red-400 text-sm">{`(+${enterDiff}분)`}</span>}
            {waiting.canceledAt && waiting.status === 'CANCEL' && <span className="text-red-400 text-sm">{`(${dayjs(waiting.canceledAt).format('a hh시 mm분')} 취소)`}</span>}
          </div>
          {waiting.name && (
            <div className="mt-[12px] text-zinc-800 flex items-center gap-x-2">
              <span className="px-1.5 py-[3px] border border-gray-100 rounded-sm text-sm font-medium">이름</span>
              <span>{waiting.name}</span>
            </div>
          )}
          <div
            className={classNames(
              'flex gap-x-1 items-center',
              (waiting.extraValues.length > 0 || (waiting.status === 'CANCEL' && TRANSACTION_STATUS[waiting.transactionStatus])) && 'mt-[12px]'
            )}
          >
            {TRANSACTION_STATUS[waiting.transactionStatus] && waiting.status === 'CANCEL' && (
              <div
                className="flex py-1 px-2 text-white text-xs rounded-full justify-center items-center"
                style={{ backgroundColor: TRANSACTION_STATUS[waiting.transactionStatus].color }}
              >
                {TRANSACTION_STATUS[waiting.transactionStatus].label}
              </div>
            )}
            {waiting.extraValues.map((item) => (
              <span className="px-2 py-[5px] text-xs bg-zinc-100 rounded-full text-zinc-800">{`${item.name}(${item.value})`}</span>
            ))}
          </div>
        </div>
      </div>
      {waiting.status === 'WAIT' && (
        <div className="border-top w-full h-16 justify-start items-center inline-flex divide-x divide-gray-100 text-sm text-zinc-800 font-medium overflow-x-scroll overflow-y-hidden">
          {isColorPick ? (
            <div className="flex items-center h-full px-[14px] py-2 gap-x-7 z-10">
              <ColorNone className="w-5 h-5" onClick={changeColor('')} />
              {COLOR_PLATE.map((color) => (
                <div onClick={changeColor(color)} key={color} className="w-5 h-5 rounded-full" style={{ background: color }} />
              ))}
            </div>
          ) : (
            <>
              <div className="flex p-[22px] justify-center items-center">
                {COLOR_PLATE.includes(waiting?.color) ? (
                  <div className="w-5 h-5 rounded-full" style={{ background: waiting.color }} onClick={() => setIsColorPick(true)} />
                ) : (
                  <img src={RainBow} alt="color picker" className="w-5 h-5 min-w-5 min-h-5 object-contain" onClick={() => setIsColorPick(true)} />
                )}
              </div>
              <div className="flex justify-center items-center w-full h-full" onClick={waitingAction('call')}>
                {waiting.callCount > 0 ? (
                  <div className="flex flex-col items-center">
                    <b>{`${waiting.callCount}회 호출`}</b>
                    <b className="text-red-400">{`+${currentTime.diff(dayjs(waiting.lastCalledAt || Date()), 'minutes')}분`}</b>
                  </div>
                ) : (
                  '호출'
                )}
              </div>
              <div className="flex justify-center items-center w-full h-full" onClick={waitingAction('enter')}>
                입장
              </div>
              <div className="flex justify-center items-center w-full h-full" onClick={waitingAction('cancel')}>
                취소
              </div>
            </>
          )}
        </div>
      )}
      {waiting.status === 'ENTER' && (
        <div className="border-top w-full h-16 justify-start items-center inline-flex divide-x divide-gray-100 text-sm text-zinc-800 font-medium overflow-x-scroll overflow-y-hidden">
          <div className="flex justify-center items-center w-full h-full" onClick={waitingAction('retract-enter')}>
            대기로 이동
          </div>
        </div>
      )}
      {waiting.status === 'CANCEL' && (
        <div className="border-top w-full h-16 justify-start items-center inline-flex divide-x divide-gray-100 text-sm text-zinc-800 font-medium overflow-x-scroll overflow-y-hidden">
          <div className="flex justify-center items-center w-full h-full" onClick={waitingAction('retract-cancel')}>
            복구
          </div>
        </div>
      )}
    </div>
  );
};

export default WaitingCard;
