import '@/styles/main.scss';
import 'normalize.css';
import React, { useEffect } from 'react';
import CustomRouter from '@/Routes';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import Spinner from '@/components/Spinner/Spinner';
import { checkOS } from '@/shared/lib/functions/check-os';
import i18n from '@/locales/i18n';
import queryClient from '@/shared/utils/queryClient';
import { useLayoutStore } from './stores/common/useLayoutStore';
import ModalPortal from './components/ModalPortal/ModalPortal';
import Modal from './components/Modal/Modal';
import { useModalStore } from './stores/common/useModalStore';
import useNative from '@/hooks/useNative';

const App = (): React.ReactElement => {
  const { spinner } = useLayoutStore();
  const { toastModal } = useModalStore();

  const originalViewSize = window.innerHeight;

  useEffect(() => {
    const handleOnDetectInput = () => {
      const elem: any = document.activeElement;
      const isInput = Boolean(elem.tagName.toUpperCase() === 'INPUT' && (elem.type === 'text' || elem.type === 'number' || elem.type === 'email' || elem.type === 'password'));

      const focus = () => {
        if (isInput && !document.body.classList.contains('keyboard-up')) {
          if (checkOS() === 'ios') {
            const interval = setInterval(() => {
              document.body.classList.add('keyboard-up');
            }, 100);
            setTimeout(() => clearInterval(interval), 500);
          } else {
            document.body.classList.add('keyboard-up');
          }
        }
      };
      const focusOut = () => {
        setTimeout(() => {
          const newElem: any = document.activeElement;
          const isNewInput = Boolean(
            newElem.tagName.toUpperCase() === 'INPUT' && (newElem.type === 'text' || newElem.type === 'number' || newElem.type === 'email' || newElem.type === 'password')
          );
          if (isInput && isNewInput) {
            // console.log(isInput, isNewInput, 'call');
          } else {
            document.body.classList.remove('keyboard-up');
          }
        }, 0);
      };
      elem.onFocus = focus;

      elem.addEventListener('blur', focusOut);
    };

    const handleOnResize = () => {
      if (window.innerHeight >= originalViewSize) document.body.classList.remove('keyboard-up');
    };

    document.addEventListener('click', handleOnDetectInput);
    window.addEventListener('resize', handleOnResize);
    return () => {
      document.removeEventListener('click', handleOnDetectInput);
      window.removeEventListener('resize', handleOnResize);
    };
  }, []);

  return (
    <>
      <div className="main-container-desktop">
        <I18nextProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>
            <CustomRouter />
            <ModalPortal>
              <Modal.Toast key="global" modal="toast" autoHideDuration={1000} isAnimation={true} position="bottom">
                <div className="font__body_7" style={{ color: '#ffffff' }}>
                  {toastModal.text}
                </div>
              </Modal.Toast>
            </ModalPortal>
          </QueryClientProvider>
        </I18nextProvider>
        {spinner ? <Spinner /> : null}
        <div className="fake" />
      </div>
    </>
  );
};

export default App;
