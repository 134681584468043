import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import '@/styles/pages/settings/information.scss';
import { useNavigate } from 'react-router-dom';
import HeaderBack from '@/components/HeaderBack/HeaderBack';
import Facilities from '@/components/Settings/Facilities';
import { devServerApi } from '@/shared/apis/devServerApi';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import { MallSetting, MallSettingInfoOpenTimeDate } from '@/shared/utils/common.type';
import Terces from '@/shared/apis/terces';
import useBackToSettings from '@/hooks/useBackToSetting';

function Information() {
  const navigate = useNavigate();
  const back = useBackToSettings();

  // @ts-ignore
  const { data, isFetching } = useQuery([QUERY_KEYS.SETTINGS_MALL], () => devServerApi.api.authGet<MallSetting>('/settings/mall').then((enc_res) => Terces.decrypt(enc_res)), {
    cacheTime: 0,
  });
  const mallSetting: Partial<MallSetting> = isFetching ? {} : data;

  const timeMaker = (time: MallSettingInfoOpenTimeDate) => {
    return `${time.hour < 12 ? '오전' : '오후'} ${String(time.hour % 12).padStart(2, '0')}:${String(time.minute).padStart(2, '0')}`;
  };

  // const mallSetting = {
  //   mallName: '흥부부대찌개',
  //   foods: [{ name: '한식' }],
  //   eid: '1234-56789-00',
  //   mainPhone: '02-1234-5678',
  //   address: {
  //     road: '서울특별시 성동구 성수이로20길 16 1층',
  //   },
  //   representative: '',
  //   representativePhone: '',
  //   facility: [
  //     {
  //       type_facility_id: 11,
  //       name: '와이파이',
  //       checked: true,
  //     },
  //     {
  //       type_facility_id: 60,
  //       name: '테라스',
  //       checked: true,
  //     },
  //     {
  //       type_facility_id: 3,
  //       name: '놀이방',
  //       checked: true,
  //     },
  //     {
  //       type_facility_id: 12,
  //       name: '반려견 동반 가능',
  //       checked: true,
  //     },
  //   ],
  //   mallDescription: '',
  // };

  const verifyValueExist = (value) => {
    const validPureValue = (pure) => {
      if (mallSetting.eid === value && value) return pure.replaceAll('-', '');
      return pure;
    };

    return <div className={`font__body_5 ${validPureValue(value)?.length ? '' : 'value-not-exist'}`}>{validPureValue(value)?.length ? value : '입력 안 함'}</div>;
  };

  // if (isFetching) {
  //   return <SpinnerEffector loading={isFetching} />;
  // }

  return (
    <div className="information-wrapper">
      <HeaderBack isFixed isCenter onBack={back}>
        <div className="font__heading_4">기본정보</div>
      </HeaderBack>
      <section className="information-body">
        <div>
          <div className="font__body_6">매장명</div>
          <div className="font__body_5">{mallSetting.mallName}</div>
        </div>
        <div>
          <div className="font__body_6">업종명</div>
          <div className="font__body_5">요식업</div>
        </div>
        <div>
          <div className="font__body_6">음식종류</div>
          <div className={'font__body_5'}>{mallSetting.foods?.map(({ name }) => name).join(', ')}</div>
        </div>
        <div>
          <div className="font__body_6">사업자번호</div>
          {verifyValueExist(mallSetting.eid)}
        </div>
        <div>
          <div className="font__body_6">매장 대표번호</div>
          <div className="font__body_5">{mallSetting.mainPhone}</div>
        </div>
        <div>
          <div className="font__body_6">매장 주소</div>
          <div className="font__body_5">{mallSetting.address?.road}</div>
        </div>
        <div>
          <div className="font__body_6">대표자 이름</div>
          {verifyValueExist(mallSetting.representative)}
        </div>
        <div>
          <div className="font__body_6">대표자 번호</div>
          <div className="font__body_5">{verifyValueExist(mallSetting.representativePhone)}</div>
        </div>
        {/*<div>
          <div className="font__body_6">영업시간</div>
          <div className="font__body_5">
            {mallSetting.infoOpenTime?.map((openTime, idx) => (
              <div key={openTime.type + openTime.week + idx}>
                {openTime.week === '매일' ? '평일' : openTime.week}{' '}
                {openTime.type === '휴무' ? (
                  '휴무'
                ) : (
                  <>
                    {timeMaker(openTime.date_start)}~{timeMaker(openTime.date_end)}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>*/}
        {/*<div>
          <div className="font__body_6">브레이크 타임</div>
          <div className="font__body_5">{mallSetting.breakTime}</div>
        </div>*/}
        {/*<div>
          <div className="font__body_6">주차정보</div>
          <div className="font__body_5">{mallSetting.parkingInfo}</div>
        </div>*/}
        <div className="flex-col items-start">
          <div className="font__body_6 w-full">편의시설 정보</div>
          {/* <div className="font__body_5 nomargin"> */}
          {mallSetting.facility ? (
            <Facilities hidden={false} edit={false} facilitiesList={mallSetting.facility?.map((e) => ({ checked: true, ...e }))} setFacilitiesList={() => {}} />
          ) : (
            '작성 안 함'
          )}
          {/* </div> */}
        </div>
        <div>
          <div className="font__body_6">매장 설명</div>
          {verifyValueExist(mallSetting.mallDescription)}
        </div>
      </section>
      <footer>
        <button className="tblm-button-normal tblm-btn-primary" onClick={() => navigate('/setting/essential/edit')}>
          편집
        </button>
      </footer>
    </div>
  );
}

export default Information;
