import terces from '@/shared/apis/terces';
import { useState } from 'react';

export default function Enc() {
  const [value, setValue] = useState('');

  const decrypt = (s) => {
    try {
      return JSON.stringify(terces.decrypt({ enc_res: s }));
    } catch (e) {
      return '';
    }
  };

  return (
    <div>
      <input type="text" value={value} onChange={(e) => setValue(e.target.value)} />
      <div>{decrypt(value)}</div>
    </div>
  );
}
