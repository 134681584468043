import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderSub from '@/components/HeaderSub/HeaderSub';
import SearchInput from '@/components/SearchInput/SearchInput';
import Tabs from '@/components/Tabs/Tabs';
import NamePhoneNumber from '@/components/UserSearch/NamePhoneNumber/NamePhoneNumber';
import RecentCallList from '@/components/UserSearch/RecentCallList/RecentCallList';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import Terces from '@/shared/apis/terces';

const initialState = {
  'search-type-list': [
    { label: '최근 통화 기록', value: 'recent', active: false },
    { label: '이름 / 전화번호', value: 'name', active: true },
  ],
  'recent-list': [
    { phone: '010-9991-6041', name: '유준호', value: '010-9991-6041' },
    { phone: '010-2223-5010', name: '나상권', value: '010-2223-5010' },
    { phone: '010-5801-6162', name: '원건우', value: '010-5801-6162' },
    { phone: '010-8585-1102', name: '김주환', value: '010-8585-1102' },
  ],
  'name-phone-list': [
    { name: '나상권', phone: '010-9991-6041', alias: '탑 고기를좋아합니다. 사장지인', memo: '안녕하세요 탑 스맵입니다.', value: '나상권010-9991-6041' },
    { name: '원건우', phone: '010-2020-1004', alias: '탑 알레르기가 있어요. 부하', memo: '안녕하세요 미드 페이커 입니다.', value: '원건우010-2020-1004' },
    { name: '김주환', phone: '010-2021-2004', alias: '새우를 좋아함', memo: '개인석 요청 입니다..', value: '김주환010-2021-2004' },
    { name: '유준호', phone: '010-2021-2004', alias: '간장 와사비 곱배기', memo: '단체 창가자리 내놓으세요 입니다.', value: '유준호010-2021-2004' },
  ],
};

const UserSearch = (): React.ReactElement => {
  const { userInfo, setUserInfo } = useReservationStore();

  const navigate = useNavigate();

  const [defaultState, setDefaultState] = useState(initialState);
  const [searchValue, setSearchValue] = useState('');
  const [recentList, setRecentList] = useState(initialState['recent-list']);
  const [namePhoneNumList, setNamePhoneNumList] = useState([]);
  const activeTabs = useMemo(() => defaultState['search-type-list'].find((item) => item.active === true), [defaultState]);

  const handleOnSearch = (e) => {
    // if (activeTabs.value === 'recent') {
    //   const searchRecentList = new AutoComplete<IRecentCallListItem>(e.target, cloneDeep(defaultState['recent-list'])).list();
    //   setRecentList(searchRecentList);
    // } else {
    //   const searchNamePhoneList = new AutoComplete<INamePhoneNumber>(e.target, cloneDeep(defaultState['name-phone-list'])).list();
    //   setNamePhoneNumList(searchNamePhoneList);
    // }
    setSearchValue(e.target.value);
  };

  const handleOnSearchClear = () => {
    setSearchValue('');
    setRecentList(initialState['recent-list']);
    setNamePhoneNumList([]);
  };

  const handleOnClickRecentItem = (target) => {};

  const handleOnClickNamePhoneItem = (target) => {
    setUserInfo({
      ...userInfo,
      userId: target.userId,
      userMemo: target.userMemo,
      userName: target.userName,
      userNickname: target.userNickname,
      userPhone: target.userPhone,
      isSearch: true,
    });

    navigate(-1);
  };

  const handleOnTabsClick = (e) => {
    // const selectedValue = e.target.getAttribute('data-value');
    // const newTabs = cloneDeep(defaultState['search-type-list']).map((item) => {
    //   item.active = selectedValue === item.value;
    //   return item;
    // });
    // handleOnSearchClear();
    // setDefaultState({ ...defaultState, 'search-type-list': newTabs });
  };

  const handleOnUserSearch = (e) => {
    devServerApi.api
      .post('/reservation/get-users', Terces.encrypt({ name: e.target.value }))
      .then(({ data: enc_res }: any) => Terces.decrypt(enc_res))
      .then((res) => {
        setNamePhoneNumList(res.users);
      });
  };

  return (
    <div className="main-user-search">
      <HeaderSub scrollMove isBorder={false} onClose={() => navigate(-1)}>
        고객 검색
      </HeaderSub>

      <Tabs isBorder isFill isFixed onClick={handleOnTabsClick} style={{ top: 55 }}>
        {defaultState['search-type-list'].map((item) => (
          <Tabs.Item key={item.value} value={item.value} active={item.active}>
            {item.label}
          </Tabs.Item>
        ))}
      </Tabs>
      <section className="search-user-wrapper">
        <SearchInput
          onChange={handleOnSearch}
          onEnter={handleOnUserSearch}
          onClear={handleOnSearchClear}
          placeholder={activeTabs.value === 'recent' ? '최근 고객 통화 기록 검색' : '고객 이름 / 전화번호 검색'}
          value={searchValue}
        />
        {activeTabs.value === 'recent' ? <RecentCallList data={recentList} onClick={handleOnClickRecentItem} /> : null}
        {activeTabs.value === 'name' ? <NamePhoneNumber data={namePhoneNumList} onClick={handleOnClickNamePhoneItem} /> : null}
      </section>
    </div>
  );
};

export default UserSearch;
