import React, { useEffect, useRef, useState } from 'react';
import './FooterSub.scss';
import classNames from 'classnames';

interface IFooterSub {
  isVisible?: boolean;
  isBorder?: boolean;
  isBackArea?: boolean;
  noTransparent?: boolean;
  children?: any;
  style?: any;
}

const FooterSub = ({ isVisible, isBorder, isBackArea, children, noTransparent, style = {} }: IFooterSub): React.ReactElement => {
  const [footerAreaHeight, setFooterAreaHeight] = useState(0);
  const footerRef = useRef(null);

  useEffect(() => {
    if (footerRef.current) setFooterAreaHeight(footerRef.current.getBoundingClientRect().height - 20);
  }, [isVisible]);

  if (!isVisible) return <></>;
  return (
    <>
      {isBackArea ? (
        <div
          className="footer-area keybord-toggler"
          ref={(el) => {
            if (el) {
              el.style.setProperty('min-height', footerAreaHeight + 'px', 'important');
              el.style.setProperty('height', footerAreaHeight + 'px', 'important');
              el.style.setProperty('max-height', footerAreaHeight + 'px', 'important');
            }
          }}
        />
      ) : null}
      <div ref={footerRef} className={classNames('footer-sub-wrapper keybord-toggler', { 'is-border': isBorder, 'no-transparent': noTransparent })} style={style}>
        {children}
      </div>
    </>
  );
};

export default FooterSub;
