import { ITimeList } from './TimePicker.type';

export const getOnlyDecimal = (_number: number, _length: number) => {
  let result;
  result = _number % 1;
  result = Number(result.toFixed(_length));
  return result;
};
export const initialTimeList: ITimeList = {
  times: {
    morning: [],
    afternoon: [],
  },
  targetDate: null,
};
