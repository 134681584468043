import './ReservationOn.scss';
import React, { useEffect, useState } from 'react';
import ReservationTopBanner from '@/components/Reservation/List/ReservationTopBanner/ReservationTopBanner';
import ReservationServiceContent from '@/components/Reservation/List/ReservationServiceContent/ReservationServiceContent';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import classNames from 'classnames';
import { useSettingTutorialStore } from '@/stores/common/useSettingTutorialStore';
import useNative from '@/hooks/useNative';

const ReservationOn = ({ onClick }): React.ReactElement => {
  const [isObserveSubmitFooter, setIsObserveSubmitFooter] = useState<boolean>(false);
  const [observe, unobserve] = useIntersectionObserver(() => setIsObserveSubmitFooter(true), { unIntersectingCallback: () => setIsObserveSubmitFooter(false) });
  const { state } = useSettingTutorialStore();
  const { callAlert } = useNative();

  const setSubmitLayout = (submitElement: HTMLElement) => {
    const submitBtnElement: HTMLElement = document.querySelector('.reservation-off-wrapper-submit-btn'),
      submitBtnBackgroundElement: HTMLElement = document.querySelector('.reservation-off-wrapper-submit-btn-bg-gradient');
    // submitBtnElement의 left, right 값 반영
    const layoutHeight = `${submitBtnElement.clientHeight + 40}px`;
    submitElement.style.minHeight = layoutHeight;
    submitBtnBackgroundElement.style.minHeight = layoutHeight;
  };

  useEffect(() => {
    const submitElement: HTMLElement = document.querySelector('.reservation-off-wrapper-submit');

    setSubmitLayout(submitElement);

    observe(submitElement);
    return () => unobserve(submitElement);
  }, []);

  const tryConfig = () => {
    if (state) {
      callAlert('설정을 진행하던 기록이 있어요', '이어서 진행하시겠어요?', [
        {
          btnNm: '닫기',
          value: 'close',
        },
        {
          btnNm: '진행하기',
          value: onClick,
        },
      ]);
    } else {
      onClick();
    }
  };

  return (
    <div className="reservation-off-wrapper">
      <ReservationTopBanner />
      <ReservationServiceContent />
      <footer className="reservation-off-wrapper-submit">
        <button className="reservation-off-wrapper-submit-btn" onClick={tryConfig}>
          설정하기
        </button>
        <div className={classNames('reservation-off-wrapper-submit-btn-bg-gradient', { 'show-background': !isObserveSubmitFooter })} />
      </footer>
    </div>
  );
};

export default ReservationOn;
