import classNames from 'classnames';
import React, { useEffect, useId } from 'react';
import { useFormContext } from 'react-hook-form';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Select from '@/components/Select/Select';
import { ControlledRadioBox } from '@/hook-form/ControlledRadioBox';
import { useMallStore } from '@/stores/common/useMallStore';
import { useModalStore } from '@/stores/common/useModalStore';

const MessageTemplateInfo = () => {
  const { setMenuModal } = useModalStore();
  const { mall } = useMallStore();

  const creationMessageTemplateId = useId();
  const alarmMessageTemplateId = useId();

  const methods = useFormContext();
  const { getValues, setValue, control } = methods;

  const creationTemplate = mall.messageTemplates.filter((item) => item.type === 'creation');
  const alarmTemplate = mall.messageTemplates.filter((item) => item.type === 'alarm');

  useEffect(() => {
    creationTemplate.length === 1 && creationTemplate.find((item) => item.name === '기본') && setValue('creationMessageTemplate', '기본');
    alarmTemplate.length === 1 && alarmTemplate.find((item) => item.name === '기본') && setValue('alarmMessageTemplate', '기본');
  }, []);

  return (
    <>
      <div className="detail-content">
        <div className="font__body_sm_sb">메시지 설정</div>
        <div className="detail-body">
          {creationTemplate.length ? (
            <div
              className={classNames('flex-container', {
                'flex-col': creationTemplate.length === 1 ? !creationTemplate.find((item) => item.name === '기본') : true,
                'gap-24': creationTemplate.length === 1 ? !creationTemplate.find((item) => item.name === '기본') : true,
              })}
            >
              <div className="font__body_6">예약 안내 메시지</div>
              {creationTemplate.length === 1 && creationTemplate.find((item) => item.name === '기본') ? (
                <label className="tblm-switch pointer">
                  <input type="checkbox" defaultChecked onChange={(e) => setValue('creationMessageTemplate', e.target.checked ? '기본' : null)} />
                  <i />
                </label>
              ) : (
                <Select value={getValues('creationMessageTemplate')} onClick={() => setMenuModal({ visible: true, key: creationMessageTemplateId })} />
              )}
            </div>
          ) : null}
          {alarmTemplate.length ? (
            <div
              className={classNames('flex-container', {
                'flex-col': alarmTemplate.length === 1 ? !alarmTemplate.find((item) => item.name === '기본') : true,
                'gap-24': alarmTemplate.length === 1 ? !alarmTemplate.find((item) => item.name === '기본') : true,
              })}
            >
              <div className="font__body_6">당일 알림 메시지</div>
              {alarmTemplate.length === 1 && alarmTemplate.find((item) => item.name === '기본') ? (
                <label className="tblm-switch pointer">
                  <input type="checkbox" defaultChecked onChange={(e) => setValue('alarmMessageTemplate', e.target.checked ? '기본' : null)} />
                  <i />
                </label>
              ) : (
                <Select value={getValues('alarmMessageTemplate')} onClick={() => setMenuModal({ visible: true, key: alarmMessageTemplateId })} />
              )}
            </div>
          ) : null}
        </div>
      </div>
      <ModalPortal>
        <Modal.Menu key={creationMessageTemplateId} modal="menu" isDim={true} isAnimation={true}>
          <div className="user-info-menu-modal-wrapper">
            <div className="title font__subtitle">예약 안내 메시지 전송</div>
            <div className="content">
              <ControlledRadioBox
                name="creationMessageTemplate"
                options={creationTemplate.map((item) => ({ label: item.name, value: item.name }))}
                control={control}
                onChange={(e) => {
                  setValue('creationMessageTemplate', e.target.value);
                  setMenuModal({ visible: false, key: creationMessageTemplateId });
                }}
                defaultChecked={getValues('creationMessageTemplate')}
              />
            </div>
          </div>
        </Modal.Menu>
        <Modal.Menu key={alarmMessageTemplateId} modal="menu" isDim={true} isAnimation={true}>
          <div className="user-info-menu-modal-wrapper">
            <div className="title font__subtitle">당일 알림 메시지 전송</div>
            <div className="content">
              <ControlledRadioBox
                name="alarmMessageTemplate"
                options={alarmTemplate.map((item) => ({ label: item.name, value: item.name }))}
                control={control}
                onChange={(e) => {
                  setValue('alarmMessageTemplate', e.target.value);
                  setMenuModal({ visible: false, key: alarmMessageTemplateId });
                }}
                defaultChecked={getValues('alarmMessageTemplate')}
              />
            </div>
          </div>
        </Modal.Menu>
      </ModalPortal>
    </>
  );
};

export default MessageTemplateInfo;
