import { useModalStore } from '@/stores/common/useModalStore';
import classNames from 'classnames';
import { useState, useId } from 'react';
import HeaderBack from '../HeaderBack/HeaderBack';
import Modal from '../Modal/Modal';
import ModalPortal from '../ModalPortal/ModalPortal';

const KakaoTextImageEdit = ({ text, setText, closeModal, TextImageIcon }) => {
  const { setAlertModal } = useModalStore();
  const [tmpValue, setTmpValue] = useState(text || '');
  const closeConfirmModalId = useId();
  const onChange = (e) => {
    setTmpValue(e.target.value.slice(0, 30));
  };
  const cancelChange = () => {
    setTmpValue(text);
    closeModal();
  };
  const handleChange = () => {
    if (tmpValue.length < 1) {
      setAlertModal({ visible: true, key: closeConfirmModalId });
    } else {
      if (tmpValue !== text) {
        setText(tmpValue.slice(0, 30));
      }
      closeModal();
    }
  };

  return (
    <>
      <section className="kakao-setting-wrapper modaled">
        <HeaderBack inBack={true} isCenter noDummy onBack={cancelChange}>
          <div className="font__heading_4">텍스트 이미지 편집</div>
          <div className="font__body_5 color-green" onClick={handleChange}>
            완료
          </div>
        </HeaderBack>
        <div className="kakao-text-image-edit">
          <div className="kakao-text-image-image dummy">
            <textarea
              rows={6}
              cols={6}
              maxLength={30}
              className={classNames({
                'small-oneline': tmpValue.length === 6,
                twoline: tmpValue.length > 6,
                'small-twoline': tmpValue.length > 12,
                'small-threeline': tmpValue.length > 14,
              })}
              onChange={onChange}
              placeholder="텍스트입력"
              value={tmpValue}
            />
            <TextImageIcon />
          </div>
        </div>
      </section>
      <ModalPortal>
        <Modal.Alert
          key={closeConfirmModalId}
          modal="alert"
          isDim={true}
          isAnimation={true}
          modalData={{
            title: '필수정보를 입력해주세요',
            content: '매장을 대표할수 있는 단어를\n한글자 이상 입력해주세요',
            buttons: [
              {
                success: true,
                text: '확인',
                onClick: () => setAlertModal(false),
              },
            ],
          }}
        />
      </ModalPortal>
    </>
  );
};

export default KakaoTextImageEdit;
