import React, { useEffect, useState } from 'react';
import './TutorialKakaoChannelName.scss';
import { ReactComponent as IcWarning } from '@/assets/images/icons/ic_warning.svg';
import KakaoInput from '@/components/KakaoInput/KakaoInput';
import { ITutorialKakaoChannelProps } from '@/components/TutorialKakaoChannel/TutorialKakaoChannel.type';

const TutorialKakaoChannelName = ({ step, dispatcher, channelName }: (ITutorialKakaoChannelProps & {channelName: string})) => {
  const [name, setName] = useState<string>(channelName);
  const regex = RegExp(/[^a-z0-9ㄱ-힣]/gi);

  const stringOrNull = (e: string | null): string | null => (e?.length > 0 ? e : null);

  useEffect(() => dispatcher({ type: 'SET_CHANGE', value: stringOrNull(name), step }), [name]);

  return (
    <div className="tutorial-kakao-channel-content-container">
      <h1 className="tutorial-kakao-channel-title">카카오톡 채널 이름</h1>
      <span className="tutorial-kakao-channel-tabletCustomInformationMessage">20자 이내 한글/영문/숫자만 사용 가능합니다.</span>
      <KakaoInput placeholder="카카오톡 채널 이름을 입력해주세요." value={name} setValue={setName} regex={regex} maxLength={20} />
      <div className="tutorial-kakao-channel-name-warning-wrapper">
        <IcWarning />
        <span className="tutorial-kakao-channel-name-warning-text">신중하게 결정해주세요. 카카오톡 채널 이름은 추후에 변경이 불가능합니다.</span>
      </div>
    </div>
  );
};

export default TutorialKakaoChannelName;
