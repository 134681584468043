/* eslint-disable no-plusplus */
import cloneDeep from 'lodash/cloneDeep';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { getSelectValues } from '@/shared/lib/functions';
import { IPersistStore, IQuestion, IQuestionAction, Nullable } from '@/shared/utils/common.type';

const INITIAL_QUESTIONS: IQuestion = {
  step0_0: {
    value: 'ok',
    question: '테이블 설정을\n시작할게요.',
    type: null,
    note: '예약을 받으시려면 \n 기본 세팅을 마쳐주셔야 해요!',
    nextStep: {
      to: 'step1_0',
    },
  },
  step1_0: {
    value: null,
    question: '매장이 한 층으로\n되어 있나요?',
    type: 'button',
    nextStep: {
      bool: ['step2_0', 'step1_1'],
    },
  },
  step1_1: {
    value: null,
    multiple: true,
    question: '사용하는 층을\n모두 선택해주세요.',
    option: new Array(16)
      .fill(null)
      .map((_, i) => i - 5)
      .filter((i) => i !== 0)
      .reverse(),
    optionUnit: '층',
    type: 'checkbox',
    nextStep: {
      to: 'step2_1',
    },
  },
  // step2_0: {
  //   value: null,
  //   question: '매장에 따로\n룸이 있나요?',
  //   type: 'button',
  //   nextStep: {
  //     bool: ['step2_2', 'step3_0'],
  //   },
  // },
  // step2_1: {
  //   value: null,
  //   question: '매장에 따로\n룸이 있나요?',
  //   type: 'button',
  //   nextStep: {
  //     bool: ['step2_3', 'step3_1'],
  //   },
  // },
  // 유룸 단층
  step2_3: {
    value: null,
    title: '룸 개수 선택',
    question: '룸은\n몇 개 있나요?',
    option: [new Array(30).fill(null).map((_, i) => i + 1)],
    optionUnit: ['개'],
    type: 'select',
    nextStep: {
      to: 'step2_0',
    },
  },
  // 무룸 단층
  step2_0: {
    value: null,
    title: '홀테이블 개수 선택',
    question: '매장 홀테이블은\n몇 개인가요?',
    option: [new Array(50).fill(null).map((_, i) => i + 1)],
    optionUnit: ['개'],
    type: 'select',
    nextStep: {
      to: 'step3_0',
    },
  },
  // 무룸 다층
  step2_1: {
    value: null,
    title: '테이블 개수 선택',
    question: '{{option}} 테이블이 \n몇 개인가요?',
    option: [new Array(51).fill(null).map((_, i) => i)],
    optionUnit: ['개'],
    type: 'select',
    reference: 'step1_1',
    nextStep: {
      to: 'step3_0',
    },
  },

  // 유룸 다층
  step2_4: {
    value: null,
    title: '룸 개수 선택',
    question: '{{option}}에\n룸이 몇 개 있나요?',
    option: [new Array(31).fill(null).map((_, i) => i)],
    optionUnit: ['개'],
    type: 'select',
    reference: 'step1_1',
    nextStep: {
      to: 'step2_2',
    },
  },
  step2_2: {
    value: null,
    title: '테이블 개수 선택',
    question: '{{option}} 홀에는 \n테이블이 \n몇 개인가요?',
    option: [new Array(51).fill(null).map((_, i) => i)],
    optionUnit: ['개'],
    type: 'select',
    reference: 'step1_1',
    nextStep: {
      to: 'step3_0',
    },
  },

  step3_0: {
    value: null,
    question: '우리 매장의 테이블이 \n제대로 설정되었는지\n확인해주세요.',
    type: 'table',
    reference: 'step1_1',
    nextStep: {
      api: ['table', 'reservation'],
    },
  },
};

export const questionReducer = (state: IQuestion, action: IQuestionAction) => {
  const { type, value, step, multiple, idx } = action;
  const copyState = cloneDeep(state);
  switch (type) {
    case 'SET_CHANGE': {
      copyState[step].value = value;
      return copyState;
    }
    case 'SET_REF_CHANGE': {
      copyState[step].value[idx][1] = value;
      return copyState;
    }
    case 'SET_SELECT_CHANGE': {
      if (multiple) {
        copyState[step].value = getSelectValues(value);
      } else {
        copyState[step].value = value.value;
      }
      return copyState;
    }
    default:
      return state;
  }
};

export const useTableSettingStore = create(
  persist<IPersistStore>(
    (set) => ({
      // @ts-ignore
      storageQuestion: INITIAL_QUESTIONS,
      setStorageQuestion: (storageQuestion) => set({ storageQuestion: storageQuestion }),
      resetStorageQuestion: () => set({ storageQuestion: INITIAL_QUESTIONS }),
    }),
    { name: 'table-setting', getStorage: () => localStorage, version: 7 }
  )
);
