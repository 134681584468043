import React, { useEffect } from 'react';

import AGREEMENT_LIST from '@/components/Agreement/Agreement.constants';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import classnames from 'classnames';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useNavigate } from 'react-router-dom';
import { useReservationSettingStore } from '@/stores/common/useReservationSettingStore';
import { useSettingTutorialStore } from '@/stores/common/useSettingTutorialStore';
import { useTableSettingStore } from '@/stores/common/useTableSettingStore';
import { instantDisabler } from '@/shared/lib/functions';
import { useKakaoChannelSettingStore } from '@/stores/common/useKakaoChannelSettingStore';
import { useBusinessInfoSettingStore } from '@/stores/common/useBusinessInfoSettingStore';
import useNative from '@/hooks/useNative';
import { useKakaoAlimtalkStore } from '@/stores/common/useKakaoAlimtalkStore';

const TutorialIndexPage = (): React.ReactElement => {
  const { accessToken } = useAuthStore();
  const { state, setState, agreements, setAgreements } = useSettingTutorialStore();
  const navigate = useNavigate();
  const { callAlert } = useNative();

  const businessReset = useBusinessInfoSettingStore((s) => s.resetStorageQuestion);
  const tableReset = useTableSettingStore((s) => s.resetStorageQuestion);
  const reservationReset = useReservationSettingStore((s) => s.resetStorageQuestion);
  const kakaoChannelReset = useKakaoChannelSettingStore((s) => s.resetStorageQuestion);
  const { refreshAlimtalk } = useKakaoAlimtalkStore();

  const no = () => {
    if (window.ExitPage) window.ExitPage.postMessage('');
    else navigate(-1);
  };
  const unProceed = (withApi?: boolean) => {
    if (withApi === true) {
      devServerApi.api.patch('/tutorial', { status: 'done' });
    }
    setTimeout(() => {
      tableReset();
      reservationReset();
    }, 0);
    no();
  };
  const start = () => {
    if (state === 'done') {
      if (window.ExitPage) window.ExitPage.postMessage('');
      else navigate('/reservation/list');
      return;
    }
    navigate('/tutorial/' + (['business', 'table', 'reservation', 'essential', 'done'].includes(state) ? state : 'essential'));
  };

  useEffect(() => {
    if (agreements && state && state !== 'done') {
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('body').style.overflow = 'auto';
    }
    if (agreements && state === 'done') {
      unProceed();
    }
  }, [state, agreements]);

  useEffect(() => {
    devServerApi.api
      .authGet('/terms-agree')
      .then((dataA) => {
        // @ts-ignore
        const { response, status, message } = dataA;
        let essentialAgreementsList = AGREEMENT_LIST.filter((a) => a.isEssential).map((a) => a.name);
        if (status < 0) {
          callAlert('오류', message, [{ btnNm: '확인', value: 'close' }]);
        }
        for (let a of response) {
          if (a.isAgree) {
            essentialAgreementsList = essentialAgreementsList.filter((e) => e !== a.type);
          }
        }
        setAgreements(essentialAgreementsList.length === 0);
        if (essentialAgreementsList.length !== 0) {
          navigate('/setting/agreement', { replace: true });
          return;
        }

        Promise.all([devServerApi.api.authGet('/tutorial'), devServerApi.lg.authGet('/api/partner/mall/is-business'), refreshAlimtalk()]).then(([dataT, dataB, alimtalk]) => {
          // @ts-ignore
          const { tutorial } = dataT;
          // @ts-ignore
          const { existsBusiness } = dataB;
          setState(tutorial);
          console.log(tutorial, existsBusiness, alimtalk.mall);
          if (!existsBusiness) {
            tableReset();
            businessReset();
            reservationReset();
            kakaoChannelReset();
            navigate('/tutorial/business');
          } else if (tutorial === null || tutorial === 'essential') {
            tableReset();
            reservationReset();
            kakaoChannelReset();
            navigate('/tutorial/essential');
          } else if (tutorial === 'table') {
            tableReset();
            reservationReset();
            kakaoChannelReset();
            navigate('/tutorial/table');
          } else if (tutorial === 'reservation') {
            reservationReset();
            kakaoChannelReset();
            navigate('/tutorial/reservation');
            // 예약 튜토리얼에서 카카오로 이동하는 로직은 일단 빼둠
            // } else if (!alimtalk.setting.channelName || alimtalk.setting.channelName?.length <= 0) {
            // kakaoChannelReset();
            // navigate('/tutorial/kakao-channel');
          } else if (tutorial === 'done') {
            tableReset();
            reservationReset();
            kakaoChannelReset();
            if (window.ExitPage) {
              console.log('윈도우 엑싯페이지');
            } else {
              console.log('윈도우 엑싯 페이지 안함');
              navigate(-1);
            }
          }
        });
      })
      .catch(() => {
        setAgreements(false);
        navigate('/setting/agreement', { replace: true });
      });
  }, []);

  return (
    <>
      <div className={classnames('tutorial-page')} />
    </>
  );
};

export default TutorialIndexPage;
