import React, { useRef } from 'react';
import { ReactComponent as Close } from '@/assets/images/icons/Close.svg';
import { ReactComponent as RadioOn } from '@/assets/images/icons/Radio_on.svg';
import { ReactComponent as RadioOff } from '@/assets/images/icons/Radio_off.svg';
import { ReactComponent as SwitchOn } from '@/assets/images/icons/Switch_On.svg';
import { ReactComponent as SwitchOff } from '@/assets/images/icons/Switch_Off.svg';
import { ReactComponent as ArrowLeft } from '@/assets/images/icons/Arrow_Left.svg';

import classNames from 'classnames';
import autosize from 'autosize';
import { useLocation, useNavigate } from 'react-router-dom';
import { devServerApi } from '@/shared/apis/devServerApi';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import { useQuery } from 'react-query';
import queryClient from '@/shared/utils/queryClient';

import '@/styles/base/tailwind.css';
import useNative from '@/hooks/useNative';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useModalStore } from '@/stores/common/useModalStore';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';

const TABLET_STATUS = {
  CLOSING: '대기 마감',
  PAUSE: '대기 일시 정지',
  ENTER: '바로 입장',
};

interface Checked {
  isWaitingClosing: boolean;
  useWaitingRemoteReception: boolean;
}

const WaitingSettingPage: React.FC = () => {
  let location = useLocation();
  const [checked, setChecked] = React.useState<Checked>();
  const [openReasonSetting, setOpenReasonSetting] = React.useState<boolean>(false);
  const [closedReason, setClosedReason] = React.useState<string>('');
  const [selectedTabletStatus, setSelectedTabletStatus] = React.useState<'CLOSING' | 'PAUSE' | 'ENTER'>();
  const [tabletStatus, setTabletStatus] = React.useState<'CLOSING' | 'PAUSE' | 'ENTER'>();
  const [message, setMessage] = React.useState<{
    tabletClosingMessage: string;
    selectedTabletClosingMessage: string;
    tabletPauseMessage: string;
    selectedTabletPauseMessage: string;
    tabletEnterMessage: string;
    selectedTabletEnterMessage: string;
  }>();
  React.useEffect(() => {
    let state = location.state;
    if (state) {
      setChecked({
        isWaitingClosing: state.option?.isWaitingClosing ? true : false,
        useWaitingRemoteReception: state.extra?.useWaitingRemoteReception ? true : false,
      });
      setMessage({
        tabletClosingMessage: state.option?.tabletClosingMessage || '',
        selectedTabletClosingMessage: state.option?.tabletClosingMessage || '',
        tabletPauseMessage: state.option?.tabletPauseMessage || '',
        selectedTabletPauseMessage: state.option?.tabletPauseMessage || '',
        tabletEnterMessage: state.option?.tabletEnterMessage || '',
        selectedTabletEnterMessage: state.option?.tabletEnterMessage || '',
      });
      setTabletStatus(state.option?.tabletStatus || 'CLOSING');
      setSelectedTabletStatus(state.option?.tabletStatus || 'CLOSING');
      let reason = '';
      switch (state.option?.tabletStatus) {
        case 'CLOSING':
          reason = state.option?.tabletClosingMessage || '';
          break;
        case 'PAUSE':
          reason = state.option?.tabletPauseMessage || '';
          break;
        case 'ENTER':
          reason = state.option?.tabletEnterMessage || '';
          break;
      }
      setClosedReason(reason);
    }
  }, []);

  const { callAlert } = useNative();
  const { setToastModal } = useModalStore();
  const { accessToken } = useAuthStore();
  const {
    data: optionsResponse,
    refetch,
    isLoading,
  } = useQuery(['waiting_options'], () => devServerApi.api.get('/waiting/option'), {
    onSuccess: ({ data }) => {
      console.log(location);
      setChecked({
        isWaitingClosing: data.option?.isWaitingClosing ? true : false,
        useWaitingRemoteReception: data.extra?.useWaitingRemoteReception ? true : false,
      });
      setMessage({
        tabletClosingMessage: data.option?.tabletClosingMessage || '',
        selectedTabletClosingMessage: data.option?.tabletClosingMessage || '',
        tabletPauseMessage: data.option?.tabletPauseMessage || '',
        selectedTabletPauseMessage: data.option?.tabletPauseMessage || '',
        tabletEnterMessage: data.option?.tabletEnterMessage || '',
        selectedTabletEnterMessage: data.option?.tabletEnterMessage || '',
      });
      setTabletStatus(data.option?.tabletStatus || 'CLOSING');
      setSelectedTabletStatus(data.option?.tabletStatus || 'CLOSING');
      let reason = '';
      switch (data.option?.tabletStatus) {
        case 'CLOSING':
          reason = data.option?.tabletClosingMessage || '';
          break;
        case 'PAUSE':
          reason = data.option?.tabletPauseMessage || '';
          break;
        case 'ENTER':
          reason = data.option?.tabletEnterMessage || '';
          break;
      }
      setClosedReason(reason);
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });
  const sheetRef = useRef<BottomSheetRef>();
  const navigate = useNavigate();

  const blurFocusedElement = () => {
    // get focused element
    const focusedElement = document.activeElement as HTMLElement;
    // blur the focused element
    focusedElement.blur();
  };

  React.useEffect(() => {
    if (openReasonSetting) {
      setTimeout(() => blurFocusedElement(), 100);
    }
  }, [openReasonSetting]);

  const changeStatus = async (requestBody: { isWaitingClosing?: boolean; useWaitingRemoteReception?: boolean }) => {
    try {
      await devServerApi.api.put('/waiting/option', {
        isWaitingClosing: requestBody.isWaitingClosing,
        extra: {
          useWaitingRemoteReception: requestBody.useWaitingRemoteReception,
        },
      });
      setChecked((prev) => ({
        ...prev,
        ...requestBody,
      }));

      await queryClient.invalidateQueries(['waiting_options']);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDialog = (key: string) => () => {
    if (key === 'isWaitingClosing' && checked.isWaitingClosing) {
      callAlert('원격 대기 접수도 받을까요?', `원격 대기 비활성화 시\n현장 대기 접수만 받습니다.`, [
        {
          btnNm: '아니오',
          value: () =>
            devServerApi.api
              .put('/waiting/option', {
                isWaitingClosing: false,
                extra: {
                  useWaitingRemoteReception: false,
                },
              })
              .then((res) => {
                // process.env.NODE_ENV === 'production' &&
                //   alert(
                //     'payload ::: ' +
                //       JSON.stringify({
                //         isWaitingClosing: false,
                //         extra: {
                //           useWaitingRemoteReception: false,
                //         },
                //       })
                //   );
                // process.env.NODE_ENV === 'production' && alert('res ::: ' + JSON.stringify(res.data.option));
                queryClient.invalidateQueries(['waiting_options']);
              }),
        },
        {
          btnNm: '원격 대기 받기',
          value: () =>
            devServerApi.api
              .put('/waiting/option', {
                isWaitingClosing: false,
                extra: {
                  useWaitingRemoteReception: true,
                },
              })
              .then((res) => {
                // process.env.NODE_ENV === 'production' &&
                //   alert(
                //     'payload ::: ' +
                //       JSON.stringify({
                //         isWaitingClosing: false,
                //         extra: {
                //           useWaitingRemoteReception: true,
                //         },
                //       })
                //   );
                // process.env.NODE_ENV === 'production' && alert('res ::: ' + JSON.stringify(res.data.option));
                queryClient.invalidateQueries(['waiting_options']);
              }),
        },
      ]);
      return;
    }
    if (key === 'isWaitingClosing') {
      if (!checked.isWaitingClosing) {
        setOpenReasonSetting(true);
      }
      changeStatus({
        isWaitingClosing: !checked.isWaitingClosing,
      });
      if (checked.isWaitingClosing) {
        setToastModal({ visible: true, key: 'global', text: '전체 대기 접수를 마감합니다.' });
      }
    }
    if (key === 'useWaitingRemoteReception') {
      changeStatus({
        useWaitingRemoteReception: !checked.useWaitingRemoteReception,
      });
      if (checked.useWaitingRemoteReception) {
        setToastModal({ visible: true, key: 'global', text: '원격 대기 접수만 마감합니다.' });
      }
    }
  };

  const toggleRadio = (status: 'CLOSING' | 'PAUSE' | 'ENTER') => () => {
    setTabletStatus(status);
  };
  const toggleSelectedRadio = (status: 'CLOSING' | 'PAUSE' | 'ENTER') => () => {
    setSelectedTabletStatus(status);
  };

  const saveClose = async () => {
    setOpenReasonSetting(false);
    await devServerApi.api.put('/waiting/option', {
      tabletStatus: selectedTabletStatus,
      tabletClosingMessage: selectedTabletStatus === 'CLOSING' ? message.selectedTabletClosingMessage : message.tabletClosingMessage,
      tabletPauseMessage: selectedTabletStatus === 'PAUSE' ? message.selectedTabletPauseMessage : message.tabletPauseMessage,
      tabletEnterMessage: selectedTabletStatus === 'ENTER' ? message.selectedTabletEnterMessage : message.tabletEnterMessage,
    });
    setChecked((prev) => ({
      ...prev,
      useWaitingRemoteReception: false,
    }));

    await queryClient.invalidateQueries(['waiting_options']);
    let reason = '';
    switch (selectedTabletStatus) {
      case 'CLOSING':
        reason = message.selectedTabletClosingMessage;
        break;
      case 'PAUSE':
        reason = message.selectedTabletPauseMessage;
        break;
      case 'ENTER':
        reason = message.selectedTabletEnterMessage;
        break;
    }
    setClosedReason(reason);
  };

  React.useEffect(() => {
    // all textarea in document
    const textareas = document.querySelectorAll('textarea');
    textareas.forEach((textarea) => {
      autosize(textarea);
    });
  }, []);

  if (isLoading || !checked) return null;

  return (
    <div className="min-w-screen min-h-screen flex flex-col">
      <SpinnerEffector loading={isLoading} />
      <div className="relative py-[15px] justify-center items-center w-full flex">
        <p className="text-zinc-800 text-lg font-bold">대기 접수</p>
        <ArrowLeft className="absolute left-5 w-[20px] h-[20px]" onClick={() => navigate(-1)} />
      </div>
      <div className="flex flex-col flex-1 bg-zinc-100 divide-y divide-gray-100">
        <div className="bg-white px-5 py-6 flex w-full items-center justify-between">
          <div className="flex flex-col">
            <p className="text-xl text-zinc-800 font-bold mb-[6px]">대기 접수</p>
            <p className="text-sm text-zinc-500 font-medium">대기 접수를 받을까요?</p>
          </div>
          <div className="flex items-center">
            <span className="text-zinc-500 text-sm font-medium mr-[16px]">{!checked.isWaitingClosing ? '대기 접수 중' : '대기 마감'}</span>
            {!checked.isWaitingClosing ? <SwitchOn onClick={handleDialog('isWaitingClosing')} /> : <SwitchOff onClick={handleDialog('isWaitingClosing')} />}
          </div>
        </div>
        {!checked.isWaitingClosing && (
          <div className="bg-white px-5 py-6 flex w-full items-center justify-between">
            <div className="flex flex-col">
              <p className="text-xl text-zinc-800 font-bold mb-[6px]">원격 대기 접수</p>
              <p className="text-sm text-zinc-500 font-medium">원격 대기 접수를 받을까요?</p>
            </div>
            <div className="flex items-center">
              <span className="text-zinc-500 text-sm font-medium mr-[16px]">{checked.useWaitingRemoteReception ? '대기 접수 중' : '대기 마감'}</span>
              {checked.useWaitingRemoteReception ? (
                <SwitchOn onClick={handleDialog('useWaitingRemoteReception')} />
              ) : (
                <SwitchOff onClick={handleDialog('useWaitingRemoteReception')} />
              )}
            </div>
          </div>
        )}
        {checked.isWaitingClosing && (
          <div className="bg-white px-5 py-6 flex w-full flex-col">
            <div className="flex w-full justify-between items-center">
              <div className="flex flex-col">
                <p className="text-xl text-zinc-800 font-bold mb-[6px]">대기 마감 사유</p>
                <p className="text-sm text-zinc-500 font-medium whitespace-pre-wrap">{`대기 마감 사유를\n어떻게 안내할까요?`}</p>
              </div>
              <div className="flex items-center text-lg font-bold text-zinc-800" onClick={() => setOpenReasonSetting(true)}>
                {TABLET_STATUS[tabletStatus]}
              </div>
            </div>
            <div className="h-px w-full bg-gray-100 my-[16px]" />
            <div className="p-[14px] rounded border border-stone-300">
              <span className="w-full text-sm text-[#2E2E30] bg-white">{closedReason}</span>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-1 bg-zinc-100 w-full" />
      {openReasonSetting && (
        <BottomSheet
          snapPoints={({ maxHeight }) => [maxHeight * 0.8]}
          defaultSnap={({ maxHeight }) => maxHeight * 0.8}
          open={openReasonSetting}
          header={
            <div className="flex justify-between items-center w-full">
              <b className="text-zinc-800 text-lg font-bold px-1">대기 마감 사유</b>
              <Close
                className="w-6 h-6"
                onClick={() => {
                  setOpenReasonSetting(false);
                  setSelectedTabletStatus(tabletStatus);
                  setMessage({
                    tabletClosingMessage: message.tabletClosingMessage || '',
                    selectedTabletClosingMessage: message.tabletClosingMessage,
                    tabletPauseMessage: message.tabletPauseMessage || '',
                    selectedTabletPauseMessage: message.tabletPauseMessage || '',
                    tabletEnterMessage: message.tabletEnterMessage || '',
                    selectedTabletEnterMessage: message.tabletEnterMessage || '',
                  });
                }}
              />
            </div>
          }
          footer={
            <div>
              <button onClick={saveClose} className={classNames('p-[14px] items-center justify-center rounded text-white w-full text-sm font-bold bg-violet-700')}>
                저장
              </button>
            </div>
          }
          ref={sheetRef}
          onDismiss={() => {
            setOpenReasonSetting(false);
            setSelectedTabletStatus(tabletStatus);
            setMessage({
              tabletClosingMessage: message.tabletClosingMessage || '',
              selectedTabletClosingMessage: message.tabletClosingMessage,
              tabletPauseMessage: message.tabletPauseMessage || '',
              selectedTabletPauseMessage: message.tabletPauseMessage || '',
              tabletEnterMessage: message.tabletEnterMessage || '',
              selectedTabletEnterMessage: message.tabletEnterMessage || '',
            });
          }}
        >
          <div className="flex justify-center flex-col">
            <p className="text-zinc-500 text-sm px-5 mt-[20px]">대기 마감 사유를 어떻게 안내할까요?</p>
          </div>
          <div className="flex flex-col p-5 my-[20px] divide-y divide-gray-100 gap-4">
            <div className="flex py-4 flex-col">
              <div className="flex justify-between items-center">
                <span className="text-zinc-800">대기 마감</span>
                {selectedTabletStatus === 'CLOSING' ? <RadioOn /> : <RadioOff onClick={toggleSelectedRadio('CLOSING')} />}
              </div>
              {selectedTabletStatus === 'CLOSING' && (
                <div className="my-[16px] relative">
                  <div className="bg-gray-100 rounded p-[14px]">
                    <textarea
                      className="border-none bg-transparent w-full text-zinc-800 text-sm font-medium"
                      value={message.selectedTabletClosingMessage}
                      onChange={(e) => {
                        setMessage((prev) => ({ ...prev, selectedTabletClosingMessage: e.target.value }));
                      }}
                      rows={1}
                      maxLength={120}
                    />
                  </div>
                  <span className="text-right text-stone-300 text-sm absolute right-0">{`${message.selectedTabletClosingMessage.length}자/120자`}</span>
                </div>
              )}
            </div>
            <div className="flex py-4 flex-col">
              <div className="flex justify-between items-center">
                <span className="text-zinc-800">대기 일시 정지</span>
                {selectedTabletStatus === 'PAUSE' ? <RadioOn /> : <RadioOff onClick={toggleSelectedRadio('PAUSE')} />}
              </div>
              {selectedTabletStatus === 'PAUSE' && (
                <div className="my-[16px] relative">
                  <div className="bg-gray-100 rounded p-[14px]">
                    <textarea
                      className="border-none border-0 bg-transparent w-full text-zinc-800 text-sm font-medium"
                      value={message.selectedTabletPauseMessage}
                      onChange={(e) => {
                        setMessage((prev) => ({ ...prev, selectedTabletPauseMessage: e.target.value }));
                      }}
                      rows={1}
                      maxLength={120}
                    />
                  </div>
                  <span className="text-right text-stone-300 text-sm absolute right-0">{`${message.selectedTabletPauseMessage.length}자/120자`}</span>
                </div>
              )}
            </div>
            <div className="flex py-4 flex-col">
              <div className="flex justify-between items-center">
                <span className="text-zinc-800">바로 입장</span>
                {selectedTabletStatus === 'ENTER' ? <RadioOn /> : <RadioOff onClick={toggleSelectedRadio('ENTER')} />}
              </div>
              {selectedTabletStatus === 'ENTER' && (
                <div className="my-[16px] relative">
                  <div className="bg-gray-100 rounded p-[14px]">
                    <textarea
                      className="border-none border-0 bg-transparent w-full text-zinc-800 text-sm font-medium"
                      value={message.selectedTabletEnterMessage}
                      onChange={(e) => {
                        if (e.target.value.length > 120) return;
                        setMessage((prev) => ({ ...prev, selectedTabletEnterMessage: e.target.value }));
                      }}
                      rows={1}
                      maxLength={120}
                    />
                  </div>
                  <span className="text-right text-stone-300 text-sm absolute right-0">{`${message.selectedTabletEnterMessage.length}자/120자`}</span>
                </div>
              )}
            </div>
          </div>
        </BottomSheet>
      )}
    </div>
  );
};

export default WaitingSettingPage;
