import 'dayjs/locale/ko';

import { IMutationProps, IReservation, reservationStateList } from '@/shared/utils/common.type';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import AGREEMENT_LIST from '@/components/Agreement/Agreement.constants';
import AccessAgreement from '@/components/Reservation/AccessAgreement/AccessAgreement';
import { ReactComponent as Calendar_Month } from '@/assets/images/icons/Calendar_Month.svg';
import Card from '@/components/Card/Card';
import { ReactComponent as EmptyDataCommonList } from '@/assets/images/icons/EmptyData-CommonList.svg';
import { ReactComponent as IcFilter } from '@/assets/images/icons/ic_filter.svg';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import ReservationListCalendar from '@/components/ReservationListCalendar/ReservationListCalendar';
import ReservationOn from '@/components/Reservation/List/ReservationOn';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import WeekCalendar from '@/components/WeekCalendar/WeekCalendar';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useReservationCalendarStore } from '@/stores/reservation/useReservationCalendarStore';
import { useReservationSettingStore } from '@/stores/common/useReservationSettingStore';
import { useSettingTutorialStore } from '@/stores/common/useSettingTutorialStore';
import { useTableSettingStore } from '@/stores/common/useTableSettingStore';

import Terces from '@/shared/apis/terces';
import EnterSetting from '@/components/Reservation/EnterSetting/EnterSetting';
import { useNativeStore } from '@/stores/reservation/useNativeStore';
import useNative from '@/hooks/useNative';
import { useMallStore } from '@/stores/common/useMallStore';
import IconTextButton from '@/components/IconTextButton/IconTextButton';
import useBackButton from '@/hooks/useBackButton';
import { useReservationStore } from '@/stores/reservation/useReservationStore';

dayjs.locale('ko');

const List = (): React.ReactElement => {
  let location = useLocation();
  const [searchParams] = useSearchParams({ type: 'default' });
  const navigate = useNavigate();
  const { setReservationList } = useReservationCalendarStore();
  const { reservationMarkingColors, rooms: storeRooms } = useMallStore((state) => state.mall);
  const tableReset = useTableSettingStore((state) => state.resetStorageQuestion);
  const reservationReset = useReservationSettingStore((state) => state.resetStorageQuestion);
  const { roomGroups } = useMallStore((state) => state.mall);
  const { state, setState, agreements, setAgreements, setAgreementsDetail } = useSettingTutorialStore();
  const [showCalendar, setShowCalendar] = useState(false);
  const [timeFilter, setTimeFilter] = useState<'all' | 'lunch' | 'dinner' | string>('all');
  const initialDate = location.state || searchParams.get('date') || dayjs().format('YYYY-MM-DD');
  const [selectedDate, setSelectedDate] = useState(dayjs(initialDate));
  const { allReservation, setAllReservation, reservationDetailId, filter, requestReservationEnable, setRequestReservationEnable } = useNativeStore();
  const today = dayjs();
  const { setReservationResetStore } = useReservationStore();
  const { openReservationCard, openReservationFilter, openReservationOffAlert, requestToken } = useNative();
  useBackButton(() => {
    setShowCalendar(false);
  });

  // const filter_menu = useId();

  const mustTutorial = () => navigate('/tutorial');

  const filterBody = useCallback(
    (reservation) => {
      // 시간대 필터
      let timeFiltered;
      switch (timeFilter) {
        case 'lunch':
          timeFiltered = reservation.visitTime < 960;
          break;
        case 'dinner':
          timeFiltered = reservation.visitTime >= 960;
          break;
        default:
          timeFiltered = true;
      }
      // 상태 필터
      let stateFiltered;
      if (filter.status.length === reservationStateList.length) {
        stateFiltered = true;
      } else {
        stateFiltered = filter.status.includes(reservation.reservationState);
      }

      // 테이블 그룹 필터
      let tableGroupFiltered;
      switch (filter.group) {
        case 'all':
          tableGroupFiltered = true;
          break;
        case 'unset':
          tableGroupFiltered = reservation.rooms.length === 0;
          break;
        default:
          tableGroupFiltered = reservation.rooms.some((room) => room.roomGroupId === filter.group);
      }
      return timeFiltered && stateFiltered && tableGroupFiltered;
    },
    [filter, timeFilter]
  );

  const reservations = allReservation.filter(filterBody) || [];
  // const reservations = [
  //   {
  //     createdAt: '2023-12-01',
  //     depositAmount: 10000,
  //     depositState: 'paid',
  //     isWalkIn: false,
  //     menus: [],
  //     parties: [{ name: '성인', size: 3 }],
  //     partnerId: 12314,
  //     partnerName: '카카오',
  //     reservationExtravalues: [{ id: '1', name: '온라인', backgroundColor: '#1481DB', display: '온라인', fontColor: '#FFF', value: '온라인' }],
  //     reservationId: 'reservationId1',
  //     reservationMemo: 'reservationMemo',
  //     reservationName: '단체 창가자리 예약',
  //     reservationRequests: 'reservationRequests',
  //     reservationState: 'valid',
  //     rooms: [],
  //     userExtravalues: [{ id: '1', name: '유저관련', backgroundColor: '#F4F4F4', display: '첫방1241문', fontColor: '#4F5861', value: '유저관련' }],
  //     userMemo: 'userMemo',
  //     userName: '한지영',
  //     userNickname: '알러지 유의 고객, 사장님 지인',
  //     userPastValidReservationCount: 0,
  //     userPhone: '010-3843-9978',
  //     visitDate: '2023-12-21',
  //     visitTime: 600,
  //   } as IReservation,
  // ];

  const handleActivateCard = (idx: number) => {
    reservations[idx].rooms.forEach((room) => {
      room.name = storeRooms.find((storeRoom) => storeRoom.id === room.id)?.name;
    });
    openReservationCard(reservations[idx], reservationMarkingColors);
  };

  const { isFetching: isReservationsLoading } = useQuery(
    [QUERY_KEYS.LIST_RESERVATIONS, selectedDate.format('YYYY-MM-DD')],
    () => devServerApi.api.authGet<{ reservations: IReservation[] }>('reservations?date=' + selectedDate.format('YYYY-MM-DD')).then((enc_res) => Terces.decrypt(enc_res)),
    {
      onSuccess: (res) => {
        let orderId = searchParams.get('orderId');
        if (orderId) {
          const index = res.reservations.findIndex((reservation) => reservation.reservationId === orderId);
          handleActivateCard(index);
          searchParams.delete('orderId');
          navigate('', { replace: true });
        }
        setAllReservation(res.reservations);
      },
      onError: () => {
        // !!!! mock
        // setAllReservation([
        //   {
        //     createdAt: '2023-12-01',
        //     depositAmount: 10000,
        //     depositState: 'paid',
        //     isWalkIn: false,
        //     menus: [],
        //     parties: [{ name: '아무개', size: 1 }],
        //     partnerId: 12314,
        //     partnerName: '파트너',
        //     reservationExtravalues: [{ id: '1', name: '첫방문', backgroundColor: '#F4F4F4', display: '첫방문', fontColor: '#4F5861', value: '값?' }],
        //     reservationId: 'reservationId1',
        //     reservationMemo: 'reservationMemo',
        //     reservationName: 'reservationName',
        //     reservationRequests: 'reservationRequests',
        //     reservationState: 'reservationState',
        //     rooms: [],
        //     userExtravalues: [{ id: '1', name: '첫방문', backgroundColor: '#F4F4F4', display: '첫방문', fontColor: '#4F5861', value: '값?' }],
        //     userMemo: 'userMemo',
        //     userName: 'userName',
        //     userNickname: 'userNickname',
        //     userPastValidReservationCount: 123,
        //     userPhone: 'userPhone',
        //     visitDate: '2023-12-21',
        //     visitTime: 600,
        //   },
        // ]);
      },
    }
  );

  const { isLoading: isReservationState } = useQuery([QUERY_KEYS.LIST_STATE], () => devServerApi.api.authGet('/settings/reservation/state'), {
    onSuccess: (res: any) => {
      res.status === 'disable' && openReservationOffAlert();
    },
  });

  const { isLoading: isCalendarLoading } = useQuery(
    [QUERY_KEYS.LIST_CALENDAR],
    () => devServerApi.api.authGet(`reservation/calendar/?from=${today.add(-1, 'month').format('YYYY-MM')}&to=${today.add(3, 'month').format('YYYY-MM')}`),
    {
      onSuccess: (res: any) => {
        const convertDate = res.reservationSummary.map((item) => ({ ...item, date: dayjs(item.date) }));
        setReservationList(convertDate);
      },
      onError: () => {
        // !!!!! mock
        // setState('done');
        // setAgreements(true);
        // setReservationList([
        //   {
        //     date: dayjs('2023-12-21'),
        //     reservationCount: 1,
        //     totalPartySize: 1,
        //   },
        // ]);
      },
    }
  );

  const { isLoading: isTermsAgreeLoading } = useQuery([QUERY_KEYS.LIST_TERMS_AGREE], () => devServerApi.api.authGet('/terms-agree'), {
    onSuccess: (res: any) => {
      const { response } = res;
      setAgreementsDetail(response);
      let essentialAgreementsList = AGREEMENT_LIST.filter((a) => a.isEssential).map((a) => a.name);
      for (let a of response) {
        if (a.isAgree) {
          essentialAgreementsList = essentialAgreementsList.filter((e) => e !== a.type);
        }
      }
      setAgreements(essentialAgreementsList.length === 0);
    },
    onError: () => setAgreements(false),
  });

  const { isLoading: isTutorialLoading } = useQuery([QUERY_KEYS.LIST_TUTORIAL], () => devServerApi.api.authGet('/tutorial'), {
    onSuccess: (res: any) => {
      const { tutorial } = res;
      setState(tutorial);
      tableReset();
      reservationReset();
    },
    onError: () => {
      // !!!! mock
      // setState('done');
      // setAgreements(true);
    },
  });

  const { mutate, isLoading: isReservationMutateLoading } = useMutation((mutatedata: IMutationProps) => devServerApi.api.mutation(mutatedata), {
    onMutate: () => ({ key: [QUERY_KEYS.LIST_STATE] }),
  });

  const reservationCount = useMemo(() => reservations.length, [reservations]);
  const reservationAllPartySize = useMemo(
    () => reservations.reduce((previousValue: number, currentValue) => previousValue + currentValue.parties.reduce((a, b) => a + Number(b.size), 0), 0),
    [reservations]
  );

  useLayoutEffect(() => {
    if (reservationDetailId) navigate(`/reservation/detail/${reservationDetailId}`);
  }, [reservationDetailId]);

  useEffect(() => {
    requestToken();
  }, []);

  useEffect(() => {
    if (!requestReservationEnable) return;

    mutate({
      operation: 'patch',
      url: '/settings/reservation/state',
      data: { mallStatus: 'using' },
    });
    setRequestReservationEnable(false);
  }, [requestReservationEnable]);

  if (isReservationState || isReservationMutateLoading || isCalendarLoading) {
    return <SpinnerEffector loading={isReservationState || isReservationMutateLoading || isCalendarLoading} />;
  }
  if (isTermsAgreeLoading && isTutorialLoading) {
    return <SpinnerEffector loading={isTermsAgreeLoading && isTutorialLoading} />;
  }
  if (state !== 'done' || !agreements) {
    return <ReservationOn onClick={mustTutorial} />;
  }

  if (showCalendar) return <ReservationListCalendar visitDate={selectedDate} setVisitDate={setSelectedDate} onClose={() => setShowCalendar(false)} />;

  return (
    <div className="reservation_list_page">
      <div className="reservation_top">
        <WeekCalendar className="schedule" selectedDate={selectedDate} select={setSelectedDate} />
        <div className="filter-menu">
          <div className="time-div">
            <label className="tblm-rc">
              <input type="radio" name="timeFilter" value={'all'} checked={timeFilter === 'all'} onChange={(e) => setTimeFilter(e.target.value)} />
              <i />
              <span>전체</span>
            </label>
            <label className="tblm-rc">
              <input type="radio" name="timeFilter" value={'lunch'} checked={timeFilter === 'lunch'} onChange={(e) => setTimeFilter(e.target.value)} />
              <i />
              <span>점심</span>
            </label>
            <label className="tblm-rc">
              <input type="radio" name="timeFilter" value={'dinner'} checked={timeFilter === 'dinner'} onChange={(e) => setTimeFilter(e.target.value)} />
              <i />
              <span>저녁</span>
            </label>
          </div>
          <div className="time-control-panel">
            <span className="calendar-icon-wrap" onClick={() => setShowCalendar(true)}>
              <Calendar_Month />
            </span>
            <span className="today-btn-wrap" onClick={() => setSelectedDate(dayjs())}>
              <button className={classNames('today', { active: selectedDate.isSame(today, 'day') })}>오늘</button>
            </span>
          </div>
        </div>
      </div>
      <div className="reservation_list">
        {isReservationsLoading ? (
          <SpinnerEffector loading={isReservationsLoading} />
        ) : (
          <>
            {!reservations.length ? <SpinnerEffector loading={Boolean(reservations.length)} /> : null}
            <div className="indicator">
              <span className="reservation-counter">
                전체 ({reservationCount}건, {reservationAllPartySize}명)
              </span>
              <IconTextButton
                icon={IcFilter}
                text="필터"
                onClick={() => {
                  const groups = Object.entries(roomGroups);
                  groups.unshift(['all', '전체'], ['unset', '미지정']);
                  openReservationFilter(groups);
                  // setMenuModal({ visible: true, key: filter_menu });
                }}
              />
            </div>
            {reservations.length > 0 ? (
              <>
                {reservations.map((reservation, idx) => (
                  <Card key={reservation.reservationId} cardIdx={idx} reservation={reservation} onActivate={handleActivateCard} />
                ))}
              </>
            ) : (
              <div className={'empty-data-note'}>
                <EmptyDataCommonList />
                <div>등록된 예약이 없습니다.</div>
              </div>
            )}
          </>
        )}
      </div>
      <Link className="tblm-btn-float no-padding" to={`/reservation/accept/step-1?visitDate=${selectedDate.format('YYYY-MM-DD')}`} onClick={() => setReservationResetStore()}>
        <button className="tblm-btn-float">
          <i />
        </button>
      </Link>
      <ModalPortal>
        <Modal.Toast key="reservationToastEnter" modal="toast" position="bottom" autoHideDuration={1500} margin={75} isAnimation={true}>
          <div className="content font__small_sb">입장 처리 되었습니다</div>
        </Modal.Toast>
        <Modal.Toast key="reservationToastExit" modal="toast" position="bottom" autoHideDuration={1500} margin={75} isAnimation={true}>
          <div className="content font__small_sb">퇴장 처리 되었습니다</div>
        </Modal.Toast>
        <Modal.Toast key="reservationToastCancel" modal="toast" position="bottom" autoHideDuration={1500} margin={75} isAnimation={true}>
          <div className="content font__small_sb">예약이 취소되었습니다</div>
        </Modal.Toast>
        <Modal.Toast key="reservationToastRollback" modal="toast" position="bottom" autoHideDuration={1500} margin={75} isAnimation={true}>
          <div className="content font__small_sb">예약이 복구되었습니다</div>
        </Modal.Toast>
        {/* <Modal.Menu isAnimation modal="menu" isDim title="필터" key={filter_menu}>
          <ListFilter
            filter={filter}
            setFilter={setFilter}
            onClose={() => {
              setMenuModal({ visible: false, key: filter_menu });
            }}
          />
        </Modal.Menu> */}
      </ModalPortal>

      <EnterSetting />
      <AccessAgreement />
    </div>
  );
};

export default List;
