import { create } from 'zustand';
import { Nullable } from '@/shared/utils/common.type';

interface IUseLayoutStore {
  spinner: Nullable<string | boolean>;
  setSpinner: (spinner?: Nullable<string | boolean>) => void;
  keyboard: boolean;
  setKeyboard: (keyboard?: boolean) => void;
  fromApp: Nullable<boolean>;
  setFromApp: (fromApp: boolean) => void;
}

export const useLayoutStore = create<IUseLayoutStore>((set) => ({
  spinner: false,
  setSpinner: (spinner?: Nullable<string | boolean>) => set(() => ({ spinner: spinner || false })),
  keyboard: false,
  setKeyboard: (keyboard?: boolean) => set(() => ({ keyboard: keyboard || false })),
  fromApp: null,
  setFromApp: (fromApp: Nullable<boolean>) => set(() => ({ fromApp: fromApp })),
}));
