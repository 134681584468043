import React, { useEffect } from 'react';
import HeaderTitle from '@/components/HeaderTitle/HeaderTitle';
import KakaoSettingNotiBanner from '@/components/Settings/KakaoSettingNotiBanner';
import KakaoChannelMessageGuideField from '@/components/KakaoChannelMessageGuideField/KakaoChannelMessageGuideField';
import KakaoChannelMessageWarningField from '@/components/KakaoChannelMessageGuideField/KakaoChannelMessageWarningField';
import KakaoTextarea from '@/components/KakaoTextarea/KakaoTextarea';
import { useModalStore } from '@/stores/common/useModalStore';
import { IKakaoMessageServiceProps, useKakaoMessageService } from '@/hooks/useKakaoMessageService';

const KakaoAlimtalkMessage = (props: IKakaoMessageServiceProps) => {
  const {
    title,
    messages: { guideMessage, warningMessage = '' },
  } = props;
  const { setMenuModal } = useModalStore();

  const { message, savable, setMessage, handleSave, handleClose, SaveMessageModals } = useKakaoMessageService(props);

  useEffect(() => {
    return () => setMenuModal(false);
  }, []);

  return (
    <div className="kakao-setting-wrapper">
      <HeaderTitle title={title} noBorder={true} onClose={handleClose} />
      <section className="kakao-setting kakao-mall-message">
        <KakaoSettingNotiBanner />
        <div className="kakao-setting-item">
          <div className="kakao-setting-item-title">
            <label>메시지 내용</label>
          </div>
          {guideMessage && <KakaoChannelMessageGuideField text={guideMessage} />}
          <KakaoTextarea value={message} setValue={setMessage} placeholder="메시지를 입력해주세요" maxLength={200} minLength={15} />
          {warningMessage && <KakaoChannelMessageWarningField text={warningMessage} />}
        </div>
        <div className="kakao-setting-button-wrapper">
          <button className="tblm-btn-secondary" onClick={() => setMenuModal({ visible: true, key: 'reminedMessage' })}>
            미리보기
          </button>
          <button className="tblm-btn-primary" disabled={!savable} onClick={handleSave}>
            변경사항 저장
          </button>
        </div>
      </section>
      <SaveMessageModals />
    </div>
  );
};

export default KakaoAlimtalkMessage;
