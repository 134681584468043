import React, { Fragment } from 'react';
import HeaderBack from '@/components/HeaderBack/HeaderBack';

import { ISettingMenu } from '@/shared/utils/common.type';
import { Link } from 'react-router-dom';
import KakaoSettingMenu from '@/components/Settings/Kakao/KakaoSettingMessage';

const KAKAO_RESERVATION_SETTINGS: ISettingMenu[] = [
  {
    title: '예약 안내',
    tabletCustomInformationMessage: '예약 생성시 전송되는 안내입니다.',
    to: 'create',
  },
  {
    title: '당일 알림',
    tabletCustomInformationMessage: '예약 당일 전송되는 확인 알림입니다.',
    to: 'alarm',
  },
  {
    title: '예약 수정',
    tabletCustomInformationMessage: '예약 수정시 전송되는 확인 안내입니다.',
    to: 'change',
  },
  {
    title: '예약 취소',
    tabletCustomInformationMessage: '예약 취소시 전송되는 확인 안내입니다.',
    to: 'cancel',
  },
];

const KakaoReservationMessage = () => {
  return (
    <div className="kakao-setting-wrapper">
      <HeaderBack inBack={false} isCenter={true}>
        <div className="font__heading_4">예약 메시지</div>
      </HeaderBack>
      <section className="kakao-setting kakao-reservation-message">
        {KAKAO_RESERVATION_SETTINGS.map((e, i) => (
          <KakaoSettingMenu key={`kakao-setting-menu-${i}`} title={e.title} tabletCustomInformationMessage={e.tabletCustomInformationMessage} to={e.to} />
        ))}
      </section>
    </div>
  );
};

export default KakaoReservationMessage;
