import React, { Fragment, useEffect } from 'react';
import HeaderBack from '@/components/HeaderBack/HeaderBack';
import { ReactComponent as IcNext } from '@/assets/images/icons/ic_next.svg';
import { Link } from 'react-router-dom';
import { ISettingMenu } from '@/shared/utils/common.type';
import KakaoSettingMenu from '@/components/Settings/Kakao/KakaoSettingMessage';
import useNative from '@/hooks/useNative';
import { useKakaoAlimtalkStore } from '@/stores/common/useKakaoAlimtalkStore';

const KakaoWaitingMessage = () => {
  const { callAlert } = useNative();
  const { waiting, refreshAlimtalk } = useKakaoAlimtalkStore((store) => ({ waiting: store.alimtalk.waiting, refreshAlimtalk: store.refreshAlimtalk }));

  useEffect(() => {
    refreshAlimtalk();
  }, []);

  const KAKAO_WAITING_SETTINGS: ISettingMenu[] = [
    {
      title: '대기 등록',
      tabletCustomInformationMessage: '대기 등록시 전송되는 안내입니다.',
      to: 'create',
    },
    {
      title: '입장 대기',
      tabletCustomInformationMessage: '입장 순서가 가까워졌을 때 전송되는 안내입니다.',
      to: 'waiting',
      check: (e) => {
        console.log(waiting.waiting.use);
        if (waiting.waiting.use) {
          return true;
        }

        e.preventDefault();
        callAlert(
          '입장 대기 알림이 꺼져있어요.',
          `입장 대기 메시지를 보려면
입장 대기 알림 설정을 활성화해주세요.`,
          [
            {
              btnNm: '닫기',
              value: 'close',
            },
            { btnNm: '알림 켜기', value: 'navigate:::/waiting/setting/alarm' },
          ]
        );
        return false;
      },
    },
    {
      title: '입장 가능',
      tabletCustomInformationMessage: '대기 호출시 매장 입장을 요청하는 알림입니다.',
      to: 'remind',
    },
    {
      title: '매장 취소',
      tabletCustomInformationMessage: '매장에서 대기 강제 취소시 전송되는 안내입니다.',
      to: 'cancel',
    },
  ];

  return (
    <div className="kakao-setting-wrapper">
      <HeaderBack inBack={false} isCenter={true}>
        <div className="font__heading_4">대기 메시지</div>
      </HeaderBack>
      <section className="kakao-setting kakao-waiting-message">
        {KAKAO_WAITING_SETTINGS.map((e, i) => (
          <KakaoSettingMenu key={`kakao-setting-menu-${i}`} title={e.title} tabletCustomInformationMessage={e.tabletCustomInformationMessage} to={e.to} check={e.check} />
        ))}
      </section>
    </div>
  );
};

export default KakaoWaitingMessage;
