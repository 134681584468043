import { relative } from 'path';
import './KakaoTextImagePreview.scss';
import KakaoHiddenArea from '../KakaoHiddenArea/KakaoHiddenArea';
import { ForwardedRef, forwardRef } from 'react';

type Props = {
  imageText: string;
  TextImageIcon: React.FunctionComponent;
  hidden?: boolean;
};

const KakaoTextImagePreview = forwardRef(({ imageText, TextImageIcon, hidden = false }: Props, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <KakaoHiddenArea hidden={hidden}>
      <div id="text-image" className="kakao-setting-item-image text-image" ref={ref}>
        <span>{imageText}</span>
        <TextImageIcon />
      </div>
    </KakaoHiddenArea>
  );
});

export default KakaoTextImagePreview;
