import React, { useState } from 'react';
import './IconTextButton.scss';
import { IIconTextButtonProps } from '@/components/IconTextButton/IconTextButton.type';
import classNames from 'classnames';

const IconTextButton = (props: IIconTextButtonProps) => {
  const { icon: Icon, text, onClick, theme, disabled } = props;

  return (
    <button className={classNames('icon-text-button', theme)} onClick={onClick} disabled={disabled}>
      {Icon && <Icon />}
      <span>{text}</span>
    </button>
  );
};

export default IconTextButton;
