import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderBack from '@/components/HeaderBack/HeaderBack';
import Picker from '@/components/Picker/Picker';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useModalStore } from '@/stores/common/useModalStore';
import { usePolicyStore } from '@/stores/common/usePolicyStore';
import { useLayoutStore } from '@/stores/common/useLayoutStore';

const timeList = [30, 60 * 1, 60 * 1.5, 60 * 2, 60 * 3, 60 * 4];

const MaxHoursUse = (): React.ReactElement => {
  const navigate = useNavigate();
  const { maxAvailableTime, setMaxAvailableTime } = usePolicyStore();
  const { setMenuModal } = useModalStore();
  const { setSpinner } = useLayoutStore();
  const handleOnPickerSuccess = async (selected) => {
    const V = selected.leftArea;

    await setSpinner(true);
    await devServerApi.api.patch('/policy/reservations', { maxAvailableTime: V });
    await setSpinner(false);
    setMaxAvailableTime(V);
  };

  const timeMaker = (arg_minutes) => {
    if (!arg_minutes) return;
    const hours = Math.floor(arg_minutes / 60);
    const minutes = arg_minutes % 60;
    return `${hours > 0 ? hours + '시간 ' : ''}${minutes > 0 ? minutes + '분' : ''}`;
  };

  return (
    <>
      <HeaderBack inBack={true} isCenter>
        <div className="font__heading_4">최대 이용 시간</div>
      </HeaderBack>
      <section className="settings-policy-detail-wrapper">
        <div className="switch-area pointer" onClick={() => setMenuModal({ visible: true, key: 'max-hours-use-reservation-picker-modal' })}>
          <div className="left-area">
            <div className="font__title_20_b">
              테이블 당 <br /> 최대 이용 시간
            </div>
            <div className="font__body_5 keep-all">한 팀은 최대 얼마 동안 테이블에 머무를 수 있나요?</div>
          </div>
          <div className="right-area gap-16">
            <div className="font__heading_4">{timeMaker(maxAvailableTime)}</div>
          </div>
        </div>
      </section>
      <Picker
        title="최대 이용 시간"
        tabletCustomInformationMessage="한 팀이 최대 얼마 동안 머무를 수 있나요?"
        modalKey="max-hours-use-reservation-picker-modal"
        leftArea={{ list: timeList, unit: '시간분', selected: maxAvailableTime }}
        onSuccess={handleOnPickerSuccess}
        onSuccessText="완료"
      />
    </>
  );
};

export default MaxHoursUse;
