import React from 'react';
import './ProgressBar.scss';
import { IProgressBarProps } from '@/components/ProgressBar/ProgressBar.type';
import classNames from 'classnames';

const ProgressBar = (props: IProgressBarProps) => {
  const { steps, currentStep } = props;

  return (
    <div className="progress-bar">
      {Array(steps)
        .fill(null)
        .map((e, i) => (
          <div className={classNames('progress-bar-item', { fill: i + 1 <= currentStep })} key={i} />
        ))}
    </div>
  );
};

export default ProgressBar;
