import * as yup from 'yup';

import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { Fragment, useEffect, useMemo, useState } from 'react';

import { ControlledInput } from '@/hook-form/ControlledInput';
import FooterSub from '@/components/FooterSub/FooterSub';
import HeaderSub from '@/components/HeaderSub/HeaderSub';
import { ReactComponent as SearchInputIcon } from '@/assets/images/icons/Search_Input.svg';
import { debounce } from 'lodash';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import { yupResolver } from '@hookform/resolvers/yup';
import Terces from '@/shared/apis/terces';
import HeaderSubType from '@/components/HeaderSubType/HeaderSubType';
import { useLayoutStore } from '@/stores/common/useLayoutStore';
import { autoHypenTel } from '@/shared/lib/functions/auto-hypen-tel';
import classNames from 'classnames';
import useNative from '@/hooks/useNative';
import { scrollToElm } from '@/shared/lib/functions/scroll';

const INITIAL_STEP_TEXTS = {
  createHeader: (
    <>
      예약 고객의 정보를 <br />
      입력해 주세요.
    </>
  ),
  modifyHeader: (
    <>
      예약 고객의 정보를 <br />
      검색해 주세요.
    </>
  ),
  footer: { save: '저장', back: '뒤로', next: '다음' },
};

const Step4 = (): React.ReactElement => {
  const { visitDate, visitTime, parties, rooms, userInfo, setUserInfo, setReservationResetStore } = useReservationStore();
  const { fromApp } = useLayoutStore();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({ type: 'default' });
  const [userList, setUserList] = useState([]);
  const [successSearch, setSuccessSearch] = useState(false);
  const [choiceUser, setChoiceUser] = useState(true);
  // const { callAlert } = useNative();

  useEffect(() => {
    scrollToElm('global', 0, 0);
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      userName: yup.string().required('이름을 입력하세요.'),
      userPhone: yup
        .string()
        .required('전화번호를 입력하세요.')
        .test('userPhone', '숫자만 입력 가능합니다.', (value) => {
          if (value.length) return /[0-9.;\\-]/.test(value);
          return true;
        }),
    });
  }, []);

  const methods = useForm({
    defaultValues: {
      ...userInfo,
      userPhone: userInfo.userPhone
        .replace(/[^0-9]/g, '')
        .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
        .replace('--', '-'),
    },
    // @ts-ignore
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const { control, reset, handleSubmit, getValues, setValue } = methods;

  const handleOnClose = () => {
    if (searchParams.get('type') === 'default') {
      setReservationResetStore();
      navigate('/reservation/list');
    }
    if (searchParams.get('type') === 'modify' || searchParams.get('type') === 'deposit') navigate('/reservation/accept/step-5');
    if (searchParams.get('type') === 'modify-1' || searchParams.get('type') === 'modify-2') navigate(-1);
  };

  const handleOnUserSearch = debounce((name) => {
    if (!name || name.length === 0) {
      // callAlert('값 입력 필요', '값을 입력해주세요', [{ btnNm: '확인', value: 'close' }]);
      return;
    }

    devServerApi.api
      .post(
        '/reservation/get-users',
        Terces.encrypt({
          name,
        })
      )
      .then(({ data: enc_res }: any) => Terces.decrypt(enc_res))
      .then((res: any) => {
        setUserList(res.users);
      });
    setSuccessSearch(false);
  }, 300);

  const handleOnClickSearchListItem = (target) => {
    reset({
      ...target,
      userPhone: target.userPhone
        .replace(/[^0-9]/g, '')
        .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
        .replace('--', '-'),
      userId: target.userId,
      userMemo: target.userMemo,
      userNickname: target.userNickname,
      isSearch: true,
    });
    setSuccessSearch(true);
    setUserList([]);
    setChoiceUser(true);
  };

  const handleResetForm = () => {
    if (userInfo.isSearch) {
      setValue('userId', '');
      setValue('userMemo', '');
      setValue('userNickname', '');
      setValue('isSearch', false);
    }
  };

  const handleOnSubmitUserInfo = (data, path) => {
    // modify-2 일 때
    // 유저의 정보를 정확히 검사 하지 못했을 때?
    // state로 관리
    // 클릭 시 true
    // 다시 입력 시 false
    // true or falswe
    if (searchParams.get('type') === 'modify-2') {
      if (successSearch) {
        // true 선택 했을 때
        setUserInfo({
          ...userInfo,
          userId: data.userId,
          userName: data.userName,
          userPhone: data.userPhone.replace(/-/g, ''),
          userMemo: data.userMemo,
          userNickname: data.userNickname,
          userEmail: data.uesrEmail,
          isSearch: data.isSearch,
          newUserId: data.userId,
        });
      } else {
        // false
        setUserInfo({
          ...userInfo,
          // userId: data.userId || data.userPhone.replace(/-/g, ''),
          userName: data.userName,
          userPhone: data.userPhone.replace(/-/g, ''),
          isSearch: data.isSearch,
          newUserPhone: data.userPhone,
          newUserId: undefined,
        });
      }
    } else {
      setUserInfo({
        ...userInfo,
        userId: data.userId || data.userPhone.replace(/-/g, ''),
        userName: data.userName,
        userPhone: data.userPhone.replace(/-/g, ''),
        userMemo: data.userMemo,
        userNickname: data.userNickname,
        userEmail: data.uesrEmail,
        isSearch: data.isSearch,
        newUserId: undefined,
        newUserPhone: undefined,
      });
    }
    navigate(path);
  };

  return (
    <FormProvider {...methods}>
      {fromApp ? (
        <>
          <HeaderSubType header={autoHypenTel(userInfo.userPhone)}>{INITIAL_STEP_TEXTS.createHeader}</HeaderSubType>
        </>
      ) : (
        <HeaderSub onClose={handleOnClose}>{searchParams.get('type') === 'modify-2' ? INITIAL_STEP_TEXTS.modifyHeader : INITIAL_STEP_TEXTS.createHeader}</HeaderSub>
      )}

      <section className="user-search-wrapper">
        <div className="flex-container flex-col gap-16 pt-20">
          <div className="font__heading_5">전화번호</div>
          <ControlledInput
            name="userPhone"
            control={control}
            placeholder="전화번호를 입력하세요."
            onChange={(e) => {
              handleOnUserSearch(getValues('userPhone').replace(/-/g, ''));
              setValue(
                'userPhone',
                e.target.value
                  .replace(/[^0-9]/g, '')
                  .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
                  .replace('--', '-')
              );
              handleResetForm();
              setChoiceUser(false);
            }}
          />
        </div>
        <div className="flex-container flex-col gap-16">
          <div className="font__heading_5">이름</div>
          <div className="flex-container gap-8">
            <ControlledInput
              name="userName"
              control={control}
              placeholder="이름을 입력하세요."
              onEnter={() => handleOnUserSearch(getValues('userName'))}
              onChange={handleResetForm}
            />
            <button
              className="tblm-btn-gray no-padding"
              style={{ minWidth: 40, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => handleOnUserSearch(getValues('userName'))}
            >
              <SearchInputIcon />
            </button>
          </div>
        </div>
        <div className="flex-container flex-col gap-8">
          {userList.map((item) => {
            return (
              <div key={item.userId} className={classNames('font__body_6', 'pt-12', 'pb-12', 'pr-4', 'pl-4')} onClick={() => handleOnClickSearchListItem(item)}>{`${
                item.userName ? item.userName : ''
              } ${
                item.userPhone
                  ? `(${item.userPhone
                      .replace(/[^0-9]/g, '')
                      .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
                      .replace('--', '-')})`
                  : ''
              }`}</div>
            );
          })}
        </div>
      </section>

      <FooterSub isBackArea isVisible isBorder noTransparent>
        <div className="font__heading_5 hidden">
          <div className="font__heading_5 flex-container items-center">
            {visitDate?.format('MM.DD(ddd)')}, {visitTime?.format('a HH:mm')}
          </div>
          <div className="font__heading_5 flex-container items-center white-pre parties-wrapper">
            {parties.map((item) => {
              if (item.size) return <span key={item.name}>{`${item.name} ${item.size}`}</span>;
              return <Fragment key={item.name} />;
            })}
            {rooms.length ? `/ ${rooms.map((item) => item.name).join(', ')}` : ''}
          </div>
        </div>

        <div className="flex-container gap-8">
          {searchParams.get('type').includes('modify') ? (
            <button onClick={handleSubmit((data) => handleOnSubmitUserInfo(data, -1))} disabled={!choiceUser} className="footer tblm-button-normal tblm-btn-primary">
              {INITIAL_STEP_TEXTS.footer.save}
            </button>
          ) : (
            <>
              <Link to="/reservation/accept/step-3/">
                <button className="footer tblm-button-normal ">{INITIAL_STEP_TEXTS.footer.back}</button>
              </Link>
              <button onClick={handleSubmit((data) => handleOnSubmitUserInfo(data, '/reservation/accept/step-5/'))} className="footer tblm-button-normal tblm-btn-primary">
                {INITIAL_STEP_TEXTS.footer.next}
              </button>
            </>
          )}
        </div>
      </FooterSub>
    </FormProvider>
  );
};

export default Step4;
