import React, { useEffect, useState } from 'react';
import { devServerApi } from '@/shared/apis/devServerApi';
import { IPolicy } from '@/shared/utils/common.type';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { IMall, useMallStore } from '@/stores/common/useMallStore';
import { usePolicyStore } from '@/stores/common/usePolicyStore';
import Terces from '@/shared/apis/terces';
import { useNativeStore } from '@/stores/reservation/useNativeStore';
import { useLocation, useNavigate } from 'react-router-dom';
import useNative from '@/hooks/useNative';
import { ErrorBoundary } from 'react-error-boundary';

const GNB_PATHS = ['/reservation/list', '/setting/kakao', '/waiting/dashboard', '/waiting', '/auth'];

const CommonProvider = ({ children }): React.ReactElement => {
  const { accessToken } = useAuthStore();
  const { setMall } = useMallStore();
  const { setPolicy } = usePolicyStore();
  const [isLoading, setIsLoading] = useState(true);
  const { routeAction, backAction, setRouteAction } = useNativeStore();
  const { showGNB, hideGNB } = useNative();
  const navigate = useNavigate();
  const { pathname, ...location } = useLocation();
  const { initMessageListener } = useNative();
  const { callAlert } = useNative();

  useEffect(() => {
    if (GNB_PATHS.includes(pathname)) {
      showGNB();
    } else {
      hideGNB();
    }
  }, [pathname]);

  useEffect(() => {
    if (accessToken) {
      devServerApi.api
        .authGet('/get-webview-key')
        .then((res) => {
          // @ts-ignore
          if (res.status < 0) return;
          Terces.fetchYek(res);
        })
        .catch(() => {})
        .finally(() => {});
      devServerApi.api
        .authGet<IMall>('/reservation/mall')
        .then((res) => {
          // @ts-ignore
          if (res.status < 0) return;

          setMall({
            ...res,
            roomGroups: Object.fromEntries(res.rooms.map(({ roomGroupId, roomGroupName }) => [roomGroupId, roomGroupName])),
            reservationMarkingColors: res.reservationMarkingColors.map((markingColor) => {
              if (!markingColor.id) markingColor.id = markingColor.color;
              return markingColor;
            }),
          });
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
      devServerApi.api
        .authGet<IPolicy>('/policy/reservations')
        .then((data: IPolicy) => {
          if (data.status < 0) return;
          let reservationTimeUnit = data.reservationTimeUnit;
          if (!data.reservationTimeUnit) {
            try {
              let resDelimiters = Array.from(
                new Set(
                  Object.values(data.reservationDays)
                    .map((r) => (Array.isArray(r.bookingTimes) ? r.bookingTimes.map((t) => t.split(':')[1]) : []))
                    .flat(1)
                )
              );
              if (resDelimiters.includes('10') || resDelimiters.includes('20') || resDelimiters.includes('40')) {
                reservationTimeUnit = 10;
              } else if (resDelimiters.includes('30')) {
                reservationTimeUnit = 30;
              } else {
                reservationTimeUnit = 60;
              }
              // eslint-disable-next-line no-empty
            } catch (e) {}
          }
          setPolicy({ ...data, reservationTimeUnit });
        })
        .catch((e) => {
          callAlert('오류', e.message, [{ btnNm: '확인', value: 'close' }]);
        });
    }
  }, [accessToken]);

  useEffect(() => {
    switch (routeAction) {
      case 'back':
        backAction ? backAction() : navigate(-1);
        break;
    }
    // Clean-up
    if (routeAction) setRouteAction(null);
  }, [routeAction]);

  useEffect(() => initMessageListener());

  if (isLoading) return <></>;
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 20 }}>
          <span>Error!!!!</span>
          <span>{error.message}</span>
          <a href="#" onClick={() => navigate(-1)}>
            back
          </a>
        </div>
      )}
    >
      {children}
    </ErrorBoundary>
  );
};

export default CommonProvider;
