import React, { ChangeEvent, useState } from 'react';
import './KakaoTextarea.scss';
import { IKakaoInputType } from '@/components/KakaoInput/KakaoInput.type';
import { ReactComponent as IcDelete } from '@/assets/images/icons/ic_delete.svg';
import { ReactComponent as IcWarning } from '@/assets/images/icons/ic_warning.svg';

const KakaoTextarea = (props: IKakaoInputType) => {
  const { value, setValue, placeholder, minLength = 0, maxLength, regex } = props;
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const onFocus = () => setIsFocused(true);

  const onBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value.trimEnd());
    setIsFocused(false);
  };

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const input = e.target.value.trimStart().replace(regex, '');
    if (maxLength && input.length > maxLength) return;
    setValue(input);
  };

  return (
    <div className="kakao-text-area">
      <textarea className="kakao-text-area-field" value={value ?? ''} placeholder={placeholder} onChange={onChange} onBlur={onBlur} onFocus={onFocus} />
      {isFocused && value?.length > 0 && (
        <button className="kakao-input-clear-btn" onMouseDown={(e) => e.preventDefault()} onClick={() => setValue?.call('')}>
          <IcDelete />
        </button>
      )}

      <div className="kakao-text-area-info">
        {minLength > 0 && value?.length < minLength ? (
          <span className="kakao-text-area-warning">
            <IcWarning />
            {minLength}자 이상 입력해주세요.
          </span>
        ) : (
          <span> </span>
        )}
        {maxLength > 0 && <span className="kakao-text-area-counter">{`${value?.length ?? 0}자/${maxLength}자`}</span>}
      </div>
    </div>
  );
};

export default KakaoTextarea;
