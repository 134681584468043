import React from 'react';
import './ReservationTopBanner.scss';
import { ReactComponent as IcBannerCalendar } from '@/assets/images/icons/ic_res_banner_calendar.svg';
import { ReactComponent as ReservationRectangleBig } from '@/assets/images/res-top-banner-rectangle-big.svg';
import { ReactComponent as ReservationRectangleSmall } from '@/assets/images/res-top-banner-rectangle-small.svg';

const ReservationTopBanner = () => (
  <div className="reservation-top-banner">
    <div className="reservation-top-banner-provider">
      <label className="reservation-top-banner-label">예약</label>
      <h1 className="reservation-top-banner-title font__title_20_m">{'다양한 온 · 오프라인 예약을\n한 번에 관리해보세요.'}</h1>
      <span className="reservation-top-banner-tabletCustomInformationMessage font__s_small">카카오, 네이버 예약도 연동 가능</span>
      <div className="reservation-top-banner-calendar-icon">
        <IcBannerCalendar />
      </div>
      <div className="reservation-top-banner-rectangle-big">
        <ReservationRectangleBig />
      </div>
      <div className="reservation-top-banner-rectangle-small">
        <ReservationRectangleSmall />
      </div>
    </div>
  </div>
);

export default ReservationTopBanner;
