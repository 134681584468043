import React, { useEffect } from 'react';
import './Table.scss';
import classnames from 'classnames';
import { IProps } from './Table.type';
function Table({ tableData, openTableSettingModal, setEnable }) {
  return (
    <div className={classnames('table-container', { disable: !tableData.enabled })} onClick={() => tableData.enabled && openTableSettingModal(tableData)}>
      <div className="table-info-box">
        <div className="font__heading_6 table-name">{tableData.name}</div>
        <div className="font__body_6 table-info">
          <>
            {tableData.peopleMin}명~{tableData.peopleMax}명
          </>
        </div>
      </div>
      <div className="table-enabled-controller">
        <label
          className="tblm-switch"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <input type="checkbox" name="switch_example" defaultChecked={tableData.enabled} onChange={(e) => setEnable({ ...tableData, enabled: e.target.checked })} />
          <i />
        </label>
      </div>
    </div>
  );
}

export default Table;
