import { useRef } from 'react';

interface IObserverParam {
  unIntersectingCallback?: () => void;
  threshold?: number;
}

export default function useIntersectionObserver(callback: () => void, props?: IObserverParam) {
  const { unIntersectingCallback, threshold } = { ...props };

  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            callback();
          } else {
            unIntersectingCallback?.();
          }
        });
      },
      { threshold: threshold ?? 0.1 }
    )
  );

  const observe = (element: Element) => {
    observer.current.observe(element);
  };

  const unobserve = (element: Element) => {
    observer.current.unobserve(element);
  };

  return [observe, unobserve];
}
