import React from 'react';
import { ReactComponent as ArrowLeft } from '@/assets/images/icons/Arrow_Left.svg';
import { ReactComponent as SwitchOn } from '@/assets/images/icons/Switch_On.svg';
import { ReactComponent as SwitchOff } from '@/assets/images/icons/Switch_Off.svg';

import { useLocation, useNavigate } from 'react-router-dom';
import BottomScrollPicker from '@/components/BottomScrollPicker/BottomScrollPicker';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useQuery } from 'react-query';
import queryClient from '@/shared/utils/queryClient';
import '@/styles/base/tailwind.css';
const OPTION_GROUPS = Array.from({ length: 8 }, (_, i) => `${(i + 1) * 5}분`);

interface Form {
  callExpiredMinute: string;
  autoCancelMessage: string;
  availableEnterMessage: string;
}

// MEMO 대기 자동취소 메시지 field는 뭐지?
const WaitingExpireSetting: React.FC = () => {
  let location = useLocation();
  const [isPickerOpen, setIsPickerOpen] = React.useState(false);
  const [isCallExpiredCancel, setIsCallExpiredCancel] = React.useState<boolean>();
  const [_form, set_Form] = React.useState<Form>();
  const [form, setForm] = React.useState<Form>();
  const navigate = useNavigate();

  React.useEffect(() => {
    let state = location.state;
    if (state) {
      const savedOptions = {
        callExpiredMinute: `${state.option.callExpiredMinute || 5}분`,
        autoCancelMessage: state.extra.autoCancelMessage || '',
        availableEnterMessage: state.extra.availableEnterMessage || '',
      };
      setIsCallExpiredCancel(state.option.isCallExpiredCancel);
      setForm(savedOptions);
    }
  }, []);

  const {
    data: optionsResponse,
    refetch,
    isLoading,
  } = useQuery(['waiting_options'], () => devServerApi.api.get('/waiting/option'), {
    onSuccess: ({ data }) => {
      const options = data.option;
      const extra = data.extra;
      const savedOptions = {
        callExpiredMinute: `${options.callExpiredMinute || 5}분`,
        autoCancelMessage: extra.autoCancelMessage || '',
        availableEnterMessage: extra.availableEnterMessage || '',
      };
      setIsCallExpiredCancel(options.isCallExpiredCancel);
      setForm(savedOptions);
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  const onChangeAlarmNum = (value: string) => {
    set_Form((prev) => ({ ...prev, callExpiredMinute: value }));
  };

  const saveIsExpired = async (value: boolean) => {
    await devServerApi.api.put('/waiting/option', {
      isCallExpiredCancel: value,
    });
    await queryClient.invalidateQueries(['waiting_options']);
    setIsCallExpiredCancel(value);
  };

  const saveAlarmNum = async () => {
    const num = Number(_form.callExpiredMinute.split('분')[0]);
    await devServerApi.api.put('/waiting/option', {
      callExpiredMinute: num,
    });
    await queryClient.invalidateQueries(['waiting_options']);
    setIsPickerOpen(false);
  };

  const undoAlarmNum = () => {
    set_Form(form);
    setIsPickerOpen(false);
  };

  if (isLoading || !form) return null;
  return (
    <div className="min-w-screen min-h-screen flex flex-col">
      <div className="relative py-[15px] justify-center items-center w-full flex">
        <p className="text-zinc-800 text-lg font-bold">대기 자동 취소</p>
        <ArrowLeft className="absolute left-5 w-[20px] h-[20px]" onClick={() => navigate(-1)} />
      </div>
      <div className="flex flex-col flex-1 bg-zinc-100 divide-y divide-stone-300">
        <div className="bg-white px-5 py-6 flex w-full items-center justify-between">
          <div className="flex flex-col">
            <p className="text-xl text-zinc-800 font-bold mb-[6px]">대기 자동 취소</p>
            <p className="text-sm text-zinc-500 font-medium whitespace-pre-wrap">{`호출 후 시간 내 입장하지 않은\n고객을 대기 취소할까요?`}</p>
          </div>
          <div className="flex items-center">
            <span className="text-zinc-500 text-sm font-medium mr-[16px]">{isCallExpiredCancel ? '사용' : '미사용'}</span>
            {isCallExpiredCancel ? <SwitchOn onClick={() => saveIsExpired(false)} /> : <SwitchOff onClick={() => saveIsExpired(true)} />}
          </div>
        </div>
        {isCallExpiredCancel && (
          <div className="flex flex-col bg-white">
            <div className="bg-white px-5 py-6 flex w-full items-center justify-between">
              <div className="flex flex-col">
                <p className="text-xl text-zinc-800 font-bold mb-[6px]">자동 취소 시점</p>
                <p className="text-sm text-zinc-500 font-medium whitespace-pre-wrap">{`호출 후 몇 분이 지났을 때\n대기를 취소할까요?`}</p>
              </div>
              <div className="flex items-center" onClick={() => setIsPickerOpen(true)}>
                <span className="text-zinc-800 text-lg font-bold whitespace-pre-wrap text-right">{`호출 후\n${form.callExpiredMinute} 경과`}</span>
              </div>
            </div>
            <div className="h-px w-full mx-[20px] bg-gray-100" />
            <div className="p-3.5 m-[20px] rounded border border-stone-300">
              <span className="text-zinc-800 text-sm whitespace-pre-wrap">{`${form.callExpiredMinute} 이내 미입장 시 자동으로 대기가 취소되며, 자동 취소 시 재접수하셔야 합니다.`}</span>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-1 bg-zinc-100 w-full" />
      <BottomScrollPicker
        isShowGNB={false}
        title="호출 후 경과 시간"
        tabletCustomInformationMessage="호출 후 몇 분이 지나면 대기를 취소할까요?"
        onDismiss={undoAlarmNum}
        onSave={saveAlarmNum}
        value={form.callExpiredMinute}
        isOpen={isPickerOpen}
        list={OPTION_GROUPS}
        onSelectedChange={onChangeAlarmNum}
      />
    </div>
  );
};

export default WaitingExpireSetting;
