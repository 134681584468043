import React, { ReactElement, forwardRef, useCallback, useEffect, useId, useMemo, useReducer, useRef, useState } from 'react';
import HeaderTitle from '@/components/HeaderTitle/HeaderTitle';
import '@/styles/pages/tutorial/tutorial.scss';
import '@/styles/pages/tutorial/kakao-channel.scss';
import ProgressBar from '@/components/ProgressBar/ProgressBar';
import useGetInitialStep from '@/hooks/useGetInitialStep';
import template from 'lodash/template';
import { questionReducer, useKakaoChannelSettingStore } from '@/stores/common/useKakaoChannelSettingStore';
import TutorialKakaoChannelName from '@/components/TutorialKakaoChannel/TutorialKakaoChannelName';
import TutorialKakaoChannelGuide from '@/components/TutorialKakaoChannel/TutorialKakaoChannelGuide';
import { ITutorialKakaoChannelGuideProps } from '@/components/TutorialKakaoChannel/TutorialKakaoChannelGuide.type';
import TutorialKakaoChannelMessage from '@/components/TutorialKakaoChannel/TutorialKakaoChannelMessage';
import classNames from 'classnames';
import { useSettingTutorialStore } from '@/stores/common/useSettingTutorialStore';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TutorialKakaoChannelContentItem from '@/components/TutorialKakaoChannel/TutorialKakaoChannelContentItem';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import { ReactComponent as Close_BoottoMenu } from '@/assets/images/icons/Close_BoottoMenu.svg';
import Modal from '@/components/Modal/Modal';
import { useModalStore } from '@/stores/common/useModalStore';
import { kakaoTutorialApi } from '@/shared/apis/tutorialApi';
import { useLayoutStore } from '@/stores/common/useLayoutStore';
import KakaoTextImagePreview from '@/components/KakaoTextImagePreview/KakaoTextImagePreview';
import KakaoTextProfilePreview from '@/components/KakaoTextProfilePreview/KakaoTextProfilePreview';
import { ReactComponent as AboutDummy } from '@/assets/images/icons/kakao_about_mall_text_image_dummy.svg';
import { ReactComponent as AdvertisementDummy } from '@/assets/images/icons/kakao_advertisement_mall_text_image_dummy.svg';
import { useScreenshot } from 'use-react-screenshot';
import { uploadImage } from '@/shared/utils/kakaoImageUtil';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useKakaoAlimtalkStore } from '@/stores/common/useKakaoAlimtalkStore';

const KAKAO_CHANNEL_GUIDE_DATA: { [key: string]: ITutorialKakaoChannelGuideProps } = {
  step1_0: {
    title: '예약/대기 메시지란?',
    contents: [
      {
        tabletCustomInformationMessage: '고객님께 예약/대기 변경 상황에 알맞은 메시지를 자동으로 보내드려요.',
        secondarytabletCustomInformationMessage: '프로필 이미지는 채널 생성후 우리가게 > 카카오 알림탭에서 바꿀수 있어요',
        image: require('@/assets/images/tutorial-kakao-channel-guide-1.png'),
      },
    ],
  },
  step2_0: {
    title: '우리매장알림 메시지란?',
    contents: [
      {
        tabletCustomInformationMessage: '처음 매장에 전화를 한 고객에게 자동으로 우리매장안내 메시지를 보내줘요.',
        secondarytabletCustomInformationMessage: '첫 번째 메시지에는 홍보성 문구가 포함 될 수 없어요.',
        image: require('@/assets/images/tutorial-kakao-channel-guide-2.png'),
      },
      {
        tabletCustomInformationMessage: '한번 안내 메시지를 수신한 고객에게는 두번째 전화부터 홍보성 메시지를 보낼 수 있어요.',
        secondarytabletCustomInformationMessage: '단, 메시지 길이는 120자 제한으로 허용됩니다.',
        image: require('@/assets/images/tutorial-kakao-channel-guide-3.png'),
      },
    ],
  },
};

const KAKAO_CHANNEL_CREATE_WARNING_CONTENTS = [
  '카카오톡 채널 이름은 추후에 변경이 불가능함에 동의합니다.',
  // eslint-disable-next-line quotes
  "카카오톡 채널 마스터가 '테이블매니저'로 설정되는 것에 동의합니다.",
  '개설된 카카오톡 채널의 마스터는 추후에 변경 불가능함에 동의합니다.',
];

const KAKAO_CHANNEL_CREATE_EXTRA_WARNING_CONTENTS = '채널 마스터가 아니어도, 공동 관리자 권한으로 동일하게 채널을 관리할 수 있습니다.';

const KAKAO_CHANNEL_DONE_CONTENTS = [
  '카카오톡 채널 가입 및 연동은 휴일 제외 3~5일이 소요되며 변동 될 수 있습니다.',
  '채널 가입 현황은 알림톡탭에서 확인하실 수 있습니다.',
];

const KakaoChannel = () => {
  const { storageQuestion, setStorageQuestion, resetStorageQuestion } = useKakaoChannelSettingStore();
  const { state, setState } = useSettingTutorialStore();
  const { spinner, setSpinner } = useLayoutStore();
  const [renderingQuestion, dispatchRenderingQuestion] = useReducer(questionReducer, storageQuestion);
  const initialStep = useGetInitialStep(storageQuestion);
  const [step, setStep] = useState(initialStep);
  const { value, question, note, notePosition, nextStep } = renderingQuestion[step];
  const formattedQuestion = template(question)();
  const navigate = useNavigate();
  const closeConfirmModalId = useId();
  const { setAlertModal, setNormalModal, normalModal } = useModalStore();
  const currentStep = useMemo(() => step && parseInt(step.split('_')[0].replace('step', '')), [step]);
  const [_, takeScreenshot] = useScreenshot();
  const [imageUploads, setImageUploads] = useState([]);
  const { alimtalk, immerAlimtalk, isFetching, setFetching, refreshAlimtalk } = useKakaoAlimtalkStore();

  const addImageUpload = (newImageUpload) => {
    setImageUploads((prev) => {
      return [...prev, newImageUpload];
    });
  };

  const normalMessageImageRef = useCallback((node: HTMLElement) => {
    node && addImageUpload(takeScreenshot(node).then((image) => uploadImage(image, 'normal')));
  }, []);
  const adMessageImageRef = useCallback((node: HTMLElement) => {
    node && addImageUpload(takeScreenshot(node).then((image) => uploadImage(image, 'ad')));
  }, []);
  const profileImageRef = useCallback((node: HTMLElement) => {
    node && addImageUpload(takeScreenshot(node).then((image) => uploadImage(image)));
  }, []);

  useEffect(() => {
    if (imageUploads.length >= 3) Promise.all(imageUploads).then(() => setSpinner(false));
  }, [imageUploads]);

  const [searchParams] = useSearchParams();
  const channelName = renderingQuestion.step1_0.value;
  const stepContent = useCallback((): ReactElement => {
    switch (step) {
      case 'step1_0':
        return (
          <>
            <TutorialKakaoChannelName step={step} dispatcher={dispatchRenderingQuestion} channelName={value as string}/>
            <TutorialKakaoChannelGuide {...KAKAO_CHANNEL_GUIDE_DATA[step]} />
          </>
        );
      case 'step2_0':
        return (
          <>
            <TutorialKakaoChannelMessage step={step} dispatcher={dispatchRenderingQuestion} />
            <TutorialKakaoChannelGuide {...KAKAO_CHANNEL_GUIDE_DATA[step]} />
          </>
        );
      case 'step3_0':
        return (
          <>
            <TutorialKakaoChannelContentItem contents={KAKAO_CHANNEL_DONE_CONTENTS} padding={true} />
          </>
        );
      default:
        return <></>;
    }
  }, [step]);

  const onPrev = () => {
    dispatchRenderingQuestion({ type: 'SET_CHANGE', value: null, step });
    // 이전으로 돌아가는 단계가 첫단계로 가는것 뿐이라 하드코딩함
    setStep('step1_0');
  };

  const onNext = async () => {
    // 다음 단계 진행 전 확인 Modal 표시
    if (step === 'step1_0' && !normalModal.visible) {
      setNormalModal({ key: 'next_confirm', visible: true });
      return;
    }

    // 표시한 Modal 클린업
    if (normalModal.visible) setNormalModal({ key: 'next_confirm', visible: false });

    let destinationStep = null;
    destinationStep = nextStep.to;

    // 데이터 업데이트
    if (nextStep.to === 'step2_0') {
      const data = {
        channel_name: value,
        mall_msg_body: '이제 카카오톡에서도 손쉽고 빠르게 예약이 가능합니다. 카카오톡으로 실시간 예약가능 여부를 확인하고 예약 즉시 확정까지 진행해보세요.',
      };

      setSpinner(true);
      kakaoTutorialApi(data, false);
    } else if (nextStep.to === 'done') {
      const data = {
        channel_name: storageQuestion.step1_0.value,
        mall_msg_body: storageQuestion.step2_0.value,
      };

      setSpinner(true);
      await kakaoTutorialApi(data, true);
      setSpinner(false);
    }

    const nextTutorial = ['table', 'reservation', 'essential', 'kakao-channel', 'done'].includes(destinationStep);
    if (!nextTutorial) renderingQuestion[destinationStep].prevStep = step;
    setStorageQuestion(renderingQuestion);

    if (nextTutorial) {
      resetStorageQuestion();
      setState(destinationStep);
      if (destinationStep === 'done') {

        navigate('/waiting/dashboard', { replace: true });
      }
    } else {
      setStep(nextStep.to);
    }
  };

  // /*
  // if (state === 'done') {
  //   exit();
  //   return <></>;
  // }
  // */
  if (state === 'done') {
    console.log(alimtalk.setting.profileImageUrl);
    if (alimtalk.setting.profileImageUrl !== undefined && alimtalk.setting.profileImageUrl !== '') {
      console.log("KAKAO TUTORIAL DONE");
      navigate('/setting/kakao');
    }
  }
  return (
    <div className="tutorial-page unpadding kakao-channel">
      <HeaderTitle title="카카오톡 설정" onClose={() => setAlertModal({ visible: true, key: closeConfirmModalId })}>
        <ProgressBar steps={3} currentStep={currentStep} />
      </HeaderTitle>
      <div className="tutorial-content-container" key={`${step}_${formattedQuestion}`}>
        <div className="tutorial-content">
          <div className="tutorial-content-title">
            {note && notePosition === 'before' ? <div className={'tutorial-note before'}>{note}</div> : null}
            <div className={classNames('tutorial-question', { unmargin: note })}>{formattedQuestion}</div>
            {note && notePosition !== 'before' ? <div className={'tutorial-note after'}>{note}</div> : null}
          </div>
          {stepContent()}
          {spinner && step === 'step2_0' ? (
            <>
              <KakaoTextImagePreview imageText={`${channelName}\n이벤트안내`} ref={adMessageImageRef} TextImageIcon={AdvertisementDummy} hidden />
              <KakaoTextImagePreview imageText={`${channelName}\n가게정보안내`} ref={normalMessageImageRef} TextImageIcon={AboutDummy} hidden />
              <KakaoTextProfilePreview imageText={`${channelName}`} ref={profileImageRef} hidden />
            </>
          ) : null}
        </div>
      </div>
      <div className="button-container">
        {step !== 'step2_0' ? null : (
          <button className="prev" onClick={() => onPrev()}>
            이전
          </button>
        )}
        <button
          className="next"
          onClick={onNext}
          disabled={(value === null || (step === 'step2_0' && typeof value === 'string' && value.length < 15)) && !(nextStep.api || nextStep?.to === 'done')}
        >
          {nextStep && (nextStep.api || nextStep?.to === 'done') ? '확인했어요' : '다음'}
        </button>
      </div>
      <ModalPortal>
        <Modal.Normal key="next_confirm" modal="normal" isAnimation={false}>
          <div className={'modal-wrapper animation check'}>
            <div className="modal-dim" />
            <div className="modal-content font__body_sm">
              <div className="title font__subtitle">
                <span className="modal_title_span">확인해주세요!</span>
                <Close_BoottoMenu onClick={() => setNormalModal({ key: 'next_confirm', visible: false })} />
              </div>
              <TutorialKakaoChannelContentItem contents={KAKAO_CHANNEL_CREATE_WARNING_CONTENTS} warning={KAKAO_CHANNEL_CREATE_EXTRA_WARNING_CONTENTS} />
              <div className="button-wrapper">
                <button className={'tblm-btn-primary'} onClick={onNext}>
                  확인했어요
                </button>
              </div>
            </div>
          </div>
        </Modal.Normal>
        <Modal.Alert
          key={closeConfirmModalId}
          modal="alert"
          isDim={true}
          isAnimation={true}
          modalData={{
            title: '알림',
            content: '카카오톡 설정을 그만하시겠어요?\n설정을 마치지 않으면\n카카오톡 메시지를 보낼 수 없어요.',
            buttons: [
              {
                text: '그만하기',
                onClick: () => {
                  resetStorageQuestion();
                  setAlertModal({ visible: false, key: closeConfirmModalId });
                  navigate('/waiting', { replace: true });
                },
              },
              { text: '계속하기', success: true, onClick: () => setAlertModal({ visible: false, key: closeConfirmModalId }) },
            ],
          }}
        />
      </ModalPortal>
    </div>
  );
};

export default KakaoChannel;
