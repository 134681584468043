import React from 'react';
import { scrollToElm } from '@/shared/lib/functions/scroll';
import { ITabs, ITabsItem } from './Tabs.type';
import './Tabs.scss';

const Tabs = ({ mode = 'default', scrollContentRef, isBorder, isFill, isFixed, children, onClick, headerMargin = 0, ...props }: ITabs) => {
  const fillClassName = isFill ? 'is-fill' : '';
  const borderClassName = isBorder ? 'is-border' : '';
  const fixedClassName = isFixed ? 'is-fixed' : '';

  const handleTabsClick = (e) => {
    const value = e.target.dataset.value;

    if (value) {
      if (mode === 'scroll') {
        Array.from(scrollContentRef.current.children).forEach((item: HTMLElement) => {
          if (item.getAttribute('data-scrolled') === String(value)) {
            const isGlobal = scrollContentRef.current.style.height;
            const fixedHeights = Array.from(document.querySelectorAll('.is-fixed'))
              .map((el) => el.getBoundingClientRect().height)
              .reduce((prev: any, cur) => prev + cur, 0);

            const current = !isGlobal ? 'global' : scrollContentRef.current;
            const child = !isGlobal ? item.offsetTop - fixedHeights : item;
            const moveY = headerMargin ? Number(child) - headerMargin : child;
            scrollToElm(current, moveY, 300);
          }
        });
      }

      onClick(e);
    }
  };
  return (
    <div {...props} className={`tabs-wrapper ${fillClassName} ${borderClassName} ${fixedClassName}`} onClick={handleTabsClick}>
      {children}
    </div>
  );
};

const Item = ({ value, active, children, ...props }: ITabsItem) => {
  return (
    <div {...props} className={`tabs-item ${active ? 'active' : ''} font__body_2`} data-value={value}>
      {children}
    </div>
  );
};

Tabs.Item = Item;

export default Tabs;
