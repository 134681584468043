import React, { Fragment } from 'react';
import './ReservationServiceContent.scss';
import { IServiceContentItem } from '@/components/Reservation/List/ReservationServiceContent/ReservationServiceContent.type';
import IcServiceAutoReceipt from '@/assets/images/icons/ic_res_service_auto_receipt.svg';
import IcServiceManagement from '@/assets/images/icons/ic_res_service_management.svg';
import IcServiceKakaoNaver from '@/assets/images/icons/ic_res_service_kakao_naver.svg';
import IcServiceChannelTalk from '@/assets/images/icons/ic_res_service_channel_talk.svg';

const services: IServiceContentItem[] = [
  { title: '자동 예약 접수', tabletCustomInformationMessage: '우리가게AI가 사장님 대신\n예약을 접수 및 확정', icon: IcServiceAutoReceipt },
  { title: '편리한 예약 관리', tabletCustomInformationMessage: '날짜별 예약 현황을 한눈에,\n접수부터 취소등 변동사항도 체크', icon: IcServiceManagement },
  { title: '카카오, 네이버 예약 연동', tabletCustomInformationMessage: '카카오, 네이버 예약도\n한 화면에서 관리', icon: IcServiceKakaoNaver },
  { title: '카카오톡 메시지 발송', tabletCustomInformationMessage: '예약 상황에 맞는 메시지 자동 전송\n(카카오톡 메시지 사용 신청 필요)', icon: IcServiceChannelTalk },
];

const ReservationServiceContent = () => (
  <div className="reservation-service-content">
    <div className="reservation-service-content-title">
      <label className="text">주요 서비스</label>
      <span className="mark">.</span>
    </div>
    <div className="reservation-service-content-list">
      {services.map((e, idx) => (
        <Fragment key={`reservation-service-content-item-${idx}`}>
          <div className="reservation-service-content-list-card">
            <div className="reservation-service-content-list-card-info">
              <h1 className="reservation-service-content-list-card-title">{e.title}</h1>
              <span className="reservation-service-content-list-card-tabletCustomInformationMessage font__body_7">{e.tabletCustomInformationMessage}</span>
            </div>
            <img className="reservation-service-content-list-card-image" src={e.icon} alt={`주요 서비스 ${idx}`} />
          </div>
          {idx + 1 < services.length && <hr className="reservation-service-content-list-card-divider" />}
        </Fragment>
      ))}
    </div>
  </div>
);

export default ReservationServiceContent;
