import classNames from 'classnames';
import './KakaoTextProfilePreview.scss';
import KakaoHiddenArea from '../KakaoHiddenArea/KakaoHiddenArea';
import { ForwardedRef, forwardRef } from 'react';

type Props = {
  imageText: string;
  hidden?: boolean;
};
const KakaoTextProfilePreview = forwardRef(({ imageText, hidden = false }: Props, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <KakaoHiddenArea hidden={hidden}>
      <div ref={ref} className="kakao-channel-profile-image text-profile">
        <span
          className={classNames({
            'small-oneline': imageText?.length === 6,
            twoline: imageText?.length > 6 && imageText?.length <= 12,
            'small-twoline': imageText?.length > 12 && imageText?.length <= 14,
            'small-threeline': imageText?.length > 14,
          })}
        >
          {imageText}
        </span>
      </div>
    </KakaoHiddenArea>
  );
});

export default KakaoTextProfilePreview;
