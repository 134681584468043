import React, { ReactElement, useCallback, useEffect, useId, useMemo, useReducer, useState } from 'react';
import HeaderTitle from '@/components/HeaderTitle/HeaderTitle';
import '@/styles/pages/tutorial/tutorial.scss';
import '@/styles/pages/tutorial/kakao-channel.scss';
import ProgressBar from '@/components/ProgressBar/ProgressBar';
import useGetInitialStep from '@/hooks/useGetInitialStep';
import template from 'lodash/template';
import { questionReducer } from '@/stores/common/useKakaoChannelSettingStore';
import TutorialKakaoChannelMessage from '@/components/TutorialKakaoChannel/TutorialKakaoChannelMessage';
import { useSettingTutorialStore } from '@/stores/common/useSettingTutorialStore';
import { useNavigate } from 'react-router-dom';
import TutorialKakaoChannelContentItem from '@/components/TutorialKakaoChannel/TutorialKakaoChannelContentItem';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Modal from '@/components/Modal/Modal';
import { useModalStore } from '@/stores/common/useModalStore';
import QuestionContent from '@/components/QuestionContent/QuestionContent';
import { useBusinessInfoSettingStore } from '@/stores/common/useBusinessInfoSettingStore';
import TutorialInput from '@/components/Tutorial/TutorialInput';
import { autoHypenCompany } from '@/shared/lib/functions/auto-hypen-company';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { businessTutorialApi, essentialTutorialApi, getApi, reservationTutorialApi, tableTutorialApi } from '@/shared/apis/tutorialApi';
import { useLayoutStore } from '@/stores/common/useLayoutStore';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { serialize } from 'v8';

const KAKAO_CHANNEL_DONE_CONTENTS = [
  '카카오톡 채널 가입 및 연동은 휴일 제외 3~5일이 소요되며 변동 될 수 있습니다.',
  '채널 가입 현황은 알림톡탭에서 확인하실 수 있습니다.',
];

const BusinessInfo = () => {
  const [progressStep, setProgressStep] = useState<number>(1);
  const { setSpinner } = useLayoutStore();
  const { storageQuestion, setStorageQuestion, resetStorageQuestion } = useBusinessInfoSettingStore();
  const { setState } = useSettingTutorialStore();
  const [renderingQuestion, dispatchRenderingQuestion] = useReducer(questionReducer, storageQuestion);
  const initialStep = useGetInitialStep(storageQuestion);
  const [step, setStep] = useState(initialStep);
  const { value, question, note, notePosition, nextStep } = renderingQuestion[step];
  const formattedQuestion = template(question)();
  const navigate = useNavigate();
  const closeConfirmModalId = useId();
  const { setAlertModal } = useModalStore();
  const payload = useAuthStore((store) => store.payload);
  const searchParams = new URLSearchParams(window.location.search);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      companyName: yup.string().required('사업자 번호와 일치하는 상호명을 입력해주세요.'),
      representative: yup.string().required('사업자 번호와 일치하는 대표자명을 입력해주세요.'),
      eid: yup.string().required('사업자 번호를 입력해주세요.').min(12, '올바른 사업자번호를 입력해주세요.').max(12, '올바른 사업자번호를 입력해주세요.'),
    });
  }, []);

  const form = useForm({
    defaultValues: {
      companyName: '',
      representative: '',
      eid: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { formState, getValues } = form;

  const stepProgress = useCallback(
    () =>
      setProgressStep(
        (() => {
          switch (step) {
            case 'step1_0':
              return 1;
            default:
              return 2;
          }
        })()
      ),
    [step]
  );

  const filterWords = (s) => s?.replace(/[^\w\sㄱ-힣]|[_]/g, '');

  const stepContent = useCallback((): ReactElement => {
    switch (step) {
      case 'step1_0':
        return (
          <>
            <TutorialInput name="eid" form={form} title="사업자 번호" placeholder="-를 제외한 숫자만 입력해주세요." valueDecorator={autoHypenCompany} maxLength={12} type="tel" />
            <TutorialInput name="companyName" form={form} title="상호명" placeholder="상호명을 입력해주세요." valueDecorator={filterWords} />
            <TutorialInput name="representative" form={form} title="대표자명" placeholder="대표자명을 입력해주세요." valueDecorator={filterWords} />
          </>
        );
      case 'step2_0':
        return (
          <>
            <TutorialKakaoChannelMessage step={step} dispatcher={dispatchRenderingQuestion} />
          </>
        );
      case 'step3_0':
        return <TutorialKakaoChannelContentItem contents={KAKAO_CHANNEL_DONE_CONTENTS} padding={true} />;
      default:
        return <></>;
    }
  }, [step]);

  function moveToWaitingAndRedirection() {
    const redirect = searchParams.get('redirect');
    const isWaiting = searchParams.get('isWaiting');
    if (isWaiting === 'true') {
      navigate('/waiting', { replace: true });
    } else {
      navigate(redirect, { replace: true });
    }
  }

  const onNext = async () => {
    const redirect = searchParams.get('redirect');
    let destinationStep = null;
    destinationStep = nextStep.to;

    if (nextStep.api) {
      const data = { ...getValues(), mallId: payload.pmid };
      // eslint-disable-next-line no-nested-ternary
      const api = getApi(nextStep.api[0]);
      setSpinner(true);
      await api(data);
      setSpinner(false);
      destinationStep = nextStep.api[1];
    }

    const nextTutorial = ['table', 'reservation', 'essential', 'kakao-channel', 'done'].includes(destinationStep);
    if (!nextTutorial) renderingQuestion[destinationStep].prevStep = step;
    setStorageQuestion(renderingQuestion);

    if (nextTutorial) {
      moveToWaitingAndRedirection();
      resetStorageQuestion();
      setState(destinationStep);
      if (destinationStep === 'done') {
        if (window.DonePage) {
          window.DonePage.postMessage('');
          window.NavigatePopPage.postMessage('');
        } else if (redirect !== null) {
          console.log(redirect);
          navigate(redirect, { replace: true });
        } else {
          navigate('/reservation/list', { replace: true });
        }
      } else {
        console.log('redirect to destinationStep');
        navigate('/tutorial/' + destinationStep);
      }
    } else {
      setStep(nextStep.to);
    }
  };

  useEffect(stepProgress, [stepProgress]);

  return (
    <div className="tutorial-page unpadding business">
      <HeaderTitle title="" onClose={() => setAlertModal({ visible: true, key: closeConfirmModalId })}>
        <ProgressBar steps={1} currentStep={progressStep} />
      </HeaderTitle>
      <div className="tutorial-content-container" key={`${step}_${formattedQuestion}`}>
        <QuestionContent question={question} option={stepContent()} note={note} type={null} value={null} key={step} />
      </div>
      <div className="button-container">
        <button className="next" onClick={onNext} disabled={!formState.isValid}>
          {nextStep && (nextStep.api || nextStep?.to === 'done') ? '확인했어요' : '다음'}
        </button>
      </div>
      <ModalPortal>
        <Modal.Alert
          key={closeConfirmModalId}
          modal="alert"
          isDim={true}
          isAnimation={true}
          modalData={{
            title: '알림',
            content: '사업자 정보를 입력하지 않으면\n예약·대기·카카오톡 메시지를\n이용할 수 없어요.',
            buttons: [
              {
                text: '그만하기',
                onClick: () => {
                  setAlertModal({ visible: false, key: closeConfirmModalId });
                  const redirect = searchParams.get('redirect');
                  if (redirect!) {
                    navigate(redirect, { replace: true });
                  } else {
                    navigate('/reservation/list', { replace: true });
                  }
                },
              },
              { text: '계속하기', success: true, onClick: () => setAlertModal({ visible: false, key: closeConfirmModalId }) },
            ],
          }}
        />
      </ModalPortal>
    </div>
  );
};

export default BusinessInfo;
