import './ScrollShadow.scss';

import classNames from 'classnames';
import { MutableRefObject, useEffect, useState } from 'react';

type ScrollShadowProp = {
  observe: MutableRefObject<any> | string;
};

const ScrollShadow = ({ observe }: ScrollShadowProp) => {
  const [isScrollMove, setIsScrollMove] = useState(false);
  useEffect(() => {
    document.querySelector('body').style.overflow = 'hidden';
    return () => {
      document.querySelector('body').style.overflow = 'auto';
    };
  }, []);

  const handleScroll = (e) => {
    const position = e.target.scrollTop;
    const scrollBoolean = Boolean(position <= 0);

    setIsScrollMove(!scrollBoolean);
  };

  useEffect(() => {
    const el = typeof observe === 'string' ? document.querySelector(`.${observe}`) : observe.current;
    el && el.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      el && el.removeEventListener('scroll', handleScroll);
    };
  }, [observe]);

  return <div className={classNames('shadow-container', { scroll: isScrollMove })} />;
};

export default ScrollShadow;
