import classNames from 'classnames';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import React, { useEffect, useState } from 'react';
import { getOnlyDecimal, initialTimeList } from './TimePicker.constants';
import { Hour, ITimeList, ITimePicker, ITimePickerList } from './TimePicker.type';
import './TimePicker.scss';

dayjs.locale('ko');

const TimePickerList = ({ list, startHour, endHour, selectTime, selectedTime }: ITimePickerList): React.ReactElement => {
  return (
    <div className="time-container">
      {list[1].map((time, i) => (
        <div className="hour-division" key={i}>
          <div className="hour-division-text font__body_4">{time.targetHour.format('H시')}</div>
          <div className="minutue-division-contianer">
            {time.minutes.map((minute, j) => {
              const isBeforTime =
                minute.isBefore(minute.set('minute', 60 * getOnlyDecimal(startHour, 1)).set('hour', Math.floor(startHour)), 'minute') &&
                minute.isAfter(minute.set('minute', 60 * getOnlyDecimal(endHour, 1)).set('hour', Math.floor(endHour)), 'minute');
              const isAfterTime = minute.isAfter(minute.set('minute', 60 * getOnlyDecimal(endHour, 1)).set('hour', Math.floor(endHour)), 'minute');

              return (
                <button
                  key={j}
                  className={classNames('minute-division-box font__body_3', {
                    selected: minute.isSame(selectedTime, 'minute'),
                    disabled: list[0] === 'morning' ? isBeforTime : isAfterTime,
                  })}
                  onClick={() => (list[0] === 'morning' ? !isBeforTime && selectTime(minute) : !isAfterTime && selectTime(minute))}
                >
                  {minute.format('HH:mm')}
                </button>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

const TimePicker = ({ startHour, endHour: endHours, selectedDate, onSelect }: ITimePicker): React.ReactElement => {
  const [timeList, setTimeList] = useState<ITimeList>(initialTimeList);
  const [selectedTime, setSelectedTime] = useState<dayjs.Dayjs>(null);
  const endHour = endHours < startHour ? 24 : endHours;

  const makeTimePicker = (date: dayjs.Dayjs) => {
    let timeGap = Math.ceil(endHour) - Math.floor(startHour);
    timeGap = timeGap < 0 ? timeGap * -1 : timeGap;
    let timeArray = new Array<Hour>(timeGap).fill(null);
    let targetDate = date.startOf('day');

    timeArray = timeArray.map((_, index) => {
      let targetHour = targetDate.hour(startHour + index);
      let minutes = new Array(6).fill(null).map((__, minuteIndex) => targetHour.minute(minuteIndex * 10));

      return { minutes, targetHour };
    });

    setTimeList({
      times: {
        morning: timeArray.filter((time) => time.targetHour.hour() < 12).sort((a, b) => a.targetHour.hour() - b.targetHour.hour()),
        afternoon: timeArray.filter((time) => time.targetHour.hour() >= 12),
      },
      targetDate: targetDate,
    });
  };

  const selectTime = (time) => {
    setSelectedTime(time);
    onSelect(time);
  };

  useEffect(() => {
    if (selectedDate) {
      makeTimePicker(selectedDate);
      setSelectedTime(selectedDate);
    }
  }, []);

  if (!timeList) return null;

  return (
    <div className="time-picker-container">
      {Object.entries(timeList.times).map((item) => {
        return (
          <div className="time-division-container" key={item[0]}>
            <div className="division-text font__small_m">{item[0] === 'morning' ? '오전' : '오후'}</div>
            <TimePickerList list={item} startHour={startHour} endHour={endHour} selectTime={selectTime} selectedTime={selectedTime} />
          </div>
        );
      })}
    </div>
  );
};

export default TimePicker;
