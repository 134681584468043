import React from 'react';
import { Form } from '@/pages/waiting/tutorial';
import { ReactComponent as Message } from '@/assets/images/icons/Message.svg';
import AlarmTalk2 from '@/assets/images/waiting/AlarmTalk2.png';
import classNames from 'classnames';

interface TutorialStep5Props {
  form: Form;
  setForm: (key: keyof Form, value: any) => () => void;
  goNext: () => Promise<void>;
}

const TutorialStep5: React.FC<TutorialStep5Props> = ({ form, setForm, goNext }) => {
  return (
    <>
      <div className="p-5 flex flex-col mb-[40px]">
        <p className="text-zinc-800 text-[28px] font-medium mb-[8px] leading-10">
          매장 입장 요청 후<br />
          일정 시간이 지나면
          <br />
          대기를 자동취소할까요?
        </p>
      </div>
      <div className="p-5 flex flex-col gap-y-2">
        <button
          className={classNames(
            'w-full border justify-center items-center rounded p-5',
            form.isCallExpiredCancel === true ? 'border-violet-700 text-violet-700 bg-indigo-50' : 'border-stone-300 text-zinc-500 bg-white'
          )}
          onClick={() => {
            setForm('isCallExpiredCancel', true);
            goNext();
          }}
        >
          네
        </button>
        <button
          className={classNames(
            'w-full border justify-center items-center rounded p-5',
            form.isCallExpiredCancel === false ? 'border-violet-700 text-violet-700 bg-indigo-50' : 'border-stone-300 text-zinc-500 bg-white'
          )}
          onClick={setForm('isCallExpiredCancel', false)}
        >
          아니오
        </button>
      </div>
      <div className="p-5 bg-zinc-100 flex-col">
        <div className="flex items-center mb-[14px]">
          <Message className="w-6 h-6 mr-1" />
          <span className="text-zinc-800 font-bold align-middle">대기 자동취소란?</span>
        </div>
        <p className="text-violet-950 text-sm font-bold mb-[4px]">{`고객님을 호출한 후 일정 시간에 입장하지 않을 경우 자동으로 대기가 취소되는 설정입니다.`}</p>
        <p className="text-zinc-500 text-sm mb-[20px]">{`설정은 채널 생성후 우리가게 > 우리가게 AI 탭 > 대기 설정에서 바꿀수 있어요`}</p>
        <p className="text-violet-950 text-sm font-bold mb-[4px]">{`대기 자동취소가 활성화되면 대기 등록 및 호출 메시지 하단에 안내 문구가 추가됩니다. `}</p>
        <p className="text-zinc-500 text-sm mb-[10px]">{`(00)분이내 미입장 시 자동취소 될 수 있으며, 자동취소 시 재접수하셔야 합니다.`}</p>
        <div className="px-10 pb-[80px]">
          <img src={AlarmTalk2} alt="알림톡 취소" className="w-full" />
        </div>
      </div>
    </>
  );
};

export default TutorialStep5;
