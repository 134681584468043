import React, { useEffect, useMemo } from 'react';

import OnOffItem from '@/components/Settings/OnOffItem';
import '../setting.scss';
import SettingItem from '@/components/Settings/SettingItem';
import { ReactComponent as Calander } from '@/assets/images/icons/ic_setting_calander.svg';
import { ReactComponent as Nor } from '@/assets/images/icons/ic_setting_nor.svg';
import { ReactComponent as People } from '@/assets/images/icons/ic_setting_people.svg';
import { ReactComponent as Advertisement } from '@/assets/images/icons/ic_setting_advertisement.svg';
import { ReactComponent as Waiting } from '@/assets/images/icons/ic_setting_waiting.svg';
import { ReactComponent as Check } from '@/assets/images/icons/ic_setting_check.svg';
import { useKakaoAlimtalkStore } from '@/stores/common/useKakaoAlimtalkStore';
import { devServerApi } from '@/shared/apis/devServerApi';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import useNative from '@/hooks/useNative';
import KakaoPreview from '@/components/KakaoPreview/KakaoPreview';
import { useSettingTutorialStore } from '@/stores/common/useSettingTutorialStore';
import { useQuery } from 'react-query';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import { useLayoutStore } from '@/stores/common/useLayoutStore';
import { useNavigate } from 'react-router-dom';
import useBackButton from '@/hooks/useBackButton';

const renderItem = (item) => {
  const state = item.getState ? item.getState() : {};

  // console.debug(item.title, state);

  if (item.type === 'onoff') {
    return (
      <OnOffItem
        title={item.title}
        tabletCustomInformationMessage={item.tabletCustomInformationMessage}
        icon={item.icon}
        {...state}
        beforeChange={item.beforeChange}
        onChagne={item.onChange}
      />
    );
  }
  return <SettingItem icon={item.icon} title={item.title} tabletCustomInformationMessage={item.tabletCustomInformationMessage} type={item.type} to={item.to} {...state} />;
};

const SettingKakao = (): React.ReactElement => {
  const navigate = useNavigate();
  const { callAlert } = useNative();
  const { alimtalk, immerAlimtalk, isFetching, setFetching, refreshAlimtalk } = useKakaoAlimtalkStore();
  const { state, setState } = useSettingTutorialStore();
  const { requestToken } = useNative();

  useBackButton(() => {});

  useEffect(() => {
    refreshAlimtalk().catch((error) => {
      callAlert('오류', error, [{ btnNm: '확인', value: 'close' }]);
    });
  }, []);

  const settingMenuItemsGroups = useMemo(
    () => [
      {
        title: null,
        items: [
          {
            type: 'onoff',
            title: '카카오톡으로 메시지 보내기',
            tabletCustomInformationMessage: '문자 대신 카카오톡으로 메시지를 보냅니다.',
            getState: () => ({ check: alimtalk.setting.toggleKakaoSendMsg }),
            onChange: (e) => {
              const stateDelta = {
                setting: {
                  toggleKakaoSendMsg: e.target.checked,
                },
              };

              setFetching(true);
              devServerApi.lg
                .post('/api/partner/kakao/alimtalk/setting', stateDelta)
                .then(refreshAlimtalk)
                .catch((error) => {
                  callAlert('오류', error, [{ btnNm: '확인', value: 'close' }]);
                });
            },
          },
        ],
      },
      {
        title: '카카오톡 채널',
        items: [
          {
            type: alimtalk.setting.existsChannel ? 'change' : 'review',
            icon: People,
            title: '내 카카오톡 채널',
            tabletCustomInformationMessage: alimtalk.setting.channelName || '설정 안됨',
            to: '/setting/kakao-channel',
          },
        ],
      },
      {
        title: '예약/대기',
        items: [
          {
            type: 'change',
            icon: Calander,
            title: '예약 메시지',
            tabletCustomInformationMessage: '예약 안내 메시지를 보냅니다.',
            to: '/setting/kakao-reservation-message',
            getState: () => ({ disabled: alimtalk.product.reservationYn !== 'Y' }),
          },
          {
            type: 'change',
            icon: Waiting,
            title: '대기 메시지',
            tabletCustomInformationMessage: '대기 안내 메시지를 보냅니다.',
            to: '/setting/kakao-waiting-message',
            getState: () => ({ disabled: alimtalk.product.waitingYn !== 'Y' }),
          },
        ],
      },
      {
        title: '우리매장알림',
        items: [
          {
            type: 'onoff',
            icon: Check,
            title: '우리매장알림 사용',
            tabletCustomInformationMessage: '매장에 전화를 걸면 자동 전송됩니다.',
            getState: () => ({ check: alimtalk.setting.toggleKakaoSendMsg && alimtalk.setting.toggleOurMall }),
            beforeChange: (e) => {
              console.debug('beforeChange');
              if (!alimtalk.setting.toggleKakaoSendMsg) {
                e.preventDefault();
                callAlert('카카오톡 메시지를 활성화할까요?', '카카오톡으로 메시지 보내기를 활성화해야\n우리매장 알림 메시지를 보낼 수 있어요.', [
                  { btnNm: '취소', value: 'close' },
                  {
                    btnNm: '활성화',
                    value: () => {
                      const stateDelta = {
                        setting: {
                          toggleKakaoSendMsg: true,
                          toggleOurMall: true,
                        },
                      };

                      immerAlimtalk((alimTalk) => {
                        alimTalk.setting.toggleKakaoSendMsg = true;
                        alimTalk.setting.toggleOurMall = true;
                      });
                      devServerApi.lg.post('/api/partner/kakao/alimtalk/setting', stateDelta).catch((error) => {
                        callAlert('오류', error, [{ btnNm: '확인', value: 'close' }]);
                      });
                    },
                  },
                ]);
              }
            },
            onChange: (e) => {
              const stateDelta = {
                setting: {
                  toggleOurMall: e.target.checked,
                },
              };

              immerAlimtalk((alimTalk) => {
                alimTalk.setting.toggleOurMall = e.target.checked;
              });
              devServerApi.lg.post('/api/partner/kakao/alimtalk/setting', stateDelta);
            },
          },
          {
            type: 'change',
            icon: Nor,
            title: '기본 메시지',
            tabletCustomInformationMessage: '처음 전화를 건 고객님께 전송됩니다.',
            to: '/setting/kakao-normal-mall-message',
          },
          {
            type: 'change',
            icon: Advertisement,
            title: '홍보 메시지',
            tabletCustomInformationMessage: '두번 이상 전화를 걸면 전송됩니다.',
            to: '/setting/kakao-ad-mall-message',
          },
        ],
      },
    ],
    [immerAlimtalk, refreshAlimtalk, setFetching, callAlert, alimtalk]
  );

  const { isLoading: isTutorialLoading } = useQuery([QUERY_KEYS.LIST_TUTORIAL], () => devServerApi.api.authGet('/tutorial'), {
    onSuccess: (res: any) => {
      const { tutorial } = res;
      setState(tutorial);
    },
    onError: () => {},
  });
  const { setSpinner } = useLayoutStore();
  useEffect(() => {
    setSpinner(isTutorialLoading);
  }, [isTutorialLoading]);
  useEffect(() => {
    requestToken();
  }, []);
  console.log(alimtalk.setting.profileImageUrl);
  if (alimtalk.setting.profileImageUrl === undefined) return null;
  if (alimtalk.setting.profileImageUrl?.length <= 0) return <KakaoPreview state={state} onClick={() => navigate('/tutorial/kakao-channel?from=kakao')} />;
  // @ts-ignore
  return (
    <div className="setting-container">
      {settingMenuItemsGroups.map((group) => (
        <>
          {group.title && <div className="setting-group-title">{group.title}</div>}
          <div className="item-container">{group.items.map((item) => renderItem(item))}</div>
        </>
      ))}
      <SpinnerEffector loading={isFetching} />
    </div>
  );
};

export default SettingKakao;
