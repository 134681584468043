import React, { useEffect, useLayoutEffect } from 'react';
import { ReactComponent as Close_BoottoMenu } from '@/assets/images/icons/Close_BoottoMenu.svg';
import { IModalProps } from '@/components/Modal/Modal.type';
import { useModalStore } from '@/stores/common/useModalStore';
import classNames from 'classnames';
import ScrollShadow from '@/components/ScrollShadow/ScrollShadow';

const Menu = (props: IModalProps) => {
  const { isDim, isClose = true, isAnimation, children, onClose, title, contentClass, scrollObserve } = props;
  const { menuModal, setMenuModal } = useModalStore((store) => ({ menuModal: store.menuModal, setMenuModal: store.setMenuModal }));

  useLayoutEffect(() => {
    if (!menuModal.animated) {
      const timeout = setTimeout(() => {
        const newMoal = Object.assign(menuModal, { animated: true });
        setMenuModal(newMoal);
      }, 300);
      return () => clearTimeout(timeout);
    }
  }, [menuModal]);
  if (!menuModal.visible) return <></>;

  return (
    <div className="modal-wrapper menu">
      {isDim ? <div className="modal-dim" onClick={() => (onClose ? onClose() : setMenuModal(false))} /> : null}
      <div className={classNames('modal-content', isAnimation && !menuModal.animated ? 'animation' : '')}>
        <div className="title">
          <span>{title}</span>
          <div className="close pointer">{isClose ? <Close_BoottoMenu onClick={() => (onClose ? onClose() : setMenuModal(false))} /> : null}</div>
        </div>
        <ScrollShadow observe={scrollObserve || 'content'} />
        {children}
      </div>
    </div>
  );
};

export default Menu;
