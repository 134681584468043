import PropTypes from 'prop-types';
import React, { Component, WheelEvent } from 'react';
import './ScrollPicker.scss';

const unitize = (value, unit = '') => {
  if (unit.startsWith('split')) {
    return value.split('|')[1];
  }
  if (unit === '년월') return `${value[0]}년 ${value[1]}월`;
  if (unit === '층') return `${value}층`.replace('-', '지하 ');
  if (unit === '시간분') {
    const h = Math.floor(value / 60);
    const m = Math.floor(value % 60);

    let ret = '';
    ret += h > 0 ? h + '시간' : '';
    ret += h > 0 && m > 0 ? ' ' : '';
    ret += m > 0 ? m + '분' : '';

    return ret;
  }
  return value + unit;
};

class PickerColumn extends Component {
  static propTypes = {
    options: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    itemHeight: PropTypes.number.isRequired,
    columnHeight: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMoving: false,
      startTouchY: 0,
      startScrollerTranslate: 0,
      ...this.computeTranslate(props),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // @ts-ignore
    if (this.state.isMoving) {
      return;
    }
    this.setState(this.computeTranslate(nextProps));
  }

  computeTranslate = (props) => {
    const { options, value, itemHeight, columnHeight } = props;
    let selectedIndex = typeof value === 'object' ? options.list.map(JSON.stringify).indexOf(JSON.stringify(value)) : options.list.indexOf(value);
    if (selectedIndex < 0) {
      // throw new ReferenceError();
      this.onValueSelected(options.list[0]);
      selectedIndex = 0;
    }
    return {
      scrollerTranslate: columnHeight / 2 - itemHeight / 2 - selectedIndex * itemHeight,
      minTranslate: columnHeight / 2 - itemHeight * options.list.length + itemHeight / 2,
      maxTranslate: columnHeight / 2 - itemHeight / 2,
    };
  };

  onValueSelected = (newValue) => {
    // @ts-ignore
    this.props.onChange(this.props.name, newValue);
  };

  handleTouchStart = (event) => {
    const startTouchY = event.targetTouches[0].pageY;
    // @ts-ignore
    this.setState(({ scrollerTranslate }) => ({
      startTouchY,
      startScrollerTranslate: scrollerTranslate,
    }));
  };

  handleTouchMove = (event) => {
    // event.preventDefault();
    event.stopPropagation();
    const touchY = event.targetTouches[0].pageY;
    // @ts-ignore
    this.setState(({ isMoving, startTouchY, startScrollerTranslate, minTranslate, maxTranslate }) => {
      if (!isMoving) {
        return {
          isMoving: true,
        };
      }

      let nextScrollerTranslate = startScrollerTranslate + touchY - startTouchY;
      if (nextScrollerTranslate < minTranslate) {
        nextScrollerTranslate = minTranslate - Math.pow(minTranslate - nextScrollerTranslate, 0.8);
      } else if (nextScrollerTranslate > maxTranslate) {
        nextScrollerTranslate = maxTranslate + Math.pow(nextScrollerTranslate - maxTranslate, 0.8);
      }
      return {
        scrollerTranslate: nextScrollerTranslate,
      };
    });
  };

  handleTouchEnd = (event) => {
    // @ts-ignore
    if (!this.state.isMoving) {
      return;
    }
    this.setState({
      isMoving: false,
      startTouchY: 0,
      startScrollerTranslate: 0,
    });
    setTimeout(() => {
      this.postMove();
    }, 0);
  };

  handleTouchCancel = (event) => {
    // @ts-ignore
    if (!this.state.isMoving) {
      return;
    }
    this.setState((startScrollerTranslate) => ({
      isMoving: false,
      startTouchY: 0,
      startScrollerTranslate: 0,
      scrollerTranslate: startScrollerTranslate,
    }));
  };

  handleItemClick = (option) => {
    // @ts-ignore
    if (option !== this.props.value) {
      this.onValueSelected(option);
    }
  };

  postMove() {
    try {
      // @ts-ignore
      const { options, itemHeight } = this.props;
      // @ts-ignore
      const { scrollerTranslate, minTranslate, maxTranslate } = this.state;
      let activeIndex;
      if (scrollerTranslate > maxTranslate) {
        activeIndex = 0;
      } else if (scrollerTranslate < minTranslate) {
        activeIndex = options.list.length - 1;
      } else {
        activeIndex = -Math.floor((scrollerTranslate - maxTranslate) / itemHeight);
      }
      this.setState((state) => ({
        ...state,
        // eslint-disable-next-line no-nested-ternary
        scrollerTranslate: scrollerTranslate < minTranslate ? minTranslate : scrollerTranslate > maxTranslate ? maxTranslate : scrollerTranslate,
      }));
      this.onValueSelected(options.list[activeIndex]);
    } catch (e) {
      console.error(e);
    }
  }

  postWheel() {
    const that = this;
    setTimeout(() => {
      // @ts-ignore
      if (that.state.isScrolling > Date.now() - 250) {
        this.postWheel();
        return;
      }
      this.postMove();
    }, 250);
  }

  handleScroll = (event) => {
    event.stopPropagation();
    // Support for keyboard up/down
    let deltaY;
    if (Boolean(event.keyCode) && (event.keyCode === 38 || event.keyCode === 40)) {
      deltaY = event.keyCode === 38 ? 53 : -53;
    } else if (event.deltaY) {
      deltaY = event.deltaY;
    } else {
      deltaY = 0;
    }
    // @ts-ignore
    this.setState(({ scrollerTranslate, minTranslate, maxTranslate }) => {
      const newValue = (scrollerTranslate || 0) + Math.round(deltaY);
      const newTranslate = Math.max(minTranslate, Math.min(maxTranslate, (scrollerTranslate || 0) + Math.round(deltaY)));

      this.postWheel();

      return {
        scrollerTranslate: newTranslate,
        isScrolling: Date.now(),
      };
    });
  };

  renderItems() {
    // @ts-ignore
    const { options, itemHeight, value } = this.props;
    return options.list.map((option, index) => {
      const style = {
        height: itemHeight + 'px',
        lineHeight: itemHeight + 'px',
      };
      const className = `picker-item${option === value || JSON.stringify(option) === JSON.stringify(value) ? ' picker-item-selected' : ''}`;
      return (
        <div key={index} className={className} style={style} onClick={() => this.handleItemClick(option)}>
          {unitize(option, options.unit)}
        </div>
      );
    });
  }

  render() {
    // @ts-ignore
    const { tabIndex } = this.props;
    // @ts-ignore
    const translateString = `translate3d(0, ${this.state.scrollerTranslate}px, 0)`;
    const style = {
      MsTransform: translateString,
      MozTransform: translateString,
      OTransform: translateString,
      WebkitTransform: translateString,
      transform: translateString,
    };
    // @ts-ignore
    if (this.state.isMoving) {
      // @ts-ignore
      style.transitionDuration = '0ms';
    }
    return (
      <div className="picker-column">
        <div
          tabIndex={tabIndex}
          className="picker-scroller"
          style={style}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
          onTouchEnd={this.handleTouchEnd}
          onTouchCancel={this.handleTouchCancel}
          onWheel={this.handleScroll}
          onKeyDown={this.handleScroll}
        >
          {this.renderItems()}
        </div>
      </div>
    );
  }
}

export interface IScrollPicker {
  optionGroups: { [key: string | number]: { list: any[]; unit?: string } };
  valueGroups: { [key: string | number]: any };
  onChange: (...args: any) => void;
  itemHeight?: number;
  height?: number;
}

const ScrollPicker = ({ optionGroups, valueGroups, onChange, itemHeight = 50, height = 164 }: IScrollPicker): React.ReactElement => {
  let index = 1000;
  return (
    <div className="picker-container" style={{ height }}>
      <div className="picker-inner">
        {Object.entries(optionGroups).map(([name, optionGroup], i) => (
          <PickerColumn
            tabIndex={index + i}
            key={name}
            name={name}
            options={optionGroups[name]}
            value={valueGroups[name]}
            itemHeight={itemHeight}
            columnHeight={height}
            onChange={onChange}
          />
        ))}
        <div
          className="picker-highlight"
          style={{
            height: itemHeight,
            marginTop: -(itemHeight / 2),
          }}
        />
      </div>
    </div>
  );
};
export default ScrollPicker;
