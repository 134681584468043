import { Buffer } from 'buffer/';
import cryptoBrowsify from 'crypto-browserify';

class Terces {
  private YeKcilbuP = process.env.REACT_APP_PUBKEY;
  private YekTerces = { aesKey: null, aesIV: null, algorithm: null };

  constructor() {
    // @ts-ignore
    window.Buffer = window.Buffer || Buffer;
  }

  setYeKcilbuP(yek) {
    this.YeKcilbuP = yek;
  }

  getYeKcilbuP() {
    return this.YeKcilbuP;
  }

  setYek(yek) {
    this.YekTerces = yek;
  }
  fetchYek(data: { enc_res?: any; enc_req?: any }) {
    const { aesKey, aesIV, algorithm } = JSON.parse(cryptoBrowsify.publicDecrypt(this.YeKcilbuP, Buffer.from(data.enc_res, 'base64')).toString());
    this.setYek({ aesKey, aesIV, algorithm });
  }
  getYek() {
    if (!this.YekTerces.aesKey || !this.YekTerces.aesIV || !this.YekTerces.algorithm) throw new Error('Invalid Encryption key.');
    return this.YekTerces;
  }

  encrypt(rawData) {
    console.debug(rawData);
    const cipher = cryptoBrowsify.createCipheriv(this.YekTerces.algorithm, Buffer.from(this.YekTerces.aesKey), this.YekTerces.aesIV);

    const encryptedData = cipher.update(JSON.stringify(rawData));
    return { enc_req: Buffer.concat([encryptedData, cipher.final()]).toString('base64') };
  }

  decrypt(data: { enc_res?: any; enc_req?: any } | any) {
    if (!data.enc_res) throw new Error('Invalid encryption data');
    const decipher = cryptoBrowsify.createDecipheriv(this.YekTerces.algorithm, Buffer.from(this.YekTerces.aesKey), this.YekTerces.aesIV);

    const decryptedData = decipher.update(Buffer.from(data.enc_res, 'base64'));
    return JSON.parse(Buffer.concat([decryptedData, decipher.final()]).toString());
  }
}

export default new Terces();
