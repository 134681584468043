import React from 'react';
import './HeaderTitle.scss';
import { ReactComponent as Close } from '@/assets/images/icons/Close.svg';
import { IHeaderTitleProps } from '@/components/HeaderTitle/HeaderTitle.type';
import classNames from 'classnames';

const HeaderTitle = (props: IHeaderTitleProps) => {
  const { title, noBorder, children, onClose } = props;

  return (
    <header className={classNames('header-title-wrapper', { 'no-border': noBorder })}>
      <div className="header-title">
        <div className="dummy" />
        <h1 className="font__header_title title">{title}</h1>
        <div className="close" onClick={onClose}>
          <Close />
        </div>
      </div>
      {children}
    </header>
  );
};

export default HeaderTitle;
