import useNative from '@/hooks/useNative';
import { useState } from 'react';

const MAX_IMAGE_SIZE = 1024 * 1024;

const useImagePicker = (finalImagePicked: () => void) => {
  const { callAlert } = useNative();
  const [image, setImage] = useState('');

  const pickImage = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';

    // 파일 선택이 변경될 때의 동작 정의
    fileInput.addEventListener('change', function (event) {
      finalImagePicked();
      setImage('');
      const target = event.target as HTMLInputElement;
      const fileList = target.files;
      if (fileList.length > 0) {
        const file = fileList[0];
        console.log(file);
        if (file.size > MAX_IMAGE_SIZE) {
          callAlert('이미지를 사용할 수 없습니다.', '1MB 이하 이미지만 업로드 가능합니다.', [{ btnNm: '닫기', value: 'close' }]);
          return;
        }
        // setImageFile(fileName);
        const reader = new FileReader();
        reader.onload = function (e) {
          const imageDataUrl = e.target.result;
          setImage(imageDataUrl.toString());
        };

        reader.readAsDataURL(file);
      }
    });

    fileInput.click();
  };

  return { image, setImage, pickImage };
};

export default useImagePicker;
