import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Agreement from '@/components/Agreement/Agreement';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { devServerApi } from '@/shared/apis/devServerApi';

const AgreementSetting = ({ isComponent, onYes }: { isComponent?: boolean; onYes?: () => void }): React.ReactElement => {
  const navigate = useNavigate();
  const [showAgreement, setShowagreement] = useState(true);

  const [searchParams] = useSearchParams();
  const [showAgreementPortal, setShowagreementPortal] = useState(true);

  interface BusinessResponse {
    existsBusiness: boolean;
  }

  async function checkBusiness(redirect) {
    const responseBusiness = devServerApi.lg.authGet('/api/partner/mall/is-business') as Promise<BusinessResponse>;

    const isBusiness = (await responseBusiness).existsBusiness;

    if (!isBusiness) {
      navigate('/tutorial/business_wait?redirect=/waiting&isWaiting=true');
    } else {
      navigate(redirect, { replace: true });
    }
  }

  function check() {
    const redirect = searchParams.get('redirect');
    const isWaiting = JSON.parse(searchParams.get('isWaiting'));

    if (isWaiting && redirect !== null) {
      checkBusiness(redirect);
    }
  }

  const close = async (yn: any) => {
    await check();
    setShowagreement(false);
    setTimeout(() => {
      setShowagreementPortal(false);
      if (!isComponent) {
        if (yn) {
          setTimeout(() => {
            if (onYes) onYes();
            else {
              if (window.NavigateReplacePage) {
                window.NavigateReplacePage.postMessage('/tutorial');
                console.log(window.NavigateReplacePage);
              } else {
                navigate('/tutorial', { replace: true });
              }
            }
          }, 0);
        } else {
          if (window.ExitPage) window.ExitPage.postMessage('');
          else navigate('/reservation/list');
        }
      }
    }, 0);
  };
  return <>{showAgreementPortal ? <ModalPortal>{showAgreement ? <Agreement onClose={close} /> : null}</ModalPortal> : null}</>;
};

export default AgreementSetting;
