import React from 'react';
import './EnterSetting.scss';
import { ReactComponent as IcSetting } from '@/assets/images/icons/setting.svg';
import useNative from '@/hooks/useNative';

const EnterSetting = (): React.ReactElement => {
  const { pageMove } = useNative();
  const moveReservationSetting = () => {
    pageMove('setting');
  };

  return (
    <div className="enter-setting">
      <button className="enter-setting-btn" onClick={moveReservationSetting}>
        <IcSetting />
        <label>예약 설정</label>
      </button>
    </div>
  );
};

export default EnterSetting;
