import 'dayjs/locale/ko';

import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';

import FooterSub from '@/components/FooterSub/FooterSub';
import HeaderSub from '@/components/HeaderSub/HeaderSub';
import MonthCalendar from '@/components/MonthCalendar/MonthCalendar';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import dayjs from 'dayjs';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useQuery } from 'react-query';
import { useReservationCalendarStore } from '@/stores/reservation/useReservationCalendarStore';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import { useLayoutStore } from '@/stores/common/useLayoutStore';
import HeaderSubType from '@/components/HeaderSubType/HeaderSubType';
import { autoHypenTel } from '@/shared/lib/functions/auto-hypen-tel';

dayjs.locale('ko');

const INITIAL_STEP_TEXTS = {
  header: (
    <>
      예약일을 <br />
      선택 해주세요.
    </>
  ),
  footer: { save: '저장', back: '뒤로', next: '다음' },
};

const Step1 = (): React.ReactElement => {
  const { visitDate, setVisitDate, setVisitTime, setParty, setRooms, depositInfo, setDepositInfo } = useReservationStore();
  const { setReservationResetStore, userInfo } = useReservationStore();
  const { reservationList, setReservationList } = useReservationCalendarStore();
  const { accessToken } = useAuthStore();
  const { fromApp } = useLayoutStore();

  const [eventDays, setEventDays] = useState([]);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams({ type: 'default' });

  const today = useMemo(() => dayjs(), []);

  const { isFetching } = useQuery([QUERY_KEYS.GET_EVENT_DAY], () => devServerApi.api.authGet('/eventDays'), {
    onSuccess: (res: any) => {
      setEventDays(res.eventdays.map((item) => ({ date: dayjs(`${item.year}-${item.month}-${item.day}`), memo: '테스트 휴무' })));
    },
  });

  const handleOnClose = () => {
    if (searchParams.get('type') === 'default') {
      setReservationResetStore();
      navigate('/reservation/list');
    }
    if (searchParams.get('type') === 'modify' || searchParams.get('type') === 'deposit') navigate(-1);
  };

  const handleOnRouterMove = (path) => navigate(path);

  const { isLoading: isCalendarLoading } = useQuery(
    [QUERY_KEYS.LIST_CALENDAR],
    () => devServerApi.api.authGet(`reservation/calendar/?from=${today.add(-1, 'month').format('YYYY-MM')}&to=${today.add(3, 'month').format('YYYY-MM')}`),
    {
      onSuccess: (res: any) => {
        const convertDate = res.reservationSummary.map((item) => ({ ...item, date: dayjs(item.date) }));
        setReservationList(convertDate);
      },
    }
  );

  useEffect(() => {
    if (searchParams.get('visitDate')) setVisitDate(dayjs(searchParams.get('visitDate')));
    else setVisitDate(dayjs());
  }, []);

  useEffect(() => {
    if (fromApp) {
      window.onpopstate = () => {
        if (window.NavigateToHistory) {
          window.NavigateToHistory.postMessage('');
        } else {
          navigate('/reservation/list');
        }
      };
      history.pushState(null, null, window.location.pathname);
    }
  }, [fromApp]);

  if (!reservationList || reservationList.length === 0 || isFetching) return <SpinnerEffector loading />;

  return (
    <>
      {fromApp ? (
        <>
          <HeaderSubType header={autoHypenTel(userInfo.userPhone)}>{INITIAL_STEP_TEXTS.header}</HeaderSubType>
        </>
      ) : (
        <HeaderSub onClose={handleOnClose}>{INITIAL_STEP_TEXTS.header}</HeaderSub>
      )}
      <section className="month-calendar-wrapper">
        <MonthCalendar
          select={(target) => {
            if (searchParams.get('type') === 'deposit') {
              setDepositInfo({ ...depositInfo, depositDue: target });
              // handleOnRouterMove();
            } else if (searchParams.get('type') === 'modify') {
              setVisitDate(target);
              setVisitTime(null);
              setParty([]);
              setRooms([]);
              handleOnRouterMove(-1);
            } else {
              setVisitDate(target);
              handleOnRouterMove('/reservation/accept/step-2/');
            }
          }}
          startDate={today.add(-1, 'month')}
          targetDate={today}
          selectedDate={searchParams.get('type') === 'deposit' ? depositInfo?.depositDue : visitDate}
          disabledBeforeDay={today}
          orderList
          period={6}
          holidays={eventDays}
          headerHeight={fromApp ? 140 : 55}
          reservationList={reservationList}
        />
      </section>
      <FooterSub isVisible isBorder isBackArea noTransparent>
        {searchParams.get('type') === 'modify' || searchParams.get('type') === 'default' ? (
          <div className="font__heading_5 flex-container items-center">{visitDate?.format('MM.DD(ddd)')} </div>
        ) : null}
        {searchParams.get('type') === 'deposit' ? <div className="font__heading_5 flex-container items-center">{depositInfo?.depositDue?.format('MM.DD(ddd)')}</div> : null}

        <div className="flex-container gap-8">
          {searchParams.get('type') === 'modify' ? (
            <Link to={-1 as any}>
              <button className="footer tblm-button-normal tblm-btn-primary" disabled={Boolean(!visitDate)}>
                {INITIAL_STEP_TEXTS.footer.save}
              </button>
            </Link>
          ) : null}

          {searchParams.get('type') === 'default' ? (
            <Link to={'/reservation/accept/step-2/'}>
              <button className="footer tblm-button-normal tblm-btn-primary" disabled={Boolean(!visitDate)}>
                {INITIAL_STEP_TEXTS.footer.next}
              </button>
            </Link>
          ) : null}
        </div>
      </FooterSub>
    </>
  );
};

export default Step1;
