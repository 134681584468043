import Tabs from '@/components/Tabs/Tabs';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import { Fragment, useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import SelectTables from '../SelectTable/SelectTables';

const customTabsStyle = { padding: '0 20px', gap: 16, top: 55 };

function TableSetting({ groups, reservations }) {
  const { rooms, setRooms } = useReservationStore();

  const [activeGroup, setActiveGroup] = useState(null);
  const groupIds = groups.map((group) => group.roomGroupId);

  const handleChangeGroup = (idx) => {
    setActiveGroup(groupIds[idx]);
  };

  const handleOnGroupTabClick = (e) => {
    const selectedId = e.target.getAttribute('data-value');
    setActiveGroup(selectedId);
  };

  const handleOnTablesClick = (id) => {
    const result = groups.find((item) => item.roomGroupId === activeGroup)?.rooms.find((item) => item.id === id);
    const isInRooms = rooms.find((item) => item.id === result.id);
    if (isInRooms) {
      const filterRooms = rooms.filter((room) => room.id !== isInRooms.id);
      setRooms(filterRooms);
    } else {
      setRooms([...rooms, result]);
    }
  };

  useEffect(() => {
    setActiveGroup(rooms.length ? rooms[0].roomGroupId : groups[0].roomGroupId);
  }, []);

  if (!groups.length) return <></>;
  return (
    <section className="select-table-wrapper">
      <div className="table-title font__subtitle1_b">테이블</div>

      <Tabs isBorder isFixed style={customTabsStyle} onClick={handleOnGroupTabClick}>
        {groups.map((item) => {
          return (
            <Tabs.Item key={item.roomGroupId} value={item.roomGroupId} active={activeGroup === item.roomGroupId}>
              {item.roomGroupName}
            </Tabs.Item>
          );
        })}
      </Tabs>
      <div className="table-content">
        <SwipeableViews index={groupIds.indexOf(activeGroup)} onChangeIndex={handleChangeGroup}>
          {groups.map((group) => (
            <div style={{ gap: 12, display: 'flex', flexDirection: 'column' }}>
              <Fragment key={group.roomGroupId}>
                {group.rooms.map((room) => {
                  const matchReservations = reservations.filter((item) => item.rooms.find((reservationRoom) => reservationRoom.id === room.id));
                  return (
                    <SelectTables
                      key={`table_${room.id}`}
                      tables={room}
                      onClick={handleOnTablesClick}
                      active={Boolean(rooms.find((storeRoom) => storeRoom.id === room.id))}
                      reservations={matchReservations}
                    />
                  );
                })}
              </Fragment>
            </div>
          ))}
        </SwipeableViews>
      </div>
    </section>
  );
}

export default TableSetting;
