import classnames from 'classnames';
import groupBy from 'lodash/groupBy';
import throttle from 'lodash/throttle';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as Minus } from '@/assets/images/icons/Minus.svg';
import { ReactComponent as Path } from '@/assets/images/icons/Path.svg';
import { ReactComponent as Plus } from '@/assets/images/icons/Plus.svg';
import { ReactComponent as Edit } from '@/assets/images/icons/Edit.svg';
import { ReactComponent as Close_BoottoMenu } from '@/assets/images/icons/Close_BoottoMenu.svg';
import FooterSub from '@/components/FooterSub/FooterSub';
import Modal from '@/components/Modal/Modal';
import ScrollPicker from '@/components/ScrollPicker/ScrollPicker';
import Select from '@/components/Select/Select';
import { ControlledInput } from '@/hook-form/ControlledInput';
import { IStep, Nullable } from '@/shared/utils/common.type';
import { useLayoutStore } from '@/stores/common/useLayoutStore';
import { useModalStore } from '@/stores/common/useModalStore';
import ModalPortal from '../ModalPortal/ModalPortal';
import { ITutorialTable } from './TutorialTable.type';
import './TutorialTable.scss';
import { instantDisabler } from '@/shared/lib/functions';
import IconTextButton from '../IconTextButton/IconTextButton';

const TutorialTable = ({ question, onSubmit, parentref }: ITutorialTable): React.ReactElement => {
  const whileInput = useLayoutStore((s) => s.keyboard);
  const { setMenuModal } = useModalStore();
  const [showLayout, setShowLayout] = useState(0);
  const [table_groups, setTableGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();
  const [selectedGroupIdx, setSelectedGroupIdx] = useState(0);
  const [sourceTable, setSourceTable] = useState<Nullable<{ group_idx: number; table_idx: number }>>(null);
  const [scrollState, setScrollState] = useState('down');

  const methods = useFormContext();
  const {
    control,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const unitize = (value, unit) => {
    if (unit === '층') return `${value}층`.replace('-', '지하 ');
    return value + unit;
  };

  const valuer = ({ value, reference, option, optionUnit }: Partial<IStep>) => {
    const referenceUnit = reference ? question[reference]?.optionUnit || null : null;
    const referenceOption = reference ? question[reference]?.option || null : null;
    if (Array.isArray(value)) {
      if (Array.isArray(value[0])) {
        // @ts-ignore
        return value.map(([t, v]) => [unitize(referenceOption[t], referenceUnit), option[v] || v]);
      }
      return value.map((t) => unitize(option[t], optionUnit));
    }
    return value;
  };

  const defaultTable = (name = '', groupId = null) => ({ enabled: true, groupId, id: null, max: 4, min: 2, name });

  const submit = (data) => {
    onSubmit(data.table_groups);
  };

  const scrollTo = () => {
    const cbody = parentref.current;
    cbody.scrollTop = scrollState === 'up' ? 0 : cbody.scrollHeight;
  };

  const changeGroup = (sourceTableTarget) => {
    setSourceTable(sourceTableTarget);
    setSelectedGroup(getValues('table_groups')[sourceTableTarget.group_idx].name);
    setMenuModal({ key: 'group-picker', visible: true });
  };
  const addNewTableGroup = () => {
    setValue('table_groups', [...getValues('table_groups'), { name: '새 그룹', tables: [] }]);
    setTableGroups(getValues('table_groups'));
  };

  const addNewTable = (group_idx) => {
    setValue(
      'table_groups',
      getValues('table_groups').map((group, i) => {
        if (i === group_idx) group.tables.push(defaultTable('새 테이블'));
        return group;
      })
    );
    setTableGroups(getValues('table_groups'));
  };

  const onClickChangeTableGroup = (group_idx, table_idx) => {
    changeGroup({ group_idx, table_idx });
    setShowLayout(2);
  };

  useEffect(() => {
    if (showLayout >= 0) document.querySelector('body').style.overflow = 'hidden';
    else document.querySelector('body').style.overflow = 'auto';
  }, [showLayout]);

  useEffect(() => {
    const scrollEvent = (e) => {
      setScrollState(e.target.scrollTop + e.target.clientHeight < e.target.scrollHeight ? 'down' : 'up');
    };
    parentref.current.addEventListener('scroll', scrollEvent);
    return () => {
      parentref.current?.removeEventListener('scroll', scrollEvent);
    };
  }, [parentref.current]);

  useEffect(() => {
    const expects: { [key: string]: Nullable<any | any[]> } = Object.fromEntries(Object.entries(question).map((q) => [q[0], valuer(q[1])]));
    const { step2_0, step2_1, step2_2, step2_3, step2_4 } = expects;
    const onefloor =
      step2_0 > 0 || step2_3 > 0
        ? [
            {
              name: '기본',
              tables: [
                ...(step2_0 > 0 ? new Array(step2_0).fill(null).map((_, i) => defaultTable('홀' + (i + 1))) : []),
                ...(step2_3 > 0 ? new Array(step2_3).fill(null).map((_, i) => defaultTable(`${i + 1}룸`)) : []),
              ],
            },
          ]
        : [];

    const multifloorhall =
      step2_1 && step2_1.length > 0
        ? step2_1.filter(([key, value]) => value > 0).map(([key, value]) => [key, new Array(value).fill(null).map((_, i) => defaultTable(`홀${i + 1}`))])
        : [];
    const multifloorroomhall =
      step2_2 && step2_2.length > 0
        ? step2_2.filter(([key, value]) => value > 0).map(([key, value]) => [key, new Array(value).fill(null).map((_, i) => defaultTable(`홀${i + 1}`))])
        : [];
    const multifloorroom =
      step2_4 && step2_4.length > 0
        ? step2_4.filter(([key, value]) => value > 0).map(([key, value]) => [key, new Array(value).fill(null).map((_, i) => defaultTable(`${i + 1}룸`))])
        : [];

    const multifloors = Object.entries(groupBy([...multifloorhall, ...multifloorroomhall, ...multifloorroom], (item) => item[0]))
      .map(([key, value]) => ({ name: key, tables: [].concat(...value.map((v) => v[1])) }))
      .sort((a, b) => parseInt(a.name.replace('지하 ', '-').replace('층', ''), 10) - parseInt(b.name.replace('지하 ', '-').replace('층', ''), 10));

    const _table_groups = [...onefloor, ...multifloors].flat(2);
    setValue('table_groups', _table_groups);
    setTableGroups(_table_groups);
  }, []);

  useEffect(() => {
    const page = document.querySelector('.tutorial-table-content__body').parentElement;
    const heads = Array.from(document.querySelectorAll('.tutorial-table-content__body > .table_group > .table_group_head'));
    const onScroll = throttle(() => {
      heads.forEach((head) => {
        head.toggleAttribute('stuck', head.getBoundingClientRect().y <= 108);
      });
    }, 100);

    page.addEventListener('scroll', onScroll);

    return () => {
      page.removeEventListener('scroll', onScroll);
    };
  }, [table_groups]);

  watch('table_groups');

  return (
    <>
      <div className="tutorial-table-content__head">
        <div className={'tutorial-question'}>{'우리 매장의 테이블이\n제대로 설정되었는지\n확인해주세요.'}</div>
      </div>
      <div className="tutorial-table-content__body">
        <>
          {getValues('table_groups').map((table_group, group_idx) => (
            <div className="table_group" key={table_group.name + group_idx}>
              <div className="table_group_head" data-idx={group_idx}>
                <span>{table_group.name}</span>
                <IconTextButton
                  icon={Edit}
                  text="변경"
                  onClick={() => {
                    setShowLayout(3);
                    setMenuModal({ key: 'change-group-name', visible: true });
                    setSelectedGroupIdx(group_idx);
                  }}
                />
              </div>
              {table_group.tables.map((table, table_idx) => {
                const minTarget = `table_groups.${group_idx}.tables.${table_idx}.min`;
                const maxTarget = `table_groups.${group_idx}.tables.${table_idx}.max`;

                return (
                  <div className="table_table" key={`${group_idx}_${table_idx}`}>
                    <div className="new-table-info-wrapper">
                      <div className="label font__small_m">테이블 이름</div>
                      <ControlledInput
                        className="table-name-input"
                        control={control}
                        name={`table_groups.${group_idx}.tables.${table_idx}.name`}
                        type="text"
                        placeholder="테이블 이름"
                      />
                      <div className="people-selector">
                        <div className="people-selector-label font__body_md">최소 인원</div>
                        <div className="people-icon-box">
                          <div
                            className="people-minus sign-icon"
                            onClick={() => {
                              if (getValues(minTarget) < 2) return;
                              setValue(minTarget, getValues(minTarget) - 1);
                            }}
                          >
                            <Minus />
                          </div>
                          <div className="now-people font__body_md_m">{getValues(minTarget)}명</div>
                          <div
                            className="people-plus sign-icon"
                            onClick={() => {
                              if (getValues(minTarget) >= getValues(maxTarget)) return;
                              setValue(minTarget, getValues(minTarget) + 1);
                            }}
                          >
                            <Plus />
                          </div>
                        </div>
                      </div>
                      <div className="people-selector">
                        <div className="people-selector-label font__body_md">최대 인원</div>
                        <div className="people-icon-box">
                          <div
                            className="people-minus sign-icon"
                            onClick={() => {
                              if (getValues(maxTarget) < 1) return;
                              if (getValues(maxTarget) <= getValues(minTarget)) return;
                              setValue(maxTarget, getValues(maxTarget) - 1);
                            }}
                          >
                            <Minus />
                          </div>
                          <div className="now-people font__body_md_m">{table.max}명</div>
                          <div
                            className="people-plus sign-icon"
                            onClick={() => {
                              if (getValues(maxTarget) >= 20) return;
                              setValue(maxTarget, getValues(maxTarget) + 1);
                            }}
                          >
                            <Plus />
                          </div>
                        </div>
                      </div>
                      {getValues('table_groups').length > 1 ? (
                        <div className="group-selector">
                          <Select
                            onClick={() => {
                              onClickChangeTableGroup(group_idx, table_idx);
                            }}
                            value="그룹 이동하기"
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
              <button className={'table_group_addtable'} onClick={() => addNewTable(group_idx)}>
                테이블 추가
              </button>
            </div>
          ))}
        </>
        <button className={classnames('addTableGroup')} onClick={() => addNewTableGroup()}>
          그룹 추가
        </button>
      </div>
      <div className="tutorial-table-content__footer"></div>

      <div className="button-container" style={whileInput ? { display: 'none' } : {}}>
        <button onClick={instantDisabler(handleSubmit((data) => submit(data)))}>확인했어요</button>
      </div>
      <button className={classnames('scrollbottom', scrollState)} onClick={scrollTo}>
        <Path />
      </button>
      {/* {showLayout === 1 ? <ScrollNote onScroll={() => setShowLayout(2)} /> : null} */}
      <ModalPortal>
        <>
          {showLayout === 0 ? (
            <div className={'modal-wrapper animation check'}>
              <div className="modal-dim" />
              <div className="modal-content font__body_md">
                <div className="title font__subtitle">
                  <span className="modal_title_span">모든 테이블 4인석 세팅</span>
                  <Close_BoottoMenu onClick={() => setShowLayout(1)} />
                </div>
                <div className="tabletCustomInformationMessage">
                  우선 모든 테이블을 4인석으로 세팅해둘게요. <br />
                  수정이 필요하면 다음 페이지에서 진행해주세요.
                </div>
                <div className="button-wrapper">
                  <button className={'tblm-btn-primary'} onClick={() => setShowLayout(1)}>
                    확인했어요
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          {showLayout === 2 ? (
            <Modal.Menu key="group-picker" modal="menu" isDim={true} isAnimation={true} title="그룹 이동하기">
              <div className="picker-body modal-content">
                <ScrollPicker
                  optionGroups={{ 0: { list: getValues('table_groups').map((group) => group.name) } }}
                  valueGroups={{ 0: selectedGroup }}
                  onChange={(_, g) => {
                    setSelectedGroup(g);
                  }}
                />
              </div>
              <div className="picker-footer">
                <button
                  className="set-button tblm-button-normal tblm-btn-primary"
                  onClick={() => {
                    setValue(
                      'table_groups',
                      getValues('table_groups').map((group) => {
                        if (group.name === selectedGroup) {
                          group.tables = [...group.tables, getValues(`table_groups.${sourceTable.group_idx}.tables`).splice(sourceTable.table_idx, 1)[0]];
                        }
                        return group;
                      })
                    );
                    setTableGroups(getValues('table_groups'));

                    setMenuModal({ visible: false, key: 'group-picker' });
                    setMenuModal({ visible: false, key: 'make-new-table' });
                  }}
                >
                  이동
                </button>
              </div>
            </Modal.Menu>
          ) : null}
          {showLayout === 3 ? (
            <Modal.Menu key="change-group-name" modal="menu" isDim={true} isAnimation={true} isClose={true} title="그룹 이름 수정하기">
              <div className="add-group-modal-wrapper">
                <ControlledInput control={control} name={`table_groups.${selectedGroupIdx}.name`} type="text" placeholder="그룹 이름" />
              </div>
              <FooterSub isVisible isBackArea>
                <button
                  className="tblm-button-normal tblm-btn-primary w-full"
                  onClick={() => {
                    // eslint-disable-next-line no-prototype-builtins
                    if (!errors.hasOwnProperty('table_groups')) {
                      setTimeout(() => {
                        document.querySelector('.tutorial-table-content__body').parentElement.dispatchEvent(new CustomEvent('scroll'));
                      }, 0);
                      setMenuModal({ key: 'change-group-name', visible: false });
                      setShowLayout(-1);
                      setTableGroups(getValues('table_groups'));
                    }
                  }}
                >
                  완료
                </button>
              </FooterSub>
            </Modal.Menu>
          ) : null}
        </>
      </ModalPortal>
    </>
  );
};

export default TutorialTable;
