import React from 'react';
import { useParams } from 'react-router-dom';
import KakaoAlimtalkMessage from '@/components/KakaoAlimtalkMessage';
import { ReactComponent as KakaoWaitingReady } from '@/assets/images/icons/kakao_waiting_ready.svg';
import { ReactComponent as KakaoWaitingEnter } from '@/assets/images/icons/kakao_waiting_enter.svg';
import { ReactComponent as KakaoWaitingCanceled } from '@/assets/images/icons/kakao_waiting_canceled.svg';
import { useKakaoAlimtalkStore } from '@/stores/common/useKakaoAlimtalkStore';

const messageProps = {
  create: {
    messages: {
      guideMessage: `안녕하세요, 고객님.
고객님의 대기가 접수되었습니다.`,
      initMessage: `변동 사항이 발생하신 경우 매장으로 전화주시기 바랍니다.`,
      warningMessage: '-매장 연락처 : (매장 연락처)',
      toastMessage: '대기 등록 메시지가 저장되었습니다.',
    },
    saveNavigateUrl: '/setting/kakao-waiting-message',
    title: '대기 등록',
  },
  waiting: {
    messages: {
      guideMessage: `안녕하세요. [(상호명)] 입니다.

대기번호 (00)번 고객님, 고객님 앞 대기팀이 (00)팀 남았습니다.`,
      initMessage: `곧 입장 순서가 다가오니, 매장 앞에서 대기해 주시기 바랍니다.

감사합니다.`,
      toastMessage: '입장 대기 메시지가 저장되었습니다.',
    },
    image: KakaoWaitingReady,
    saveNavigateUrl: '/setting/kakao-waiting-message',
    title: '입장 대기',
  },
  remind: {
    messages: {
      guideMessage: `안녕하세요. [(상호명)] 입니다.

대기번호 (00)번 고객님, 지금 입장해주세요.`,
      initMessage: ``,
      toastMessage: '입장 가능 메시지가 저장되었습니다.',
    },
    image: KakaoWaitingEnter,
    saveNavigateUrl: '/setting/kakao-waiting-message',
    title: '입장 가능',
  },
  cancel: {
    messages: {
      guideMessage: `안녕하세요. [(상호명)] 입니다.

대기번호 (00)번 고객님, 접수하신 대기내역이 매장사유로 인해 취소되었습니다.`,
      toastMessage: '매장 취소 메시지가 저장되었습니다.',
    },
    image: KakaoWaitingCanceled,
    saveNavigateUrl: '/setting/kakao-waiting-message',
    title: '매장 취소',
  },
};

const KakaoWaitingMessage = () => {
  const { type } = useParams();
  const { alimtalk } = useKakaoAlimtalkStore();

  if (!messageProps[type]) {
    throw new Response('Not Found', { status: 404 });
  }

  messageProps[type].type = 'waiting-' + type;
  messageProps[type].messages.initMessage = alimtalk.waiting[type]?.customMessage;
  return <KakaoAlimtalkMessage {...messageProps[type]} />;
};

export default KakaoWaitingMessage;
