import * as yup from 'yup';
export const reservationSchema = yup.object().shape({
  visitDate: yup.mixed().test('required', '예약 날짜를 입력해 주세요.', (value) => {
    const result = Boolean(typeof value?.format('YYYY-MM-DD') === 'string');
    return result;
  }),
  visitTime: yup.mixed().test('required', '예약 시간을 입력해 주세요.', (value) => {
    const result = Number(value?.hour() * 60 + value?.minute()) > 0 || Number(value?.hour() * 60 + value?.minute()) <= 1440;
    return result;
  }),
  parties: yup.array().test('partyMinlen', '인원을 입력해 주세요.', (value) => {
    const checkSize = value.map((item) => Boolean(item.size));

    return checkSize.length ? checkSize.includes(true) : false;
  }),
  // rooms: yup.array().test('roomsMinLen', '인원과 테이블을 입력해 주세요.', (value) => Boolean(value.length)),
  //userInfo-------------------------------------------------------------------------
  userId: yup.string().required('예약 고객 정보를 입력해 주세요.'),
  userName: yup.string().required('이름을 입력하세요.'),
  userPhone: yup
    .string()
    .required('전화번호를 입력하세요.')
    .test('userPhone', '숫자만 입력 가능합니다.', (value) => {
      if (value.length) return /[0-9.;\\-]/.test(value.replace(/[^0-9]/g, ''));
      return true;
    }),
  userNickname: yup.string(),
  userMemo: yup.string(),
  userEmail: yup.string().test('userEmail', '이메일 형식을 지켜 입력해 주세요.', (value) => (value.length ? /\S+@\S+\.\S+/.test(value) : true)),
  userExtravalues: yup.array(
    yup.object({
      id: yup.string(),
      name: yup.string(),
      value: yup.mixed().when(['required', 'type'], {
        is: (required, type) => {
          if (required) {
            if (type === 'string' || type === 'select' || type === 'multiselect' || type === 'textarea' || type === 'user' || type === 'text' || type === 'longtext') {
              return true;
            }
          }
          return false;
        },
        then: yup
          .string()
          .transform((value: string, originalValue) => (originalValue ? value : null))
          .nullable()
          .required('필수 입니다'),
        otherwise: yup.mixed().when(['required', 'type'], {
          is: (required, type) => required && type === 'toggle',
          then: yup.boolean(),
          otherwise: yup.mixed().when(['required', 'type'], {
            is: (required, type) => required && type === 'multiselect',
            then: yup.array(),
            otherwise: yup.mixed().when(['required', 'type'], {
              is: (required, type) => required && type === 'number',
              then: yup.number(),
            }),
          }),
        }),
      }),
    })
  ),
  // reservationInfo------------------------------------------------------------------
  reservationName: yup.string(),
  reservationRequests: yup.string(),
  reservationMemo: yup.string(),
  reservationExtravalues: yup.array(
    yup.object({
      id: yup.string(),
      name: yup.string(),
      value: yup.mixed().when(['required', 'type'], {
        is: (required, type) => {
          if (required) {
            if (type === 'string' || type === 'select' || type === 'multiselect' || type === 'textarea' || type === 'user' || type === 'text' || type === 'longtext') {
              return true;
            }
          }
          return false;
        },
        then: yup
          .string()
          .transform((value: string, originalValue) => (originalValue ? value : null))
          .nullable()
          .required('필수 입니다'),
        otherwise: yup.mixed().when(['required', 'type'], {
          is: (required, type) => required && type === 'toggle',
          then: yup.boolean(),
          otherwise: yup.mixed().when(['required', 'type'], {
            is: (required, type) => required && type === 'multiselect',
            then: yup.array(),
            otherwise: yup.mixed().when(['required', 'type'], {
              is: (required, type) => required && type === 'count',
              then: yup.number(),
            }),
          }),
        }),
      }),
    })
  ),
  // depositInfo----------------------------------------------------------------------
  // useDeposit: yup.boolean().required('필수'),
  // depositAmount: yup.mixed().when('useDeposit', {
  //   is: (useDeposit) => useDeposit,
  //   then: yup.string().required('예약금을 입력해주세요.'),
  // }),
  // depositPaymentType: yup.mixed().when('useDeposit', {
  //   is: (useDeposit) => useDeposit,
  //   then: yup.string().required('결제 타입을 입력해주세요.'),
  // }),
  // depositDue: yup.mixed().when('useDeposit', {
  //   is: (useDeposit) => useDeposit,
  //   then: yup.string(),
  // }),
  // depositEmailLanguage: yup.mixed().when('useDeposit', {
  //   is: (useDeposit) => useDeposit,
  //   then: yup.string().required('메일 언어를 선택 해주세요.'),
  // }),
});
