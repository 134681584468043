import React, { ChangeEvent, useRef, useState } from 'react';
import './KakaoInput.scss';
import { ReactComponent as IcDelete } from '@/assets/images/icons/ic_delete.svg';
import { IKakaoInputType } from '@/components/KakaoInput/KakaoInput.type';
import classNames from 'classnames';

const KakaoInput = (props: IKakaoInputType) => {
  const { value, setValue, disabled, regex, maxLength, placeholder } = props;
  const ref = useRef<HTMLInputElement>();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.trimStart().replace(regex, '');
    if (maxLength && input.length > maxLength) return;
    setValue?.call(this, input);
  };

  const onFocus = (e) => {
    setIsFocused(true);
    document.querySelector('.tutorial-content-container').scrollTop += e.target.getBoundingClientRect().top - 189;
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    setValue?.call(this, e.target.value.trimEnd());
  };

  return (
    <label className={classNames('kakao-input', { disabled: disabled || !setValue })}>
      <input
        className="kakao-input-field"
        ref={ref}
        value={value ?? ''}
        placeholder={placeholder}
        disabled={disabled || !setValue}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        style={{ lineHeight: '1.5' }}
      />
      {isFocused && value?.length > 0 && (
        <button className="kakao-input-clear-btn" onMouseDown={(e) => e.preventDefault()} onClick={() => setValue?.call('')}>
          <IcDelete />
        </button>
      )}
    </label>
  );
};

export default KakaoInput;
