import React, { useRef, useState } from 'react';
import { ReactComponent as Close_Small } from '@/assets/images/icons/Close_Small.svg';
import { ReactComponent as InfoIcon } from '@/assets/images/icons/Info.svg';
import styles from './InfoTooltip.module.scss';

const InfoTooltip = ({ message }) => {
  const [isOpen, setIsOpen] = useState(false);
  const otherSection = useRef();

  const handleOuterClick = (e) => {
    setIsOpen(otherSection.current !== e.target);
  };

  return (
    <>
      <div className={styles.info_tooltip}>
        <InfoIcon className={styles.info_tooltip__icon} onClick={() => setIsOpen(true)} />
        {isOpen && (
          <>
            <div className={styles.outside_tooltip} ref={otherSection} onClick={handleOuterClick} />
            <section className={styles.tooltip_message}>
              <p>{message}</p>
              <Close_Small className={styles.tooltip_message__close} onClick={() => setIsOpen(false)} />
            </section>
          </>
        )}
      </div>
    </>
  );
};

export default InfoTooltip;
