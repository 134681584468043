/* eslint-disable max-lines-per-function */
import classnames from 'classnames';
import groupBy from 'lodash/groupBy';
import React, { useCallback, useEffect, useId, useMemo, useState } from 'react';
import { ReactComponent as Arrow_Right } from '@/assets/images/icons/Arrow_Right.svg';
import HeaderSub from '@/components/HeaderSub/HeaderSub';
import InfoTooltip from '@/components/InfoTooltip/InfoTooltip';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Picker from '@/components/Picker/Picker';
import Select from '@/components/Select/Select';
import { timeFilter, timesMaker } from '@/shared/lib/functions';
import { Nullable } from '@/shared/utils/common.type';
import { useMallStore } from '@/stores/common/useMallStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useReservationSettingStore } from '@/stores/common/useReservationSettingStore';
import { ReactComponent as Back } from '@/assets/images/icons/back.svg';
import FooterSub from '../FooterSub/FooterSub';
import HeaderBack from '../HeaderBack/HeaderBack';

const timeUnitList = [10, 30, 60];
const daylist = ['월', '화', '수', '목', '금', '토', '일'];
const daylistMap = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const ReceiveReservation = ({ commit }): React.ReactElement => {
  const { mallTime } = useMallStore();
  const mallTimeMap = daylistMap.map((d) => mallTime[d]);
  const mallTimeCloseMap = mallTimeMap.map((t, idx) => (!t.isOpen ? idx : -1)).filter((i) => i >= 0);
  // const navigate = useNavigate();
  const { setAlertModal, setMenuModal } = useModalStore();
  const alertModalId = useId();
  const timeSelectModalId = useId();
  const weekSelectModalId = useId();
  const hollidayModalId = useId();

  const { storageQuestion, setStorageQuestion } = useReservationSettingStore();
  const [term, setTerm] = useState(storageQuestion.step2_2.value as number);
  const [tmpTerm, setTmpTerm] = useState(storageQuestion.step2_2.value as number);

  // 개별 세팅 여부
  const [enableCopyAny, setEnableCopyAny] = useState(false);
  const [picker, setPicker] = useState(false);

  const handleOnPickerSuccess = (value) => {
    if (value.leftArea === term) return;
    setTmpTerm(value.leftArea);
    setAlertModal({ visible: true, key: alertModalId });
    setPicker(false);
  };

  const closeAlert = () => {
    setAlertModal({ visible: false, key: alertModalId });
    setMenuModal({ visible: false, key: timeSelectModalId });
    // eslint-disable-next-line no-use-before-define
  };

  const handleOnClickAlertModal = () => {
    setAlertModal({ visible: false, key: alertModalId });
    setMenuModal({ visible: true, key: timeSelectModalId });
  };

  const storedValue = storageQuestion.step3_0.value || {
    enabledTimeList: daylist.map((key) => [key, []]),
    copyList: daylist.map((key) => [key, []]),
    dayIndex: 0,
    showType: 0,
    ynList: daylist.map((key) => [key, null]),
    step: [],
  };

  const [copyDayList, setCopyDayList] = useState([]);

  // @ts-ignore
  const [enabledTimeList, setEnabledTimeList] = useState<[string | number, Nullable<any[] | any>][]>(storedValue.enabledTimeList);
  // @ts-ignore
  const [copyList, setCopyList] = useState<[string | number, Nullable<any[] | any>][]>(storedValue.copyList);
  // @ts-ignore
  const [dayIndex, setDayIndex] = useState(storedValue.dayIndex);
  // @ts-ignore
  const [showType, setShowType] = useState<number>(storedValue.showType);
  // @ts-ignore
  const [ynList, setYnList] = useState<[string | number, Nullable<any[] | any>][]>(storedValue.ynList);
  // @ts-ignore
  const [step, setStep] = useState(storedValue.step);

  const resetStore = () => {
    storageQuestion.step2_2.value = term;
    storageQuestion.step3_0.value = {
      // @ts-ignore
      enabledTimeList: daylist.map((key) => [key, []]),
      copyList: daylist.map((key) => [key, []]),
      dayIndex: 0,
      showType: 0,
      ynList: daylist.map((key) => [key, null]),
      step: [],
    };
    // @ts-ignore
    setEnabledTimeList(storageQuestion.step3_0.value.enabledTimeList);
    // @ts-ignore
    setCopyList(storageQuestion.step3_0.value.copyList);
    // @ts-ignore
    setDayIndex(storageQuestion.step3_0.value.dayIndex);
    // @ts-ignore
    setShowType(storageQuestion.step3_0.value.showType);
    // @ts-ignore
    setYnList(storageQuestion.step3_0.value.ynList);
    // @ts-ignore
    setStep(storageQuestion.step3_0.value.step);
    setStorageQuestion(storageQuestion);
  };
  const timeListFactory = (e) => {
    // @ts-ignore
    const l = JSON.parse(JSON.stringify(e));
    // @ts-ignore
    for (const dayIdx in copyList) {
      // @ts-ignore
      let day = copyList[dayIdx];
      for (const i of day[1]) {
        l[i][1] = [...l[dayIdx][1]];
      }
    }
    return l;
  };

  const commitChange = () => {
    const e = Array.from(new Set(enabledTimeList)).map(([a, b]) => [a, b.sort()]);
    // @ts-ignore
    setEnabledTimeList(e);
  };

  const commitToOrigin = () => {
    storageQuestion.step3_0.value = {
      // @ts-ignore
      enabledTimeList: timeListFactory(enabledTimeList),
      copyList: daylist.map((key) => [key, []]),
      dayIndex: 0,
      showType: 0,
      ynList: daylist.map((key) => [key, null]),
      step: [],
    };
    commit();
  };

  const submit = () => {
    closeAlert();
  };

  const prev = () => {
    if (showType === 0) {
      if (dayIndex === 0) {
        storageQuestion.step3_0.value = {
          // @ts-ignore
          enabledTimeList: daylist.map((key) => [key, []]),
          copyList: daylist.map((key) => [key, []]),
          dayIndex: 0,
          showType: 0,
          ynList: daylist.map((key) => [key, null]),
          step: [],
        };
        setStorageQuestion(storageQuestion);
        return;
      }
      let lastStep = step[step.length - 1];
      if (lastStep === dayIndex) lastStep = step[step.length - 2];
      setDayIndex(lastStep === undefined ? 0 : lastStep);
      setStep((state) => state.slice(0, -1));
      setShowType(ynList[lastStep] ? 2 : 0);
      return;
    }
    setShowType((state) => state - 1);
  };

  const next = (bool) => {
    if (bool === undefined) return;
    if (showType === 0) {
      setStep((steps) => [...steps, dayIndex]);
      if (!bool) {
        if (enableCopyAny) {
          for (const idx of copyDayList) {
            enabledTimeList[idx][1] = [];
          }
          commitChange();
          closeAlert();
          setShowType(0);
          return;
        }
        setYnList((state) => {
          state[dayIndex][1] = false;
          return state;
        });
        setCopyList((state) => {
          state[dayIndex][1] = [];
          return state;
        });
        enabledTimeList[dayIndex][1] = [];
        commitChange();
        if (dayIndex === 6) {
          submit();
          return;
        }
        let remainDays = [0, 1, 2, 3, 4, 5, 6]
          .filter((d) => d > dayIndex)
          .filter((d) => !mallTimeCloseMap.includes(d))
          .find((d) => copyList.every(([_, list]) => !list.includes(d)));
        if (!remainDays) {
          submit();
        } else {
          setDayIndex(remainDays);
        }
      } else {
        setYnList((state) => {
          state[dayIndex][1] = true;
          return state;
        });
        setShowType(1);
      }
      return;
    }
    if (showType === 1) {
      if (enableCopyAny) {
        for (const copy of copyList) {
          copy[1] = copy[1].filter((idx) => !copyDayList.includes(idx));
        }
        setCopyList(copyList);
        for (const idx of copyDayList) {
          enabledTimeList[idx][1] = [...enabledTimeList[dayIndex][1]];
        }
        commitChange();
        closeAlert();
        setShowType(0);
        return;
      }
      let remainDays = [0, 1, 2, 3, 4, 5, 6]
        .filter((d) => d > dayIndex)
        .filter((d) => !mallTimeCloseMap.includes(d))
        .find((d) => copyList.every(([_, list]) => !list.includes(d)));
      if (!remainDays) {
        submit();
      } else {
        setDayIndex(remainDays);
      }
      setShowType(0);
    }
  };

  const times = timesMaker(term, mallTimeMap[dayIndex]);
  const dayTimeGroup = groupBy(times, (time) => time.split(':')[0]);
  const dayTimeList = Object.entries(dayTimeGroup).sort((a, b) => Number(a[0]) - Number(b[0]));

  const allCheck = (e) => {
    if (e.target.checked) {
      enabledTimeList[dayIndex][1] = [...times];
    } else {
      enabledTimeList[dayIndex][1] = [];
    }
    commitChange();
  };

  const hourChecked = (hour) => {
    return dayTimeGroup[hour].every((time) => enabledTimeList[dayIndex][1].includes(time));
  };
  const minuteChecked = (time) => {
    return enabledTimeList[dayIndex][1].includes(time);
  };

  const [backupCurrentDay, setBackupCurrentDay] = useState([]);

  const revertChange = () => {
    enabledTimeList[dayIndex][1] = backupCurrentDay;
    commitChange();
    setBackupCurrentDay([]);
  };

  const hourCheck = (hour, checked) => {
    if (checked) {
      enabledTimeList[dayIndex][1].push(...dayTimeGroup[hour]);
    } else {
      enabledTimeList[dayIndex][1] = enabledTimeList[dayIndex][1].filter((time) => !time.startsWith(hour));
    }
    commitChange();
  };
  const minuteCheck = (time, checked) => {
    if (checked) {
      enabledTimeList[dayIndex][1].push(time);
    } else {
      enabledTimeList[dayIndex][1] = enabledTimeList[dayIndex][1].filter((item) => item !== time);
    }
    commitChange();
  };

  const copyDayCheck = (e) => {
    if (e.target.checked) {
      if (enableCopyAny) {
        setCopyList((state) => {
          const tmpState = [...state];
          for (let dl of tmpState) {
            dl[1] = dl[1].filter((item) => item !== Number(e.target.value));
          }
          return tmpState;
        });
        setCopyDayList((state) => {
          state.push(Number(e.target.value));
          // @ts-ignore
          return Array.from(new Set(state)).sort();
        });
      } else {
        setCopyList((state) => {
          const tmpState = [...state];
          tmpState[dayIndex][1].push(Number(e.target.value));
          // @ts-ignore
          tmpState[dayIndex][1] = Array.from(new Set(tmpState[dayIndex][1])).sort();
          return tmpState;
        });
      }
    } else {
      if (enableCopyAny) {
        setCopyDayList((state) => {
          return state.filter((item) => item !== Number(e.target.value));
        });
      } else {
        setCopyList((state) => {
          const tmpState = [...state];
          tmpState[dayIndex][1] = tmpState[dayIndex][1].filter((item) => item !== Number(e.target.value));
          return tmpState;
        });
      }
    }
  };
  const isDisabledCopy = (idx) => {
    if (!enableCopyAny) {
      for (let copyDayIndex = 0; copyDayIndex < copyList.length; copyDayIndex += 1) {
        if (copyDayIndex < dayIndex && copyList[copyDayIndex][1].includes(Number(idx))) return true;
      }
    }

    return false;
  };

  const dispatchRealtime = () => {
    storageQuestion.step3_0.value = {
      // @ts-ignore
      enabledTimeList,
      copyList,
      dayIndex,
      showType,
      ynList,
      step,
    };
    // @ts-ignore
    setStorageQuestion({ ...storageQuestion });
  };

  useEffect(() => {
    dispatchRealtime();
  }, [enabledTimeList]);
  useEffect(() => {
    dispatchRealtime();
  }, [copyList]);
  useEffect(() => {
    dispatchRealtime();
  }, [dayIndex]);
  useEffect(() => {
    dispatchRealtime();
  }, [showType]);
  useEffect(() => {
    dispatchRealtime();
  }, [ynList]);
  useEffect(() => {
    dispatchRealtime();
  }, [step]);

  const openTime = (idx) => {
    const enabledTime = Array.from(document.querySelectorAll(`.reservation-time-tag-wrapper[data-idx='${idx}'] > div`)).map((e) => e.innerHTML.trim());
    enabledTimeList[idx][1] = enabledTime;
    setBackupCurrentDay([...enabledTime]);
    setCopyList((state) => {
      const tmpState = [...state];
      for (let dl of tmpState) {
        dl[1] = dl[1].filter((item) => item !== Number(idx));
      }
      return tmpState;
    });
    setEnableCopyAny(true);
    setCopyDayList([]);
    setShowType(1);
    setDayIndex(idx);
    setMenuModal({ visible: true, key: timeSelectModalId });
  };

  const unitize = (option, unit) => {
    if (unit === '층') return `${option}층`.replace('-', '지하 ');
    if (unit === '시간분') {
      const h = Math.floor(option / 60);
      const m = Math.floor(option % 60);

      let ret = '';
      ret += h > 0 ? h + '시간' : '';
      ret += h > 0 && m > 0 ? ' ' : '';
      ret += m > 0 ? m + '분' : '';

      return ret;
    }
    return option + unit;
  };

  return (
    <>
      <section className="reservation-time-unit-wrapper modaled">
        <HeaderBack inBack={true} isCenter isFixed onBack={commit}>
          <div className="font__heading_4">예약 받는 시간</div>
        </HeaderBack>
        <div className="contents">
          <div className="reservation-time-unit pointer">
            <div className="left-area">
              <div className="font__body_3">
                예약 시간 단위
                <InfoTooltip message={'예약 시간 단위를 바꾸면 \n예약 받는 시간을 다시 설정해야 해요.'} />
              </div>
            </div>
            <div className="right-area gap-16">
              <Select
                value={unitize(term, '시간분')}
                style={{ maxWidth: 130 }}
                onClick={() => {
                  setPicker(true);
                }}
              />
            </div>
          </div>
          {timeListFactory(enabledTimeList).map(([day, list], i) => (
            <div className={classnames('reservation-time-wrapper', { holiday: list.length === 0 })} key={day}>
              <span className="flex-container-center flex-space-between">
                {day}{' '}
                {list.length > 0 ? (
                  <Arrow_Right
                    onClick={() => {
                      openTime(i);
                    }}
                  />
                ) : null}
              </span>
              {list.length > 0 ? (
                <div className="reservation-time-tag-wrapper" data-idx={i}>
                  {list.map((v) => (
                    <div className="reservation-time-tag font__body_sm roboto" key={`${day}_${v}`}>
                      {v}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="font__body_6 flex-container-center flex-space-between w-full">
                  {mallTimeCloseMap.includes(i) ? (
                    <>
                      휴무일
                      <Arrow_Right
                        onClick={() => {
                          setDayIndex(i);
                          setMenuModal({ visible: true, key: hollidayModalId });
                        }}
                      />
                    </>
                  ) : (
                    <>
                      예약받는 시간이 없는 날{' '}
                      <Arrow_Right
                        onClick={() => {
                          openTime(i);
                        }}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="button-container">
          <button
            className="next"
            onClick={() => {
              commitToOrigin();
            }}
          >
            확인
          </button>
        </div>
      </section>
      {picker && <Picker
        visible={picker}
        title="예약 시간 단위"
        tabletCustomInformationMessage="예약을 몇 분 간격으로 받으시겠어요?"
        modalKey="reservation-time-picker-modal"
        leftArea={{ list: timeUnitList, selected: term, unit: '시간분' }}
        onSuccess={handleOnPickerSuccess}
        onClose={() => setPicker(false)}
      />}
      <ModalPortal>
        <Modal.Alert
          key={alertModalId}
          modal="alert"
          isDim
          isAnimation
          modalData={{
            title: '예약 시간 단위를 변경할까요?',
            content: '시간 단위가 바뀌면\n예약 받는 시간을 다시 설정해야 해요.',
            buttons: [
              {
                text: '취소',
                onClick: () => closeAlert(),
              },
              {
                text: '변경',
                success: true,
                onClick: () => {
                  setTerm(tmpTerm);
                  resetStore();
                  handleOnClickAlertModal();
                },
              },
            ],
          }}
        />
        <Modal.Menu key={timeSelectModalId} modal="menu" isDim isAnimation onClose={closeAlert} title={`${daylist[dayIndex]}요일 예약 받을 시간`}>
          <div className="content">
            <div className="reservation-time-select-wrapper receive-reservation-wrapper" key={dayIndex}>
              <div className="time-select-header">
                {daylist[dayIndex]}요일에 예약 받을 시간을 모두 골라주세요.
                <button
                  className="tblm-button-normal w-full"
                  onClick={() => {
                    enabledTimeList[dayIndex][1] = [];
                    commitChange();
                    next(false);
                  }}
                >
                  {daylist[dayIndex]}요일 예약 안 받기
                </button>
                <label className="tblm-rc font__heading_6">
                  <input type="checkbox" name="switch_example" onChange={allCheck} checked={enabledTimeList[dayIndex][1].length === times.length} />
                  <i />
                  전체선택
                </label>
              </div>
              <div className="time-select-content">
                {dayTimeList.map(([hour, timeArray]) => {
                  return (
                    <div className="time-select-item" key={hour}>
                      <label className="tblm-rc">
                        <input type="checkbox" name="day" checked={hourChecked(hour)} onChange={(e) => hourCheck(hour, e.target.checked)} />
                        <i />
                        <span>{hour}시</span>
                      </label>
                      <div className="times">
                        {timeArray.map((time) => (
                          <label className={'time-item'} key={time}>
                            <input type="checkbox" name="time" checked={minuteChecked(time)} onChange={(e) => minuteCheck(time, e.target.checked)} />
                            <div>{time}</div>
                          </label>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <FooterSub isVisible isBackArea>
              <button
                className="tblm-button-normal tblm-btn-primary w-full"
                onClick={() => {
                  setMenuModal({ visible: false, key: timeSelectModalId });
                  setMenuModal({ visible: true, key: weekSelectModalId });
                  let remainDays = [0, 1, 2, 3, 4, 5, 6].filter((d) => d > dayIndex).find((d) => copyList.every(([_, list]) => !list.includes(d)));
                  if (!remainDays) {
                    submit();
                  } else {
                    setShowType(1);
                  }
                }}
              >
                완료
              </button>
            </FooterSub>
          </div>
        </Modal.Menu>
        <Modal.Menu key={weekSelectModalId} modal="menu" isDim isAnimation onClose={closeAlert} title="수정사항 공통 적용">
          <div className="content">
            <div className="week-content-wrapper receive-reservation-wrapper">
              수정사항을 똑같이 적용할 요일이 있나요?
              <div className="week-select-content">
                {daylist.map((day, idx) => (
                  <div className={classnames('week-select-item', { active: idx === dayIndex })} key={idx}>
                    <label className="tblm-rc">
                      {dayIndex <= idx ? (
                        <>
                          <input
                            type="checkbox"
                            name="day"
                            readOnly={!enableCopyAny && idx <= dayIndex}
                            disabled={dayIndex !== idx && isDisabledCopy(idx)}
                            value={idx}
                            checked={!enableCopyAny ? copyList[dayIndex][1].includes(idx) : copyDayList.includes(idx)}
                            onChange={copyDayCheck}
                          />
                          <i />
                        </>
                      ) : (
                        <>
                          <input type="checkbox" name="day" readOnly={idx <= dayIndex} disabled={isDisabledCopy(idx)} value={idx} />
                          <i />
                        </>
                      )}
                      <span>{day}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <FooterSub isVisible isBackArea>
              {/*
            <button
              className="tblm-button-normal tblm-btn-gray"
              onClick={() => {
                prev();
              }}
            >
              이전
            </button>*/}
              <button
                className="tblm-button-normal tblm-btn-primary w-full"
                onClick={() => {
                  closeAlert();
                  handleOnClickAlertModal();
                  next(true);
                }}
              >
                다음
              </button>
            </FooterSub>
          </div>
        </Modal.Menu>
        {/*schedule loop*/}
        <Modal.Menu key={hollidayModalId} modal="menu" isDim isAnimation onClose={closeAlert} title={`${daylist[dayIndex]}요일은 휴무일이에요.`}>
          <div className="content">
            {`휴무 일정은 예약 설정이 끝난 후
가게 관리에서 변경할 수 있어요.`}
            <FooterSub isVisible isBackArea>
              <button
                className="tblm-button-normal tblm-btn-primary w-full"
                onClick={() => {
                  closeAlert();
                }}
              >
                확인
              </button>
            </FooterSub>
          </div>
        </Modal.Menu>
      </ModalPortal>
    </>
  );
};

export default ReceiveReservation;
