import React from 'react';
import { Form } from '@/pages/waiting/tutorial';
import { ReactComponent as ArrowDown } from '@/assets/images/icons/Arrow_Down.svg';
import BottomScrollPicker from '@/components/BottomScrollPicker/BottomScrollPicker';

interface TutorialStep2Props {
  form: Form;
  setForm: (key: keyof Form, value: any) => () => void;
}

const MIN_OPTION_GROUPS = Array.from({ length: 10 }, (_, i) => `${i + 1}명`);

const MAX_OPTION_GROUPS = Array.from({ length: 51 }, (_, i) => {
  if (i === 0) return '제한없음';
  return `${i}명`;
});

const TutorialStep2: React.FC<TutorialStep2Props> = ({ form, setForm }) => {
  const [isPickerOpen, setIsPickerOpen] = React.useState({
    min: false,
    max: false,
  });
  const [tempMinPeople, setTempMinPeople] = React.useState(form.minPeople);
  const [tempMaxPeople, setTempMaxPeople] = React.useState(form.maxPeople);
  return (
    <>
      <div className="p-5 flex flex-col mb-[40px]">
        <p className="text-zinc-800 text-[28px] font-medium mb-[8px] leading-10">
          대기 접수 인원을
          <br />
          제한할 수 있어요.
        </p>
        <p className="text-zinc-500 text-sm leading-5">
          대기 접수가 가능한 최대·최소 인원수를
          <br />
          설정해주세요.
        </p>
      </div>
      <div className="p-5 flex flex-col gap-y-3 text-zinc-800 text-xl font-medium">
        <div className="flex gap-x-3 items-center">
          <span>최소</span>
          <div
            onClick={() => {
              setIsPickerOpen((prev) => ({
                ...prev,
                min: true,
              }));
            }}
            className="text-sm font-medium w-[120px] justify-between flex px-3 py-2 gap-2 rounded border border-stone-300 items-center"
          >
            <span>{form.minPeople.split('명')[0]}</span>
            <ArrowDown className="w-6 h-6" />
          </div>
          <span>명 부터</span>
        </div>
        <div
          onClick={() => {
            setIsPickerOpen((prev) => ({
              ...prev,
              max: true,
            }));
          }}
          className="flex gap-x-3 items-center"
        >
          <span>최대</span>
          <div className="text-sm font-medium w-[120px] justify-between flex px-3 py-2 gap-2 rounded border border-stone-300 items-center">
            <span>{form.maxPeople.split('명')[0]}</span>
            <ArrowDown className="w-6 h-6" />
          </div>
          <span>명 까지</span>
        </div>
        <p>대기 접수를 받을게요.</p>
      </div>
      <BottomScrollPicker
        title="최소 인원수"
        onDismiss={() => {
          setIsPickerOpen((prev) => ({
            ...prev,
            min: false,
          }));
        }}
        value={form.minPeople}
        isOpen={isPickerOpen.min}
        list={MIN_OPTION_GROUPS}
        onSelectedSave={(selected) => {
          setForm('minPeople', selected)();
        }}
      />
      <BottomScrollPicker
        value={form.maxPeople}
        title="최대 인원수"
        onDismiss={() => {
          setIsPickerOpen((prev) => ({
            ...prev,
            max: false,
          }));
        }}
        isOpen={isPickerOpen.max}
        list={MAX_OPTION_GROUPS}
        onSelectedSave={(selected) => setForm('maxPeople', selected)()}
      />
    </>
  );
};

export default TutorialStep2;
