import { devServerApi } from '@/shared/apis/devServerApi';
import { promiseSeq } from '@/shared/utils/promiseUtil';
import Terces from '@/shared/apis/terces';

const daylist = ['월', '화', '수', '목', '금', '토', '일'];
export const dayNames = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
export const dayNamesTypeB = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const minuteToTimeString = (minute) => {
  return `${String(Math.floor(minute / 60)).padStart(2, '0')}:${String(minute % 60).padStart(2, '0')}`;
};

export const tableTutorialApi = (table_groups) => {
  const requestBody = [];
  requestBody.push(
    devServerApi.api.post(
      '/settings/lg/groups/bulk',
      table_groups.map((g) => ({ name: g.name, tableList: g.tables.map((table) => ({ name: table.name, peopleMin: table.min, peopleMax: table.max })) }))
    )
  );
  requestBody.push(devServerApi.api.patch('/tutorial', { status: 'reservation' }));
  return Promise.allSettled(requestBody);
};

export const reservationTutorialApi = (data) => {
  const bookingToday = data.step1_0.value ? 'Y' : 'N';
  const bookingDeadLine = data.step2_0.value ? data.step2_1.value[0] * 60 + data.step2_1.value[1] : 0;
  const maxAvailableTime = data.step2_3.value;
  const bookingAvailableDays = data.step4_0.value;
  const reservationTimeUnit = data.step2_2.value;
  const list = Array.from(document.querySelectorAll('.timelist > ul > li')).map((e) => [
    e.querySelector('p').innerText,
    Array.from(e.querySelectorAll('button')).map((d) => d.innerText),
  ]);
  const reservationDays = Object.fromEntries(
    data.step3_0.value.enabledTimeList.map(([day, l], idx) => [
      dayNames[daylist.indexOf(day)],
      {
        reservation: l.length > 0 ? 1 : 0,
        bookingTimes: l || [],
      },
    ])
  );
  return Promise.allSettled([
    devServerApi.api.patch('/policy/reservations', {
      bookingToday,
      bookingDeadLine,
      maxAvailableTime,
      bookingAvailableDays,
      reservationDays,
      reservationTimeUnit,
    }),
    devServerApi.api.patch('/tutorial', { status: 'done' }),
  ]);
};
export const essentialTutorialApi = async (basicData) => {
  const data = await devServerApi.api
    .get('/settings/mall')
    .then((e) => e.data)
    .then((enc_res) => Terces.decrypt(enc_res));
  devServerApi.api.patch('/settings/tutorial', { ...data, foods: basicData.foods.map((e) => e.type_food_id), facility: basicData.facility.map((e) => e.type_facility_id) });
  devServerApi.api.patch('/tutorial', { status: 'table' });
};

export const kakaoTutorialApi = (data, done = true) => {
  let promise = devServerApi.lg.post('/api/partner/kakao/alimtalk/tutorial', data).then(() =>
    devServerApi.lg.post('/api/partner/kakao/alimtalk/setting', {
      setting: {
        toggleOurMall: true,
        toggleKakaoSendMsg: true,
      },
    })
  );

  if (done) {
    promise = promise.then(() => devServerApi.api.patch('/tutorial', { status: 'done' }));
  }

  return promise;
};

export const businessTutorialApi = (data) => {
  return Promise.allSettled([
    devServerApi.lg.put('/api/partner/mall/update', { mallId: data.mallId, bizName: data.companyName, bizNumber: data.eid, bizOwner: data.representative }),
    devServerApi.api.patch('/tutorial', { status: 'essential' }),
  ]);
};

export const getApi = (api) => {
  if (api === 'table') {
    return tableTutorialApi;
  } else if (api === 'reservation') {
    return reservationTutorialApi;
  } else if (api === 'essential') {
    return essentialTutorialApi;
  } else if (api === 'kakao') {
    return kakaoTutorialApi;
  } else if (api === 'business') {
    return businessTutorialApi;
  }
  return () => null;
};
