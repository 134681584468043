import React from 'react';

import { ReactComponent as Call } from '@/assets/images/icons/call_full+bg.svg';
import { ICard } from './Card.type';
import { TIME_FORMAT } from './Card.constants';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { reservationStateListKorean } from '@/shared/utils/common.type';
import styles from './Card.module.scss';
import { useMallStore } from '@/stores/common/useMallStore';
import { useModalStore } from '@/stores/common/useModalStore';
import useNative from '@/hooks/useNative';

const partnerTagColorMap = {
  카카오: '#F1CB05',
  네이버: '#1EC800',
  LG우리가게: '#6931E0',
};

function partnerTag(partnerName: string) {
  if (!partnerName) {
    return null;
  }

  const partnerTagColor = partnerTagColorMap[partnerName] || '#1481DB';
  return (
    <button style={{ '--bdr': 'transparent', '--bg': partnerTagColor, '--txt': '#ffffff', '--fix': '#' } as React.CSSProperties} className={classnames('tblm-tag-color-user')}>
      #{partnerName}
    </button>
  );
}

const Card = ({ reservation, cardIdx, className, isModal = false, onActivate }: ICard): React.ReactElement => {
  const { reservationMarkingColors, rooms: storeRooms, menus: storeMenus } = useMallStore((state) => state.mall);
  const { setMenuModal } = useModalStore();
  const { callAlert } = useNative();
  const {
    createdAt,
    reservationMarkingColorId,
    userName,
    userPhone,
    userExtravalues,
    visitDate,
    visitTime,
    parties,
    rooms,
    userNickname,
    reservationName,
    reservationMemo,
    reservationRequests,
    menus,
    reservationState,
    reservationExtravalues,
    userPastValidReservationCount,
    depositState,
    depositAmount,
    partnerName,
  } = reservation;
  const reservation_color = reservationMarkingColors.find(({ id }) => id === reservationMarkingColorId)?.color || null;
  const reservation_headcounts = parties.map(Object.values).filter(([_, cnt]) => cnt > 0);
  const reservation_table = storeRooms.filter((item) => rooms.find((subItem) => item.id === subItem.id));
  const reservation_menus = storeMenus.filter((item) => menus.find((subItem) => item.menuId === subItem.id));

  const handleOnCall = () => {
    document.location.href = `tel:${userPhone}`;
  };

  const formatted_reservation_time: string = dayjs(`${visitDate} ${Math.floor(visitTime / 60)}:${visitTime % 60}:00`).format(TIME_FORMAT);

  return (
    <>
      <div
        className={classnames(
          'card',
          styles.card,
          { [styles.card__spreaded]: true, [styles.card__active]: false, [styles.card__is_modal]: isModal },
          styles[reservationState],
          className
        )}
        onClick={() => {
          onActivate && onActivate(cardIdx);

          onActivate && setMenuModal({ key: 'reservationsModal', visible: true });
        }}
      >
        <div className={styles.card__time}>{formatted_reservation_time}</div>
        {reservationName ? <div className={styles.card__reservation_name}>{reservationName}</div> : null}
        <div className={styles.card__essential}>
          <span style={{ background: userName ? reservation_color : 'transparent' }}>
            {userName}
            <span className={styles.card__essential_phone}>{userPhone?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3')}</span>
          </span>
          {!onActivate && userPhone ? <Call className={styles.call_icon} onClick={handleOnCall} /> : null}
        </div>
        <div className={styles.card__assign}>
          <div className={styles.card__assign_headcount}>
            {reservation_headcounts.length > 1
              ? reservation_headcounts.map((headcount) => <span key={headcount[0]}>{`${headcount[0]} ${headcount[1]}명`}</span>)
              : reservation_headcounts.map((headcount) => <span key={headcount[0]}>{`인원 ${headcount[1]}명`}</span>)}
          </div>
          <div className={styles.card__assign_table}>
            <span className={styles.card__assign_table_separator} />
            <span className={styles.card__assign_table_value}>
              {!reservation_table || reservation_table.length > 0 ? reservation_table.map((item) => item.name).join(', ') : '미지정'}
            </span>
          </div>
        </div>
        <ul className={styles.card__extrainfo}>
          {userNickname ? (
            <li>
              <div className={styles.card__extrainfo_label}>별칭</div>
              <div className={styles.card__extrainfo_value}>{userNickname}</div>
            </li>
          ) : null}
          {reservation_menus.length > 0 ? (
            <li>
              <div className={styles.card__extrainfo_label}>메뉴명</div>
              <div className={styles.card__extrainfo_value}>
                {reservation_menus.map((menu) => `${menu.menuName}(${menus.find((item) => item.id === menu.menuId).count})`).join(', ')}
              </div>
            </li>
          ) : null}
        </ul>
        <div className={styles.card__tag_container}>
          {userPastValidReservationCount < 1 ? <button className={classnames('tblm-tag-tag')}>첫방문</button> : null}
          <button className={classnames('tblm-tag-tag')}>{dayjs(createdAt).format('MM월DD일')}</button>
          <button className={classnames('tblm-tag-tag')}>{reservationStateListKorean[reservationState]}</button>
          {partnerTag(partnerName)}
          {reservationMemo ? (
            <button
              className={classnames('tblm-tag-tag', 'link')}
              onClick={(e) => {
                e.stopPropagation();
                callAlert('예약메모', reservationMemo, [{ btnNm: '확인', value: '0' }]);
              }}
            >
              예약메모
            </button>
          ) : null}
          {reservationRequests ? (
            <button
              className={classnames('tblm-tag-tag', 'link')}
              onClick={(e) => {
                e.stopPropagation();
                callAlert('요청사항', reservationRequests, [{ btnNm: '확인', value: '0' }]);
              }}
            >
              요청사항
            </button>
          ) : null}
          {depositState !== 'unused' ? <button className={classnames('tblm-tag-tag', 'link')}>{depositState === 'unpaid' ? '예약금대기' : '예약금지불'}</button> : null}
          {reservationExtravalues.map((reservationExtravalue, i) =>
            reservationExtravalue.name ? (
              <button
                key={reservationExtravalue.name + String(i)}
                className={classnames(reservationExtravalue.backgroundColor || reservationExtravalue.fontColor ? 'tblm-tag-color-user' : 'tblm-tag-tag')}
                style={{ backgroundColor: reservationExtravalue.backgroundColor, color: reservationExtravalue.fontColor }}
              >
                {reservationExtravalue.name}
              </button>
            ) : null
          )}
          {userExtravalues.map((userExtravalue, i) =>
            userExtravalue.name ? (
              <button
                key={userExtravalue.name + String(i)}
                className={classnames(userExtravalue.backgroundColor || userExtravalue.fontColor ? 'tblm-tag-color-user' : 'tblm-tag-tag')}
                style={{ backgroundColor: userExtravalue.backgroundColor, color: userExtravalue.fontColor }}
              >
                {userExtravalue.name}
              </button>
            ) : null
          )}
          {depositState !== 'unused' ? <button className={classnames('tblm-tag-tag', 'link')}>{depositAmount.toLocaleString()}원</button> : null}
        </div>
      </div>
    </>
  );
};

export default Card;
