import './ManagementCalendar.scss';

import { Calendar, Day, Week } from './ManagementCalendar.type';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useDevServerApi } from '@/shared/apis/devServerApi';
import { useQuery } from 'react-query';

// const mockSchedule = [
//   { date: '2024-01-26', state: 'opened' as Day['state'] },
//   { date: '2024-01-28', state: 'opened' as Day['state'] },
//   { date: '2024-01-29', state: 'opened' as Day['state'] },
//   { date: '2024-02-02', state: 'opened' as Day['state'] },
//   { date: '2024-02-05', state: 'opened' as Day['state'] },
// ];
const ManagementCalendar = ({ startDate, targetDate, select, headerHeight = 0, holidays }): React.ReactElement => {
  const [viewDate, setViewDate] = useState<dayjs.Dayjs>(dayjs());
  const [calendarList, setCalendarList] = useState<Calendar[]>([]);
  const [scheduleList, setScheduleList] = useState<{ date?: dayjs.Dayjs | string; state?: Day['state'] }[]>([]);
  const devServerApi = useDevServerApi();
  const [readyToScroll, setReadyToScroll] = useState(false);
  const scrollElement = document.querySelector('.main-container-desktop');
  const refs = useRef([]);
  const today = dayjs();
  const period = today.add(90, 'day').diff(today, 'month') + 1;

  // const isFetching = false;
  const { isFetching } = useQuery(
    [QUERY_KEYS.SCHEDULE_CALENDAR],
    () => Promise.all(Array.from({ length: period + 1 }, (_, i) => today.add(i, 'month').format('YYYY-MM')).map((date) => devServerApi.api.authGet(`/schedule/calendar/${date}`))),
    {
      onSuccess: (ress: any) => {
        setScheduleList(ress.map((res) => res.onlineScheduleSummary.map((item) => ({ ...item, date: dayjs(item.date) }))).flat(1));
      },
    }
  );

  const makeMonthCalendar = (date: dayjs.Dayjs, datePeriod: number) => {
    let tempCalendarList = new Array<Calendar>(datePeriod).fill(null);
    tempCalendarList = tempCalendarList.map((calendar, monthIndex) => {
      const targetMonthDate = date.add(monthIndex, 'month').startOf('month');
      const firstDay = date.add(monthIndex, 'month').startOf('month').day();
      const weekOfMonth = Math.ceil((firstDay + targetMonthDate.daysInMonth()) / 7);

      let weeks = new Array<Week>(weekOfMonth).fill(null);
      weeks = weeks.map((_, weekIndex) => {
        let targetWeekDate = targetMonthDate.add(weekIndex, 'week');
        let days = new Array<Day>(7).fill(null);
        days = days.map((__, dayIndex) => {
          const targetDay = targetWeekDate.startOf('week').add(dayIndex, 'day');
          const foundSchedule = scheduleList.find((r) => targetDay.isSame(r.date, 'day')) || {};
          return {
            isToday: today.isSame(targetDay, 'day'),
            isBeforeDay: today.isBefore(targetDay, 'day'),
            isSameMonth: targetMonthDate.isSame(targetDay, 'month'),
            isBeforePeriod: today.add(90, 'day').isBefore(targetDay, 'day'),
            orderInfo: { orderCount: 0, personCount: 0 },
            targetDay,
            state: foundSchedule.state || null,
          };
        });
        return { days, targetWeek: targetWeekDate };
      });

      return { weeks, targetMonth: targetMonthDate };
    });
    setCalendarList(tempCalendarList);
  };

  const scrollToTargetDate = (date: dayjs.Dayjs) => {
    let targetCalendarIndex = calendarList.findIndex((calendar) => calendar.targetMonth.isSame(date.startOf('month'), 'day'));
    if (targetCalendarIndex > -1) {
      setTimeout(() => {
        if (refs.current[targetCalendarIndex] && refs.current[targetCalendarIndex].scrollIntoView) {
          refs.current[targetCalendarIndex].scrollIntoView();
          scrollElement.scrollTo(0, scrollElement.scrollTop - 102);
        }
        setReadyToScroll(true);
      }, 400);
    }
  };

  const onChangeViewDate = () => {
    const lastScrollTop = scrollElement.scrollTop + headerHeight + 126;
    const filteredItems = refs.current.filter((item) => lastScrollTop > item.offsetTop);
    let targetItem = filteredItems[filteredItems.length - 1];

    if (targetItem) {
      let index = Number(targetItem.id);
      setViewDate(calendarList[index].targetMonth);
    }
  };

  useEffect(() => {
    if (calendarList.length > 0) {
      scrollToTargetDate(targetDate);
      if (readyToScroll) {
        scrollElement.addEventListener('scroll', onChangeViewDate);
        return () => {
          scrollElement.removeEventListener('scroll', onChangeViewDate);
        };
      }
    }
  }, [calendarList, readyToScroll]);

  useEffect(() => {
    makeMonthCalendar(startDate, period + 2);
  }, [startDate, period, scheduleList]);

  if (!calendarList || calendarList.length === 0 || isFetching) return <SpinnerEffector loading={isFetching} />;

  return (
    <>
      <div className="calendar-sticky-header" style={{ top: headerHeight }}>
        <div className="view-date-text font__subtitle1_sb">{viewDate.format('YYYY년 MM월')}</div>
        <div className="week-header">
          <div className="week-header-item font__subtitle1_sb">일</div>
          <div className="week-header-item font__subtitle1_sb">월</div>
          <div className="week-header-item font__subtitle1_sb">화</div>
          <div className="week-header-item font__subtitle1_sb">수</div>
          <div className="week-header-item font__subtitle1_sb">목</div>
          <div className="week-header-item font__subtitle1_sb">금</div>
          <div className="week-header-item font__subtitle1_sb">토</div>
        </div>
      </div>
      {calendarList.map((calendar, index) => (
        <div
          key={index}
          className="calendar-container"
          id={index.toString()}
          ref={(element) => {
            refs.current[index] = element;
          }}
        >
          <div className="calendar-month font__subtitle1_sb">{calendar.targetMonth.format('M월')}</div>
          {calendar.weeks.map((week, i) => {
            return (
              <div className="calendar-week" key={i}>
                {week.days.map((day, j) => {
                  const isSunday = week.targetWeek.day(0).isSame(day.targetDay, 'day');
                  const isSaturday = week.targetWeek.day(6).isSame(day.targetDay, 'day');
                  const isHoliday = holidays.find((holiday) => holiday.date.isSame(day.targetDay, 'day'));
                  return (
                    <Fragment key={j}>
                      {day.isSameMonth ? (
                        <div
                          className={classNames('management-calendar-day', { active: (day.isToday || day.isBeforeDay) && !day.isBeforePeriod })}
                          onClick={() => select(day.targetDay)}
                        >
                          <div
                            className={classNames('date-box font__heading_6', {
                              today: day.isSameMonth && day.isToday,
                              sunday: isSunday,
                              saturday: isSaturday,
                              holiday: isHoliday,
                            })}
                          >
                            {day.targetDay.format('D')}
                          </div>

                          {(day.isToday || day.isBeforeDay) && !day.isBeforePeriod ? (
                            <div className="order-info font__s_small">
                              <div className={classNames('onoff-tag', { on: day.state === 'opened' || day.state === 'edited' })}>
                                {day.state === 'opened' || day.state === 'edited' ? 'On' : 'Off'}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div className="calendar-day" />
                      )}
                    </Fragment>
                  );
                })}
              </div>
            );
          })}
        </div>
      ))}
    </>
  );
};

export default ManagementCalendar;
