import React from 'react';
import { Form } from '@/pages/waiting/tutorial';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

interface TutorialStep1Props {
  form: Form;
  setForm: (key: keyof Form, value: any) => () => void;
  goNext: () => Promise<void>;
}

const TutorialStep1: React.FC<TutorialStep1Props> = ({ form, setForm, goNext }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="p-5 flex flex-col mb-[40px]">
        <p className="text-zinc-800 text-[28px] font-medium mb-[8px] leading-10">
          대기 추가 설정을
          <br />
          사용할까요?
        </p>
        <p className="text-zinc-500 text-sm leading-5">
          대기 인원수 제한, 자동 취소 설정 및<br />
          안내사항 내용을 변경할 수 있어요.
        </p>
      </div>
      <div className="p-5 flex flex-col gap-y-2">
        <button
          className={classNames(
            'w-full border justify-center items-center rounded p-5',
            form.isWaitingClosing === true ? 'border-violet-700 text-violet-700 bg-indigo-50' : 'border-stone-300 text-zinc-500 bg-white'
          )}
          onClick={()=> {
            setForm('isWaitingClosing', true);
            goNext();
          }
        }
        >
          네
        </button>
        <button
          className={classNames(
            'w-full border justify-center items-center rounded p-5',
            form.isWaitingClosing === false ? 'border-violet-700 text-violet-700 bg-indigo-50' : 'border-stone-300 text-zinc-500 bg-white'
          )}
          onClick={() => {
            setForm('isWaitingClosing', false)();
            navigate('/tutorial/kakao-channel_wait?from=waiting');
          }}
        >
          아니오
        </button>
      </div>
    </>
  );
};

export default TutorialStep1;
