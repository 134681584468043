import React from 'react';
import { ReactComponent as AdvertisementDummy } from '@/assets/images/icons/kakao_advertisement_mall_text_image_dummy.svg';
import KakaoMallMessage from '@/components/KakaoMallMessage';
import { useKakaoAlimtalkStore } from '@/stores/common/useKakaoAlimtalkStore';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';

const KakaoAdvertisementMallMessage = () => {
  const { isFetching, ad } = useKakaoAlimtalkStore((store) => ({ isFetching: store.isFetching, ad: store.alimtalk.ourmall.ad }));

  const data = {
    type: 'ourmall-ad',
    title: '홍보 메시지',
    label: '홍보 이미지',
    messages: {
      guideMessage: undefined,
      initMessage: ad.customMessage,
      warningMessage: '*해당 메시지는 민생경제 활성화를 위한 소상공인 지원 서비스로 발송되는 가게정보 알림 메시지입니다.',
      toastMessage: '가게정보안내 메시지가 변경되었습니다.',
    },
    initImage: ad.imageUrl,
    textImageIcon: AdvertisementDummy,
  };
  return (
    <>
      <KakaoMallMessage {...data} />
      <SpinnerEffector loading={isFetching} />
    </>
  );
};

export default KakaoAdvertisementMallMessage;
