import { ReactComponent as Close } from '@/assets/images/icons/Close_BoottoMenu.svg';
import { ReactComponent as Back } from '@/assets/images/icons/back.svg';
import classNames from 'classnames';
import { useEffect, useId, useState } from 'react';
import './HeaderSubType.scss';
import { IHeaderSubType } from './HeaderSubType.type';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Modal from '@/components/Modal/Modal';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigate } from 'react-router-dom';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import { useLayoutStore } from '@/stores/common/useLayoutStore';

const HeaderSubType = ({ isScroll = true, isBorder = true, scrollMove = false, isScrollTitleVisible = false, children, isNeedPlaceholder, header }: IHeaderSubType) => {
  const [isScrollMove, setIsScrollMove] = useState(scrollMove);
  const [borderClassName, setBorderClassName] = useState('');
  const [scrollMoveClassName, setScrollMoveClassName] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const scrollElement = document.querySelector('.main-container-desktop');
  const closeConfirmModalId = useId();
  const navigate = useNavigate();
  const { setReservationResetStore } = useReservationStore();
  const { setAlertModal } = useModalStore();
  const { setFromApp } = useLayoutStore();
  const handleScroll = () => {
    if (isScroll && !scrollMove) {
      const position = scrollElement.scrollTop;
      const scrollBoolean = Boolean(position <= 0);
      const scrollPlaceholder = Boolean(position <= 80);

      if (isNeedPlaceholder) setIsScrollMove(!scrollPlaceholder);
      else setIsScrollMove(!scrollBoolean);
    }
  };

  const onClose = () => {
    setAlertModal({ visible: true, key: closeConfirmModalId });
  };

  const onBack = () => {
    if (window.NavigateToHistory) {
      window.NavigateToHistory.postMessage('');
    } else {
      history.back();
    }
  };

  useEffect(() => {
    if (isScroll) scrollElement.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      if (isScroll) scrollElement.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setBorderClassName(isScrollMove && isBorder ? 'is-border' : '');
    setScrollMoveClassName(isScrollMove ? 'is-fixed' : '');
    if (isNeedPlaceholder) setPlaceholder(isScrollMove ? 'header-placeholder' : '');
  }, [isScrollMove]);

  return (
    <>
      <div className={placeholder} />
      <nav className={`header-sub-type-wrapper header-sub ${borderClassName} ${scrollMoveClassName}`}>
        <div className="close">
          <Back onClick={onBack} />
          <span className="header-text font__title_20_sb">{header}</span>
          <Close onClick={onClose} />
        </div>
        <div className={classNames('title font__title_20_sb')}>{children}</div>
      </nav>
      <ModalPortal>
        <Modal.Alert key={closeConfirmModalId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__header_title">접수를 취소하시겠습니까?</div>
          <div className="content font__body_md">현재 작성중인 접수를 취소하면 복구가 불가능합니다.</div>
          <div className="footer font__body_sm_sb">
            <div
              className="success"
              onClick={() => {
                setAlertModal({ visible: false, key: closeConfirmModalId });
                setReservationResetStore();
                setFromApp(null);
                if (window.NavigateToHistory) {
                  window.NavigateToHistory.postMessage('');
                } else {
                  navigate('/reservation/list');
                }
              }}
            >
              접수 취소
            </div>
          </div>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

export default HeaderSubType;
