import React, { ReactComponentElement, ReactElement, Ref, RefObject, useEffect, useState } from 'react';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import { useModalStore } from '../stores/common/useModalStore';
import { ReactComponent as IcNext } from '@/assets/images/icons/ic_next.svg';
import useImagePicker from './useImagePicker';
import { useScreenshot } from 'use-react-screenshot';
import { useKakaoTextImageStore } from '../stores/common/useKakaoTextImageStore';

const ModifyModal = ({ onClickPickImage, onClickEditText, onClose, title, editTextLabel }) => {
  return (
    <ModalPortal>
      {/* Alert 공통화 가능 요소 */}
      <Modal.Alert key="profile_image_modify" modal="alert" isAnimation={false} isDim={true}>
        <div className="title font__subtitle">{title}</div>
        <div className="content font__body_2">
          <div className="modal-item-container">
            <div className="modal-item" onClick={onClickPickImage}>
              앨범에서 사진 선택
              <IcNext />
            </div>
            <div className="modal-item" onClick={onClickEditText}>
              {editTextLabel}
              <IcNext />
            </div>
          </div>
        </div>
        <div className="footer font__body_4">
          <div onClick={onClose} className="success">
            닫기
          </div>
        </div>
      </Modal.Alert>
    </ModalPortal>
  );
};

type UseKakaoImagePickerProps = {
  title: string;
  editTextLabel: string;
  renderEditor: ({ imageText, setImageText, close }) => ReactElement;
  textImageRef: RefObject<Element>;
  initImage: string;
  type: 'ad' | 'normal' | 'profile';
};

const useKakaoImagePicker = ({ title, editTextLabel, renderEditor, textImageRef, initImage, type }: UseKakaoImagePickerProps) => {
  const { text } = useKakaoTextImageStore((store) => ({
    text: store.getText(type),
  }));

  const { setAlertModal } = useModalStore();
  const [isEdit, setEdit] = useState(false);
  const { image: pickerImage, pickImage } = useImagePicker(() => {
    setAlertModal(false);
  });
  const [imageText, setImageText] = useState(text);
  const [screenshotImage, takeScreenshot] = useScreenshot();
  const [image, setImage] = useState(initImage);

  useEffect(() => {
    textImageRef.current && takeScreenshot(textImageRef.current);
  }, [imageText]);

  useEffect(() => {
    screenshotImage && setImage(screenshotImage);
  }, [screenshotImage]);

  useEffect(() => {
    if (pickerImage) {
      setImageText('');
      setImage(pickerImage);
    }
  }, [pickerImage]);

  const modifyModal = () => (
    <ModifyModal
      title={title}
      editTextLabel={editTextLabel}
      onClickPickImage={() => {
        pickImage();
      }}
      onClickEditText={() => {
        setAlertModal(false);
        setEdit(true);
      }}
      onClose={() => setAlertModal(false)}
    />
  );

  const editor = () => isEdit && renderEditor({ imageText, setImageText, close: () => setEdit(false) });

  const showModal = () => setAlertModal({ visible: true, key: 'profile_image_modify' });
  return {
    ModifyModal: modifyModal,
    Editor: editor,
    showModal,
    image,
    isEdit,
    imageText,
  };
};

export default useKakaoImagePicker;
