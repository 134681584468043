import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import './TutorialNaverKakao.scss';
import Modal from '@/components/Modal/Modal';
import { ControlledInput } from '@/hook-form/ControlledInput';
import { devServerApi } from '@/shared/apis/devServerApi';
import { autoHypenCompany } from '@/shared/lib/functions/auto-hypen-company';
import { autoHypenTel } from '@/shared/lib/functions/auto-hypen-tel';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useModalStore } from '@/stores/common/useModalStore';
import ModalPortal from '../ModalPortal/ModalPortal';
import { ITutorialNaverKakao } from './TutorialNaverKakao.type';
import { instantDisabler, parseJwt } from '@/shared/lib/functions';
import Terces from '@/shared/apis/terces';

const TutorialNaverKakao = ({ onNext }: ITutorialNaverKakao): React.ReactElement => {
  const { accessToken, setPayload, payload } = useAuthStore();
  const { setAlertModal, setMenuModal } = useModalStore();
  const [failedChatbotMessage, setFailedChatbotMessage] = useState(null);
  const [channelConnect, setChannelConnect] = useState({});
  useEffect(() => {
    setChannelConnect(JSON.parse(localStorage['channel-connect'] || '{}'));
    const { pid, pmid, tmid } = parseJwt(accessToken);
    setPayload({ pid, pmid, tmid });
  }, [accessToken]);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      mallName: yup.string().required('매장명을 입력해주세요.'),
      subscriber: yup.string().required('신청인 이름을 입력해주세요.'),
      phone: yup.string().required('신청인 연락처를 입력해주세요.').max(13, '올바른 연락처를 입력해주세요.'),
      eid: yup.string().required('사업자 번호를 입력해주세요.').min(12, '올바른 사업자번호를 입력해주세요.').max(12, '올바른 사업자번호를 입력해주세요.'),
    });
  }, []);

  const methods = useForm({
    defaultValues: {
      mallName: '',
      subscriber: '',
      phone: '',
      eid: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { handleSubmit, control, setValue } = methods;

  const onClickNo = () => {
    setAlertModal({ visible: true, key: 'naverkakao' });
  };
  const onClickReallyNo = () => {
    setAlertModal(false);
    onNext(false);
  };
  const onClickYes = () => {
    setAlertModal({ visible: false, key: 'naverkakao' });
    setMenuModal({ visible: true, key: 'naverkakaoform' });
  };

  const onSubmit = (form) => {
    devServerApi.api.post('/chatbot', Terces.encrypt(form)).then((res: any) => {
      if (res.data.status === -1009) {
        setFailedChatbotMessage(res.data.message);
        setAlertModal({ visible: true, key: 'failedChatbot' });

        return;
      }
      channelConnect[payload.tmid] = true;
      localStorage['channel-connect'] = JSON.stringify(channelConnect);
      onNext(true);
      setMenuModal({ visible: false, key: 'naverkakaoform' });
    });
  };

  return (
    <FormProvider {...methods}>
      <div className="tutorial-naverkakao">
        <div className={'tutorial-naverkakao-content'}>
          <a onClick={onClickNo}>지금은 괜찮아요</a>
        </div>
        <div className="button-container">
          <button className="next" onClick={onClickYes}>
            받을게요
          </button>
        </div>
        <ModalPortal>
          <Modal.Alert key="naverkakao" modal="alert" isDim={true} isAnimation={true}>
            <div className="title font__body_md_sb">
              복잡한 신청 절차 없이
              <br />
              예약채널을 편하게 늘릴 수 있어요.
            </div>
            <div className="content font__body_sm">그래도 받지 말까요?</div>
            <div className="footer font__body_sm_sb">
              <div onClick={onClickReallyNo}>안받을게요</div>
              <div className="success" onClick={onClickYes}>
                받을게요
              </div>
            </div>
          </Modal.Alert>
          <Modal.Alert
            key="failedChatbot"
            modal="alert"
            isDim={true}
            isAnimation={true}
            modalData={{
              title: '연동신청에 실패하였습니다.',
              content: failedChatbotMessage,
              buttons: [
                {
                  text: '확인',
                  success: true,
                  onClick: () => setAlertModal({ visible: false, key: 'failedChatbot' }),
                },
              ],
            }}
          />
          <Modal.Menu key="naverkakaoform" modal="menu" isDim={true} isAnimation={true} title="서비스 신청 정보">
            <div className="title font__body_md_sb with-note">
              <div className="title-note font__body_6">*필수 입력</div>
            </div>
            <ul className={'form-wrapper'}>
              <li>
                <div className="form-label">*매장명</div>
                <div className="form-value">
                  <ControlledInput
                    name="mallName"
                    control={control}
                    placeholder="매장명을 입력해주세요."
                    onChange={(e) => {
                      setValue('mallName', e.target.value.replace(/[^\w\sㄱ-힣]|[_]/g, ''));
                    }}
                  />
                </div>
              </li>
              <li>
                <div className="form-label">*신청인 이름</div>
                <div className="form-value">
                  <ControlledInput
                    name="subscriber"
                    control={control}
                    placeholder="신청인 이름을 입력해주세요."
                    onChange={(e) => {
                      setValue('subscriber', e.target.value.replace(/[^\w\sㄱ-힣]|[_]/g, ''));
                    }}
                  />
                </div>
              </li>
              <li>
                <div className="form-label">*신청인 연락처</div>
                <div className="form-value three">
                  <ControlledInput
                    name="phone"
                    type="text"
                    control={control}
                    placeholder="010-0000-0000"
                    onChange={(e) => {
                      setValue('phone', autoHypenTel(e.target.value));
                    }}
                    maxLength={13}
                  />
                </div>
              </li>
              <li>
                <div className="form-label">*사업자 번호</div>
                <div className="form-value three">
                  <ControlledInput
                    name="eid"
                    control={control}
                    placeholder="000-00-00000"
                    onChange={(e) => {
                      setValue('eid', autoHypenCompany(e.target.value));
                    }}
                    maxLength={12}
                  />
                </div>
              </li>
            </ul>
            <div className="form-button-container">
              <button className="next" onClick={handleSubmit((result) => onSubmit(result))}>
                완료
              </button>
            </div>
          </Modal.Menu>
        </ModalPortal>
      </div>
    </FormProvider>
  );
};

export default TutorialNaverKakao;
