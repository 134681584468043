import React from 'react';
import { Form } from '@/pages/waiting/tutorial';
import { ReactComponent as RadioOn } from '@/assets/images/icons/Radio-On.svg';
import { ReactComponent as RadioOff } from '@/assets/images/icons/Radio-Off.svg';

interface TutorialStep7Props {
  form: Form;
  setForm: (key: keyof Form, value: any) => () => void;
}

const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent);

const TutorialStep7: React.FC<TutorialStep7Props> = ({ form, setForm }) => {

  const platformMargin = isIOS() ? 'p-5 flex flex-col mb-[120px]' : 'p-5 flex flex-col';

  return (
    <>
      <div className='p-5 flex flex-col mb-[40px]'>
        <p className="text-zinc-800 text-[28px] font-medium mb-[8px] leading-10">
          대기 고객에게 안내하고
          <br />
          싶은 내용이 있나요?
        </p>
        <p className="text-zinc-500 text-sm leading-5">
          대기를 등록하는 태블릿에
          <br />
          해당 안내사항이 노출됩니다.
        </p>
      </div>
      <div className={platformMargin}>
        <div className="flex items-center mb-[10px]" onClick={setForm('tabletDefaultInformationMessage', true)}>
          {form.tabletDefaultInformationMessage ? <RadioOn className="w-5 h-5 mr-[8px]" /> : <RadioOff className="w-5 h-5 mr-[8px]" />}
          <span className="text-zinc-800">기본 안내사항</span>
        </div>
        <div className="mb-[16px] p-[14px] border border-gray-100 rounded text-sm font-medium text-zinc-800 leading-5">
          <li>입장 순서가 가까워지면 카카오톡이나 문자로 안내 메세지가 나갑니다.</li>
          <li>인원수에 따라 입장 순서가 변경될 수 있습니다.</li>
          <li>입장 안내 메세지를 받으셨다면 매장 직원에게 확인 후 입장하실 수 있습니다.</li>
          <li>입장 순서가 되었을 때 매장 앞에 계시지 않을 경우 웨이팅이 취소될 수 있습니다.</li>
        </div>
        <div className="w-full h-px bg-gray-100 mb-[16px]" />
        <div className="flex items-center mb-[10px]" onClick={setForm('tabletDefaultInformationMessage', false)}>
          {!form.tabletDefaultInformationMessage ? <RadioOn className="w-5 h-5 mr-[8px]" /> : <RadioOff className="w-5 h-5 mr-[8px]" />}
          <span className="text-zinc-800">직접 입력</span>
        </div>
        <textarea
          disabled={form.tabletDefaultInformationMessage}
          value={form.tabletCustomInformationMessage}
          onChange={(value) => {
            setForm('tabletCustomInformationMessage', value.target.value)();
          }}
          maxLength={200}
          className="h-[150px] p-[14px] bg-gray-100 border-none"
          placeholder="안내사항을 입력해주세요."
        />
        <p className="text-right mt-1 text-stone-300">{`${form.tabletCustomInformationMessage.length}자/200자`}</p>
      </div>
    </>
  );
};

export default TutorialStep7;
