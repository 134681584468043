import React from 'react';
import { ReactComponent as AboutDummy } from '@/assets/images/icons/kakao_about_mall_text_image_dummy.svg';
import KakaoMallMessage from '@/components/KakaoMallMessage';
import { useKakaoAlimtalkStore } from '@/stores/common/useKakaoAlimtalkStore';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';

const KakaoNormalMallMessage = () => {
  const { isFetching, normal } = useKakaoAlimtalkStore((store) => ({ isFetching: store.isFetching, normal: store.alimtalk.ourmall.normal }));
  const data = {
    type: 'ourmall-normal',
    title: '기본 메시지',
    label: '가게 대표 이미지',
    messages: {
      guideMessage: `- 매장명 [(상호명)]
- 매장위치 (매장 위치)
[(상호명)]입니다.`,
      initMessage: normal.customMessage,
      warningMessage: `*이후 광고성 메시지가 발송될 수 있습니다.
*해당 메시지는 민생경제 활성화를 위한 소상공인 지원 서비스로 발송되는 가게정보 알림 메시지입니다.`,
      toastMessage: '가게정보안내 메시지가 변경되었습니다',
    },
    initImage: normal.imageUrl,
    textImageIcon: AboutDummy,
  };
  return (
    <>
      <KakaoMallMessage {...data} />
      <SpinnerEffector loading={isFetching} />
    </>
  );
};

export default KakaoNormalMallMessage;
