import { IPersistStore } from '@/shared/utils/common.type';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type IUseKakaoTextImageStore = {
  getText: (type: string) => string;
  setText: (type: string, text: string) => void;
  resetText: (type: string) => void;
};

export const useKakaoTextImageStore = create(
  persist<IUseKakaoTextImageStore>(
    (set, get) => ({
      ad: '',
      normal: '',
      profile: '',
      getText: (type) => get()[type],
      setText: (type, text) => {
        const d = {};
        d[type] = text;
        set(d);
      },
      resetText: (type) => {
        const d = {};
        d[type] = '';
        set(d);
      },
    }),
    {
      name: 'kakao-text-image',
      version: 0,
    }
  )
);
