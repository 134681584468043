import React from 'react';
import { IFilterChipNum } from './FilterChipNum.type';
import {} from './FilterChipNum.constants';
// import styles from './FilterChipNum.module.scss';
import classnames from 'classnames';
//import classnames from 'classnames';

const FilterChipNum = ({ selected, editable = false, name, value, onChange, onClick, placeholder = '직접 입력' }: IFilterChipNum): React.ReactElement => {
  const input = (event) => {
    onChange && onChange(event.target.innerText);
  };
  const keypress = (event) => {
    if (event.key?.toLowerCase().includes('enter')) {
      event.preventDefault();
    }
  };
  const paste = (event) => {
    event.target.innerHTML = event.target.innerText.replace(/\n/g, ' ');
  };
  return (
    <button
      className={classnames('tblm-chip-filter-num', { selected })}
      data-name={name}
      data-value={value}
      spellCheck="false"
      contentEditable={editable}
      onInput={input}
      onKeyPress={keypress}
      onClick={onClick}
      onPaste={paste}
      suppressContentEditableWarning={true}
    >
      {value}
    </button>
  );
};

export default FilterChipNum;
