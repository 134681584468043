import React from 'react';
import './Table.scss';
import { ITablesTable } from '@/shared/utils/common.type';

function Table({ tableData }: { tableData: ITablesTable }) {
  return (
    <div className="table-container">
      <div className="table-header font__heading_6">{tableData.name}</div>
      <div className="table-body font__body_6">{`${tableData.peopleMin}명~${tableData.peopleMax}명`}</div>
    </div>
  );
}

export default Table;
