export type Nullable<T> = T | null;

export interface IQueryKeyProps {
  queryKey: string;
}

export interface IResponseError {
  message?: string;
}

export type TOperation = 'get' | 'post' | 'patch' | 'put' | 'delete';
export interface IMutationProps {
  operation: TOperation;
  data?: unknown;
  headers?: any;
  url: string;
  meta?: object;
}
export interface IVariables {
  data?: object;
  operation?: string;
  url?: string;
  meta?: object;
}
export interface IContext {
  key?: string | string[];
  onSuccess?: (...props) => void;
  onError?: (...props) => void;
}
export interface IMutableRefObject<T> {
  current: T;
}

export interface IStep {
  value: boolean | string | number | number[] | [number, number][] | null;
  title?: string;
  question: string;
  note?: string;
  notePosition?: 'before' | 'after';
  type?: 'button' | 'select' | 'multiselect' | 'checkbox' | 'table' | 'time' | 'until' | 'reservation' | 'naverkakao' | 'plain';
  boolText?: [string, string];
  option?: string[] | number[] | any[] | any;
  optionUnit?: string | string[];
  multiple?: boolean;
  reference?: string;
  prevStep?: string;
  nextStep?: {
    to?: string;
    bool?: [string, string];
    // [no, yes]
    range?: [string, Nullable<number>, Nullable<number>][];
    // ['stepX_Y', 'range_start', 'range_stop'][]
    api?: [string, string];
    // [doapi, stepX_Y]
  };
}
export interface IQuestion {
  [step: string]: IStep;
}
export interface IQuestionAction {
  type: string;
  value: any;
  step: string;
  multiple?: boolean;
  prevStep?: string;
  idx?: number;
}
export interface IPersistStore {
  storageQuestion: IQuestion;
  setStorageQuestion: (question) => void;
  resetStorageQuestion: () => void;
}

////
export interface IReservationsExtravalues {
  id: string;
  name: string;
  value: string;
  backgroundColor: string;
  fontColor: string;
  display: string;
}
export interface IReservationsParties {
  name: string;
  size: number;
}
export const reservationStateList = ['canceled', 'enter', 'exit', 'noShow', 'valid', null] as const;
export const reservationStateListKorean = { canceled: '취소', enter: '입장', exit: '퇴장', noShow: '노쇼', valid: '접수' };
export type TReservationState = (typeof reservationStateList)[number];

export const depositStateList = ['unused', 'unpaid', 'paid'] as const;
export const depositStateListKorean = { unused: '사용안함', unpaid: '대기', paid: '지불' };
export type TDepositState = (typeof depositStateList)[number];

export interface IReservation {
  reservationId: string;
  isWalkIn: boolean;
  userId?: any;
  userName: string;
  userPhone: string;
  userNickname: string;
  userMemo: string;
  userPastValidReservationCount: number;
  userExtravalues: IReservationsExtravalues[];
  reservationName: string;
  reservationMemo: string;
  reservationRequests: string;
  reservationMarkingColorIndex?: number;
  reservationMarkingColorId?: string;
  reservationExtravalues: IReservationsExtravalues[];
  partnerId: number;
  partnerName: string;
  parties: IReservationsParties[];
  rooms: any[];
  menus: any[];
  visitDate: string | number;
  visitTime: number;
  createdAt: string | number;
  updatedAt?: string | number;
  enteredAt?: string | number;
  exitedAt?: string | number;
  reservationState: TReservationState;
  depositState: TDepositState;
  depositAmount: number;
}

///////

export interface ITablesTable {
  _id?: string;
  groupId?: string;
  name: string;
  peopleMin: number;
  peopleMax: number;
  enabled?: boolean;
}
export interface ITablesGroup {
  groupName: string;
  groupId?: string;
  item: ITablesTable[];
}
export interface ITables {
  status: number;
  message: string;
  'tm-api-request-uuid': string;
  list: ITablesGroup[];
}

////////

export interface MallSettingFoods {
  type_food_id: number;
  name: string;
}
export interface MallSettingFacility {
  type_facility_id: number;
  name: string;
}
export interface MallSettingAddress {
  road: string;
  detail: string;
  address: string;
  zipcode: string;
}
export interface MallSettingInfoOpenTimeDate {
  hour: number;
  minute: number;
}
export interface MallSettingInfoOpenTime {
  type: string;
  week: string;
  date_end: MallSettingInfoOpenTimeDate;
  date_start: MallSettingInfoOpenTimeDate;
  tabletCustomInformationMessage: string;
}
export interface MallSettingControlInfoFacilities {
  type_facility_id: number;
  name: string;
}
export interface MallSettingControlInfoFoods {
  type_food_id: number;
  name: string;
}
export interface MallSettingControlInfo {
  facilities: MallSettingControlInfoFacilities[];
  foods: MallSettingControlInfoFoods[];
}
export interface MallSetting {
  status: number;
  message: string;
  'tm-api-request-uuid': string;
  mallName: string;
  eid: string;
  foods: MallSettingFoods[];
  facility: MallSettingFacility[];
  mainPhone: string;
  address: MallSettingAddress;
  representative: string;
  infoOpenTime: MallSettingInfoOpenTime[];
  mallDescription: string;
  controlInfo: MallSettingControlInfo;
  representativePhone?: string;
  parkingInfo?: string;
  breakTime?: string;
}

export interface IPolicyReservationDay {
  policyFormId: number;
  reservation: number;
  bookingTimes: string[];
}
export interface IPolicyReservationDays {
  monday: IPolicyReservationDay;
  tuesday: IPolicyReservationDay;
  wednesday: IPolicyReservationDay;
  thursday: IPolicyReservationDay;
  friday: IPolicyReservationDay;
  saturday: IPolicyReservationDay;
  sunday: IPolicyReservationDay;
}

export interface IPolicy {
  status?: number;
  message?: string;
  'tm-api-request-uuid'?: string;
  bookingToday: string;
  bookingDeadLine: number;
  maxAvailableTime: number;
  bookingAvailableDays: number;
  reservationDays: IPolicyReservationDays;
  reservationTimeUnit?: number;
}

export interface ISettingMenu {
  title: string;
  tabletCustomInformationMessage: string;
  to: string;
  check?: (ICustomMessage) => boolean;
}
