import { devServerApi } from '../apis/devServerApi';

export function blobtoFile(imageData) {
  // data URI를 Blob으로 변환
  const byteString = atob(imageData.split(',')[1]);
  const mimeString = imageData.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: mimeString });

  // Blob을 File 객체로 변환 (optional)
  return new File([blob], 'image.png', { type: mimeString });
}

export function uploadImage(image: string, type?: 'ad' | 'normal' | undefined) {
  const formData = new FormData();
  formData.append('image', blobtoFile(image));
  type && formData.append('ourmall', type);
  return devServerApi.lg.postForm(`/api/partner/kakao/alimtalk/upload-image`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
