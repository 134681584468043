import React from 'react';
import classNames from 'classnames';
import { ReactComponent as WaitingPlaceholder } from '@/assets/images/waiting/waiting-placeholder.svg';
import { ReactComponent as OpenIcon } from '@/assets/images/waiting/open-floating.svg';
import { ReactComponent as CloseIcon } from '@/assets/images/waiting/closed-floating.svg';
import { useNavigate } from 'react-router-dom';
import WaitingCard, { Waiting, WaitingStatus } from '@/components/WaitingCard/WaitingCard';
import AccessAgreement from '@/components/Reservation/AccessAgreement/AccessAgreement';
import WaitingSetting from '@/components/WaitingSetting';
import { useQuery } from 'react-query';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useSettingTutorialStore } from '@/stores/common/useSettingTutorialStore';
import '@/styles/base/tailwind.css';
import useNative from '@/hooks/useNative';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import { useAuthStore } from '@/stores/common/useAuthStore';
import AGREEMENT_LIST from '@/components/Agreement/Agreement.constants';
import { useDevServerApi } from '@/shared/apis/devServerApi';
import useBackButton from '@/hooks/useBackButton';

const TABS = [
  {
    label: '대기',
    value: 'WAIT',
  },
  {
    label: '입장',
    value: 'ENTER',
  },
  {
    label: '취소',
    value: 'CANCEL',
  },
  {
    label: '설정',
    value: 'SETTING',
  },
];

const INIT_TAB = {
  label: '대기',
  value: 'WAIT',
};

interface BusinessResponse {
  existsBusiness: boolean;
}

interface AlimtalkResponse {
  setting: {
    profileImageUrl: string;
  };
}

interface TermsResponse {
  response: [
    {
      type: String;
      isAgree: boolean;
      createdAt: String;
    }
  ];
}

const WaitingDashBoardPage: React.FC = () => {
  const navigate = useNavigate();
  const { requestToken } = useNative();

  const [waitingList, setWaitingList] = React.useState<Record<WaitingStatus, Waiting[]>>({
    WAIT: [],
    ENTER: [],
    CANCEL: [],
  });

  const [selectedTab, setSelectedTab] = React.useState<{
    label: string;
    value: string;
  }>();

  const checkDuplicate = () => {
    requestToken();
  };

  useBackButton(() => {});

  const fetchData = async () => {
    try {
      const termsAgreementsPromise = devServerApi.api.authGet('/terms-agree') as Promise<TermsResponse>;

      const alimtalkResPromise = devServerApi.lg.authGet('/api/partner/kakao/alimtalk/setting') as Promise<AlimtalkResponse>;

      const businessProfilePromise = devServerApi.lg.authGet('/api/partner/mall/is-business') as Promise<BusinessResponse>;

      const [termsRes, alimtalkRes, businessRes] = await Promise.all([termsAgreementsPromise, alimtalkResPromise, businessProfilePromise]);
      // termsAgreementsPromise

      // 비동기 요청 결과를 사용
      const terms = termsRes.response;
      const hasTerms = terms.length <= 0;
      const business = businessRes.existsBusiness;
      const profileImageUrl = alimtalkRes.setting;

      if (hasTerms) {
        navigate('/waiting', { replace: true });
        // navigate('/setting/agreement?redirect=/waiting', { replace: true });
      } else if (!business) {
        navigate('/waiting', { replace: true });
        // navigate('/tutorial/business?redirect=/waiting');
      } else if (profileImageUrl.profileImageUrl === '') {
        navigate('/waiting', { replace: true });
      }
      // const url = new URL(window.location.href);
      // const tab = url.searchParams.get('tab');
      // const selected = tab && TABS.find((t) => t.value === tab);
      // if (!selected) {
      //   navigate(`/waiting/dashboard?tab=${INIT_TAB.value}`);
      //   return;
      // }
      // setSelectedTab(selected || INIT_TAB);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      // 에러 처리 로직
    }
  };

  React.useEffect(() => {
    const url = new URL(window.location.href);
    const tab = url.searchParams.get('tab');
    const selected = tab && TABS.find((t) => t.value === tab);
    if (!selected) {
      navigate(`/waiting/dashboard?tab=${INIT_TAB.value}`, { replace: true });
    }
    setSelectedTab(selected || INIT_TAB);
  }, []);

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isRemoteOpenm, setIsRemoteOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    // 비동기 작업들을 병렬로 실행하고, 모든 결과를 기다립니다.
    fetchData();
    checkDuplicate();
  }, []);

  const {
    data: optionsResponse,
    refetch: optionRefetch,
    isLoading: isOptionsLoading,
  } = useQuery(['waiting_options'], () => devServerApi.api.get('/waiting/option'), {
    onSuccess: ({ data }) => {
      // process.env.NODE_ENV === 'production' && alert('option response :::' + JSON.stringify(data?.option));
      const savedOpeningValue = !data.option?.isWaitingClosing;
      const remoteOpen = data.extra?.useWaitingRemoteReception;
      setIsOpen(savedOpeningValue);
      setIsRemoteOpen(remoteOpen);
    },
    onError: (error) => {
      // process.env.NODE_ENV === 'production' && alert('option error :::' + JSON.stringify(error));
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  const {
    data: waitingListResponse,
    refetch: waitingListRefetch,
    isLoading: isWaitingListLoading,
  } = useQuery(['waiting_list'], () => devServerApi.api.get('/waiting/list'), {
    onSuccess: ({ data }) => {
      const { waitings } = data;
      // process.env.NODE_ENV === 'production' && alert('list response :::' + waitings.length);
      const _waitingList = waitings?.reduce(
        (acc: Record<WaitingStatus, Waiting[]>, cur: Waiting) => {
          acc[cur.status].push(cur);
          return acc;
        },
        { WAIT: [], ENTER: [], CANCEL: [] }
      );
      setWaitingList(_waitingList);
    },
    onError: (error) => {
      // process.env.NODE_ENV === 'production' && alert('list error :::' + JSON.stringify(error));
    },
    // MEMO polling every 3 seconds
    refetchInterval: 3000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchIntervalInBackground: true,
  });

  React.useEffect(() => {
    optionRefetch();
    waitingListRefetch();
  }, []);

  const handleTabClick = (tab: any) => () => {
    navigate(`/waiting/dashboard?tab=${tab.value}`);
    setSelectedTab(tab);
  };

  const toggleOpen = () => {
    // if (!isOpen) {
    navigate('/waiting/setting/waiting');
    // return;
    // }
    // await devServerApi.api.put('/waiting/option', {
    //   isWaitingClosing: true,
    // });
    // await queryClient.invalidateQueries(['waiting_options']);
    // optionRefetch();
  };

  if (!selectedTab || isOptionsLoading || isWaitingListLoading) {
    return null;
  }

  return (
    <div className="min-w-screen min-h-screen flex flex-col relative mt-gnb">
      {selectedTab.value !== 'setting' && (
        <div className="fixed right-[10px] bottom-[10px] z-50" onClick={toggleOpen}>
          {isOpen ? <OpenIcon className="w-20 h-20" /> : <CloseIcon className="w-20 h-20" />}
        </div>
      )}
      <div className="p-5 flex">
        {TABS.map((tab, index) => (
          <button
            key={tab.value}
            onClick={handleTabClick(tab)}
            className={classNames(
              'w-full py-3 font-bold text-sm',
              selectedTab.value === tab.value ? 'bg-white text-violet-700 border-x border-t border-violet-700' : 'bg-zinc-100 border border-stone-300 text-stone-300',
              index === 0 && 'rounded-tl-lg rounded-bl-sm',
              index === 3 && 'rounded-tr-lg rounded-br-sm',
              (index === 1 || index === 2) && 'rounded-none'
            )}
          >
            {tab.label}
          </button>
        ))}
      </div>
      {!isOpen && selectedTab.value !== 'SETTING' && (
        <div className="w-full bg-red-400 bg-opacity-95 px-5 py-2.5 text-white text-sm font-medium">
          <p>대기 접수가 마감되어있습니다.</p>
        </div>
      )}
      {isOpen && !isRemoteOpenm && selectedTab.value !== 'SETTING' && (
        <div className="w-full bg-[#FF7530] bg-opacity-95 px-5 py-2.5 text-white text-sm font-medium">
          <p>원격 대기 접수가 마감되어있습니다.</p>
        </div>
      )}
      {selectedTab.value === 'WAIT' && (
        <>
          <div className="w-full bg-gray-100 px-5 py-2.5 text-zinc-800 text-sm font-medium">
            <p>{`호출 완료 (${waitingList[selectedTab.value]?.filter((waiting) => waiting.callCount > 0).length}팀)`}</p>
          </div>
          {Boolean(waitingList[selectedTab.value]?.filter((waiting) => waiting.callCount > 0).length) && (
            <div className="flex flex-col p-5 gap-y-[10px]">
              {waitingList[selectedTab.value]
                ?.filter((waiting) => waiting.callCount > 0)
                ?.map((data: Waiting) => (
                  <WaitingCard key={data.id} {...data} />
                ))}
            </div>
          )}
          <div className="w-full bg-gray-100 px-5 py-2.5 text-zinc-800 text-sm font-medium">
            <p>{`대기 중 (${waitingList[selectedTab.value]?.filter((waiting) => waiting.callCount === 0).length}팀)`}</p>
          </div>
          {Boolean(waitingList[selectedTab.value]?.filter((waiting) => waiting.callCount === 0).length) && (
            <div className="flex flex-col p-5 gap-y-[10px]">
              {waitingList[selectedTab.value]
                ?.filter((waiting) => waiting.callCount === 0)
                ?.map((data: Waiting) => (
                  <WaitingCard key={data.id} {...data} />
                ))}
            </div>
          )}
        </>
      )}
      {(selectedTab.value === 'ENTER' || selectedTab.value === 'CANCEL') && (
        <>
          <div className="w-full bg-gray-100 px-5 py-2.5 text-zinc-800 text-sm font-medium">
            <p>{`전체 (${waitingList[selectedTab.value]?.length}팀)`}</p>
          </div>
          {Boolean(waitingList[selectedTab.value]?.length) && (
            <div className="flex flex-col p-5 gap-y-[10px]">
              {waitingList[selectedTab.value]?.map((data: Waiting) => (
                <WaitingCard key={data.id} {...data} />
              ))}
            </div>
          )}
        </>
      )}
      {selectedTab.value === 'SETTING' && <WaitingSetting setting={optionsResponse?.data} />}
      {selectedTab.value !== 'SETTING' && (
        <div className="flex flex-1 flex-col justify-center items-center">
          {waitingList[selectedTab.value]?.length === 0 && (
            <>
              <WaitingPlaceholder className="w-[100px] h-[100px] mb-[10px]" />
              <p className="text-stone-300 mb-[56px]">{`등록된 ${selectedTab.label} 내역이 없습니다.`}</p>
            </>
          )}
          <div className="w-full">
            <AccessAgreement />
          </div>
        </div>
      )}
      <SpinnerEffector loading={isOptionsLoading || isWaitingListLoading} />
    </div>
  );
};

export default WaitingDashBoardPage;
