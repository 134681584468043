import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import { devServerApi } from '@/shared/apis/devServerApi';
import AgreementWait from '@/components/AgreementWait/Agreement';
const AgreementSettingWait = ({ isComponent, onYes }: { isComponent?: boolean; onYes?: () => void }): React.ReactElement => {
  const navigate = useNavigate();
  const [showAgreement, setShowAgreement] = useState(true);

  const [showAgreementPortal, setShowAgreementPortal] = useState(true);


  interface BusinessResponse {
    existsBusiness: boolean;
  }


  async function checkBusiness() {
    const responseBusiness = devServerApi.lg.authGet('/api/partner/mall/is-business') as Promise<BusinessResponse>;

    const isBusiness = (await responseBusiness).existsBusiness;

    if (!isBusiness) {
      // 비즈니스 정보가 없는 경우, 비즈니스 설정 튜토리얼 페이지로 리다이렉트
      navigate('/tutorial/business_wait?redirect=/waiting&isWaiting=true', { replace: true });
    } else {
      navigate('/waiting/tutorial', { replace: true });
    }

  }

  const close = async (yn: any) => {
    setShowAgreement(false);
    await new Promise(resolve => setTimeout(resolve, 0));
    setShowAgreementPortal(false);
    if (!isComponent) {
      if (yn) {
        checkBusiness();
      } else {
        // 동의하지 않은 경우, waiting으로 리디렉션
        navigate('/waiting', { replace: true });
      }
    }
  };

  return <>{showAgreementPortal ? <ModalPortal>{showAgreement ? <AgreementWait onClose={close} /> : null}</ModalPortal> : null}</>;
};

export default AgreementSettingWait;
