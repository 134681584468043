import { create } from 'zustand';
import { devServerApi } from '@/shared/apis/devServerApi';
import { Nullable } from '@/shared/utils/common.type';
import { parseJwt } from '@/shared/lib/functions';

interface IUseAuthStore {
  accessToken: Nullable<string>;
  setAccessToken: (accessToken: Nullable<string>) => void;
  refreshToken: Nullable<string>;
  setRefreshToken: (refreshToken: Nullable<string>) => void;
  resetToken: () => void;
  payload?: {
    pid?: Nullable<string>;
    pmid?: Nullable<string>;
    tmid?: Nullable<string>;
  };
  setPayload: ({ pid, pmid, tmid }) => void;
}

export const useAuthStore = create<IUseAuthStore>((set) => ({
  accessToken: process.env.REACT_APP_TOKEN,
  setAccessToken: (accessToken: string) => {
    const { pid, pmid, tmid } = parseJwt(accessToken);
    devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });
    return set(() => ({ accessToken, payload: { pid, pmid, tmid } }));
  },
  refreshToken: process.env.REACT_APP_TOKEN,
  setRefreshToken: (refreshToken: string) => set(() => ({ refreshToken })),
  resetToken: () => set(() => ({ accessToken: null, refreshToken: null, payload: { pid: null, pmid: null, tmid: null } })),
  payload: { pid: null, pmid: null, tmid: null },
  setPayload: (payload) => set(() => ({ payload })),
}));
