import React from 'react';
import { Form } from '@/pages/waiting/tutorial';
import { ReactComponent as ArrowDown } from '@/assets/images/icons/Arrow_Down.svg';
import BottomScrollPicker from '@/components/BottomScrollPicker/BottomScrollPicker';

interface TutorialStep4Props {
  form: Form;
  setForm: (key: keyof Form, value: any) => () => void;
}

const OPTION_GROUPS = Array.from({ length: 10 }, (_, i) => `${i + 1}팀`);

const TutorialStep4: React.FC<TutorialStep4Props> = ({ form, setForm }) => {
  const [isPickerOpen, setIsPickerOpen] = React.useState(false);
  return (
    <>
      <div className="p-5 flex flex-col mb-[40px]">
        <p className="text-zinc-800 text-[28px] font-medium mb-[8px] leading-10">
          고객님 앞에
          <br />
          대기가 몇 팀 남았을 때<br />
          메시지를 보낼까요?
        </p>
      </div>
      <div className="p-5 flex flex-col gap-y-3 text-zinc-800 text-xl font-medium">
        <p>고객님 앞 대기가</p>
        <div className="flex gap-x-3 items-center">
          <div
            onClick={() => {
              setIsPickerOpen(true);
            }}
            className="text-sm font-medium w-[120px] justify-between flex px-3 py-2 gap-2 rounded border border-stone-300 items-center"
          >
            <span>{form.enterWaitingAlarmNum.split('팀')?.[0]}</span>
            <ArrowDown className="w-6 h-6" />
          </div>
          <span>팀이 남으면</span>
        </div>
        <p>알림 메시지가 전송됩니다.</p>
      </div>
      <BottomScrollPicker
        title="남은 대기 팀 수"
        tabletCustomInformationMessage="앞에 대기가 몇 팀 남았을 때 알림을 보낼까요?"
        onDismiss={() => setIsPickerOpen(false)}
        value={form.enterWaitingAlarmNum}
        isOpen={isPickerOpen}
        list={OPTION_GROUPS}
        onSelectedSave={(selected) => setForm('enterWaitingAlarmNum', selected)()}
      />
    </>
  );
};

export default TutorialStep4;
