import React from 'react';
import { ReactComponent as ArrowLeft } from '@/assets/images/icons/Arrow_Left.svg';
import { ReactComponent as Edit } from '@/assets/images/icons/Edit.svg';
import { ReactComponent as SwitchOn } from '@/assets/images/icons/Switch_On.svg';
import { ReactComponent as SwitchOff } from '@/assets/images/icons/Switch_Off.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import BottomScrollPicker from '@/components/BottomScrollPicker/BottomScrollPicker';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useQuery } from 'react-query';
import queryClient from '@/shared/utils/queryClient';
import '@/styles/base/tailwind.css';

const OPTION_GROUPS = Array.from({ length: 10 }, (_, i) => `${i + 1}팀`);

const DEFAULT_INFORMATION_MESSAGE = `입장 순서가 가까워지면 카카오톡이나 문자로 안내 메세지가 나갑니다.\n인원수에 따라 입장 순서가 변경될 수 있습니다.\n입장 안내 메세지를 받으셨다면 매장 직원에게 확인 후 입장하실 수 있습니다.\n입장 순서가 되었을 때 매장 앞에 계시지 않을 경우 웨이팅이 취소될 수 있습니다.`;

interface Form {
  enterWaitingAlarmNum: string;
  tabletDefaultInformationMessage: string;
  tabletCustomInformationMessage: string;
}

const WaitingAlarmSetting: React.FC = () => {
  let location = useLocation();
  const [isPickerOpen, setIsPickerOpen] = React.useState(false);
  const [useCustomEnterWaitingAlarm, setUseCustomEnterWaitingAlarm] = React.useState<boolean>();
  const [_form, set_Form] = React.useState<Form>();
  const [form, setForm] = React.useState<Form>();

  React.useEffect(() => {
    let state = location.state;
    if (state) {
      const savedOptions = {
        enterWaitingAlarmNum: `${state.option.enterWaitingAlarmNum || 1}팀`,
        tabletDefaultInformationMessage: state.option.tabletDefaultInformationMessage || DEFAULT_INFORMATION_MESSAGE,
        tabletCustomInformationMessage: state.option.tabletCustomInformationMessage,
      };
      setForm(savedOptions);
      set_Form(savedOptions);
      setUseCustomEnterWaitingAlarm(state?.extra?.useCustomEnterWaitingAlarm);
    }
  }, []);

  const {
    data: optionsResponse,
    refetch,
    isLoading,
  } = useQuery(['waiting_options'], () => devServerApi.api.get('/waiting/option'), {
    onSuccess: ({ data }) => {
      console.log(data.option);
      const options = data.option;
      const savedOptions = {
        enterWaitingAlarmNum: `${options.enterWaitingAlarmNum || 1}팀`,
        tabletDefaultInformationMessage: options.tabletDefaultInformationMessage || DEFAULT_INFORMATION_MESSAGE,
        tabletCustomInformationMessage: options.tabletCustomInformationMessage,
      };
      setForm(savedOptions);
      set_Form(savedOptions);
      setUseCustomEnterWaitingAlarm(data?.extra?.useCustomEnterWaitingAlarm);
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });
  const navigate = useNavigate();

  const onChangeAlarmNum = (value: string) => {
    set_Form((prev) => ({ ...prev, enterWaitingAlarmNum: value }));
  };

  const saveAlarm = async (value: boolean) => {
    await devServerApi.api.put('/waiting/option', {
      extra: { useCustomEnterWaitingAlarm: value },
    });
    await queryClient.invalidateQueries(['waiting_options']);
    setUseCustomEnterWaitingAlarm(value);
  };

  const saveAlarmNum = async () => {
    const num = Number(_form.enterWaitingAlarmNum.split('팀')[0]);
    await devServerApi.api.put('/waiting/option', {
      enterWaitingAlarmNum: num,
    });
    await queryClient.invalidateQueries(['waiting_options']);
    setIsPickerOpen(false);
    setForm(_form);
  };

  const undoAlarmNum = () => {
    set_Form(form);
    setIsPickerOpen(false);
  };

  if (isLoading || !form) return null;

  return (
    <div className="min-w-screen min-h-screen flex flex-col">
      <div className="relative py-[15px] justify-center items-center w-full flex">
        <p className="text-zinc-800 text-lg font-bold">입장 대기 알림</p>
        <ArrowLeft className="absolute left-5 w-[20px] h-[20px]" onClick={() => navigate(-1)} />
      </div>
      <div className="flex flex-col flex-1 bg-zinc-100 divide-y divide-gray-100">
        <div className="bg-white px-5 py-6 flex w-full items-center justify-between">
          <div className="flex flex-col">
            <p className="text-xl text-zinc-800 font-bold mb-[6px]">입장 대기 알림</p>
            <p className="text-sm text-zinc-500 font-medium whitespace-pre-wrap">{`입장 순서가 가까워지면\n미리 알림을 보낼까요?`}</p>
          </div>
          <div className="flex items-center">
            <span className="text-zinc-500 text-sm font-medium mr-[16px]">{useCustomEnterWaitingAlarm ? '알림 사용' : '알림 미사용'}</span>
            {useCustomEnterWaitingAlarm ? <SwitchOn onClick={() => saveAlarm(false)} /> : <SwitchOff onClick={() => saveAlarm(true)} />}
          </div>
        </div>
        {useCustomEnterWaitingAlarm && (
          <>
            <div className="bg-white px-5 py-6 flex w-full items-center justify-between">
              <div className="flex flex-col">
                <p className="text-xl text-zinc-800 font-bold mb-[6px]">알림 전송 시점</p>
                <p className="text-sm text-zinc-500 font-medium whitespace-pre-wrap">{`앞에 대기가 몇 팀 남았을 때\n알림을 보낼까요?`}</p>
              </div>
              <div className="flex items-center" onClick={() => setIsPickerOpen(true)}>
                <span className="text-zinc-800 text-lg font-bold whitespace-pre-wrap text-right">{`남은 대기가\n${form.enterWaitingAlarmNum}일 때`}</span>
              </div>
            </div>
            <div className="bg-white px-5 py-6 flex w-full items-center justify-between">
              <div className="flex flex-col">
                <p className="text-xl text-zinc-800 font-bold mb-[6px]">입장 대기 메시지</p>
                <p className="text-sm text-zinc-500 font-medium whitespace-pre-wrap">{`입장 순서가 가까워졌을때 보내주는\n알림 메시지를 확인해주세요.`}</p>
              </div>
              <div className="flex items-center justify-center px-3.5 py-2 bg-gray-200 rounded-[40px]" onClick={() => navigate('/setting/kakao-waiting-message/waiting')}>
                <Edit />
                <span className="text-zinc-800 text-sm font-medium">편집</span>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="flex flex-1 bg-zinc-100 w-full" />
      <BottomScrollPicker
        title="남은 대기 팀 수"
        tabletCustomInformationMessage="앞에 대기가 몇 팀 남았을 때 알림을 보낼까요?"
        onDismiss={undoAlarmNum}
        onSave={saveAlarmNum}
        value={form.enterWaitingAlarmNum}
        isOpen={isPickerOpen}
        list={OPTION_GROUPS}
        onSelectedChange={onChangeAlarmNum}
      />
    </div>
  );
};

export default WaitingAlarmSetting;
