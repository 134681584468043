import { ReactComponent as Close } from '@/assets/images/icons/Close_BoottoMenu.svg';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import './HeaderSub.scss';
import { IHeaderSub } from './HeaderSub.type';

const HeaderSub = ({ isScroll = true, isBorder = false, scrollMove = false, isScrollTitleVisible = false, onClose, children, isNeedPlaceholder }: IHeaderSub) => {
  const [isScrollMove, setIsScrollMove] = useState(scrollMove);
  const [borderClassName, setBorderClassName] = useState('');
  const [scrollMoveClassName, setScrollMoveClassName] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const scrollElement = document.querySelector('.main-container-desktop');

  const handleScroll = () => {
    if (isScroll && !scrollMove) {
      const position = scrollElement.scrollTop;
      const scrollBoolean = Boolean(position <= 0);
      const scrollPlaceholder = Boolean(position <= 80);

      if (isNeedPlaceholder) setIsScrollMove(!scrollPlaceholder);
      else setIsScrollMove(!scrollBoolean);
    }
  };

  useEffect(() => {
    if (isScroll) scrollElement.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      if (isScroll) scrollElement.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setBorderClassName(isScrollMove && isBorder ? 'is-border' : '');
    setScrollMoveClassName(isScrollMove ? 'is-fixed' : '');
    if (isNeedPlaceholder) setPlaceholder(isScrollMove ? 'header-placeholder' : '');
  }, [isScrollMove]);

  return (
    <>
      <div className={placeholder} />
      <nav className={`header-sub-wrapper header-sub ${borderClassName} ${scrollMoveClassName}`}>
        <div className="close">
          <Close onClick={onClose} />
        </div>
        <div className={classNames('title', { 'font__heading_4 remove-br': isScrollMove, font__title_20_b: !isScrollMove, 'is-scroll-title-visible': isScrollTitleVisible })}>
          {children}
        </div>
      </nav>
    </>
  );
};

export default HeaderSub;
