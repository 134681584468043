import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSettingTutorialStore } from '@/stores/common/useSettingTutorialStore';

const DebugIndexPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const { state, setState, agreements, setAgreements, setAgreementsDetail } = useSettingTutorialStore();

  useEffect(() => {
    setState('done');
    navigate(-1);
  }, [state]);

  return <div />;
};

export default DebugIndexPage;
