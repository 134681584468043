import React, { useEffect } from 'react';
import { useMallStore } from '@/stores/common/useMallStore';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import FilterChipNum from '@/components/FilterChipNum/FilterChipNum';

const PartySetting = ({ searchParamsType }): React.ReactElement => {
  const { mall } = useMallStore();
  const { parties, setParty, setRooms } = useReservationStore();
  const partiesSettingLen = mall.partyNames.length;
  const handleChangeParties = (e) => {
    if (searchParamsType === 'modify' || searchParamsType === 'modify-1') setRooms([]);
    setParty(
      parties.map((item) => {
        if (item.name === e.target.dataset.name) return { ...item, size: Number(e.target.dataset.value) };
        return item;
      })
    );
  };

  useEffect(() => {
    if (!parties.length) setParty(mall.partyNames.map((item) => ({ name: item, size: 0 })));
  }, []);

  if (!parties.length) return <></>;
  return (
    <section className="select-persons-wrapper">
      {mall.partyNames.map((item, j) => (
        <div key={item} className="flex-container flex-col gap-16">
          <div className="font__heading_5">{item}</div>
          <div className="flex-container slide-x gap-8">
            {new Array(mall.maxPartySize).fill(null).map((_, i) => {
              const currentPartyNum = partiesSettingLen === 1 ? i + 1 : i;
              return (
                <FilterChipNum
                  name={item}
                  value={String(currentPartyNum)}
                  key={`adult_${currentPartyNum}`}
                  selected={currentPartyNum === parties[j].size}
                  onClick={handleChangeParties}
                />
              );
            })}
          </div>
        </div>
      ))}
    </section>
  );
};

export default PartySetting;

// (() => {
// })
