/* eslint-disable nonblock-statement-body-position */
import React, { useEffect, useId } from 'react';
import './UserInfo.scss';
import { useFormContext } from 'react-hook-form';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Select from '@/components/Select/Select';
import { ControlledInput } from '@/hook-form/ControlledInput';
import { ControlledRadioBox } from '@/hook-form/ControlledRadioBox';
import { ControlledToggle } from '@/hook-form/ControlledToggle';
import { useMallStore } from '@/stores/common/useMallStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useReservationStore } from '@/stores/reservation/useReservationStore';

const UserInfo = (): React.ReactElement => {
  const { userInfo } = useReservationStore();
  const { mall } = useMallStore();
  const { setMenuModal } = useModalStore();

  const methods = useFormContext();
  const { control, getValues, setValue, register } = methods;

  useEffect(() => {
    setValue('userExtravalues', !userInfo.userExtravalues.length ? mall.userExtravalues : userInfo.userExtravalues);
  }, []);

  return (
    <div className="detail-content">
      <div className="font__body_sm_sb">고객 추가정보</div>
      <div className="detail-body">
        <div>
          <div className="font__body_6">고객 별칭</div>
          <ControlledInput name="userNickname" control={control} placeholder="별칭을 입력해주세요" />
        </div>
        <div>
          <div className="font__body_6">고객 메모</div>
          <ControlledInput name="userMemo" control={control} placeholder="메모를 입력해주세요" />
        </div>
        <div>
          <div className="font__body_6">고객 이메일</div>
          <ControlledInput name="userEmail" control={control} placeholder="이메일을 입력해주세요" />
        </div>
        {getValues('userExtravalues').map((item: any, i) => {
          return (
            <div key={item.id}>
              <div className="font__body_6">{item.name}</div>
              {item.type === 'string' || item.type === 'text' || item.type === 'textarea' || item.type === 'user' || item.type === 'longtext' ? (
                <ControlledInput name={`userExtravalues.${i}.value`} control={control} placeholder={`${item.name} 입력`} />
              ) : null}
              {item.type === 'toggle' ? <ControlledToggle name={`userExtravalues.${i}.value`} control={control} defaultChecked={Boolean(item.choices[0])} /> : null}
              {item.type === 'select' || item.type === 'multiselect' ? (
                <>
                  <Select
                    onClick={() => {
                      setMenuModal({ visible: true, key: item.id });
                    }}
                    value={getValues(`userExtravalues.${i}.value`)}
                  />
                  <ModalPortal>
                    <Modal.Menu key={item.id} modal="menu" isDim={true} isAnimation={true}>
                      <div className="user-info-menu-modal-wrapper">
                        <div className="title title font__subtitle_sb">{item.name}</div>

                        {item.type === 'multiselect' ? (
                          <div className="content custom-check-wrapper">
                            {item.choices.map((subItem, j) => (
                              <div className="custom-check-item" key={`${item}_${j}`}>
                                <label className="tblm-rc">
                                  <input type="checkbox" value={subItem} {...register(`userExtravalues.${i}.value`)} />
                                  <i />
                                  <span>{subItem}</span>
                                </label>
                              </div>
                            ))}
                          </div>
                        ) : null}

                        {item.type === 'select' ? (
                          <ControlledRadioBox
                            name={`userExtravalues.${i}.value`}
                            options={item.choices.map((subItem) => ({ label: subItem, value: subItem }))}
                            control={control}
                            defaultChecked={getValues(`userExtravalues.${i}.value`)}
                            onChange={() => setMenuModal({ visible: false, key: item.id })}
                          />
                        ) : null}
                      </div>
                      {item.type === 'multiselect' ? (
                        <div className="footer is-border">
                          <button className="tblm-button-normal tblm-btn-primary" onClick={() => setMenuModal({ visible: false, key: item.id })}>
                            확인
                          </button>
                        </div>
                      ) : null}
                    </Modal.Menu>
                  </ModalPortal>
                </>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserInfo;
