import '@/styles/pages/settings/editInformation.scss';

import * as yup from 'yup';

import { FormProvider, useForm } from 'react-hook-form';
import { IMutationProps, MallSetting, MallSettingInfoOpenTimeDate } from '@/shared/utils/common.type';
import React, { useEffect, useMemo, useState } from 'react';
import { confirmedFoods, initialFoods } from '@/shared/lib/controlInfo';
import { useMutation, useQuery } from 'react-query';

import { ControlledInput } from '@/hook-form/ControlledInput';
import Facilities from '@/components/Settings/Facilities';
import HeaderBack from '@/components/HeaderBack/HeaderBack';
import Input from '@/components/Input/Input';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import Select from '@/components/Select/Select';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import Terces from '@/shared/apis/terces';
import { autoHypenCompany } from '@/shared/lib/functions/auto-hypen-company';
import { autoHypenTel } from '@/shared/lib/functions/auto-hypen-tel';
import classnames from 'classnames';
import { devServerApi } from '@/shared/apis/devServerApi';
import styles from '@/components/ListFilter/ListFilter.module.scss';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { instantDisabler } from '@/shared/lib/functions';
import Modal from '@/components/Modal/Modal';
import { useModalStore } from '@/stores/common/useModalStore';
import FooterSub from '@/components/FooterSub/FooterSub';
import classNames from 'classnames';

// const mallSetting: {
//   [key: string]: any;
//   facility: { type_facility_id: number; name: string; checked?: boolean }[];
// } = {
//   controlInfo: {
//     facilities: initialFacilities,
//   },
//   mallName: '흥부부대찌개',
//   foods: [{ name: '한식' }],
//   eid: '1234-56789-00',
//   mainPhone: '02-1234-5678',
//   address: {
//     road: '서울특별시 성동구 성수이로20길 16 1층',
//   },
//   representative: '',
//   representativePhone: '',
//   facility: [
//     {
//       type_facility_id: 11,
//       name: '와이파이',
//       checked: true,
//     },
//     {
//       type_facility_id: 60,
//       name: '테라스',
//       checked: true,
//     },
//     {
//       type_facility_id: 3,
//       name: '놀이방',
//       checked: true,
//     },
//     {
//       type_facility_id: 12,
//       name: '반려견 동반 가능',
//       checked: true,
//     },
//   ],
//   mallDescription: '',
// };

function SelectFoodModal({ key, foodTypeList, defaultFood, setFoodType }) {
  const [selectedFood, setSelectedFood] = useState(defaultFood);
  const { setMenuModal } = useModalStore();

  const save = () => {
    setMenuModal({ visible: false, key });
    setFoodType(selectedFood);
  };
  return (
    <Modal.Menu title="음식종류" key={key} isClose isDim isAnimation modal="menu" contentClass={styles.list_filter} scrollObserve={styles.list__filter_content}>
      <div className={classNames('user-info-menu-modal-wrapper', styles.list_filter)}>
        <ul className={classnames(styles.list__filter_content, styles.list__filter_group_list)} style={{ marginBottom: 100 }}>
          {foodTypeList
            ?.filter((food) => confirmedFoods.includes(food.type_food_id))
            .map((food) => (
              <li key={food.type_food_id}>
                <label className="tblm-rc">
                  <input
                    type="radio"
                    name="foods"
                    value={food.type_food_id}
                    defaultChecked={defaultFood && defaultFood.type_food_id === food.type_food_id}
                    onChange={() => setSelectedFood(food)}
                  />
                  <i />
                  <span>{food.name}</span>
                </label>
              </li>
            ))}
        </ul>
        {/* <button className={styles.list__filter_apply} onClick={() => setMenuModal({ visible: false, key: 'select-food-kind' })}>
                완료
              </button> */}
      </div>
      <FooterSub isVisible>
        <button className="tblm-button-normal tblm-btn-primary w-full" onClick={save}>
          완료
        </button>
      </FooterSub>
    </Modal.Menu>
  );
}

function Edit() {
  const navigate = useNavigate();
  const { isLoading, data } = useQuery(
    [QUERY_KEYS.SETTINGS_MALL],
    () => devServerApi.api.authGet<MallSetting>('/settings/mall').then((enc_res) => Terces.decrypt(enc_res))
    // .then((mallSetting) => Object.assign({ facility: [] }, mallSetting))
  );
  const mallSetting: Partial<MallSetting> = isLoading ? {} : data;
  const [representative, setRepresentative] = useState(mallSetting.representative);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      eid: yup.string().test('checkEid', '올바른 사업자번호를 입력해주세요.', (value) => {
        if (!value.length) return true;
        return value.length === 12;
      }),
      representativePhone: yup.string().max(13, '올바른 연락처를 입력해주세요.'),
    });
  }, []);

  const methods = useForm({
    defaultValues: {
      representativePhone: mallSetting.representativePhone,
      eid: mallSetting.eid,
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const { handleSubmit, control, setValue } = methods;

  const timeMaker = (time: MallSettingInfoOpenTimeDate) => {
    return `${time.hour < 12 ? '오전' : '오후'} ${String(time.hour % 12).padStart(2, '0')}:${String(time.minute).padStart(2, '0')}`;
  };

  useEffect(() => {
    mallSetting.representative = representative;
  }, [representative]);

  // const [visibleModal, setVisibleModal] = useState(false);
  const { setMenuModal } = useModalStore();

  const foodTypeList = initialFoods || [];

  useEffect(() => {
    return () => setMenuModal(false);
  }, []);

  const setFoodType = (food) => {
    console.debug(food);
    mallSetting.foods = [food];
  };

  const setFacilitiesList = (facility) => {
    if (facility.checked) {
      if (!mallSetting.facility.some((item) => item.type_facility_id === facility.type_facility_id)) {
        mallSetting.facility = [...mallSetting.facility, { name: facility.name, type_facility_id: facility.type_facility_id }];
      }
    } else {
      if (mallSetting.facility.some((item) => item.type_facility_id === facility.type_facility_id)) {
        mallSetting.facility = mallSetting.facility.filter((item) => item.type_facility_id !== facility.type_facility_id);
      }
    }
  };

  const { mutate, isLoading: isMutateLoading } = useMutation((mutatedata: IMutationProps) => devServerApi.api.mutation(mutatedata), {
    onMutate: () => ({
      key: [QUERY_KEYS.SETTINGS_MALL],
    }),
    onSuccess: () => {
      navigate('/setting/essential');
    },
  });
  const onSave = async (form) => {
    await mutate({
      operation: 'patch',
      url: '/settings/mall',
      data: Terces.encrypt({
        ...mallSetting,
        eid: form.eid,
        representativePhone: form.representativePhone,
        foods: mallSetting.foods.map((e) => e.type_food_id),
        facility: mallSetting.facility.map((e) => e.type_facility_id),
      }),
    });
  };

  const setupFacilities = (() => {
    if (!mallSetting.facility || !mallSetting.controlInfo.facilities) return [];
    const checkedFacilityIds = mallSetting.facility.map((f) => f.type_facility_id);
    return mallSetting.controlInfo.facilities.map((f) => ({ checked: checkedFacilityIds.includes(f.type_facility_id), ...f }));
  })();

  if (isLoading) {
    return <SpinnerEffector loading={isLoading} />;
  }

  return (
    <FormProvider {...methods}>
      <HeaderBack isFixed inBack={true} isCenter>
        <div className="font__heading_4">기본정보</div>
      </HeaderBack>
      <div className="edit-information-wrapper">
        <section className="edit-information-body">
          <div>
            <div className="font__body_6">매장명</div>
            <div className="font__body_5">{mallSetting.mallName}</div>
          </div>
          <div>
            <div className="font__body_6">업종명</div>
            <div className="font__body_5">요식업</div>
          </div>
          <div>
            <div className="font__body_6">음식종류</div>
            <Select onClick={() => setMenuModal({ visible: true, key: 'select-food-kind' })} value={mallSetting.foods?.map(({ name }) => name).join(', ')} />
          </div>
          <div className="business-Number">
            <div className="font__body_6">사업자번호</div>
            <div className="input-wrapper font__body_5">
              <ControlledInput name="eid" control={control} onChange={(e) => setValue('eid', autoHypenCompany(e.target.value))} maxLength={12} />
            </div>
          </div>
          <div>
            <div className="font__body_6">매장 대표번호</div>
            <div className="font__body_5">{mallSetting.mainPhone?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3')}</div>
          </div>
          <div>
            <div className="font__body_6">매장 주소</div>
            <div className="font__body_5">{mallSetting.address?.road}</div>
          </div>
          <div>
            <div className="font__body_6">대표자 이름</div>
            <div className="font__body_5">
              <Input
                value={representative || ''}
                onChange={(e) => {
                  setRepresentative(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="business-Number">
            <div className="font__body_6">대표자 번호</div>
            <div className="input-wrapper font__body_5">
              <ControlledInput name="representativePhone" control={control} maxLength={13} onChange={(e) => setValue('representativePhone', autoHypenTel(e.target.value))} />
            </div>
          </div>
          {/*<div>
            <div className="font__body_6">영업시간</div>
            <div className="font__body_5">
              {mallSetting.infoOpenTime?.map((openTime, idx) => (
                <div key={openTime.type + openTime.week + idx}>
                  {openTime.week === '매일' ? '평일' : openTime.week}{' '}
                  {openTime.type === '휴무' ? (
                    '휴무'
                  ) : (
                    <>
                      {timeMaker(openTime.date_start)}~{timeMaker(openTime.date_end)}
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>*/}
          {/* <div>
            <div className="font__body_6">브레이크 타임</div>
            <div className="font__body_5">{mallSetting.breakTime || '없음'}</div>
          </div>
          <div>
            <div className="font__body_6">주차정보</div>
            <div className="font__body_5">{mallSetting.parkingInfo || '없음'}</div>
          </div> */}
          <div className="flex-col items-start">
            <div className="font__body_6 w-full">편의시설 정보</div>
            <Facilities edit={true} facilitiesList={setupFacilities} setFacilitiesList={setFacilitiesList} />
          </div>
          <div className="store-explanation">
            <div className="font__body_6">매장 설명</div>
            <textarea
              defaultValue={mallSetting.mallDescription}
              onChange={(e) => {
                mallSetting.mallDescription = e.target.value;
              }}
            />
          </div>
        </section>
        <footer>
          <button className="tblm-button-normal tblm-btn-primary" onClick={instantDisabler(handleSubmit((result) => onSave(result)))}>
            저장
          </button>
        </footer>
        <ModalPortal>
          <SelectFoodModal key="select-food-kind" defaultFood={mallSetting.foods[0]} foodTypeList={foodTypeList} setFoodType={setFoodType} />
        </ModalPortal>
      </div>
    </FormProvider>
  );
}

export default Edit;
